import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { MonthCalendar, PageLoading } from "components";
import dayjs from "dayjs";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import {
  FetchCalendarHolidayTargetMonth,
  FetchShiftTargetMonth,
  FixShift,
} from "server/collectionConnect";
import { CalendarDataWithRequest, HolidayCollection, User } from "types";
import { AscSort, ConvertTimeStampFromDate } from "utils";

export const ShiftManagementScreen =
  ({}: RootStackScreenProps<"ShiftManagement">) => {
    const { userInfo } = useContext(UserInfoContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { setConfirm, setAlert } = useContext(DialogContext);
    const {
      UserCollection,
      CalendarCollection,
      RequestCollection,
      HolidayCollection,
      onSnapShot,
    } = useContext(CollectionContext);
    const [visibleDate, setVisibleDate] = useState<dayjs.Dayjs>(dayjs());
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [allCalendars, setAllCalendars] = useState<CalendarDataWithRequest[]>(
      []
    );
    const [holidays, setHolidays] = useState<HolidayCollection[]>([]);
    const [editShift, setEditShift] = useState<CalendarDataWithRequest[]>([]);

    useEffect(() => {
      if (
        UserCollection === null ||
        CalendarCollection === null ||
        RequestCollection ||
        HolidayCollection
      ) {
        setIsLoading(true);
      }
      // シフト変更中は同期処理として行う
      if (editShift.length > 0) {
        setConfirm({
          isConfirm: true,
          msg: "カレンダーの変更があります。\n※更新を行った場合、変更が保存されません。",
          confTxt: "更新する",
          cancelTxt: "更新しない",
          afterExec: () => {},
        });
      }
      fetchShiftTargetMonth();
      fetchUserList();
      fetchHolidays();
      setIsLoading(false);
    }, [
      UserCollection,
      visibleDate,
      CalendarCollection,
      RequestCollection,
      HolidayCollection,
    ]);

    const fetchHolidays = () => {
      const targetDate = visibleDate.toDate();
      const result = FetchCalendarHolidayTargetMonth(
        HolidayCollection,
        targetDate
      );
      setHolidays(result);
    };

    const fetchUserList = () => {
      if (UserCollection === null) return;
      const users = UserCollection.sort((a, b) => AscSort(a.sort, b.sort)).map(
        user =>
          ({
            id: user.id,
            name: user.name,
            uid: user.uid,
            authorityId: user.authorityId,
            email: user.email,
            retirementAt: user.retirementAt,
          } as User)
      );
      const activeDutyUsers = users.filter((user: User) => !user.retirementAt);
      setAllUsers(activeDutyUsers);
    };

    const fetchShiftTargetMonth = () => {
      if (CalendarCollection === null || RequestCollection === null) return;
      const targetDate = visibleDate.toDate();
      const calendars = FetchShiftTargetMonth(
        CalendarCollection,
        RequestCollection,
        targetDate
      );
      setAllCalendars(calendars);
    };

    const dailyElementClick = (date: dayjs.Dayjs, user: User) => {
      if (
        holidays.some(s =>
          dayjs(s.targetAt?.toDate()).isBetween(
            dayjs(date.toDate()).startOf("day"),
            dayjs(date.toDate()).endOf("day"),
            null,
            "[]"
          )
        )
      )
        return;
      const monthBeginning = date.startOf("day").toDate();
      const monthEnd = dayjs(date).endOf("day").toDate();
      const targetShift = allCalendars
        .filter(f => f.userId === user.id)
        .filter(f =>
          dayjs(f.startDate?.toDate()).isBetween(
            monthBeginning,
            monthEnd,
            null,
            "[]"
          )
        )
        .shift();
      if (targetShift !== undefined) {
        // 削除の処理
        const editTargetShit = editShift
          .filter(f => f.userId === user.id)
          .filter(f =>
            dayjs(f.startDate?.toDate()).isBetween(
              monthBeginning,
              monthEnd,
              null,
              "[]"
            )
          )
          .shift();
        if (editTargetShit !== undefined) {
          // 新規追加されたタスクの削除
          setEditShift([
            ...editShift.filter(f => !CheckObject(f, targetShift)),
          ]);
          setAllCalendars([
            ...allCalendars.filter(f => !CheckObject(f, targetShift)),
          ]);
        } else {
          if (targetShift.isRequest === true) return;
          // 登録済みのタスクの削除
          setEditShift([...editShift, shiftTemplate(monthBeginning, user.id)]);
          setAllCalendars([
            ...allCalendars.filter(f => !CheckObject(f, targetShift)),
          ]);
        }
      } else {
        // 追加
        setEditShift([...editShift, shiftTemplate(monthBeginning, user.id)]);
        setAllCalendars([
          ...allCalendars,
          shiftTemplate(monthBeginning, user.id),
        ]);
      }
    };

    const handlePrev = () => {
      // シフトの変更が存在する時はアラートを表示
      if (editShift.length > 0) {
        setConfirm({
          isConfirm: true,
          msg: "変更中のシフトが存在しています。\n※月の切り替えを行った場合、変更が保存されません。",
          confTxt: "移動する",
          cancelTxt: "戻る",
          afterExec: () => {
            setIsLoading(true);
            setEditShift([]);
            setVisibleDate(visibleDate.subtract(1, "month"));
          },
        });
      } else {
        setIsLoading(true);
        setVisibleDate(visibleDate.subtract(1, "month"));
      }
    };

    const handleNext = () => {
      // シフトの変更が存在する時はアラートを表示
      if (editShift.length > 0) {
        setConfirm({
          isConfirm: true,
          msg: "変更中のシフトが存在しています。\n※月の切り替えを行った場合、変更が保存されません。",
          confTxt: "移動する",
          cancelTxt: "戻る",
          afterExec: () => {
            setIsLoading(true);
            setEditShift([]);
            setVisibleDate(visibleDate.add(1, "month"));
          },
        });
      } else {
        setIsLoading(true);
        setVisibleDate(visibleDate.add(1, "month"));
      }
    };

    const onSubmitClick = async () => {
      setIsLoading(true);
      const result = await FixShift(userInfo, editShift);
      if (result.isCheck) setAlert({ isAlert: true, msg: result.message });
      else setAlert({ isAlert: true, msg: result.message });

      setEditShift([]);
      setIsLoading(false);
    };
    if (isLoading) return <PageLoading isLoading={isLoading} />;

    return (
      <View style={styles.container}>
        <View style={styles.calendarHeader}>
          <Pressable
            style={[styles.button, styles.smallBtn]}
            onPress={() => handlePrev()}
          >
            <Text style={styles.buttonTxt}>{"< 前月"}</Text>
          </Pressable>
          <Text style={styles.month}>{visibleDate.format("YYYY年M月")}</Text>
          <Pressable
            style={[styles.button, styles.smallBtn]}
            onPress={() => handleNext()}
          >
            <Text style={styles.buttonTxt}>{"次月 >"}</Text>
          </Pressable>
        </View>
        <View style={{ alignItems: "flex-start" }}>
          <Pressable style={styles.button} onPress={() => onSubmitClick()}>
            <Text style={styles.buttonTxt}>保存</Text>
          </Pressable>
        </View>
        <MonthCalendar
          visibleDate={visibleDate}
          allUsers={allUsers}
          allCalendars={allCalendars}
          holidays={holidays}
          DailyUserClick={dailyElementClick}
        />
      </View>
    );
  };

/** シフト登録の初期値を登録します */
const shiftTemplate = (
  targetDate: Date,
  userId: string
): CalendarDataWithRequest => {
  return {
    uid: "",
    title: "休日",
    startDate: ConvertTimeStampFromDate(targetDate),
    endDate: ConvertTimeStampFromDate(targetDate),
    isHoliday: true,
    isAllDay: true,
    memo: "",
    userId: userId,
    isRequest: false,
  };
};

function CheckObject(a: CalendarDataWithRequest, b: CalendarDataWithRequest) {
  return JSON.stringify(objectSort(a)) === JSON.stringify(objectSort(b));
}
function objectSort(obj: CalendarDataWithRequest) {
  // ソートする
  const sorted = Object.entries(obj).sort();

  // valueを調べ、objectならsorted entriesに変換する
  for (let i in sorted) {
    const val = sorted[i][1];
    if (typeof val === "object") {
      sorted[i][1] = objectSort(val);
    }
  }

  return sorted;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    paddingTop: 32,
  },
  calendarHeader: {
    flexDirection: "row",
    marginBottom: 24,
  },
  month: {
    fontSize: 21,
    paddingHorizontal: 32,
    paddingTop: 4,
  },
  button: {
    padding: 16,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
  },
  smallBtn: {
    paddingVertical: 8,
    backgroundColor: "#4289cb",
  },
  buttonTxt: {
    fontWeight: "bold",
  },
});
