import { TableCell } from "components";
import React from "react";
import { DimensionValue, ScrollView, Text, View } from "react-native";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { PaidVacationAcquiredList, UserCollection } from "types";
import { ConvertTimeStampFromDate, GetUserName } from "utils";

const tableHead = ["名前", "取得予定日", "申請日", "承認日"];

export const WebAcquiredTable = ({
  onEditClick,
  tableList,
  UserCollection,
}: {
  onEditClick: (paidVacation: PaidVacationAcquiredList) => void;
  tableList: PaidVacationAcquiredList[];
  UserCollection: UserCollection[];
}) => {
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );

  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "500px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList, UserCollection)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

/**
 *
 * @param tableList テーブルの行をレンダリングします
 * @param onEditClick 編集ボタンを押した時のアクションを渡します
 * @param UserCollection ユーザー名を検索するための情報を渡します。
 * @returns
 */
const TableBody = (
  tableList: PaidVacationAcquiredList[],
  UserCollection: UserCollection[]
) => {
  const table = tableList.map(tableData => {
    var tempArr = [];
    var userName = GetUserName(UserCollection, tableData.userId ?? "");

    // const tableHead = ["名前", "取得予定日", "申請日", "承認日"];
    tempArr.push(<TableCell dataType="string" value={userName} />);
    // 日付
    tempArr.push(
      <TableCell
        dataType="date"
        value={ConvertTimeStampFromDate(tableData.acquisitionStartAt)}
      />
    );
    // 出勤時間
    tempArr.push(
      <TableCell
        dataType="date"
        value={
          tableData.requestAt
            ? ConvertTimeStampFromDate(tableData.requestAt)
            : null
        }
      />
    );
    // 退勤時間
    tempArr.push(
      <TableCell
        dataType="date"
        value={
          tableData.approveAt
            ? ConvertTimeStampFromDate(tableData.approveAt)
            : null
        }
      />
    );

    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return table.filter(m => m !== undefined);
};
