import { userAuthority } from "constants/index";
import firebase from "firebase/compat/app";
import { UserCollection } from "types";
/**
 * user Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const UserOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<UserCollection[] | null>>
) => {
  return firebase
    .firestore()
    .collection("user")
    .onSnapshot(
      async docs => {
        const collectionList = docs.docs.map(
          doc => ({ ...doc.data(), id: doc.id } as UserCollection)
        );
        const uidList = collectionList.map(m => m.uid);
        const authList = await fetchUserAuth(uidList);
        const result = collectionList.map(m => getUserAuthority(m, authList));
        setState(result);
      },
      (err: any) => console.log(err)
    );
};

const fetchUserAuth = async (uids: string[]) => {
  return await firebase
    .functions()
    .httpsCallable("fetchUserAuthList")({ userIds: uids })
    .then(res => {
      return res.data as {
        uid: string;
        authorityId: any;
        lastSignInTime: string;
      }[];
    })
    .catch(
      e =>
        [] as {
          uid: string;
          authorityId: any;
          lastSignInTime: string;
        }[]
    );
};
const getUserAuthority = (
  user: UserCollection,
  authList: {
    uid: string;
    authorityId: any;
    lastSignInTime: string;
  }[]
) => {
  const target = authList.filter(f => f.uid === user.uid).shift();
  if (target === undefined) "";
  user.authorityId = target?.authorityId ?? "";
  user.authorityTitle = userAuthority[target?.authorityId ?? ""]?.text;
  user.signedAt = target?.lastSignInTime ? target.lastSignInTime : "";
  return user;
};
