import dayjs from "dayjs";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import { NoteBookDeleteListType } from "types";

export function NoteBookList({
  noteBooksData,
}: {
  noteBooksData: NoteBookDeleteListType[];
}) {
  return (
    <>
      {noteBooksData.map(noteBooks => {
        return renderDataContents(noteBooks);
      })}
    </>
  );
}

const renderDataContents = (row: NoteBookDeleteListType): JSX.Element => {
  return (
    <View style={[styles.listContainer, { borderTopWidth: 1 }]}>
      <View style={styles.listItem}>
        <View>
          <FontAwesomeIcon name="book" size={25} />
        </View>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[styles.columnText, styles.contentsTextRight]}
        >
          {row?.bookName}
        </Text>
      </View>

      <View style={styles.listItem}>
        <Text style={[styles.columnText, styles.contentsTextLeft]}>
          最終更新日：
          {dayjs(row?.updateAt?.toDate()).format("YYYY/MM/DD")}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tableLayoutContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 40,
    marginRight: 40,
  },
  titleText: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 15,
    lineHeight: 30,
  },
  listContainer: {
    paddingTop: 10,
    width: "100%",
  },
  row: {
    flexDirection: "row",
    padding: 5,
  },
  columnText: {
    alignSelf: "center",
    textAlign: "center",
    lineHeight: 30,
    width: 100,
  },
  contentsTextRight: {
    textAlign: "right",
    width: "50%",
  },
  contentsTextLeft: {
    textAlign: "left",
    width: "100%",
  },
  listItem: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
});
