import { CreateLeftCol, CreateRow, MobileTableViewStatus } from "components";
import { scheduleType } from "constants/index";
import dayjs from "dayjs";
import React from "react";
import { ScrollView, Text, View } from "react-native";
import { mobileTableStyles } from "styles";
import { CalendarCollection } from "types";
import { CheckNullOrUndefined } from "utils";

/** モバイル用のカレンダーを返却します */
export const MobileCalendarTable = ({
  tableList,
  onEditClick,
}: {
  tableList: CalendarCollection[];
  onEditClick: (id: string) => void;
}) => {
  return (
    <ScrollView
      style={mobileTableStyles.scroll_view_style}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <View style={mobileTableStyles.list_line_view} />
        {tableList?.map((data, i) => (
          <View key={i} style={mobileTableStyles.list_line_view}>
            <View style={mobileTableStyles.contents_container}>
              <CreateRow>
                <CreateLeftCol>
                  <MobileTableViewStatus
                    buttonColor={
                      scheduleType[data.isHoliday ? "true" : "false"]?.color ??
                      ""
                    }
                    buttonText={
                      scheduleType[data.isHoliday ? "true" : "false"].text
                    }
                  />
                </CreateLeftCol>
                <CreateLeftCol>
                  <Text style={mobileTableStyles.base_text}>{data.title}</Text>
                </CreateLeftCol>
              </CreateRow>
              <CreateRow>
                <CreateLeftCol>
                  <View>
                    {data.isAllDay ? (
                      <Text style={mobileTableStyles.base_text}>終日</Text>
                    ) : (
                      <Text style={mobileTableStyles.base_text}>
                        {`${
                          CheckNullOrUndefined(data.startDate)
                            ? dayjs(data.startDate.toDate()).format(
                                "YYYY/MM/DD HH:mm"
                              )
                            : ""
                        }〜${
                          CheckNullOrUndefined(data.endDate)
                            ? dayjs(data.endDate.toDate()).format(
                                "YYYY/MM/DD HH:mm"
                              )
                            : ""
                        }`}
                      </Text>
                    )}
                  </View>
                </CreateLeftCol>
              </CreateRow>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};
