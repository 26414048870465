import firebase from "firebase/compat/app";
import { NoteBookCollection, NoteBookPage } from "types";

/**
 * noteBook Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const NoteBookOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<NoteBookCollection[] | null>>
) => {
  return firebase
    .firestore()
    .collection("noteBook")
    .onSnapshot(
      async docs => {
        const collectionList = await Promise.all(
          await docs.docs.map(async doc => {
            const noteBookPage = await GetNoteBookPage(doc);
            return {
              id: doc.id,
              notebookPage: noteBookPage,
              ...doc.data(),
            } as NoteBookCollection;
          })
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};

/**
 * requestコレクション内のHistoryを取得するメソットです
 * @param doc：firebase.collectionオブジェクト
 * @returns NoteBookPage
 */
const GetNoteBookPage = async (doc: firebase.firestore.DocumentSnapshot) => {
  return await doc.ref
    .collection("noteBookPage")
    .get()
    .then(details =>
      details.docs.map(
        detail => ({ ...detail.data(), id: detail.id } as NoteBookPage)
      )
    )
    .catch(() => [] as NoteBookPage[]);
};
