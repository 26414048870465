import { DialogContext } from "GlobalContext";
import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { ConfirmInfo } from "types";

/** OK・キャンセルの選択肢が存在するアラートを描写します */
export function Confirm({
  isConfirm,
  msg,
  confTxt,
  cancelTxt,
  afterExec,
  cancelAfterExec,
}: ConfirmInfo) {
  const { setConfirm } = useContext(DialogContext);

  const initConfirmContext = () => {
    setConfirm({
      isConfirm: false,
      msg: "",
      confTxt: "",
      cancelTxt: "",
      afterExec: () => {},
      cancelAfterExec: () => {},
    });
  };

  if (!isConfirm) return <></>;

  return (
    <AwesomeAlert
      show={isConfirm}
      message={msg}
      showConfirmButton={true}
      closeOnTouchOutside={false}
      closeOnHardwareBackPress={false}
      messageStyle={styles.alertText}
      onConfirmPressed={() => {
        afterExec && afterExec();
        initConfirmContext();
      }}
      confirmButtonStyle={styles.alertButton}
      confirmText={confTxt ? confTxt : "はい"}
      showCancelButton={true}
      onCancelPressed={() => {
        cancelAfterExec && cancelAfterExec();
        initConfirmContext();
      }}
      cancelButtonStyle={styles.alertButton}
      cancelText={cancelTxt ? cancelTxt : "いいえ"}
    />
  );
}

const styles = StyleSheet.create({
  alertText: {
    fontSize: 20,
    color: "black",
    alignItems: "center",
  },
  alertButton: {
    borderRadius: 4,
    backgroundColor: "#4169e1",
  },
});
