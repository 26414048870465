import React from "react";
import { Pressable, StyleSheet, Text, ViewStyle } from "react-native";

type Props = {
  /** ボタンのテキスト */
  buttonText: string;
  /** ボタンを押した時の処理 */
  onClick: () => void;
  /** 横幅の指定が必要な場合に使用します */
  widths?: string;
};

/**
 * 削除系のモーダルボタンを作成します
 * @param param0
 * @returns
 */
export const ModalDeleteButton = ({ buttonText, onClick, widths }: Props) => {
  const buttonWidth = widths as ViewStyle['width'] ?? "60%";
  return (
    <Pressable
      style={[styles.button, { width: buttonWidth }]}
      onPress={onClick}
    >
      <Text style={styles.buttonText}>{buttonText}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 32,
    paddingVertical: 16,
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "red",
  },

  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
});
