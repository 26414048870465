import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { DateForm, PageLoading, SelectForm } from "components";
import { requestDateList } from "constants/index";
import dayjs from "dayjs";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import firebase from "firebase/compat/app";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { FetchExpensesDownLoadList } from "server/collectionConnect";
import { IsMobile, IsWeb } from "utils";

const saveAs = IsMobile() ? null : require("file-saver").saveAs;

export function ExpensesDownloadScreen({
  navigation,
}: RootStackScreenProps<"ExpensesDownload">) {
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userInfo } = useContext(UserInfoContext);
  const [targetDateColumn, setTargetDateColumn] = useState<string>("");
  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(new Date());
  const {
    RequestCollection,
    UserCollection,
    SiteCollection,
    ExpenseCollection,
    ExpenseItemCollection,
    ExpenseLimitSettingCollection,
    AttendanceCollection,
    HolidayCollection,
    onSnapShot,
  } = useContext(CollectionContext);
  useEffect(() => {
    if (
      RequestCollection === null ||
      UserCollection === null ||
      SiteCollection === null ||
      AttendanceCollection === null ||
      ExpenseCollection === null ||
      ExpenseLimitSettingCollection === null ||
      ExpenseItemCollection === null ||
      HolidayCollection === null
    ) {
      onSnapShot("request");
      onSnapShot("user");
      onSnapShot("site");
      onSnapShot("attendance");
      onSnapShot("expense");
      onSnapShot("expenseLimit");
      onSnapShot("expenseItem");
      onSnapShot("holiday");
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }, [
    RequestCollection,
    UserCollection,
    SiteCollection,
    ExpenseCollection,
    ExpenseItemCollection,
    ExpenseLimitSettingCollection,
  ]);

  const onStartDateChangeHandler = (evt: Date): void => {
    setFrom(evt);
  };

  const onEndDateChangeHandler = (evt: any): void => {
    setTo(evt);
  };

  const download = async (): Promise<void> => {
    const start = dayjs(from).startOf("date").toDate();
    const end = dayjs(to).endOf("date").toDate();
    if (!targetDateColumn) {
      setAlert({ isAlert: true, msg: "対象日付を選択してください" });
      return;
    }
    if (start.valueOf() > end.valueOf()) {
      setAlert({
        isAlert: true,
        msg: "開始日が終了日より大きくなっています。",
      });
      return;
    }
    setIsLoading(true);
    try {
      const result = FetchExpensesDownLoadList(
        userInfo,
        UserCollection,
        ExpenseCollection,
        SiteCollection,
        start,
        end,
        targetDateColumn
      );
      await firebase
        .functions()
        .httpsCallable("targetExpensesExcelOutput")({
          user: userInfo?.user,
          targetMonth: start,
          data: result,
        })
        .then(async res => {
          const formatMonth = dayjs(start).format("YYYY-MM");
          const downloadUrl = await firebase
            .storage()
            .ref(`allExpense/${formatMonth}.xlsx`)
            .getDownloadURL();

          if (IsWeb()) {
            // WEBの場合は通常のDL
            const downloadFileName = `${formatMonth}.xlsx`;
            saveAs(downloadUrl, downloadFileName);
          } else {
            const downloadFileName = `${formatMonth}.xlsx`;
            const downloadPath = FileSystem.cacheDirectory + downloadFileName;
            const { uri: localUrl } = await FileSystem.downloadAsync(
              downloadUrl,
              downloadPath
            );
            // SPの場合はシェアリングでDLもしくは共有
            Sharing.shareAsync(localUrl, {
              mimeType: "application/octet-binary",
              dialogTitle: downloadFileName,
              UTI: "application/octet-binary",
            });
          }
        });
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={styles.container}>
        <View style={styles.formContainer}>
          <View style={styles.inputFormContainer}>
            <View style={styles.selectForm}>
              <SelectForm
                label="対象日付"
                placeholderLabel=""
                value={targetDateColumn}
                onChange={e => setTargetDateColumn(e)}
                items={requestDateList}
              />
            </View>

            <View style={styles.selectForm}>
              <DateForm
                value={from}
                onChange={onStartDateChangeHandler}
                disabled={false}
                label={"開始日時"}
                type={"date"}
              />
            </View>

            <View style={styles.selectForm}>
              <DateForm
                value={to}
                onChange={onEndDateChangeHandler}
                disabled={false}
                label={"終了日時"}
                type={"date"}
              />
            </View>
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <Pressable
            style={[styles.processButton, { backgroundColor: "#7879F1" }]}
            onPress={download}
          >
            <Text style={styles.buttonText}>ダウンロード</Text>
          </Pressable>
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 10,
  },
  formContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  inputFormLabelContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  inputFormContainer: {
    alignSelf: "stretch",
    flexDirection: "column",
    alignItems: "center",
  },
  titleLabel: {
    fontSize: 30,
    fontWeight: "bold",
    textAlign: "center",
  },
  inputFormLabel: {
    marginTop: 25,
    paddingRight: 15,
    padding: 5,
    fontSize: 20,
  },
  DateForm2: {
    height: 20,
    width: 225,
    padding: 10,
    marginLeft: -10,
    borderRadius: 5,
  },
  selectForm: {
    height: 40,
    width: 250,
    padding: 10,
    marginTop: 25,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    bottom: 30,
    padding: 30,
  },
  processButton: {
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 5,
    elevation: 3,
    width: 100,
    height: 50,
    margin: 20,
    alignSelf: "center",
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 50,
  },
});
