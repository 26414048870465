import { ExpoPushToken } from "expo-notifications";
import firebase from "firebase/compat/app";

export const UpdateDeviceToken = async (
  token: ExpoPushToken,
  userId: string,
  budge: number | null
) => {
  try {
    if (token == null) {
      return;
    }
    const matchResults = token.data.match(/ExponentPushToken\[(.*)\]/);
    if (matchResults === null) return;
    const actualToken = matchResults[1];
    if (!actualToken) return;
    // userから保存済みのデバイストークンを取得
    const userRef = firebase.firestore().collection("user").doc(userId);
    const userDoc = await userRef.get();
    const userInfo = userDoc.data();
    if (!userInfo) return;

    const currentTokens = userInfo.fcmTokens || [];
    // デバイストークンが未登録の場合、保存する
    if (!currentTokens[actualToken]) {
      // デバイストークンをキーに、値を1（バッジの初期値）で登録
      currentTokens[actualToken] = 1;
      await userRef.update({ fcmTokens: { ...currentTokens } });
    } else {
      if (budge != null) {
        // デバイストークンをキーに、バッジの値を更新
        currentTokens[actualToken] = budge;
        await userRef.update({ fcmTokens: { ...currentTokens } });
      }
    }
    return;
  } catch (error: any) {
    console.log("[Error] => ", error);
  }
};
