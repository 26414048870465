import firebase from "firebase/compat/app";
import { CreateCheckResult, SiteCollection, SiteData, UserInfo } from "types";

/**
 * 現場の新規作成の書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param site collectionContextから取得した現場情報
 * @param siteData 更新・登録対象の現場情報
 * @returns　CreateCheckResult
 */
export const CreateSite = async (
  reqUser: UserInfo | null,
  site: SiteCollection[] | null,
  siteData: SiteData
): Promise<CreateCheckResult> => {
  const date = new Date();

  return await firebase
    .firestore()
    .collection("site")
    .add({
      name: siteData.name,
      matterNumber: siteData.matterNumber,
      agreementNumber: siteData.agreementNumber,
      progressStatus: siteData.progressStatus,
      deleted: false,
      updateAt: date,
      updateUser: reqUser?.user.id,
    })
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
