import firebase from "firebase/compat/app";
import { CalendarData, CreateCheckResult, UserInfo } from "types";

/**
 * カレンダー更新の書き込みを行います
 * @param reqUser userContextから取得した情報
 * @param CalendarData 更新予定のカレンダーデータ
 * @returns
 */
export const UpdateCalendar = async (
  reqUser: UserInfo | null,
  CalendarData: CalendarData
): Promise<CreateCheckResult> => {
  const date = new Date();

  const siteRef = firebase
    .firestore()
    .collection("site")
    .doc(CalendarData.siteId);

  const doc = await firebase
    .firestore()
    .collection("calendar")
    .doc(CalendarData.uid)
    .get();

  return await doc.ref
    .update({
      ...doc.data(),
      title: CalendarData.title,
      startDate: CalendarData.startDate.toDate(),
      endDate: CalendarData.endDate.toDate(),
      isHoliday: CalendarData.isHoliday,
      memo: CalendarData.memo,
      userId: CalendarData.userId,
      isAllDay: CalendarData.isAllDay,
      siteRef: siteRef,
    })
    .then(() => {
      return { isCheck: true, message: "更新しました" };
    })
    .catch(() => {
      return { isCheck: false, message: "更新に失敗しました" };
    });
};
