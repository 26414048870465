import { ExpenseLimitSettingCollection, ExpenseLimitSettingData } from "types";

/**
 * 整形した経費枠情報を返却します
 * @param expenseLimitSettings collectionContextから取得した経費枠情報
 * @param targetId 対象Id
 * @returns ExpenseLimitSettingData
 */
export const FetchExpenseLimitSetting = (
  expenseLimitSettings: ExpenseLimitSettingCollection[] | null,
  targetId: string
): ExpenseLimitSettingData | null => {
  if (expenseLimitSettings === null) return null;

  const targetExpenseLimitSetting = expenseLimitSettings
    .filter(t => t.id === targetId)
    .shift();
  if (targetExpenseLimitSetting === undefined) return null;
  return {
    id: targetExpenseLimitSetting.id,
    userId: targetExpenseLimitSetting.userId ?? "",
    itemId: targetExpenseLimitSetting.itemId ?? "",
    amountLimit: targetExpenseLimitSetting.amountLimit ?? 0,
    grantDate: targetExpenseLimitSetting.grantDate,
    expirationDate: targetExpenseLimitSetting.expirationDate,
    deleted: targetExpenseLimitSetting.deleted,
  } as ExpenseLimitSettingData;
};
