import { CalendarForm, ModalCloseButton } from "components";
import React from "react";
import { View } from "react-native";
import { modalStyles } from "styles";
import { CalendarModalStatus } from "types";
import { ModalLibrary } from "utils";
type Props = {
  /** モーダルの情報を定義します */
  modalStatus: CalendarModalStatus;
  /** ボタンを閉じる時の挙動を定義します */
  onCloseModal: () => void;
};

/** モーダルの外枠を定義します */
export function CalendarModal({ modalStatus, onCloseModal }: Props) {
  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={modalStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />

          <CalendarForm hideModal={onCloseModal} modalStatus={modalStatus} />
        </View>
      </ModalLibrary>
    </>
  );
}
