import { DialogContext, UserInfoContext } from "GlobalContext";
import { HyperLink } from "components";
import { env } from "constants/index";
import Constants from "expo-constants";
import { StatusBar } from "expo-status-bar";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, TextInput, View } from "react-native";
import { auth } from "server/Firebase";
import { IsAndroid, IsIOS, RegistPushNotifications, errorLog } from "utils";

const versionNumber = Constants.expoConfig?.version;
const androidVersionNumber = Constants.expoConfig?.android?.versionCode;
const iosVersionNumber = Constants.expoConfig?.ios?.buildNumber;

export function SignInScreen({ navigation }: RootStackScreenProps<"SignIn">) {
  const { setAlert } = useContext(DialogContext);
  const { userInfo } = useContext(UserInfoContext);
  const [email, onChangeEmail] = useState<string>("");
  const [password, onChangePassword] = useState<string>("");

  const handleOnLogin = (email: string, password: string): void => {
    try {
      auth.signInWithEmailAndPassword(email, password).catch(e => {
        setAlert({ isAlert: true, msg: "ログインに失敗しました" });
        errorLog(e, "SignIn");
      });
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    }
  };

  useEffect(() => {
    if (userInfo !== null) navigation.navigate("Home");
  }, [userInfo]);

  useEffect(() => {
    // デバイストークンを登録
    async function registPushNotifications() {
      if (userInfo && userInfo.user)
        await RegistPushNotifications(userInfo.user.id);
    }
    registPushNotifications();
  }, [userInfo]);

  return (
    <>
      <View style={styles.container}>
        <TextInput
          style={styles.input}
          inputAccessoryViewID={"1"}
          onChangeText={onChangeEmail}
          value={email}
          placeholder="メールアドレス"
          textContentType="emailAddress"
        />
        <TextInput
          style={styles.input}
          inputAccessoryViewID={"1"}
          value={password}
          onChangeText={onChangePassword}
          placeholder="パスワード"
          textContentType="password"
          secureTextEntry={true}
        />
        <Pressable
          style={styles.button}
          onPress={() => {
            handleOnLogin(email, password);
          }}
        >
          <Text style={styles.buttonText}>ログイン</Text>
        </Pressable>

        <View style={styles.termsContent}>
          <HyperLink url={env.baseRuleUrl + "terms.html"} text="利用規約" />
          <HyperLink
            url={env.baseRuleUrl + "privacypolicy.html"}
            text="プライバシーポリシー"
          />
          <HyperLink url={env.baseRuleUrl + "credit.html"} text="データ提供" />
        </View>

        <View style={styles.versionContent}>
          <Text style={styles.versionContentText}>
            version: {versionNumber}
          </Text>
          {IsIOS() && (
            <Text style={styles.versionContentText}>
              iOS version: {iosVersionNumber}
            </Text>
          )}
          {IsAndroid() && (
            <Text style={styles.versionContentText}>
              Android version: {androidVersionNumber}
            </Text>
          )}
        </View>

        <StatusBar style="auto" />
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    height: 40,
    width: "85%",
    borderColor: "gray",
    borderWidth: 2,
    marginBottom: 16,
    borderRadius: 10,
    paddingLeft: 16,
  },
  button: {
    marginTop: 32,
    paddingVertical: 16,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  versionContent: {
    alignItems: "center",
    position: "absolute",
    bottom: 25,
  },
  versionContentText: {
    fontSize: 10,
    color: "gray",
  },
  termsContent: {
    alignItems: "center",
    position: "absolute",
    bottom: 60,
  },
});
