import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";

type Props = {
  /** クリック時の処理を定義します */
  onClick: () => void;
  /** タイトルを定義します */
  title: string;
};

/**
 * トップのページ遷移用のボタンを描写します
 * @param param0
 * @returns
 */
export const HomeButton = ({ onClick, title }: Props): JSX.Element => {
  return (
    <Pressable style={styles.button} onPress={onClick}>
      <Text style={styles.buttonText}>{title}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    width: "80%",
    marginTop: 32,
    paddingVertical: 24,
    borderRadius: 8,
    backgroundColor: "#4169e1",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
});
