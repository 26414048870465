import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  NoteBookCollection,
  NoteBookData,
  UserInfo,
} from "types";

/**
 * Wikiの更新の書き込みを行います
 * @param userInfo userContextから取得したユーザー情報
 * @param noteBooks collectionContextから取得したWiki情報
 * @param noteBookData 更新対象のWiki情報
 * @returns　CreateCheckResult
 */
export const UpdateNoteBook = async (
  userInfo: UserInfo | null,
  noteBooks: NoteBookCollection[] | null,
  noteBookData: NoteBookData
): Promise<CreateCheckResult> => {
  const date = new Date();
  if (noteBooks === null)
    return { isCheck: false, message: "更新に失敗しました" };

  const doc = await firebase
    .firestore()
    .collection("noteBook")
    .doc(noteBookData.id)
    .get();

  return await doc.ref
    .update({
      ...doc.data(),
      bookName: noteBookData.bookName,
      sort: noteBookData.sort,
      deleted: noteBookData.deleted,
      isManual: false,
      updateAt: date,
      updateUser: userInfo?.user.id,
    })
    .then(() => ({ isCheck: true, message: "更新しました" }))
    .catch(error => ({ isCheck: false, message: "更新に失敗しました" }));
};
