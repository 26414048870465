import { MobileRequestTable, WebRequestTable } from "components";
import React from "react";
import { View } from "react-native";
import { RequestList, UserCollection } from "types";
import { IsWeb } from "utils";

export const RequestTable = ({
  tableList,
  onEditClick,
  UserCollection,
}: {
  tableList: RequestList[] | null;
  onEditClick: (id: string) => void;
  UserCollection: UserCollection[];
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : IsWeb() ? (
        <WebRequestTable
          onEditClick={onEditClick}
          tableList={tableList}
          UserCollection={UserCollection}
        />
      ) : (
        <MobileRequestTable onEditClick={onEditClick} tableList={tableList} />
      )}
    </>
  );
};
