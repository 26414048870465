import { UserSchedule } from "components";
import dayjs from "dayjs";
import React from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { CalendarData, HolidayCollection, User } from "types";
import { GetIsCurrentDateHoliday } from "utils";

type Props = {
  visibleDate: dayjs.Dayjs;
  allUsers: User[];
  allCalendars: CalendarData[];
  holidays: HolidayCollection[];
  UserClick?: (user: User) => void;
  DailyUserClick?: (date: dayjs.Dayjs, user: User) => void;
  DailyClick?: (date: dayjs.Dayjs) => void;
};

/** モバイル用の週間のカレンダーを描写します */
export const WeeklyCalendar = ({
  visibleDate,
  allUsers,
  allCalendars,
  holidays,
  DailyClick,
  UserClick,
  DailyUserClick,
}: Props) => {
  const dayElements: JSX.Element[] = [];
  const endDate: dayjs.Dayjs = visibleDate.endOf("week");
  for (
    let startDate: dayjs.Dayjs = visibleDate.startOf("week");
    endDate.isSameOrAfter(startDate);
    startDate = startDate.add(1, "day")
  ) {
    const currentDate = startDate;
    const dayOfWeek = currentDate.day();
    let dayOfWeekColor: string = "black";
    switch (dayOfWeek) {
      case 0:
        dayOfWeekColor = "red";
        break;
      case 6:
        dayOfWeekColor = "blue";
        break;
      default:
        break;
    }
    const holiday = GetIsCurrentDateHoliday(holidays, currentDate.toDate());
    if (holiday) dayOfWeekColor = "red";

    dayElements.push(
      <View key={Math.random()} style={styles.flexItem}>
        <View style={styles.flexItemContent}>
          <Pressable onPress={() => DailyClick && DailyClick(currentDate)}>
            <Text style={[styles.flexItemText, { color: dayOfWeekColor }]}>
              {currentDate.format("D(dd)")}
            </Text>
          </Pressable>
        </View>
      </View>
    );
  }

  return (
    <View style={[styles.container]}>
      <View style={styles.flex}>
        <View style={[styles.flexItem, { width: "16%" }]}>
          <View style={styles.flexItemContent}>
            <Text style={styles.flexItemText}>社員名</Text>
          </View>
        </View>
        {dayElements}
      </View>

      <ScrollView>
        <View style={{ flex: 8, paddingBottom: 100 }}>
          {allUsers.map((user: User, i) => (
            <UserSchedule
              key={i}
              visibleDate={visibleDate}
              user={user}
              allCalendars={allCalendars}
              holidays={holidays}
              UserClick={UserClick}
              DailyUserClick={DailyUserClick}
            />
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: 32,
    paddingHorizontal: 12,
    marginBottom: 60,
  },
  flex: {
    flexDirection: "row",
  },
  flexItem: {
    width: "12%",
    height: 32,
    borderWidth: 1,
    borderColor: "gray",
  },
  flexItemContent: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  flexItemText: {
    fontWeight: "bold",
    fontSize: 12,
  },
});
