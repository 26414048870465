import { DimensionValue, StyleSheet } from "react-native";
import { IsWeb } from "utils";

export const pageStyle = StyleSheet.create({
  /** ページ全体を覆うElementに定義するCSSです。 */
  basic_container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  /** ページ全体を覆うElementに定義するCSSです。 */
  container: {
    backgroundColor: "#fff",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    width: "100%",
  },
  /** スクロールビューを覆うElementに定義するCSSです。
   *
   * 追加ボタンがある前提の高さの設定を行います
   */
  contents_area: {
    height: IsWeb() ? "700px" as DimensionValue : 750,
  },
  /** スクロールビューに定義するCSSです */
  scroll_container: {
    flex: 1,
    padding: IsWeb() ? 50 : 0,
  },
  /** ページのセンター寄せタイトルを覆うElementに定義するCSSです */
  page_title_container: {
    flexDirection: "row",
    alignItems: "center",
  },
  /** タイトルにつける文字サイズの定義です */
  header_text: {
    fontSize: 26,
  },
  /** 文字を太文字にします */
  text_bold: {
    fontWeight: "bold",
  },
  /** 左右中央寄せのElementに定義するCSSです */
  row_center_contents: {
    flexDirection: "row",
  },
});
