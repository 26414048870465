import { SelectForm } from "components";
import dayjs from "dayjs";
import React from "react";
import { Button, StyleSheet, Text, View } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { IsAndroid, IsWeb } from "utils";

type Props = {
  /** 対象日付を指定します */
  value: Date;
  /** 日付変更の処理を定義します */
  onChange?: (evt: Date) => void;
  /** 操作できるかどうかを定義します
   *
   * 操作不可：true
   */
  disabled: boolean;
  /** 日付に表示されるテキストを指定します */
  label: string;
  /** 時間に表示されるテキストを指定します */
  hourLabel?: string;
  /** 分に表示されるテキストを指定します */
  minuteLabel?: string;
  /** 入力形式を指定します
   *
   * date: YYYY/MM/DD
   *
   * datetime: YYYY/MM/DD hh:mm:ss
   */
  type: "date" | "datetime" | "month";
};

/**
 * 日付の入力フォームを作成します
 * @param param0
 * @returns
 */
export const DateForm = ({
  value,
  onChange,
  disabled,
  label,
  type,
  hourLabel,
  minuteLabel,
}: Props) => {
  const [isVisibilityDate, setIsVisibilityDate] =
    React.useState<boolean>(false);

  return (
    <View style={styles.container}>
      {IsWeb() ? (
        <>
          <Text style={styles.label}>{label}</Text>
          <input
            style={{
              fontSize: 16,
              color: "black",
              backgroundColor: disabled ? "darkgray" : "white",
              borderWidth: 1,
              borderColor: "gray",
              borderRadius: 4,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 3,
              paddingBottom: 3,
            }}
            type={type === "datetime" ? "date" : type}
            value={
              type === "month"
                ? dayjs(value).ceil("minute", 15).format("YYYY-MM")
                : dayjs(value).ceil("minute", 15).format("YYYY-MM-DD")
            }
            onChange={(e: any) =>
              onChange && onChange(new Date(e.currentTarget.value))
            }
            disabled={disabled}
          />
          {type === "datetime" && (
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <SelectForm
                  label={hourLabel ?? "時間"}
                  placeholderLabel=""
                  items={[...Array(24)].map((_, i) => ({
                    label: i.toString(),
                    value: i,
                  }))}
                  value={dayjs(value).ceil("minute", 15).get("h")}
                  onChange={(e: any) => {
                    const targetDate = dayjs(
                      dayjs(value).ceil("minute", 15)
                    ).toDate();
                    return (
                      onChange &&
                      onChange(dayjs(targetDate).hour(parseInt(e)).toDate())
                    );
                  }}
                  disabled={disabled}
                />
              </div>
              <div style={{ width: "50%" }}>
                <SelectForm
                  label={minuteLabel ?? "分"}
                  placeholderLabel=""
                  items={[
                    { label: "00", value: 0 },
                    { label: "15", value: 15 },
                    { label: "30", value: 30 },
                    { label: "45", value: 45 },
                  ]}
                  value={dayjs(value).ceil("minute", 15).get("m")}
                  onChange={(e: number) => {
                    const targetDate = dayjs(
                      dayjs(value).ceil("minute", 15)
                    ).toDate();

                    return (
                      onChange &&
                      onChange(dayjs(targetDate).minute(e).toDate())
                    );
                  }}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        // appの日付フォーム
        <>
          <Text style={styles.label}>{`${label}：${
            type === "date"
              ? dayjs(value).ceil("minute", 15).format("YY年MM月DD日")
              : dayjs(value).ceil("minute", 15).format("YY年MM月DD日 HH時mm分")
          }`}</Text>

          <View style={styles.dateTimeButton}>
            <Button
              title={label}
              onPress={() => setIsVisibilityDate(true)}
              color={IsAndroid() ? "steelblue" : "white"}
              disabled={disabled}
            />
          </View>
          <DateTimePickerModal
            isVisible={isVisibilityDate}
            mode={type === "month" ? "date" : type}
            onConfirm={e => {
              onChange && onChange(e);
              setIsVisibilityDate(false);
            }}
            onCancel={() => setIsVisibilityDate(false)}
            confirmTextIOS="完了"
            cancelTextIOS="キャンセル"
            locale="ja-JA"
            minuteInterval={15}
            date={value}
          />
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  label: {
    fontSize: IsWeb() ? 20 : 16,
  },
  dateTimeButton: {
    marginVertical: 8,
    backgroundColor: "steelblue",
    borderRadius: 8,
    marginBottom: 24,
  },
});
