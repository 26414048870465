import { TableCell } from "components";
import { requestType } from "constants/index";
import React from "react";
import { DimensionValue, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { RequestList, UserCollection } from "types";
import { GetUserName } from "utils";
const tableHead = [
  "",
  "申請状況",
  "種別",
  "申請日",
  "申請者",
  "取得予定日",
  "承認日",
  "否決日",
  "更新者",
];

export const WebRequestApprovalWaitTable = ({
  onEditClick,
  tableList,
  UserCollection,
}: {
  onEditClick: (id: string) => void;
  tableList: RequestList[];
  UserCollection: UserCollection[];
}) => {
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );

  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "500px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList, onEditClick, UserCollection)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

/**
 *
 * @param requests テーブルの行をレンダリングします
 * @param onEditClick 編集ボタンを押した時のアクションを渡します
 * @param UserCollection ユーザー名を検索するための情報を渡します。
 * @returns
 */
const TableBody = (
  tableList: RequestList[],
  onEditClick: (id: string) => void,
  UserCollection: UserCollection[]
) => {
  const table = tableList.map(tableData => {
    var tempArr = [];
    var createUserName = GetUserName(
      UserCollection,
      tableData.createUser ?? ""
    );
    var userName = GetUserName(UserCollection, tableData.updateUser ?? "");
    if (userName === null) return;
    tempArr.push(
      <TableCell
        dataType="button"
        buttonText="編集"
        onEditClick={() => onEditClick(tableData.id)}
      />
    );
    // 進捗状況の表示
    tempArr.push(
      <TableCell dataType="requestStatus" value={tableData.status} />
    );
    // 種別
    tempArr.push(
      <TableCell
        dataType="string"
        value={requestType[tableData.requestType ?? ""]?.text ?? ""}
      />
    );
    // 申請日
    tempArr.push(<TableCell dataType="date" value={tableData.requestAt} />);
    // 申請者
    tempArr.push(<TableCell dataType="string" value={createUserName} />);
    // 取得予定日
    tempArr.push(
      <TableCell dataType="date" value={tableData.acquisitionStartAt} />
    );
    // 承認日
    tempArr.push(<TableCell dataType="date" value={tableData.approveAt} />);
    // 否決日
    tempArr.push(<TableCell dataType="date" value={tableData.rejectAt} />);
    // 更新者
    tempArr.push(<TableCell dataType="string" value={userName} />);
    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return table.filter(m => m !== undefined);
};
