import Constants from "expo-constants";
import { Item } from "react-native-picker-select";
import {
  AuthorityType,
  PaymentStatusType,
  RequestStatusType,
  ScheduleType,
  SiteType,
  SwitchButtonListType,
  UserAuthorityType,
} from "types";

/**
 * request.requestTypeから種類名を取得する
 */
export const requestType: { [index: string]: RequestStatusType } = {
  overtime: {
    text: "残業",
  },
  paidVacation: {
    text: "有給休暇",
  },
  leaveEarly: {
    text: "早退",
  },
  absence: {
    text: "欠勤",
  },
  lateness: {
    text: "遅刻",
  },
  privateOuting: {
    text: "私用外出",
  },
  substituteAttendance: {
    text: "振替出勤",
  },
  substituteHoliday: {
    text: "振替休日",
  },
  attendanceCorrection: {
    text: "勤怠修正",
  },
  staggeredWorkHours: {
    text: "時差出勤",
  },
  holidayWork: {
    text: "休日出勤",
  },
};

/**
 * request.Statusからステータス名とボタンのカラーを取得する
 */
export const requestStatusType: { [index: string]: RequestStatusType } = {
  applying: {
    text: "申請中",
    color: "#5D5FEF",
  },
  withdrawal: {
    text: "取下げ",
    color: "#8b4513",
  },
  approval: {
    text: "承認済",
    color: "#04C500",
  },
  reject: {
    text: "否決",
    color: "#CA1800",
  },
  paid: {
    text: "支払済",
    color: "#F178B6",
  },
  received: {
    text: "受領済",
    color: "#C0C0C0",
  },
};

/** payment.statusからステータス名とボタンのカラーを取得する */
export const paymentStatusType: { [index: string]: PaymentStatusType } = {
  unpaid: {
    text: "未払",
    color: "#9370db",
  },
  full: {
    text: "全額支払",
    color: "#C0C0C0",
  },
  partial: {
    text: "一部支払",
    color: "#b8860b",
  },
};

/**
 * calendar.typeから名称と色を取得する
 */
export const scheduleType: { [index: string]: ScheduleType } = {
  true: {
    text: "休み",
    color: "#FF7F50",
  },
  false: {
    text: "予定",
    color: "#5BB0E9",
  },
};

/** 権限名から色を取得する */
export const authorityType: { [index: string]: AuthorityType } = {
  社長: {
    color: "#CA1800",
  },
  経理: {
    color: "#FF69B4",
  },
  部長: {
    color: "#5D5FEF",
  },
  社員: {
    color: "#04C500",
  },
  アルバイト: {
    color: "#8b4513",
  },
};

/** site.statusから名称と色を取得する */
export const progressStatusType: { [index: string]: SiteType } = {
  inProgress: {
    text: "進行中",
    color: "#5D5FEF",
  },
  completed: {
    text: "完工済",
    color: "#CA1800",
  },
  cancel: {
    text: "キャンセル",
    color: "#c0c0c0",
  },
};

/** アルバイト */
export const PART_TYPE = "PART_TIME";
/** 社員 */
export const EMPLOYEE = "EMPLOYEE";
/** 経理 */
export const ACCOUNTING = "ACCOUNTING";
/** 部長 */
export const HEAD = "HEAD";
/** 社長 */
export const PRESIDENT = "PRESIDENT";

/** 権限IDから名称・並びを取得する */
export const userAuthority: { [index: string]: UserAuthorityType } = {
  PART_TIME: {
    id: PART_TYPE,
    text: "アルバイト",
    sort: 5,
  },
  EMPLOYEE: {
    id: EMPLOYEE,
    text: "社員",
    sort: 4,
  },
  ACCOUNTING: {
    id: ACCOUNTING,
    text: "経理",
    sort: 3,
  },
  HEAD: {
    id: HEAD,
    text: "部長",
    sort: 2,
  },
  PRESIDENT: {
    id: PRESIDENT,
    text: "社長",
    sort: 1,
  },
};

const ENVs = {
  dev: {
    // 開発環境の変数
    environment: "dev",
    baseRuleUrl: "https://kakeru.web.app/",
  },
  staging: {
    // ステージング環境の変数
    environment: "staging",
    baseRuleUrl: "https://rules-site.web.app/",
  },
  production: {
    // 本番環境の変数
    environment: "production",
    baseRuleUrl: "https://kakeru.web.app/",
  },
};

function getEnvVars() {
  const options = Constants.ExpoGoPackagerOpts?.packagerOpts;
  const channel = Constants.ExpoClientConfig?.releaseChannel;
  const isDev = options != null ? options.dev : true;
  if (isDev) {
    return ENVs.dev;
  } else {
    // FIXME release channel について、現状 default が、production になっている
    if (channel === "default") {
      return ENVs.production;
    } else {
      return ENVs.staging;
    }
  }
}

export const env = getEnvVars();

/** 申請種類のプルダウンリストです */
export const requestTypeList = [
  { label: "勤怠修正申請", value: "attendanceCorrection" },
  { label: "有給申請", value: "paidVacation" },
  { label: "残業申請", value: "overtime" },
  { label: "早退申請", value: "leaveEarly" },
  { label: "欠勤申請", value: "absence" },
  { label: "遅刻申請", value: "lateness" },
  { label: "私用外出申請", value: "privateOuting" },
  { label: "振替出勤申請", value: "substituteAttendance" },
  { label: "振替休日申請", value: "substituteHoliday" },
  { label: "時差出勤申請", value: "staggeredWorkHours" },
  { label: "休日出勤申請", value: "holidayWork" },
] as Item[];

/** 現場進捗のプルダウンリストです */
export const siteStatusList = [
  { label: "進行中", value: "inProgress" },
  { label: "完工済み", value: "completed" },
  { label: "キャンセル", value: "cancel" },
] as Item[];

/** 申請系の日付のプルダウンリストです */
export const requestDateList = [
  { label: "申請日", value: "requestAt" },
  { label: "利用日", value: "reckoningAt" },
  { label: "承認日", value: "approveAt" },
  { label: "否決日", value: "rejectAt" },
  { label: "支払日", value: "paymentAt" },
  // { label: "受領日", value: "receivedAt" },
  { label: "取下日", value: "withdrawalAt" },
] as Item[];

/** 性別のプルダウンリストです */
export const genderList = [
  { label: "男", value: "男" },
  { label: "女", value: "女" },
] as Item[];

/** 有給保持期間のプルダウンリストです */
export const expirationList = [
  { label: "2年間", value: 2 },
  { label: "3年間", value: 3 },
  { label: "4年間", value: 4 },
] as Item[];

/** 経費申請種類のプルダウンリストです */
export const expenseRequestTypeList = [
  { label: "経費申請", value: "expenseRequest" },
] as Item[];

/** ダッシュボードのボタン切り替えリストです */
export const DashboardSwitchButtonList = [
  {
    key: "calendar",
    label: "カレンダー",
  },
  {
    key: "request",
    label: "勤怠申請",
  },
  {
    key: "expense",
    label: "経費申請",
  },
] as SwitchButtonListType[];

/** 有給種類のプルダウンリストです */
export const paidVacationTypeList = [
  { label: "全休", value: "allDay" },
  { label: "午前", value: "morning" },
  { label: "午後", value: "afternoon" },
] as Item[];
