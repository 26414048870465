import { Loading } from "components";
import { StatusBar } from "expo-status-bar";
import React from "react";
import { View } from "react-native";

type Props = {
  /** ローディングステータスを定義します */
  isLoading: boolean;
  text?: string;
};
/**
 * ページでのローディング画面の描写に使用します
 * @param param0
 * @returns
 */
export const PageLoading = ({ isLoading, text }: Props) => {
  return (
    <View style={{ flex: 1 }}>
      <StatusBar style="auto" />
      <Loading isLoading={isLoading} text={text} />
    </View>
  );
};
