import { NoteBookCollection, NoteBookData } from "types";

/**
 * 指定したWikiページ情報を返却します
 * @param noteBook collectionContextから取得したWikiページ情報
 * @param targetId 指定のWikiページID
 * @returns NoteBookData
 */
export const FetchNoteBook = (
  NoteBookCollection: NoteBookCollection[] | null,
  targetId: string
): NoteBookData | null => {
  if (NoteBookCollection === null) return null;

  const target = NoteBookCollection.filter(f => f.id === targetId).shift();
  if (target === undefined) return null;

  return {
    id: target.id,
    bookName: target.bookName,
    sort: target.sort,
    deleted: target.deleted,
  } as NoteBookData;
};
