import { CollectionContext, DialogContext } from "GlobalContext";
import { ExpenseLimitSettingForm, ModalCloseButton } from "components";
import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Item } from "react-native-picker-select";
import { modalStyles } from "styles";
import { ExpenseLimitSettingModalStatus } from "types";
import { ModalLibrary } from "utils";
type Props = {
  /** モーダルの情報を定義します */
  modalStatus: ExpenseLimitSettingModalStatus;
  /** ボタンを閉じる時の挙動を定義します */
  onCloseModal: () => void;
};

/** モーダルの外枠を定義します */
export function ExpenseLimitSettingModal({ modalStatus, onCloseModal }: Props) {
  const { setAlert } = useContext(DialogContext);
  const { UserCollection, ExpenseItemCollection } =
    useContext(CollectionContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [expenseItemSelect, setExpenseItemSelect] = useState<Item[]>([
    { key: "", label: "", value: "" },
  ]);
  const [userSelect, setUserSelect] = useState<Item[]>([
    { key: "", label: "", value: "" },
  ]);

  const hideModal = () => {
    setModalVisible(!modalVisible);
  };

  const fetchExpenseItems = () => {
    if (ExpenseItemCollection !== null) {
      const items = ExpenseItemCollection.filter(f => !f.isNoExpenseLimit).map(
        m =>
          ({
            key: m.id,
            label: m.name,
            value: m.id,
          } as Item)
      );
      setExpenseItemSelect(items);
    }
  };

  const fetchUsers = () => {
    if (UserCollection !== null) {
      const users = UserCollection.filter(f => !f.retirementAt).map(
        m =>
          ({
            label: m.name,
            value: m.id,
          } as Item)
      );
      setUserSelect(users);
    }
  };

  useEffect(() => {
    fetchExpenseItems();
    fetchUsers();
  }, [UserCollection, ExpenseItemCollection]);

  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={modalStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />

          <ExpenseLimitSettingForm
            hideModal={onCloseModal}
            modalStatus={modalStatus}
            expenseItemSelect={expenseItemSelect}
            userSelect={userSelect}
          />
        </View>
      </ModalLibrary>
    </>
  );
}
