import { requestStatusType } from "constants/index";
import React from "react";
import { StyleSheet, Text, View } from "react-native";

type Props = {
  /** 申請テーブルのstatusを定義します */
  status: string;
};
/**
 * 申請系統のステータステキストを作成します
 * @param param0
 * @returns
 */
export const MobileTableRequestStatusText = ({ status }: Props) => {
  return (
    <View>
      <Text style={[styles.text, { color: requestStatusType[status].color }]}>
        {`進捗状況：${requestStatusType[status].text}`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
  },
});
