import { ExpenseCollection } from "types";
import { AscSort, DescSort } from "utils";

export const GetUseAmount = (
  /** 経費申請情報 */
  expenses: ExpenseCollection[],
  /** expenseLimitSetting.documentId */
  id: string,
  /** 使用するユーザーのID */
  userId: string
) => {
  /** 自分の経費申請で、承認済以降のステータス且つ使用する経費枠が指定のものを取得 */
  const targets = expenses
    .filter(f => f.userId === userId)
    .filter(f => f.expenseLimitSettingId === id)
    .filter(
      f =>
        f.status === "approval" ||
        f.status === "paid" ||
        f.status === "received"
    );

  const dateTargets = expenses
    .filter(f => f.userId === userId)
    .filter(f => f.expenseLimitSettingId === id)
    .filter(f => f.status !== "withdrawal" && f.status !== "reject");
  return {
    amount: sumAmount(targets),
    firstDate: GetFirstDate(dateTargets),
    lastDate: GetLastDate(dateTargets),
  };
};

/**
 * 経費の合計を算出
 * @param expenses 経費申請情報
 * @returns
 */
const sumAmount = (/** 経費申請情報 */ expenses: ExpenseCollection[]) => {
  let sum = 0;
  for (let i = 0, len = expenses.length; i < len; i++) {
    sum += expenses[i].totalAmount ?? 0;
  }
  return sum;
};

/**
 * 利用日の中で一番古いデータを取得
 * @param targets 承認済み以降のステータスの経費申請データ
 * @returns
 */
const GetFirstDate = (targets: ExpenseCollection[]) => {
  const target = targets.sort((a, b) =>
    AscSort(a.reckoningAt?.toDate(), b.reckoningAt?.toDate())
  );
  if (target.length === 0 || target[0].reckoningAt === null) return null;
  return target[0].reckoningAt?.toDate();
};

/**
 * 利用日の中で一番新しいデータを取得
 * @param targets 承認済み以降のステータスの経費申請データ
 * @returns
 */
const GetLastDate = (targets: ExpenseCollection[]) => {
  const target = targets.sort((a, b) =>
    DescSort(a.reckoningAt?.toDate(), b.reckoningAt?.toDate())
  );
  if (target.length === 0 || target[0].reckoningAt === null) return null;
  return target[0].reckoningAt.toDate();
};
