import { Alert, Confirm } from "components";
import { DialogContext } from "GlobalContext";
import React from "react";

export const Dialog = () => (
  <DialogContext.Consumer>
    {context => (
      <>
        <Alert
          isAlert={context.alert.isAlert}
          msg={context.alert.msg}
          confTxt={context.alert.confTxt ? context.alert.confTxt : ""}
          afterExec={context.alert.afterExec}
        />
        <Confirm
          isConfirm={context.confirm.isConfirm}
          msg={context.confirm.msg}
          confTxt={context.confirm.confTxt ? context.confirm.confTxt : ""}
          cancelTxt={context.confirm.cancelTxt ? context.confirm.cancelTxt : ""}
          afterExec={
            context.confirm.afterExec ? context.confirm.afterExec : () => {}
          }
          cancelAfterExec={context.confirm.cancelAfterExec}
        />
      </>
    )}
  </DialogContext.Consumer>
);
