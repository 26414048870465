import { ModalCloseButton, SiteForm } from "components";
import React from "react";
import { View } from "react-native";
import { modalStyles } from "styles";
import { SiteModalStatus } from "types";
import { ModalLibrary } from "utils";

type Props = {
  modalStatus: SiteModalStatus;
  onCloseModal: () => void;
};

export function SiteModal({ modalStatus, onCloseModal }: Props) {
  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={modalStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />

          <SiteForm hideModal={onCloseModal} modalStatus={modalStatus} />
        </View>
      </ModalLibrary>
    </>
  );
}
