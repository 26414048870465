import { DialogContext, UserInfoContext } from "GlobalContext";
import {
  ImageDropArea,
  ImageUpload,
  ModalApprovalButton,
  ModalCancelButton,
  SelectForm,
} from "components";
import React, { useContext } from "react";
import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { Item } from "react-native-picker-select";
import { ImageDataSources, NoteBookPageData, PageStatus } from "types";
import { IsAndroid, IsMobile, IsWeb } from "utils/CheckPlatform";
import { isAccounting, isPresident } from "utils/authority";
type Props = {
  /** ページのデータ */
  data: NoteBookPageData;
  /** ページ状況のステータスを定義します */
  status: PageStatus;
  /** 画像データ */
  images: ImageDataSources;
  /** ノートブックのプルダウンのリストを定義します */
  notebooks: Item[];
  /** 編集・登録ボタンクリック時の動作 */
  onSubmit: () => void;
  /** キャンセルボタンをクリックした時の動作 */
  onCancel: () => void;
  /** ページのデータを更新する関数を定義します */
  setState: React.Dispatch<React.SetStateAction<NoteBookPageData | null>>;
  /** 画像をアップロードする時の処理を定義します */
  updateImgDataSources: (
    images: React.SetStateAction<ImageDataSources>
  ) => void;
  /** 画像アップロード時に本文に文章を挿入する動作を定義します */
  insertImageString: (words: string[]) => void;
  /** ドラッグ&ドロップで画像を入れた時の動作を定義します */
  handleDrop: (e: any) => Promise<void>;
};

/** Wikiのページ情報を表示します */
export const EditPage = ({
  data,
  notebooks,
  images,
  onSubmit,
  onCancel,
  setState,
  updateImgDataSources,
  insertImageString,
  handleDrop,
}: Props) => {
  const { setAlert } = useContext(DialogContext);
  const { userInfo } = useContext(UserInfoContext);

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, backgroundColor: "red" }}
      >
        <View style={styles.container}>
          <View style={styles.formWrap}>
            <SelectForm
              label="ノートブック"
              placeholderLabel="選択してください"
              value={data.noteBookId ?? ""}
              onChange={(e, index) => {
                setState({
                  ...data,
                  noteBookId: e,
                  bookName: notebooks[index - 1]?.label,
                });
              }}
              items={notebooks}
            />
            <View style={styles.formItem}>
              <Text style={styles.label}>ページ順序</Text>
              <View style={{ width: "50%" }}>
                <TextInput
                  keyboardType="number-pad"
                  inputAccessoryViewID={"1"}
                  value={`${data.sort}`}
                  onChangeText={e => {
                    const input = Number(e.replace(/,/g, ""));
                    if (isNaN(input)) {
                      setAlert({
                        isAlert: true,
                        msg: "数値で入力してください",
                      });
                      return;
                    }
                    setState({
                      ...data,
                      sort: input,
                    });
                  }}
                  style={[styles.input, styles.able]}
                  editable={true}
                />
              </View>
            </View>
            <Text
              style={[
                styles.unionLabel,
                {
                  fontSize: IsAndroid() ? 14 : 20,
                  paddingTop: IsAndroid() ? 0 : 10,
                },
              ]}
            >
              ページタイトル
            </Text>
            <TextInput
              value={data.pageTitle}
              inputAccessoryViewID={"1"}
              onChangeText={e => {
                setState({ ...data, pageTitle: e });
              }}
              editable={true}
              style={[styles.unionInput, styles.able]}
            />

            <View style={styles.toolContainer}>
              <Text
                style={[
                  styles.unionLabel,
                  {
                    fontSize: IsAndroid() ? 14 : 20,
                    paddingTop: IsAndroid() ? 0 : 10,
                  },
                ]}
              >
                本文
              </Text>
              <View style={{ marginTop: 5 }}>
                <ImageUpload
                  dispIconText={false}
                  parentImgDataSources={images}
                  setParentImgDataSources={updateImgDataSources}
                  setImageString={insertImageString}
                />
              </View>
            </View>

            <View style={[IsWeb() && styles.textContainer, { height: "50%" }]}>
              <>
                <ImageDropArea onDrop={handleDrop}>
                  <TextInput
                    value={data.text}
                    inputAccessoryViewID={"1"}
                    onChangeText={e => setState({ ...data, text: e })}
                    multiline={true}
                    numberOfLines={30}
                    style={[styles.inputTextArea]}
                    editable={true}
                  />
                </ImageDropArea>
              </>
            </View>
          </View>
          <View
            style={[
              styles.buttonContainer,
              {
                paddingBottom: IsMobile() ? 500 : 0,
                padding: IsWeb() ? 25 : 0,
              },
            ]}
          >
            <ModalCancelButton buttonText={"戻る"} onClick={() => onCancel()} />

            {isPresident(userInfo) || isAccounting(userInfo) ? (
              <ModalApprovalButton
                buttonText={"プレビュー"}
                onClick={() => onSubmit()}
              />
            ) : (
              <></>
            )}
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 30,
  },
  headerContainer: {
    backgroundColor: "#fff",
    alignItems: "center",
    padding: 10,
    marginTop: -30,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
  },
  headingText: {
    flex: 8,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    fontSize: 25,
  },
  textContainer: {
    flex: 5,
    backgroundColor: "#fff",
  },
  footerContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  formWrap: {
    marginTop: 0,
  },
  formItem: {
    backgroundColor: "#fff",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  },
  toolContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  buttonContainer: {
    backgroundColor: "#fff",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: 25,
  },
  label: {
    fontSize: 20,
    width: "40%",
    paddingTop: 10,
  },
  unionLabel: {
    fontSize: 20,
    paddingTop: 10,
  },
  input: {
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  unionInput: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  able: {
    color: "black",
    backgroundColor: "white",
  },
  button: {
    marginTop: 32,
    paddingVertical: 16,
    width: 120,
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  inputTextArea: {
    width: "100%",
    height: "100%",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
});
