import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";

/** 切り替えボタンを描写します */
export const SwitchButton = ({
  selected,
  onClick,
  label,
}: {
  /** 選択中かどうか */
  selected: boolean;
  /** ボタン内に表示するテキスト */
  label: string;
  /** クリック時の挙動 */
  onClick: () => void;
}) => {
  return (
    <Pressable
      style={[
        styles.processButton,
        {
          backgroundColor: selected ? "#707070" : "#C0C0C0",
        },
      ]}
      onPress={onClick}
    >
      <Text style={styles.buttonText}>{label}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  processButton: {
    top: 0,
    left: 0,
    right: 0,
    alignSelf: "center",
    borderRadius: 20,
    elevation: 3,
    width: 150,
    height: 30,
    margin: 5,
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
});
