import React, { useContext } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";

import * as ImagePicker from "expo-image-picker";
import { DialogContext } from "GlobalContext";
import Feather from "react-native-vector-icons/Feather";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import { ImageData, ImageDataSources } from "types";
import { IsMobile } from "utils";

type Props = {
  /** イメージのデータソースを定義します */
  parentImgDataSources: ImageDataSources;
  /** イメージのデータソースの変更時の挙動を定義します */
  setParentImgDataSources: (
    value: React.SetStateAction<ImageDataSources>
  ) => void;
  /** 本文中に画像の文字列を格納する動作を定義します */
  setImageString: (key: string[]) => void;
  /** アイコンのテキストを定義します */
  dispIconText: boolean;
};

/** 画像アップロード機能を描写します */
export const ImageUpload = ({
  parentImgDataSources,
  setParentImgDataSources,
  setImageString,
  dispIconText,
}: Props) => {
  const { setAlert } = useContext(DialogContext);

  const createImageData = (count: number, img: any): ImageData | undefined => {
    const uri = img.uri;

    let extension;
    if (IsMobile()) {
      extension = uri.split(".").pop();
    } else {
      extension = uri.toString().slice(uri.indexOf("/") + 1, uri.indexOf(";"));
    }

    if (extension && !["png", "PNG", "jpg", "jpeg"].includes(extension)) {
      setAlert({
        isAlert: true,
        msg: "ファイル形式はpngまたはjpegを指定してください",
      });
      return;
    }

    return {
      docId: "Upload" + parentImgDataSources.data.length,
      id: String(count),
      url: IsMobile()
        ? img.base64
          ? `data:image/${
              extension && ["jpg", "jpeg"].includes(extension) ? "jpeg" : "png"
            };base64,` + img.base64
          : ""
        : uri,
      title: "image" + (count + 1),
      width: 0,
      height: 0,
    };
  };

  const choicePicture = async () => {
    try {
      if (IsMobile()) {
        if (
          (await ImagePicker.requestMediaLibraryPermissionsAsync()).status !==
          "granted"
        ) {
          setAlert({ isAlert: true, msg: "利用には許可が必要です" });
          return;
        }
      }

      let imageDataArray: ImageData[] = [];
      let count = parentImgDataSources.data.length;
      if (IsMobile()) {
        const result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          base64: true,
        });
        if (!result.canceled) {
          const imageData = createImageData(count, result);
          if (imageData) {
            imageDataArray.push(imageData);
          }
          count++;
        }
      } else {
        const result = await ImagePicker.launchImageLibraryAsync({
          allowsMultipleSelection: true,
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
        });
        if (!result.canceled) {
          result.assets.forEach(async img => {
            const imageData = createImageData(count, img);
            if (imageData) {
              imageDataArray.push(imageData);
            }
            count++;
          });
        }
      }
      setImageString(imageDataArray.map(m => m.docId));
      setParentImgDataSources({
        ...parentImgDataSources,
        data: parentImgDataSources.data.concat(imageDataArray),
      });
    } catch (e: any) {
      console.log(e.message);
      setAlert({ isAlert: true, msg: e.message });
    }
  };

  const takePicture = async () => {
    try {
      if (
        (await ImagePicker.requestCameraPermissionsAsync()).status !== "granted"
      ) {
        setAlert({ isAlert: true, msg: "利用には許可が必要です" });
        return;
      }

      let imageDataArray: ImageData[] = [];
      let count = parentImgDataSources.data.length;
      const result = await ImagePicker.launchCameraAsync({
        allowsEditing: false,
        base64: true,
      });
      if (!result.canceled) {
        const imageData = createImageData(count, result);
        if (imageData) {
          imageDataArray.push(imageData);
        }
      }
      setImageString(imageDataArray.map(m => m.docId));
      setParentImgDataSources({
        ...parentImgDataSources,
        data: parentImgDataSources.data.concat(imageDataArray),
      });
    } catch (e: any) {
      console.log(e.message);
      setAlert({ isAlert: true, msg: e.message });
    }
  };

  return (
    <>
      <View style={styles.uploadFormWrap}>
        <Pressable onPress={choicePicture}>
          <FontAwesomeIcon style={[styles.icon]} name="photo" size={25} />
        </Pressable>

        {IsMobile() ? (
          <Pressable onPress={takePicture}>
            <Feather style={[styles.icon]} name="camera" size={25} />
          </Pressable>
        ) : (
          <></>
        )}
      </View>

      {dispIconText ? (
        <View style={[styles.uploadFormWrap, { paddingBottom: 50 }]}>
          <Text style={[styles.icon]}>写真を選択</Text>
          {IsMobile() ? <Text style={[styles.icon]}>写真を撮影</Text> : <></>}
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  uploadFormWrap: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  icon: {
    top: 0,
    left: 0,
    right: 0,
    width: 120,
    margin: 5,
    alignSelf: "center",
    textAlign: "center",
  },
});
