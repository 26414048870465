import {
  CreateCenterCol,
  CreateLeftCol,
  CreateRightCol,
  CreateRow,
  MobileTableEditButton,
  MobileTableViewStatus,
} from "components";
import { progressStatusType } from "constants/index";
import React from "react";
import { ScrollView, Text, View } from "react-native";
import { mobileTableStyles } from "styles";
import { SiteList } from "types";

export const MobileSiteTable = ({
  tableList,
  onEditClick,
}: {
  tableList: SiteList[];
  onEditClick: (id: string) => void;
}) => {
  return (
    <ScrollView
      style={mobileTableStyles.scroll_view_style}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <View style={mobileTableStyles.list_line_view} />
        {tableList?.map((data, i) => (
          <View key={i} style={mobileTableStyles.list_line_view}>
            <View style={mobileTableStyles.contents_container}>
              <CreateRow>
                <CreateLeftCol>
                  <MobileTableEditButton
                    onEditClick={() => onEditClick(data.id)}
                    buttonText="編集"
                  />
                </CreateLeftCol>
                <CreateRightCol>
                  <MobileTableViewStatus
                    buttonColor={
                      progressStatusType[data.progressStatus]?.color ?? ""
                    }
                    buttonText={progressStatusType[data.progressStatus].text}
                  />
                </CreateRightCol>
              </CreateRow>
              <CreateRow>
                <CreateLeftCol>
                  <Text style={mobileTableStyles.base_text}>
                    {`現場名：${data.name}`}
                  </Text>
                </CreateLeftCol>
                <CreateCenterCol>
                  <View>
                    <Text style={mobileTableStyles.small_text}>
                      {`案件番号：${data.matterNumber}`}
                    </Text>
                    <Text
                      style={mobileTableStyles.small_text}
                    >{`契約番号：${data.agreementNumber}`}</Text>
                  </View>
                </CreateCenterCol>
              </CreateRow>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};
