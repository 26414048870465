import { UserInfoContext } from "GlobalContext";
import { StatusBar } from "expo-status-bar";
import { RootStackScreenProps } from "navigations";
import React, { useContext } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { IsWeb, isPresident } from "utils";

export function EmployeeManagementScreen({
  navigation,
}: RootStackScreenProps<"EmployeeManagement">) {
  const { userInfo } = useContext(UserInfoContext);
  return (
    <View style={styles.container}>
      {/* WEB表示の場合、かつ社長権限の場合のみ表示 */}
      {IsWeb() && isPresident(userInfo) ? (
        <Pressable
          style={styles.button}
          onPress={() => {
            navigation.navigate("AttendanceDownload");
          }}
        >
          <Text style={styles.buttonText}>勤務実績ダウンロード</Text>
        </Pressable>
      ) : (
        <></>
      )}

      {/* WEB表示の場合のみ表示 */}
      {IsWeb() ? (
        <Pressable
          style={styles.button}
          onPress={() => {
            navigation.navigate("ExpensesDownload");
          }}
        >
          <Text style={styles.buttonText}>経費ダウンロード</Text>
        </Pressable>
      ) : (
        <></>
      )}

      <Pressable
        style={styles.button}
        onPress={() => {
          navigation.navigate("User");
        }}
      >
        <Text style={styles.buttonText}>社員管理</Text>
      </Pressable>

      {/* WEB表示の場合のみ表示
      {IsWeb() && (
        <Pressable
          style={styles.button}
          onPress={() => {
            navigation.navigate("ShiftManagement");
          }}
        >
          <Text style={styles.buttonText}>シフト管理</Text>
        </Pressable>
      )} */}
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    height: 40,
    width: "85%",
    borderColor: "gray",
    marginBottom: 16,
    borderRadius: 10,
  },
  button: {
    width: "80%",
    marginTop: 32,
    paddingVertical: 24,
    borderRadius: 8,
    backgroundColor: "#4169e1",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
});
