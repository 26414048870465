import dayjs from "dayjs";
import firebase from "firebase/compat/app";
import React, { createContext, ReactNode, useState } from "react";
import {
  AttendanceOnSnapShot,
  CalendarOnSnapShot,
  CompanyOnSnapShot,
  ExpenseItemOnSnapShot,
  ExpenseLimitSettingOnSnapShot,
  ExpenseOnSnapShot,
  HolidayOnSnapShot,
  NoteBookOnSnapShot,
  PaidVacationOnSnapShot,
  RequestOnSnapShot,
  SiteOnSnapShot,
  UserOnSnapShot,
} from "server/collectionConnect";
import {
  AttendanceCollection,
  CalendarCollection,
  CompanyCollection,
  ExpenseCollection,
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  HolidayCollection,
  NoteBookCollection,
  PaidVacationCollection,
  RequestCollection,
  SiteCollection,
  UserCollection,
} from "types";
/** バインディングするデーブルのキー */
type CollectionKey =
  | "user"
  | "site"
  | "calendar"
  | "request"
  | "notebook"
  | "expenseItem"
  | "company"
  | "paidVacation"
  | "expense"
  | "expenseLimit"
  | "attendance"
  | "holiday";

type Option = {
  /** 開始日 */
  targetStartDate?: Date;
  /** 終了日 */
  targetEndDate?: Date;
};
/** データベースを取得した結果を保存するContextです
 */
export const CollectionContext = createContext(
  {} as {
    /** データベースのバインディングを構築します */
    onSnapShot: (key: CollectionKey) => void;
    /** データベースのバインディングを解除します */
    clearSnapShot: (key: CollectionKey) => void;
    /** 現場のテーブル情報 */
    SiteCollection: SiteCollection[] | null;
    /** 休日のテーブル情報 */
    HolidayCollection: HolidayCollection[] | null;
    /** カレンダーのテーブル情報 */
    CalendarCollection: CalendarCollection[] | null;
    /** ユーザーのテーブル情報 */
    UserCollection: UserCollection[] | null;
    /** 勤怠申請のテーブル情報 */
    RequestCollection: RequestCollection[] | null;
    /** Wikiのテーブル情報 */
    NoteBookCollection: NoteBookCollection[] | null;
    /** 経費種類のテーブル情報 */
    ExpenseItemCollection: ExpenseItemCollection[] | null;
    /** 経費のテーブル情報 */
    ExpenseCollection: ExpenseCollection[] | null;
    /** 経費枠のテーブル情報 */
    ExpenseLimitSettingCollection: ExpenseLimitSettingCollection[] | null;
    /** 会社のテーブル情報 */
    CompanyCollection: CompanyCollection | null;
    /** 有給のテーブル情報 */
    PaidVacationCollection: PaidVacationCollection[] | null;
    /** 勤怠のテーブル情報 */
    AttendanceCollection: AttendanceCollection[] | null;
  }
);

/**
 * Collection用のプロバイダーです。
 *
 * 削除するとCollectionとのバインディングが解除されるため削除しないでください
 * @param param0 子要素を持たせます
 * @returns
 */
export const CollectionProvider = ({ children }: { children: ReactNode }) => {
  const [SiteCollection, setSiteCollection] = useState<SiteCollection[] | null>(
    null
  );
  const [AttendanceCollection, setAttendanceCollection] = useState<
    AttendanceCollection[] | null
  >(null);
  const [CalendarCollection, setCalendarCollection] = useState<
    CalendarCollection[] | null
  >(null);
  const [UserCollection, setUserCollection] = useState<UserCollection[] | null>(
    null
  );
  const [RequestCollection, setRequestCollection] = useState<
    RequestCollection[] | null
  >(null);
  const [NoteBookCollection, setNoteBookCollection] = useState<
    NoteBookCollection[] | null
  >(null);
  const [ExpenseItemCollection, setExpenseItemCollection] = useState<
    ExpenseItemCollection[] | null
  >(null);
  const [ExpenseCollection, setExpenseCollection] = useState<
    ExpenseCollection[] | null
  >(null);
  const [ExpenseLimitSettingCollection, setExpenseLimitSettingCollection] =
    useState<ExpenseLimitSettingCollection[] | null>(null);
  const [CompanyCollection, setCompanyCollection] =
    useState<CompanyCollection | null>(null);
  const [PaidVacationCollection, setPaidVacationCollection] = useState<
    PaidVacationCollection[] | null
  >(null);
  const [HolidayCollection, setHolidayCollection] = useState<
    HolidayCollection[] | null
  >(null);

  var clearUserOnSnapShot: () => void = () => {};
  var clearSiteOnSnapShot: () => void = () => {};
  var clearCalendarOnSnapShot: () => void = () => {};
  var clearRequestOnSnapShot: () => void = () => {};
  var clearNoteBookOnSnapShot: () => void = () => {};
  var clearExpenseOnSnapShot: () => void = () => {};
  var clearExpenseItemOnSnapShot: () => void = () => {};
  var clearExpenseLimitOnSnapShot: () => void = () => {};
  var clearCompanyOnSnapShot: () => void = () => {};
  var clearPaidVacationOnSnapShot: () => void = () => {};
  var clearAttendanceOnSnapShot: () => void = () => {};
  var clearHolidayOnSnapShot: () => void = () => {};

  /** データベースとのバインディングを作成し、取得結果をProviderに格納します
   * @param key バインディングを作成する対象のキーを指定します
   * @param targetDate 日付の指定
   */
  const onSnapShot = (key: CollectionKey, option?: Option) => {
    switch (key) {
      case "user":
        // if (UserCollection === null)
        clearUserOnSnapShot = UserOnSnapShot(setUserCollection);
        break;
      case "site":
        if (SiteCollection === null)
          clearSiteOnSnapShot = SiteOnSnapShot(setSiteCollection);
        break;
      case "calendar":
        if (CalendarCollection === null) {
          const monthBeginning =
            (option && option.targetStartDate) ??
            dayjs(new Date()).startOf("month").subtract(6, "month").toDate();
          const monthEnd =
            (option && option.targetStartDate) ??
            dayjs().endOf("month").add(6, "month").toDate();

          clearCalendarOnSnapShot = CalendarOnSnapShot(
            setCalendarCollection,
            monthBeginning,
            monthEnd
          );
        }
        break;
      case "request":
        if (RequestCollection === null)
          clearRequestOnSnapShot = RequestOnSnapShot(setRequestCollection);
        break;
      case "notebook":
        if (NoteBookCollection === null)
          clearNoteBookOnSnapShot = NoteBookOnSnapShot(setNoteBookCollection);
        break;
      case "expense":
        // if (ExpenseCollection === null)
        clearExpenseOnSnapShot = ExpenseOnSnapShot(setExpenseCollection);
        break;
      case "expenseItem":
        if (ExpenseItemCollection === null)
          clearExpenseItemOnSnapShot = ExpenseItemOnSnapShot(
            setExpenseItemCollection
          );
        break;
      case "expenseLimit":
        if (ExpenseLimitSettingCollection === null)
          clearExpenseLimitOnSnapShot = ExpenseLimitSettingOnSnapShot(
            setExpenseLimitSettingCollection
          );
        break;
      case "company":
        if (CompanyCollection === null)
          clearCompanyOnSnapShot = CompanyOnSnapShot(setCompanyCollection);
        break;
      case "paidVacation":
        if (PaidVacationCollection === null)
          clearPaidVacationOnSnapShot = PaidVacationOnSnapShot(
            setPaidVacationCollection
          );
        break;
      case "attendance":
        if (AttendanceCollection === null) {
          const monthBeginning =
            (option && option.targetStartDate) ??
            dayjs(new Date()).startOf("month").subtract(6, "month").toDate();
          const monthEnd =
            (option && option.targetStartDate) ??
            dayjs().endOf("month").add(6, "month").toDate();

          clearCalendarOnSnapShot = CalendarOnSnapShot(
            setCalendarCollection,
            monthBeginning,
            monthEnd
          );

          clearAttendanceOnSnapShot = AttendanceOnSnapShot(
            setAttendanceCollection,
            monthBeginning,
            monthEnd
          );
        }
        break;
      case "holiday":
        if (HolidayCollection === null)
          clearHolidayOnSnapShot = HolidayOnSnapShot(setHolidayCollection);
        break;
    }
  };

  /** データベースとのバインディングを解除します
   * @param key データベースとのバインディングを解除するキーを指定
   */
  const clearSnapShot = (key: CollectionKey) => {
    switch (key) {
      case "user":
        clearUserOnSnapShot;
        break;
      case "site":
        clearSiteOnSnapShot;
        break;
      case "calendar":
        clearCalendarOnSnapShot;
        break;
      case "request":
        clearRequestOnSnapShot;
        break;
      case "notebook":
        clearNoteBookOnSnapShot;
        break;
      case "expense":
        clearExpenseOnSnapShot;
        break;
      case "expenseItem":
        clearExpenseItemOnSnapShot;
        break;
      case "expenseLimit":
        clearExpenseLimitOnSnapShot;
        break;
      case "company":
        clearCompanyOnSnapShot;
        break;
      case "paidVacation":
        clearPaidVacationOnSnapShot;
        break;
      case "attendance":
        clearAttendanceOnSnapShot;
        break;
      case "holiday":
        clearHolidayOnSnapShot;
        break;
    }
  };

  React.useEffect(() => {
    // ログインしている時のみデータの取得をバックグラウンドで実行しておく
    firebase.auth().onAuthStateChanged(user => {
      if (user !== null) {
      }
    });
  }, []);

  return (
    <>
      <CollectionContext.Provider
        value={{
          AttendanceCollection,
          SiteCollection,
          CalendarCollection,
          UserCollection,
          RequestCollection,
          NoteBookCollection,
          ExpenseItemCollection,
          CompanyCollection,
          PaidVacationCollection,
          ExpenseCollection,
          ExpenseLimitSettingCollection,
          HolidayCollection,
          onSnapShot,
          clearSnapShot,
        }}
      >
        {children}
      </CollectionContext.Provider>
    </>
  );
};
