import { ModalCloseButton, NoteBookPageDeleteForm } from "components";
import React from "react";
import { View } from "react-native";
import { modalStyles } from "styles";
import { WikiDeleteModalStatus } from "types";
import { ModalLibrary } from "utils";

type Props = {
  deleteStatus: WikiDeleteModalStatus;
  onCloseModal: () => void;
  onDelete: () => void;
};

export function NoteBookPageDeleteModal({
  deleteStatus,
  onCloseModal,
  onDelete,
}: Props) {
  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={deleteStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />
          <NoteBookPageDeleteForm
            hideModal={onCloseModal}
            onDelete={onDelete}
            modalStatus={deleteStatus}
          />
        </View>
      </ModalLibrary>
    </>
  );
}
