import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  DateForm,
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  ModalDeleteButton,
  SelectForm,
} from "components";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, ScrollView, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  CreateExpenseLimitSetting,
  FetchExpenseLimitSetting,
  UpdateExpenseLimitSetting,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { ExpenseLimitSettingData, ExpenseLimitSettingModalStatus } from "types";
import { ConvertTimeStampFromDate, numberFormat } from "utils";

type Props = {
  /** ボタンを閉じる時の挙動を定義します */
  hideModal: Function;
  /** モーダルの情報を定義します */
  modalStatus: ExpenseLimitSettingModalStatus;
  /** 経費種類選択のリスト */
  expenseItemSelect: Item[];
  /** ユーザー選択のリスト */
  userSelect: Item[];
};

/** 経費枠登録用のモーダルの入力欄を定義します */
export function ExpenseLimitSettingForm({
  hideModal,
  modalStatus,
  expenseItemSelect,
  userSelect,
}: Props) {
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { ExpenseCollection, ExpenseLimitSettingCollection } =
    useContext(CollectionContext);
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  if (userInfo == null) {
    setAlert({ isAlert: true, msg: "ユーザー情報が取得できませんでした" });
    return <></>;
  }
  const [expenseLimitSetting, setExpenseLimitSetting] =
    useState<ExpenseLimitSettingData>(defaultData());

  useEffect(() => {
    if (modalStatus === null) {
      setExpenseLimitSetting(defaultData());
      return;
    }
    const result = FetchExpenseLimitSetting(
      ExpenseLimitSettingCollection,
      modalStatus.id ?? ""
    );
    if (result === null) {
      setExpenseLimitSetting(defaultData());
      return;
    }
    setExpenseLimitSetting(result);
  }, [modalStatus]);

  const onGrantDateChangeHandler = (evt: Date): void => {
    setExpenseLimitSetting({
      ...expenseLimitSetting,
      grantDate: ConvertTimeStampFromDate(evt),
    });
  };

  const onExpirationDateChangeHandler = (evt: Date): void => {
    setExpenseLimitSetting({
      ...expenseLimitSetting,
      expirationDate: ConvertTimeStampFromDate(evt),
    });
  };

  const validationCheck = (): boolean => {
    if (!expenseLimitSetting.userId) {
      setAlert({ isAlert: true, msg: "社員を選択してください" });
      return false;
    }
    if (!expenseLimitSetting.itemId) {
      setAlert({ isAlert: true, msg: "経費種類を選択してください" });
      return false;
    }
    if (!Number.isInteger(expenseLimitSetting.amountLimit)) {
      setAlert({ isAlert: true, msg: "金額は数値で入力してください" });
      return false;
    }
    if (!expenseLimitSetting.grantDate) {
      setAlert({ isAlert: true, msg: "付与日を選択してください" });
      return false;
    }
    if (!expenseLimitSetting.expirationDate) {
      setAlert({ isAlert: true, msg: "有効期限を選択してください" });
      return false;
    }
    if (
      dayjs(expenseLimitSetting.grantDate.toDate()).startOf("date") >
      dayjs(expenseLimitSetting.expirationDate.toDate()).endOf("date")
    ) {
      setAlert({
        isAlert: true,
        msg: "付与日が有効期限より大きくなっています。",
      });
      return false;
    }
    return true;
  };

  const createExpenseLimitSetting = async () => {
    if (!validationCheck()) return;

    setIsLoading(true);
    try {
      const result = await CreateExpenseLimitSetting(
        userInfo,
        expenseLimitSetting
      );
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: "登録しました",
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "登録に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateExpenseLimitSetting = async (deleted?: boolean) => {
    if (ExpenseCollection === null) return;
    if (!validationCheck()) return;

    setIsLoading(true);

    if (deleted ?? false) expenseLimitSetting.deleted = true;

    try {
      const result = await UpdateExpenseLimitSetting(
        expenseLimitSetting,
        ExpenseCollection
      );
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>経費枠登録</Text>
        <Loading isLoading={isLoading} />
        <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={modalFormStyles.scrollContainer}
          >
            <View style={[formWrap(windowWidth).formWrap]}>
              <SelectForm
                label="社員"
                placeholderLabel="選択してください"
                value={expenseLimitSetting.userId}
                onChange={e =>
                  setExpenseLimitSetting({
                    ...expenseLimitSetting,
                    userId: e as string,
                  })
                }
                items={userSelect}
                disabled={!modalStatus?.isEdit ? false : true}
              />
              <SelectForm
                label="経費種類"
                placeholderLabel="選択してください"
                value={expenseLimitSetting.itemId}
                onChange={e =>
                  setExpenseLimitSetting({
                    ...expenseLimitSetting,
                    itemId: e as string,
                  })
                }
                items={expenseItemSelect}
                disabled={!modalStatus?.isEdit ? false : true}
              />
              <InputForm
                label="金額"
                value={numberFormat(expenseLimitSetting.amountLimit, "")}
                onChange={e => {
                  const input = Number(e.replace(/,/g, ""));
                  if (isNaN(input)) {
                    setAlert({
                      isAlert: true,
                      msg: "数値で入力してください",
                    });
                    return;
                  }
                  setExpenseLimitSetting({
                    ...expenseLimitSetting,
                    amountLimit: input,
                  });
                }}
                disabled={true}
              />
              <DateForm
                value={expenseLimitSetting.grantDate.toDate()}
                onChange={onGrantDateChangeHandler}
                disabled={false}
                label={"付与日"}
                type={"date"}
              />
              <DateForm
                value={expenseLimitSetting.expirationDate.toDate()}
                onChange={onExpirationDateChangeHandler}
                disabled={false}
                label={"有効期限"}
                type={"date"}
              />
              <View style={modalFormStyles.formItem}>
                <ModalCancelButton
                  buttonText="キャンセル"
                  onClick={() => hideModal()}
                />

                {!modalStatus?.isEdit ? (
                  <ModalApprovalButton
                    buttonText="登録"
                    onClick={() => createExpenseLimitSetting()}
                  />
                ) : (
                  <ModalApprovalButton
                    buttonText="更新"
                    onClick={() => updateExpenseLimitSetting()}
                  />
                )}
              </View>

              {modalStatus?.isEdit ? (
                <View style={modalFormStyles.formItemCenter}>
                  <ModalDeleteButton
                    buttonText="削除"
                    onClick={() => updateExpenseLimitSetting(true)}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
      <Dialog />
    </>
  );
}

const defaultData = () => {
  return {
    id: "",
    userId: "",
    itemId: "",
    amountLimit: 0,
    grantDate: ConvertTimeStampFromDate(dayjs().toDate()),
    expirationDate: ConvertTimeStampFromDate(dayjs().add(1, "year").toDate()),
    deleted: false,
  };
};
