import AsyncStorage from "@react-native-async-storage/async-storage";
import { initializeAuth } from "firebase/auth";
import { getReactNativePersistence } from "firebase/auth/react-native";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { Platform } from "react-native";
import firebaseConfig from "./firebaseConfig";

if (!firebase.apps.length) {
  const fireApp = firebase.initializeApp(firebaseConfig);
  // if (__DEV__) {
  //   console.log("Switching to local Firebase instance...");
  //   const origin =
  //     Constants.manifest?.debuggerHost?.split(":").shift() || "localhost";

  //   firebase.auth().useEmulator(`http://${origin}:9099/`);
  //   firebase.firestore().useEmulator(origin, 8080);
  //   firebase.functions().useEmulator(origin, 5001);
  //   firebase.storage().useEmulator(origin, 9199);
  // }

  Platform.OS !== "web" &&
    initializeAuth(fireApp, {
      persistence: getReactNativePersistence(AsyncStorage),
    });
  firebase.firestore().settings({
    ignoreUndefinedProperties: true,
    experimentalAutoDetectLongPolling: true,
  });
}
export const auth = firebase.auth();

export const registerWithEmail = (email: string, password: string) =>
  auth.createUserWithEmailAndPassword(email, password);

export const logout = () => auth.signOut();

export const passwordReset = (email: string) =>
  auth.sendPasswordResetEmail(email);
