import { Item } from "react-native-picker-select";
import { NoteBookCollection } from "types";
import { AscSort } from "utils";

/**
 * 整形したWikiプルダウン表示用情報を返却します
 * @param noteBooks collectionContextから取得したWiki情報
 * @returns Item[]
 */
export const FetchNoteBooksSelect = (
  noteBooks: NoteBookCollection[] | null
): Item[] => {
  if (noteBooks === null) return [] as Item[];

  const targetNoteBooks = noteBooks
    .filter(f => f.deleted === false)
    .sort((a, b) => AscSort(a.sort, b.sort));

  return targetNoteBooks.map(
    elem =>
      ({
        label: elem.bookName,
        value: elem.id,
      } as Item)
  );
};
