import dayjs from "dayjs";
import {
  CalendarCollection,
  CalendarDataWithRequest,
  RequestCollection,
} from "types";
import { AscSort, DescSort } from "utils/sortBy";
import { GetViewRequest } from "./utils/GetViewRequest";

/**
 * 整形したカレンダー表示用情報を返却します
 * @param calendars collectionContextから取得したカレンダー情報
 * @param targetDate 対象の日付
 * @returns CalendarData[]
 */
export const FetchCalendarTargetMonth = (
  calendars: CalendarCollection[] | null,
  requests: RequestCollection[] | null,
  targetDate: Date
): CalendarDataWithRequest[] => {
  if (calendars === null || requests === null)
    return [] as CalendarDataWithRequest[];

  const monthBeginning = dayjs(targetDate)
    .startOf("month")
    .subtract(1, "week")
    .toDate();
  const monthEnd = dayjs(targetDate).endOf("month").add(1, "week").toDate();

  // 承認済みの有給・振替休日・欠勤を取得
  const targetRequest = GetViewRequest(requests, monthBeginning, monthEnd);

  const targetCalendars = calendars
    .filter(f =>
      dayjs(f.startDate?.toDate()).isBetween(
        monthBeginning,
        monthEnd,
        null,
        "[]"
      )
    )
    .map(
      m =>
        ({
          uid: m.id,
          title: m.title,
          startDate: m.startDate,
          endDate: m.endDate,
          isHoliday: m.isHoliday,
          isAllDay: m.isAllDay,
          memo: m.memo,
          userId: m.userId,
          siteId: m.site && (m.site.id ?? ""),
          site: m.site && {
            id: m.site.id,
            agreementNumber: m.site.agreementNumber,
            matterNumber: m.site.matterNumber,
            name: m.site.name,
            progressStatus: m.site.progressStatus,
          },
          isRequest: false,
        } as CalendarDataWithRequest)
    );

  return targetCalendars.concat(targetRequest).sort((a, b) => {
    if (a.isAllDay != b.isAllDay) return DescSort(a.isAllDay, b.isAllDay);
    if (a.startDate != b.startDate) return AscSort(a.startDate, b.startDate);
    return 0;
  });
};
