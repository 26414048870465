import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import {
  AddButton,
  ExpenseLimitSettingModal,
  ExpenseLimitSettingTable,
  PageLoading,
} from "components";
import dayjs from "dayjs";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { FetchExpenseLimitSettings } from "server/collectionConnect";
import { ExpenseLimitSettingList, ExpenseLimitSettingModalStatus } from "types";
import { IsWeb } from "utils";

export function ExpenseLimitSettingScreen({}: RootStackScreenProps<"ExpenseLimitSetting">) {
  const [editData, setEditData] =
    useState<ExpenseLimitSettingModalStatus>(null);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    ExpenseLimitSettingCollection,
    UserCollection,
    ExpenseItemCollection,
    onSnapShot,
  } = useContext(CollectionContext);
  const { userInfo } = useContext(UserInfoContext);
  const [expenseLimitSettings, setExpenseLimitSettings] = useState<
    ExpenseLimitSettingList[] | null
  >(null);
  const [targetMonth, setTargetMonth] = useState<dayjs.Dayjs>(dayjs());

  useEffect(() => {
    if (
      ExpenseLimitSettingCollection === null ||
      UserCollection === null ||
      ExpenseItemCollection === null ||
      userInfo === null
    ) {
      onSnapShot("user");
      onSnapShot("expenseLimit");
      onSnapShot("expenseItem");
      setIsLoading(true);
      return;
    }

    // 当月の場合のみ、今日の日付以降が対象
    var targetDate =
      targetMonth.format("YYYY/MM") === dayjs(new Date()).format("YYYY/MM")
        ? dayjs(new Date()).startOf("date").toDate()
        : targetMonth.startOf("month").toDate();
    const result = FetchExpenseLimitSettings(
      ExpenseLimitSettingCollection,
      targetDate,
      UserCollection ?? [],
      ExpenseItemCollection ?? []
    );

    setExpenseLimitSettings(result);
    setIsLoading(false);
  }, [
    targetMonth,
    ExpenseLimitSettingCollection,
    UserCollection,
    ExpenseItemCollection,
    userInfo,
  ]);

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={styles.monthContainer}>
        <Pressable
          style={[styles.monthView]}
          onPress={() => {
            setTargetMonth(targetMonth.subtract(1, "M"));
          }}
        >
          <Text style={styles.monthViewValueText}>←前の月へ</Text>
        </Pressable>
        <Pressable
          style={[styles.monthView, IsWeb() ? { width: 150 } : { width: 90 }]}
        >
          <Text
            style={[
              styles.monthViewValueText,
              IsWeb()
                ? { fontSize: 30, lineHeight: 10 }
                : { fontSize: 20, lineHeight: 20 },
            ]}
          >
            {targetMonth.format("YYYY/MM")}
          </Text>
        </Pressable>
        <Pressable
          style={[styles.monthView]}
          onPress={() => {
            setTargetMonth(targetMonth.add(1, "M"));
          }}
        >
          <Text style={styles.monthViewValueText}>次の月へ→</Text>
        </Pressable>
      </View>
      <View style={styles.listContainer}>
        <ExpenseLimitSettingTable
          tableList={expenseLimitSettings}
          onEditClick={id => setEditData({ id: id, isEdit: true })}
        />
      </View>
      <AddButton onClick={() => setEditData({ id: null, isEdit: false })} />
      <ExpenseLimitSettingModal
        modalStatus={editData}
        onCloseModal={() => setEditData(null)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  monthContainer: {
    flex: 0.7,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  listContainer: {
    flex: 5,
    backgroundColor: "#fff",
  },
  footerContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headerButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    height: 100,
  },
  processButton: {
    top: 0,
    left: 0,
    right: 0,
    alignSelf: "center",
    borderRadius: 20,
    elevation: 3,
    width: 150,
    height: 30,
    margin: 5,
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
  monthView: {
    height: 30,
    margin: 15,
  },
  monthViewValueText: {
    textAlign: "center",
    color: "black",
    fontWeight: "900",
    fontSize: 15,
  },
});
