import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  CheckBoxForm,
  DateForm,
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  ModalDeleteButton,
  MultipleInputForm,
  SelectForm,
} from "components";
import { paidVacationTypeList, requestTypeList } from "constants/index";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, ScrollView, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  CreateRequest,
  FetchLatestAttendanceByDate,
  FetchLatestAttendanceById,
  FetchRequest,
  FetchWorkScheduleList,
  UpdateRequest,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import {
  AttendanceCollection,
  PaidVacationType,
  RequestCollection,
  RequestData,
  RequestModalStatus,
  RequestStatusList,
  RequestType,
  UserInfo,
} from "types";
import { ConvertTimeStampFromDate } from "utils";

type Props = {
  hideModal: Function;
  modalStatus: RequestModalStatus;
  RequestCollection: RequestCollection[];
  AttendanceCollection: AttendanceCollection[];
};

export function RequestForm({
  hideModal,
  modalStatus,
  AttendanceCollection,
}: Props) {
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { UserCollection, RequestCollection, PaidVacationCollection } =
    useContext(CollectionContext);
  if (userInfo == null) {
    setAlert({ isAlert: true, msg: "ユーザー情報が取得できませんでした" });
    return <></>;
  }
  const [mode, setMode] = useState<"date" | "datetime">("datetime");
  const [request, setRequest] = useState<RequestData>(
    defaultStateValue(userInfo, AttendanceCollection, modalStatus?.targetDate)
  );
  const [workScheduleList, setWorkScheduleList] = useState<Item[]>([]);

  useEffect(() => {
    if (modalStatus === null) {
      setRequest(defaultStateValue(userInfo, AttendanceCollection));
      return;
    }
    if (modalStatus.type === "request") {
      const result = FetchRequest(RequestCollection, modalStatus.id ?? "");
      if (result === null) {
        const defaultResult = defaultStateValue(
          userInfo,
          AttendanceCollection,
          modalStatus?.targetDate
        );
        onTypeChangeHandler(defaultResult.requestType);
        setRequest(defaultResult);
        return;
      }
      onTypeChangeHandler(result.requestType);
      setRequest(result);
    }
  }, [modalStatus]);

  useEffect(() => {
    getWorkScheduleList(request);
  }, [request.isAttendanceCancel]);

  useEffect(() => {
    if (request.paidVacationType) {
      onAcquisitionDateChangeHandler(
        request?.acquisitionStartAt?.toDate() ?? new Date()
      );
    }
  }, [request.paidVacationType]);

  const onAcquisitionDateChangeHandler = (evt: Date): void => {
    let newEvtStart = dayjs(evt);
    let newEvtEnd = dayjs(evt);
    switch (request.paidVacationType) {
      case "allDay":
        newEvtStart = newEvtStart.startOf("day");
        newEvtEnd = newEvtEnd.startOf("day");
        break;
      case "morning":
        newEvtStart = newEvtStart.hour(8).minute(30);
        newEvtEnd = newEvtEnd.hour(12).minute(30);
        break;
      case "afternoon":
        newEvtStart = newEvtStart.hour(13).minute(30);
        newEvtEnd = newEvtEnd.hour(17).minute(30);
        break;
      default:
        break;
    }
    setRequest({
      ...request,
      acquisitionStartAt: ConvertTimeStampFromDate(newEvtStart.toDate()),
      acquisitionEndAt: ConvertTimeStampFromDate(newEvtEnd.toDate()),
    });
  };

  const onStartDateChangeHandler = (evt: Date): void => {
    switch (request.requestType) {
      case "lateness":
        setRequest({
          ...request,
          acquisitionStartAt: ConvertTimeStampFromDate(evt),
          acquisitionEndAt: ConvertTimeStampFromDate(evt),
        });
        break;
      case "substituteAttendance":
      case "holidayWork":
        const target = dayjs(evt).startOf("D").hour(8).minute(30);
        setRequest({
          ...request,
          acquisitionStartAt: ConvertTimeStampFromDate(target.toDate()),
          acquisitionEndAt: ConvertTimeStampFromDate(
            target.add(9, "hour").toDate()
          ),
        });
        break;
      case "staggeredWorkHours":
        setRequest({
          ...request,
          acquisitionStartAt: ConvertTimeStampFromDate(evt),
          acquisitionEndAt: ConvertTimeStampFromDate(
            dayjs(evt).add(9, "hour").toDate()
          ),
        });
        break;
      default:
        setRequest({
          ...request,
          acquisitionStartAt: ConvertTimeStampFromDate(evt),
        });
        break;
    }
  };

  const onEndDateChangeHandler = (evt: Date): void => {
    if (IsLeaveEarly(request.requestType)) {
      setRequest({
        ...request,
        acquisitionStartAt: ConvertTimeStampFromDate(evt),
        acquisitionEndAt: ConvertTimeStampFromDate(evt),
      });
    } else {
      setRequest({
        ...request,
        acquisitionEndAt: ConvertTimeStampFromDate(evt),
      });
    }
  };

  const validationCheck = (): boolean => {
    if (!request.requestType) {
      setAlert({ isAlert: true, msg: "申請種類を選択してください" });
      return false;
    }
    if (request.acquisitionStartAt > request.acquisitionEndAt) {
      setAlert({
        isAlert: true,
        msg: "開始予定日が終了予定日より大きくなっています。",
      });
      return false;
    }
    if (
      request.requestType === "attendanceCorrection" &&
      request.isAttendanceCancel &&
      request.correctionTargetAttendanceId === ""
    ) {
      setAlert({
        isAlert: true,
        msg: "取消対象が選択されていません",
      });
      return false;
    }
    if (request.requestType === "paidVacation" && !request.paidVacationType) {
      setAlert({
        isAlert: true,
        msg: "有給種類が選択されていません",
      });
      return false;
    }
    return true;
  };

  const createRequest = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await CreateRequest(
        request,
        PaidVacationCollection,
        RequestCollection
      );
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "登録に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateRequest = async (status?: RequestStatusList): Promise<void> => {
    if (!validationCheck()) {
      return;
    }
    setIsLoading(true);

    const targetRequest = request;
    targetRequest.status = status !== undefined ? status : targetRequest.status;
    // 勤怠修正以外は修正対象の勤怠IDを削除する
    if (targetRequest.requestType !== "attendanceCorrection") {
      targetRequest.correctionTargetAttendanceId = "";
    }
    try {
      const result = await UpdateRequest(
        userInfo,
        targetRequest,
        PaidVacationCollection,
        RequestCollection
      );
      setIsLoading(false);
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const getWorkScheduleList = async (data: RequestData): Promise<void> => {
    setIsLoading(true);
    try {
      const user = UserCollection?.filter(f => f.id === data.userId).shift();
      const result = FetchWorkScheduleList(
        user === undefined ? "" : user.uid,
        AttendanceCollection,
        data.acquisitionStartAt.toDate(),
        data.isAttendanceCancel
      );
      setWorkScheduleList(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const isInvalidDate = (date: string) => {
    const targetDate = dayjs(date).toDate();
    return Number.isNaN(targetDate.getTime());
  };

  const onChangeCurrentAttendanceCorrection = (id: string) => {
    if (id === "") return;

    //　勤怠情報がある場合
    if (isInvalidDate(id)) {
      var target = FetchLatestAttendanceById(id, AttendanceCollection);
      if (target === null) {
        setAlert({
          isAlert: true,
          msg: "指定された勤怠情報の取得に失敗しました。",
        });
        return;
      }
      if (
        !request.correctionTargetAttendanceId ||
        request.correctionTargetAttendanceId != target.id
      ) {
        setRequest({
          ...request,
          acquisitionStartAt: ConvertTimeStampFromDate(
            dayjs(target.startWorkSchedule?.toDate()).toDate()
          ),
          acquisitionEndAt: ConvertTimeStampFromDate(
            dayjs(target.endWorkSchedule?.toDate()).toDate()
          ),
          correctionTargetAttendanceId: target.id,
        });
      }
    } else {
      setRequest({
        ...request,
        acquisitionStartAt: ConvertTimeStampFromDate(dayjs(id).toDate()),
        acquisitionEndAt: ConvertTimeStampFromDate(dayjs(id).toDate()),
        correctionTargetAttendanceId: "",
      });
    }
  };

  const onTypeChangeHandler = (e: string) => {
    const type = e as RequestType;
    if (IsPaidVacation(type)) setMode("date");
    if (IsOverTime(type)) setMode("datetime");
    if (IsLeaveEarly(type)) setMode("datetime");
    if (IsAbsence(type)) setMode("date");
    if (IsLateness(type)) setMode("datetime");
    if (IsPrivateOuting(type)) setMode("datetime");
    if (IsSubstituteHoliday(type)) setMode("date");
    if (IsStaggeredWorkHours(type)) setMode("datetime");
    if (IsAttendanceCorrection(type)) setMode("datetime");
    if (IsPaidVacation(type)) setMode("date");
    if (IsSubstituteAttendance(type) || IsHolidayWork(type)) {
      setMode("date");
      const target = dayjs(request.acquisitionStartAt.toDate())
        .hour(8)
        .minute(30);
      setRequest({
        ...request,
        acquisitionStartAt: ConvertTimeStampFromDate(target.toDate()),
        acquisitionEndAt: ConvertTimeStampFromDate(
          target.hour(17).minute(30).toDate()
        ),
      });
    }
    if (IsSubstituteAttendance(type)) {
      setRequest({
        ...request,
        requestType: type,
        isAttendanceCancel: !request.isAttendanceCancel,
      });
    } else {
      setRequest({ ...request, requestType: type });
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>勤怠申請</Text>
        <Loading isLoading={isLoading} />
        <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={modalFormStyles.scrollContainer}
          >
            <View style={[formWrap(windowWidth).formWrap]}>
              <SelectForm
                label="申請種類"
                placeholderLabel="選択してください"
                value={request.requestType}
                onChange={onTypeChangeHandler}
                items={requestTypeList}
                disabled={!CheckCanEditIsNew(request.status ?? "")}
              />
              <InputForm
                label="申請者名"
                value={userInfo.user.name}
                onChange={() => {}}
                disabled={false}
              />
              <DateForm
                value={request.requestAt.toDate()}
                disabled={true}
                label={"申請日"}
                type={"date"}
              />
              <PaidVacationTypeSelect
                label="有給種類"
                value={request.paidVacationType ?? ""}
                onChange={e => setRequest({ ...request, paidVacationType: e })}
                disabled={
                  !CheckCanEditIsNewOrIdRequest(request.status ?? "") ||
                  request.isAttendanceCancel
                }
                items={paidVacationTypeList}
                type={request.requestType}
              />
              <AttendanceCancelCheckBox
                checked={request.isAttendanceCancel}
                onChange={() =>
                  setRequest({
                    ...request,
                    isAttendanceCancel: !request.isAttendanceCancel,
                  })
                }
                disabled={!CheckCanEditIsNewOrIdRequest(request.status ?? "")}
                type={request.requestType}
              />
              <CorrectionTargetSelect
                label="修正対象"
                value={
                  (request.correctionTargetAttendanceId &&
                    request.correctionTargetAttendanceId !== "") ||
                  request.isAttendanceCancel
                    ? request.correctionTargetAttendanceId
                    : dayjs(
                        request?.acquisitionStartAt.toDate() ?? new Date()
                      ).format("YYYY/MM/DD")
                }
                onChange={e => onChangeCurrentAttendanceCorrection(e)}
                items={workScheduleList}
                disabled={!CheckCanEditIsNewOrIdRequest(request.status ?? "")}
                type={request.requestType}
              />
              <AcquisitionAt
                value={request?.acquisitionStartAt.toDate() ?? new Date()}
                onChange={onAcquisitionDateChangeHandler}
                disabled={
                  !CheckCanEditIsNewOrIdRequest(request.status ?? "") ||
                  request.isAttendanceCancel
                }
                type={request.requestType}
                mode={mode}
              />
              <AcquisitionStartAt
                value={request?.acquisitionStartAt.toDate() ?? new Date()}
                onChange={onStartDateChangeHandler}
                disabled={
                  !CheckCanEditIsNewOrIdRequest(request.status ?? "") ||
                  (IsAttendanceCorrection(request.requestType) &&
                    request.isAttendanceCancel)
                }
                type={request.requestType}
                mode={mode}
                visible={
                  !(
                    IsAttendanceCorrection(request.requestType) &&
                    request.isAttendanceCancel
                  )
                }
              />
              <AcquisitionEndAt
                value={request?.acquisitionEndAt.toDate() ?? new Date()}
                onChange={onEndDateChangeHandler}
                disabled={
                  !CheckCanEditIsNewOrIdRequest(request.status ?? "") ||
                  (IsAttendanceCorrection(request.requestType) &&
                    request.isAttendanceCancel)
                }
                type={request.requestType}
                mode={mode}
                visible={
                  !(
                    IsAttendanceCorrection(request.requestType) &&
                    request.isAttendanceCancel
                  )
                }
              />
              <MultipleInputForm
                label="申請事由"
                value={request.description}
                onChange={e => setRequest({ ...request, description: e })}
                disabled={CheckCanEditIsNewOrIdRequest(request.status ?? "")}
              />
              <MultipleInputForm
                label="備考"
                value={request.memo}
                onChange={e => setRequest({ ...request, memo: e })}
                disabled={CheckCanEditIsNewOrIdRequest(request.status ?? "")}
              />

              {CheckNotCanEditIsCheckedRequest(request.status ?? "") && (
                <MultipleInputForm
                  label="コメント"
                  value={request.managerDescription}
                  onChange={() => {}}
                  disabled={false}
                />
              )}

              <View style={modalFormStyles.formItem}>
                <ModalCancelButton
                  buttonText="キャンセル"
                  onClick={() => hideModal()}
                />

                {CheckCanEditIsNew(request.status ?? "") ? (
                  <ModalApprovalButton
                    buttonText="登録"
                    onClick={() => createRequest()}
                  />
                ) : CheckCanEditIsRequest(request.status ?? "") ? (
                  <ModalApprovalButton
                    buttonText="更新"
                    onClick={() => updateRequest()}
                  />
                ) : (
                  <></>
                )}
              </View>

              {CheckCanEditIsRequest(request.status ?? "") ? (
                <View style={modalFormStyles.formItemCenter}>
                  <ModalDeleteButton
                    buttonText="申請を取り下げる"
                    onClick={() => updateRequest("withdrawal")}
                  />
                </View>
              ) : (
                <></>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
      <Dialog />
    </>
  );
}

/**
 * 申請状況が申請中の時のみtrueを返却します
 * @param status 申請ステータス
 */
const CheckCanEditIsRequest = (status: string) => {
  return status === "applying";
};

/**
 *
 * @param status 新規申請の時のみtrueを返却します
 * @returns
 */
const CheckCanEditIsNew = (status: string, type?: RequestType) => {
  return status === "";
};

/**
 *
 * @param status 新規申請・申請中の時のみtrueを返却します
 * @returns
 */
const CheckCanEditIsNewOrIdRequest = (status: string) => {
  return status === "" || status === "applying";
};

/**
 *
 * @param status 新規申請・申請中の以外の時のみtrueを返却します
 * @returns
 */
const CheckNotCanEditIsCheckedRequest = (status: string) => {
  return status !== "" && status !== "applying";
};

type AttendanceCancelProps = {
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
  type: RequestType;
};
const AttendanceCancelCheckBox = ({
  checked,
  onChange,
  disabled,
  type,
}: AttendanceCancelProps) => {
  return (
    <>
      {/* 勤怠修正申請のみ表示 */}
      {!IsBlanc(type) && IsAttendanceCorrection(type) && (
        <CheckBoxForm
          label="出勤を取り消す"
          checked={checked}
          onChange={() => onChange()}
          disabled={disabled}
        />
      )}
    </>
  );
};

type CorrectionTargetProps = {
  label: string;
  value: string;
  onChange: (e: string) => void;
  disabled: boolean;
  items: Item[];
  type: RequestType;
};
const CorrectionTargetSelect = ({
  label,
  value,
  onChange,
  disabled,
  items,
  type,
}: CorrectionTargetProps) => {
  return (
    <>
      {type === "attendanceCorrection" && (
        <SelectForm
          label={label}
          placeholderLabel="選択してください"
          value={value}
          onChange={onChange}
          items={items}
          disabled={disabled}
        />
      )}
    </>
  );
};

type PaidVacationTypeProps = {
  label: string;
  value: PaidVacationType;
  onChange: (e: PaidVacationType) => void;
  disabled: boolean;
  items: Item[];
  type: RequestType;
};
const PaidVacationTypeSelect = ({
  label,
  value,
  onChange,
  disabled,
  items,
  type,
}: PaidVacationTypeProps) => {
  return (
    <>
      {type === "paidVacation" && (
        <SelectForm
          label={label}
          placeholderLabel="選択してください"
          value={value}
          onChange={onChange}
          disabled={disabled}
          items={items}
        />
      )}
    </>
  );
};

type AcquisitionAtProps = {
  value: Date;
  onChange: (e: Date) => void;
  disabled: boolean;
  mode: "date" | "datetime";
  type: RequestType;
};
const AcquisitionAt = ({
  value,
  onChange,
  disabled,
  mode,
  type,
}: AcquisitionAtProps) => {
  var label = "取得日";
  return (
    <>
      {/* 有給申請は表示 */}
      {IsPaidVacation(type) && (
        <DateForm
          value={value}
          onChange={e => onChange(e)}
          disabled={disabled}
          label={label}
          type={mode}
          hourLabel={undefined}
        />
      )}
    </>
  );
};

type AcquisitionStartAtProps = {
  value: Date;
  onChange: (e: Date) => void;
  disabled: boolean;
  mode: "date" | "datetime";
  type: RequestType;
  visible?: boolean;
};
const AcquisitionStartAt = ({
  value,
  onChange,
  disabled,
  mode,
  type,
  visible,
}: AcquisitionStartAtProps) => {
  var label = "取得開始日";
  if (IsOverTime(type)) label = "開始日";
  if (IsLateness(type)) label = "遅刻日";
  if (IsAttendanceCorrection(type)) label = "出勤日";
  if (IsSubstituteAttendance(type)) label = "振替出勤日";
  if (IsHolidayWork(type)) label = "休日出勤日";
  if (IsStaggeredWorkHours(type)) label = "時差出勤日";
  return (
    <>
      {/* 未選択・残業・早退・有給申請以外は表示 */}
      {!IsBlanc(type) &&
        !IsLeaveEarly(type) &&
        !IsPaidVacation(type) &&
        (visible ?? true) && (
          <DateForm
            value={value}
            onChange={e => onChange(e)}
            disabled={disabled}
            label={label}
            type={mode}
            hourLabel={IsLateness(type) ? "出勤時間" : undefined}
          />
        )}
    </>
  );
};

type AcquisitionEndAtProps = {
  value: Date;
  onChange: (e: Date) => void;
  disabled: boolean;
  mode: "date" | "datetime";
  type: RequestType;
  visible?: boolean;
};
const AcquisitionEndAt = ({
  value,
  onChange,
  disabled,
  mode,
  type,
  visible,
}: AcquisitionEndAtProps) => {
  var label = "取得終了日";
  if (IsOverTime(type)) label = "退勤日";
  if (IsAttendanceCorrection(type)) label = "退勤日";
  if (IsLeaveEarly(type)) label = "早退日";
  return (
    <>
      {/* 未選択・遅刻・有給申請以外は表示 */}
      {!IsBlanc(type) &&
        !IsLateness(type) &&
        !IsPaidVacation(type) &&
        !IsHolidayWork(type) &&
        !IsSubstituteAttendance(type) &&
        !IsStaggeredWorkHours(type) &&
        (visible ?? true) && (
          <DateForm
            value={value}
            onChange={e => onChange(e)}
            disabled={disabled}
            label={label}
            type={mode}
          />
        )}
    </>
  );
};

/** 申請タイプが未選択 */
const IsBlanc = (e: RequestType) => e === "";
/** 申請タイプが有給休暇 */
const IsPaidVacation = (e: RequestType) => e === "paidVacation";
/** 申請タイプが残業 */
const IsOverTime = (e: RequestType) => e === "overtime";
/** 申請タイプが早退 */
const IsLeaveEarly = (e: RequestType) => e === "leaveEarly";
/** 申請タイプが欠勤 */
const IsAbsence = (e: RequestType) => e === "absence";
/** 申請タイプが遅刻 */
const IsLateness = (e: RequestType) => e === "lateness";
/** 申請タイプが私用外出 */
const IsPrivateOuting = (e: RequestType) => e === "privateOuting";
/** 申請タイプが振替出勤 */
const IsSubstituteAttendance = (e: RequestType) => e === "substituteAttendance";
/** 申請タイプが振替休日 */
const IsSubstituteHoliday = (e: RequestType) => e === "substituteHoliday";
/** 申請タイプが時差出勤 */
const IsStaggeredWorkHours = (e: RequestType) => e === "staggeredWorkHours";
/** 申請タイプが勤怠修正 */
const IsAttendanceCorrection = (e: RequestType) => e === "attendanceCorrection";
/** 申請タイプが休日出勤 */
const IsHolidayWork = (e: RequestType) => e === "holidayWork";
/**
 * モーダル内のデフォルトのデータ
 * @param userInfo
 * @returns
 */
const defaultStateValue = (
  userInfo: UserInfo,
  attendanceCorrection: AttendanceCollection[],
  targetDate?: Date
): RequestData => {
  const date =
    targetDate === undefined
      ? dayjs().startOf("D").hour(8).minute(30)
      : dayjs(targetDate).startOf("D").hour(8).minute(30);

  const correctionTargetAttendanceId = FetchLatestAttendanceByDate(
    userInfo.user.uid,
    date.toDate(),
    attendanceCorrection
  );
  return {
    id: "",
    userId: userInfo.user.id,
    requestType: "",
    requestAuthorityId: userInfo.authorityId,
    requestAt: ConvertTimeStampFromDate(new Date()),
    acquisitionStartAt: ConvertTimeStampFromDate(date.toDate()),
    acquisitionEndAt: ConvertTimeStampFromDate(date.add(9, "hour").toDate()),
    status: null,
    description: "",
    memo: "",
    managerDescription: "",
    approveAt: null,
    isAttendanceCancel: false,
    correctionTargetAttendanceId: correctionTargetAttendanceId ?? "",
  };
};
