import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  AddButton,
  DeleteButton,
  NoteBookModal,
  NoteBookPageDeleteModal,
  NoteBookPageList,
  PageLoading,
} from "components";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import {
  DimensionValue,
  Pressable,
  ScrollView,
  Text,
  View,
} from "react-native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import { FetchNoteBookPages } from "server/collectionConnect";
import { pageStyle } from "styles";
import {
  NoteBookPageListType,
  WikiDeleteModalStatus,
  WikiModalStatus,
} from "types";
import { IsWeb, isPresidentOrAccounting } from "utils";

export const NoteBookListScreen = ({
  navigation,
  route,
}: RootStackScreenProps<"NoteBookList">) => {
  const { windowHeight, windowWidth } = useContext(WindowContext);
  const { userInfo } = useContext(UserInfoContext);
  const documentId = route.params.id;
  const { setAlert } = useContext(DialogContext);
  const [isLoading] = useState<boolean>(false);
  const { NoteBookCollection, onSnapShot } = useContext(CollectionContext);
  const [bookEditModal, setBookEditModal] = useState<WikiModalStatus>(null);
  const [isDeleteModal, setIsDeleteModal] =
    useState<WikiDeleteModalStatus>(null);
  const [noteBookPages, setNoteBookPages] = useState<NoteBookPageListType>(
    defaultData()
  );
  const [deleting, setDeleting] = useState<boolean>(false);
  const [deleteList, setDeleteList] = useState<string[]>([]);
  useEffect(() => {
    if (documentId === null || documentId === undefined) {
      setAlert({
        isAlert: true,
        msg: "Wiki情報取得時にエラーが発生しました。一覧に戻ります。",
        confTxt: "OK",
      });
      return;
    }
    if (NoteBookCollection === null) {
      onSnapShot("notebook");
      return;
    }
    const result = FetchNoteBookPages(NoteBookCollection, documentId);
    if (result === null) {
      setNoteBookPages(defaultData());
      return;
    }
    setNoteBookPages(result);
  }, [NoteBookCollection]);

  /** 削除チェックをつけた時の処理 */
  const updateDeletingWiki = (id: string) => {
    const target = deleteList.filter(f => f === id).shift();
    if (target === undefined) {
      setDeleteList([...deleteList, id]);
    } else {
      const targets = deleteList.filter(f => f !== id);
      setDeleteList(targets);
    }
  };

  /** 削除処理中に削除ボタンを押した時の処理 */
  const confirmDeleteButton = () => {
    if (deleteList.length === 0) {
      setDeleting(false);
    } else {
      setIsDeleteModal({
        id: documentId,
        isEdit: true,
        ids: deleteList,
      });
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={pageStyle.container}>
        <View style={pageStyle.page_title_container}>
          {isPresidentOrAccounting(userInfo) && (
            <Pressable
              onPress={() => setBookEditModal({ id: documentId, isEdit: true })}
            >
              <FontAwesomeIcon
                style={pageStyle.header_text}
                name="pencil-square-o"
                size={25}
              />
            </Pressable>
          )}
          <Text style={pageStyle.header_text}>{noteBookPages.bookName}</Text>
        </View>

        <View
          style={{
            height: IsWeb() ? ("700px" as DimensionValue) : "85%",
            width: IsWeb() ? "70%" : (windowWidth as DimensionValue),
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={pageStyle.scroll_container}
          >
            <View>
              <NoteBookPageList
                onClick={id =>
                  navigation.navigate("NoteBookPage", {
                    noteBookId: documentId,
                    noteBookPageId: id,
                  })
                }
                onDeleteClick={id => updateDeletingWiki(id)}
                deleting={deleting}
                deleteList={deleteList}
                NoteBookPageList={noteBookPages.pages}
              />
            </View>
          </ScrollView>
        </View>

        {isPresidentOrAccounting(userInfo) && (
          <>
            <DeleteButton
              onClick={() => setDeleting(true)}
              onDeleteClick={confirmDeleteButton}
              deleting={deleting}
            />
            <AddButton
              onClick={() =>
                navigation.navigate("NoteBookPage", {
                  noteBookId: documentId,
                })
              }
            />
          </>
        )}
        <NoteBookModal
          editStatus={bookEditModal}
          deleteStatus={null}
          onCloseModal={() => setBookEditModal(null)}
        />
        <NoteBookPageDeleteModal
          deleteStatus={isDeleteModal}
          onCloseModal={() => setIsDeleteModal(null)}
          onDelete={() => {
            setDeleteList([]);
            setDeleting(false);
            setIsDeleteModal(null);
          }}
        />
      </View>
    </>
  );
};
const defaultData = (): NoteBookPageListType => {
  return {
    id: "",
    bookName: "",
    pages: [],
  };
};
