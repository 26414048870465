import firebase from "firebase/compat/app";
import { CreateCheckResult } from "types";

/**
 * カレンダーの削除を行います
 * @param reqUser userContextから取得した情報
 * @param calendarId 削除予定のカレンダーID
 * @returns
 */
export const DeleteCalendar = async (
  calendarId: string
): Promise<CreateCheckResult> => {
  return await firebase
    .firestore()
    .collection("calendar")
    .doc(calendarId)
    .delete()
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
