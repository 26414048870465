import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";
import { IsAndroid } from "utils";

type Props = {
  /** ボタンのテキスト */
  buttonText: string;
  /** ボタンを押した時の処理 */
  onClick: () => void;
};

/**
 * キャンセルのモーダルボタンを作成します
 * @param param0
 * @returns
 */
export const ModalCancelButton = ({ buttonText, onClick }: Props) => {
  return (
    <Pressable style={[styles.button]} onPress={onClick}>
      <Text style={styles.buttonText}>{buttonText}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 32,
    paddingVertical: 16,
    width: "40%",
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "gray",
  },

  buttonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: IsAndroid() ? 12 : 15,
  },
});
