import dayjs from "dayjs";
import {
  CalendarCollection,
  CalendarDataWithRequest,
  RequestCollection,
} from "types";
import { AscSort } from "utils";
import { GetViewRequest } from "./utils/GetViewRequest";

/**
 * 整形した担当者カレンダー表示用情報を返却します
 * @param calendars collectionContextから取得したカレンダー情報
 * @param targetDate 対象の日付
 * @param targetUserId 指定のユーザーID
 * @returns CalendarData[]
 */
export const FetchPersonCalendarTargetMonth = (
  calendars: CalendarCollection[] | null,
  requests: RequestCollection[] | null,
  targetDate: Date,
  targetUserId: string
): Array<{ date: Date; calendars: CalendarDataWithRequest[] }> => {
  if (calendars === null || requests === null)
    return [] as Array<{ date: Date; calendars: CalendarDataWithRequest[] }>;

  const monthBeginning = dayjs(targetDate)
    .startOf("month")
    .subtract(1, "week")
    .toDate();
  const monthEnd = dayjs(targetDate).endOf("month").add(1, "week").toDate();

  // 承認済みの有給・振替休日・欠勤を取得
  var targetRequest = GetViewRequest(requests, monthBeginning, monthEnd);
  targetRequest = targetRequest.filter(f => f.userId === targetUserId);
  const targetCalendars = calendars
    .filter(f => f.userId === targetUserId)
    .filter(f =>
      dayjs(f.startDate?.toDate()).isBetween(
        monthBeginning,
        monthEnd,
        null,
        "[]"
      )
    )
    .map(
      m =>
        ({
          uid: m.id,
          title: m.title,
          startDate: m.startDate,
          endDate: m.endDate,
          isHoliday: m.isHoliday,
          isAllDay: m.isAllDay,
          memo: m.memo,
          userId: m.userId,
          siteId: m.site && (m.site.id ?? ""),
          site: m.site && {
            id: m.site.id,
            agreementNumber: m.site.agreementNumber,
            matterNumber: m.site.matterNumber,
            name: m.site.name,
            progressStatus: m.site.progressStatus,
          },
          isRequest: false,
        } as CalendarDataWithRequest)
    );

  const sortCalendar = targetCalendars
    .concat(targetRequest)
    .sort((a, b) => AscSort(a.startDate, b.startDate));

  const currentDate = dayjs(targetDate);
  const endOfDate: dayjs.Dayjs = currentDate.endOf("month");
  const calendarList = [] as Array<{
    date: Date;
    calendars: CalendarDataWithRequest[];
  }>;
  for (
    let day: dayjs.Dayjs = currentDate.startOf("month");
    endOfDate.isSameOrAfter(day);
    day = day.add(1, "day")
  ) {
    const filterCalendarData = sortCalendar.filter(data => {
      const startDate = dayjs(data.startDate.toDate());
      const endDate = dayjs(data.endDate.toDate());

      return day.isBetween(
        startDate.startOf("day"),
        endDate.endOf("day"),
        "day",
        "[]"
      );
    });

    const targetCalendar = {
      date: day.toDate(),
      calendars: filterCalendarData,
    } as { date: Date; calendars: CalendarDataWithRequest[] };

    calendarList.push(targetCalendar);
  }
  return calendarList;
};
