import firebase from "firebase/compat/app";
import { PaidVacationCollection } from "types";

/**
 * paidVacation Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const PaidVacationOnSnapShot = (
  setState: React.Dispatch<
    React.SetStateAction<PaidVacationCollection[] | null>
  >
) => {
  return firebase
    .firestore()
    .collection("paidVacation")
    .onSnapshot(
      docs => {
        const collectionList = docs.docs.map(
          doc => ({ ...doc.data(), id: doc.id } as PaidVacationCollection)
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};
