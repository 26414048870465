import { StatusBar } from "expo-status-bar";
import { UserInfoContext } from "GlobalContext";
import { RootStackScreenProps } from "navigations";
import React, { useContext } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { logout } from "server/Firebase";
import { isPresidentOrAccounting } from "utils";

export function HomeScreen({ navigation }: RootStackScreenProps<"Home">) {
  const { userInfo } = useContext(UserInfoContext);

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.center}>
          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("Dashboard");
            }}
          >
            <Text style={styles.buttonText}>ダッシュボード</Text>
          </Pressable>

          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("Calendar");
            }}
          >
            <Text style={styles.buttonText}>カレンダー</Text>
          </Pressable>

          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("Attendance");
            }}
          >
            <Text style={styles.buttonText}>勤怠管理</Text>
          </Pressable>

          {/* 社長/経理権限の場合のみ表示*/}
          {isPresidentOrAccounting(userInfo) ? (
            <Pressable
              style={styles.button}
              onPress={() => {
                navigation.navigate("EmployeeManagement");
              }}
            >
              <Text style={styles.buttonText}>従業員管理</Text>
            </Pressable>
          ) : (
            <></>
          )}

          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("Site");
            }}
          >
            <Text style={styles.buttonText}>現場管理</Text>
          </Pressable>

          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("Request", {});
            }}
          >
            <Text style={styles.buttonText}>勤怠申請一覧</Text>
          </Pressable>

          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("Configuration");
            }}
          >
            <Text style={styles.buttonText}>設定</Text>
          </Pressable>

          <Pressable
            style={styles.button}
            onPress={() => {
              navigation.navigate("Wiki");
            }}
          >
            <Text style={styles.buttonText}>社内Wiki</Text>
          </Pressable>
          {userInfo?.user.debug === true && (
            <Pressable
              style={styles.button}
              onPress={() => navigation.navigate("AddAttendanceData")}
            >
              <Text style={styles.buttonText}>勤怠追加（開発用）</Text>
            </Pressable>
          )}

          <Pressable
            style={styles.button}
            onPress={() => {
              logout();
              navigation.navigate("SignIn");
            }}
          >
            <Text style={styles.buttonText}>ログアウト</Text>
          </Pressable>
        </View>
      </ScrollView>

      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingTop: 26,
    paddingBottom: 66,
  },
  center: {
    alignItems: "center",
  },
  input: {
    height: 40,
    width: "85%",
    borderColor: "gray",
    marginBottom: 16,
    borderRadius: 10,
  },
  button: {
    width: "80%",
    marginTop: 32,
    paddingVertical: 24,
    borderRadius: 8,
    backgroundColor: "#4169e1",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
});
