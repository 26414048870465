import { CollectionContext, DialogContext } from "GlobalContext";
import { PageLoading } from "components";
import dayjs from "dayjs";
import { StatusBar } from "expo-status-bar";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { FetchPersonCalendarTargetMonth } from "server/collectionConnect";
import { CalendarData, User } from "types";

type PersonalCalendarData = {
  date: Date;
  calendars: CalendarData[];
};

export function CalendarPersonalScreen({
  route,
}: RootStackScreenProps<"CalendarPersonal">) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { CalendarCollection, RequestCollection } =
    useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const user: User = route.params.user;
  const [calendarsData, setCalendarsData] = useState<
    Array<{ date: Date; calendars: CalendarData[] }>
  >([]);
  const [days, setDays] = useState<dayjs.Dayjs[]>([]);
  const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(dayjs());

  useEffect(() => {
    setIsLoading(true);
    const endOfDate: dayjs.Dayjs = dayjs().endOf("month");
    const temporaryDays = [];

    for (
      let day: dayjs.Dayjs = currentDate.startOf("month");
      endOfDate.isSameOrAfter(day);
      day = day.add(1, "day")
    ) {
      temporaryDays.push(day);
    }
    setDays(temporaryDays);

    (async () => {
      try {
        const result = FetchPersonCalendarTargetMonth(
          CalendarCollection,
          RequestCollection,
          currentDate.toDate(),
          user.id
        );
        setCalendarsData(result);
      } catch (error) {
        setAlert({ isAlert: true, msg: "データを取得できませんでした" });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [currentDate]);

  const dayOfWeekColor = (dayOfWeek: dayjs.Dayjs) => {
    switch (dayOfWeek.day()) {
      case 0:
        return "red";
      case 6:
        return "blue";
      default:
        return "black";
    }
  };

  const handlePrevMonth = () => {
    setCurrentDate(currentDate.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentDate(currentDate.add(1, "month"));
  };

  const getCalendarText = (calendar: CalendarData) => {
    if (calendar.isAllDay || calendar.paidVacationType === "allDay") {
      return "終日";
    } else if (calendar.paidVacationType === "morning") {
      return "午前";
    } else if (calendar.paidVacationType === "afternoon") {
      return "午後";
    } else {
      const formattedStartDate = dayjs(calendar.startDate.toDate())
        .ceil("minute", 15)
        .format("M月D日 HH:mm");
      const formattedEndDate = dayjs(calendar.endDate.toDate())
        .ceil("minute", 15)
        .format("M月D日 HH:mm");
      return `${formattedStartDate}〜${formattedEndDate}`;
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <View>
        <View style={styles.navigation}>
          <Text style={styles.navigationMonthTitle}>
            {currentDate.startOf("month").format("YYYY年M月")}
          </Text>
          <View style={styles.navigationRightContent}>
            <Pressable
              style={[styles.button, styles.smallBtn]}
              onPress={() => {
                handlePrevMonth();
              }}
            >
              <Text style={styles.buttonTxt}>&lt;</Text>
            </Pressable>
            <Pressable
              style={[styles.button, styles.smallBtn]}
              onPress={() => {
                handleNextMonth();
              }}
            >
              <Text style={styles.buttonTxt}>&gt;</Text>
            </Pressable>
          </View>
        </View>

        <ScrollView contentContainerStyle={{ paddingBottom: 240 }}>
          {calendarsData.map((data: PersonalCalendarData, index: number) => (
            <View key={index}>
              <View
                style={{
                  borderWidth: 0.5,
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                  backgroundColor: "gainsboro",
                }}
              >
                <Text>
                  {dayjs(data.date).format("M月D日")}
                  &#040;
                  <Text style={{ color: dayOfWeekColor(dayjs(data.date)) }}>
                    {dayjs(data.date).format("dd")}
                  </Text>
                  &#041;
                </Text>
              </View>
              <View style={{ paddingHorizontal: 24, paddingBottom: 12 }}>
                {data.calendars.map((calendar: CalendarData, index: number) => (
                  <View key={index} style={styles.listHead}>
                    <Text>{getCalendarText(calendar)}</Text>
                    <Text>タイトル: {calendar.title}</Text>
                    <View style={{ flexDirection: "row" }}>
                      <Text>種類: </Text>
                      {calendar.isHoliday ? (
                        <Text>休み</Text>
                      ) : (
                        <Text>予定</Text>
                      )}
                    </View>
                    <View>
                      <Text>現場: {calendar.site?.name}</Text>
                      <Text>メモ: {calendar.memo}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: "#fff",
    marginRight: 32,
  },
  navigation: {
    marginBottom: 12,
    paddingHorizontal: 12,
    paddingVertical: 16,
    width: "100%",
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  navigationMonthTitle: {
    fontSize: 20,
    fontWeight: "bold",
    justifyContent: "flex-start",
  },
  navigationRightContent: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  button: {
    padding: 16,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
    marginHorizontal: 8,
  },
  smallBtn: {
    paddingVertical: 6,
    backgroundColor: "#4289cb",
  },
  buttonTxt: {
    fontWeight: "bold",
  },
  listHead: {
    marginVertical: 12,
    paddingBottom: 6,
    borderBottomWidth: 0.5,
  },
});
