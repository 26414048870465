import { NavigationContainer } from "@react-navigation/native";
import {
  CollectionProvider,
  DialogProvider,
  UserInfoProvider,
  WindowProvider,
} from "GlobalContext";
import { Dialog } from "components";
import * as Notifications from "expo-notifications";
import React, { useEffect } from "react";
import { InputAccessoryView, Keyboard, LogBox } from "react-native";
import { Button } from "react-native-elements";
import "react-native-gesture-handler";
import { IsIOS } from "utils";
import { Stacks } from "./navigations";

LogBox.ignoreAllLogs();

// フォアグラウンドで通知を受信した場合の設定
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});
LogBox.ignoreLogs(["Invalid prop textStyle of type array supplied to Cell"]);
LogBox.ignoreLogs([
  "Failed prop type: Invalid prop `style` of type `number` supplied to `Row`, expected `object`",
]);
export default function App() {
  // const Stack = createNativeStackNavigator < RootStackParamList>();
  useEffect(() => {
    // 通知がタップされた場合
    Notifications.addNotificationResponseReceivedListener(response => {
      // 現在のバッジ件数から-1を設定
      Notifications.getBadgeCountAsync().then(badgeCount => {
        // TODO テスト的に通知タップ時にバッジの件数を変更しているが
        // 何故かこの場所でuserInfoやデバイストークンが取得できないためfirebase（userのfcmTokens）とは同期できていない
        Notifications.setBadgeCountAsync(badgeCount - 1);
      });
    });
  }, []);

  return (
    <NavigationContainer>
      <UserInfoProvider>
        <WindowProvider>
          <CollectionProvider>
            <DialogProvider>
              <Stacks />
              <Dialog />
              {IsIOS() && (
                <InputAccessoryView nativeID={"1"}>
                  <Button
                    onPress={() => Keyboard.dismiss()}
                    title="キーボードを閉じる"
                  />
                </InputAccessoryView>
              )}
            </DialogProvider>
          </CollectionProvider>
        </WindowProvider>
      </UserInfoProvider>
    </NavigationContainer>
  );
}
