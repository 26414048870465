import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import { AddButton, PageLoading, RequestModal, Toggle } from "components";

import {
  AttendanceHistoryTable,
  ExpenseApprovalWaitTable,
  ExpenseTable,
  RequestApprovalWaitTable,
  RequestTable,
} from "components";
import dayjs from "dayjs";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import firebase from "firebase/compat/app";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  FetchApprovalWaitRequests,
  FetchAttendanceHistory,
  FetchExpenseApprovalWaits,
  FetchExpenses,
  FetchRequests,
  FetchUserDownLoadList,
  FetchUserItems,
} from "server/collectionConnect";
import {
  AttendanceHistoryList,
  ExpenseApprovalWaitList,
  ExpenseList,
  RequestList,
  RequestModalStatus,
  RequestModalStatusType,
} from "types";
import {
  IsAndroid,
  IsMobile,
  IsWeb,
  isPresidentOrHeadOrAccounting,
} from "utils";
const saveAs = IsMobile() ? null : require("file-saver").saveAs;

export function RequestScreen({ route }: RootStackScreenProps<"Request">) {
  const { setAlert } = useContext(DialogContext);
  const {
    RequestCollection,
    UserCollection,
    SiteCollection,
    ExpenseCollection,
    ExpenseItemCollection,
    ExpenseLimitSettingCollection,
    AttendanceCollection,
    HolidayCollection,
    PaidVacationCollection,
    onSnapShot,
  } = useContext(CollectionContext);
  const [editRequest, setEditRequest] = useState<RequestModalStatus>(null);
  const [isLoading, setIsLoading] = useState<{
    isLoading: boolean;
    text?: string;
  }>({ isLoading: false });
  const { userInfo } = useContext(UserInfoContext);
  const { windowHeight } = useContext(WindowContext);
  const [requestList, setRequestList] = useState<RequestList[] | null>(null);
  const [expenseList, setExpenseList] = useState<ExpenseList[] | null>(null);
  const [expenseApprovalWaitList, setExpenseApprovalWaitList] = useState<
    ExpenseApprovalWaitList[] | null
  >(null);
  expenseApprovalWaitList;
  const [requestApprovalWaitList, setRequestApprovalWaitList] = useState<
    RequestList[] | null
  >(null);
  const [userList, setUserList] = useState<Item[]>([]);
  const [requests, setRequests] = useState<AttendanceHistoryList[] | null>(
    null
  );
  const [selectedButton, setSelectedButton] =
    useState<RequestModalStatusType>("");
  const selectionOnPress = (requestButtonType: RequestModalStatusType) => {
    setSelectedButton(requestButtonType);
  };
  const [targetMonth, setTargetMonth] = useState<dayjs.Dayjs>(dayjs());

  useEffect(() => {
    if (
      RequestCollection === null ||
      UserCollection === null ||
      SiteCollection === null ||
      AttendanceCollection === null ||
      ExpenseCollection === null ||
      ExpenseLimitSettingCollection === null ||
      ExpenseItemCollection === null ||
      HolidayCollection === null ||
      PaidVacationCollection === null
    ) {
      onSnapShot("request");
      onSnapShot("user");
      onSnapShot("site");
      onSnapShot("attendance");
      onSnapShot("expense");
      onSnapShot("expenseLimit");
      onSnapShot("expenseItem");
      onSnapShot("holiday");
      onSnapShot("paidVacation");
      setIsLoading({ isLoading: true, text: "データ読み込み中" });
      return;
    }
    fetchUserList();
    if (selectedButton === "request") fetchRequests();
    if (selectedButton === "attendanceHistory") fetchAttendanceHistory();
    if (selectedButton === "requestApprovalWait") fetchRequestApprovalWait();
    if (selectedButton === "expense") fetchExpenses();
    if (selectedButton === "expenseApprovalWait") fetchExpenseApprovalWait();
  }, [
    userInfo,
    RequestCollection,
    UserCollection,
    SiteCollection,
    ExpenseCollection,
    ExpenseItemCollection,
    ExpenseLimitSettingCollection,
    targetMonth,
    selectedButton,
  ]);

  useEffect(() => {
    var defaultTarget = route.params.target;

    selectionOnPress(defaultTarget !== undefined ? defaultTarget : "request");
  }, []);
  /**
   *勤怠申請一覧のデータを取得します
   */
  const fetchRequests = () => {
    try {
      const result = FetchRequests(
        userInfo,
        RequestCollection,
        targetMonth.toDate()
      );

      setRequestList(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading({ isLoading: false });
    }
  };

  /**
   *勤怠履歴一覧のデータを取得します
   */
  const fetchAttendanceHistory = () => {
    try {
      const result = FetchAttendanceHistory(
        userInfo,
        UserCollection,
        AttendanceCollection,
        RequestCollection,
        HolidayCollection,
        targetMonth.toDate()
      );
      setRequests(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading({ isLoading: false });
    }
  };

  /**
   * ユーザーのリストを取得します
   */
  const fetchUserList = async () => {
    try {
      const users = FetchUserItems(UserCollection);
      setUserList(users);
    } catch {
      setAlert({
        isAlert: true,
        msg: "データが取得できませんでした",
      });
    }
  };

  /**
   * 勤怠承認待ちのデータを取得します
   */
  const fetchRequestApprovalWait = () => {
    try {
      const result = FetchApprovalWaitRequests(
        userInfo,
        RequestCollection,
        targetMonth.toDate()
      );

      setRequestApprovalWaitList(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading({ isLoading: false });
    }
  };

  /**
   * 経費待ちのデータを取得します
   */
  const fetchExpenses = () => {
    try {
      const result = FetchExpenses(
        userInfo,
        ExpenseCollection,
        targetMonth.toDate(),
        ExpenseLimitSettingCollection ?? [],
        ExpenseItemCollection ?? [],
        SiteCollection ?? []
      );
      setExpenseList(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading({ isLoading: false });
    }
  };

  /**
   * 経費承認待ちのデータを取得します
   */
  const fetchExpenseApprovalWait = () => {
    try {
      const result = FetchExpenseApprovalWaits(
        userInfo,
        ExpenseCollection,
        targetMonth.toDate(),
        ExpenseItemCollection,
        ExpenseLimitSettingCollection,
        SiteCollection ?? []
      );

      setExpenseApprovalWaitList(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading({ isLoading: false });
    }
  };

  // 出勤簿のPDFダウンロード
  const pdfDownload = async (): Promise<void> => {
    if (
      userInfo === null ||
      AttendanceCollection === null ||
      RequestCollection === null ||
      HolidayCollection === null
    ) {
      setAlert({
        isAlert: true,
        msg: "データの取得に失敗しました。\n時間をおいて再度試してください",
      });
      return;
    }
    if (userInfo == null) {
      setAlert({ isAlert: true, msg: "ユーザー情報の取得に失敗しました" });
      return;
    }
    if (!setTargetMonth) {
      setAlert({ isAlert: true, msg: "該当年月を選択してください" });
      return;
    }
    setIsLoading({ isLoading: true, text: "データ取得中" });
    try {
      const result = FetchUserDownLoadList(
        userInfo,
        UserCollection,
        AttendanceCollection,
        RequestCollection,
        HolidayCollection,
        targetMonth.toDate()
      );

      if (result === null) {
        setAlert({
          isAlert: true,
          msg: "データの取得に失敗しました。\n時間をおいて再度試してください",
        });
        return;
      }
      setIsLoading({ isLoading: true, text: "PDF出力処理中" });
      await firebase
        .functions()
        .httpsCallable("targetMonthUserAttendancePdfOutput")({
          user: userInfo.user,
          targetMonth: dayjs(targetMonth).format(),
          data: result,
        })
        .then(async res => {
          setIsLoading({ isLoading: false });
          if (res.data) {
            const formatMonth = dayjs(targetMonth).format("YYYY-MM");
            const downloadUrl = await firebase
              .storage()
              .ref(`attendanceRecord/${userInfo.user.id}/${formatMonth}.pdf`)
              .getDownloadURL();

            if (IsWeb()) {
              // WEBの場合は通常のDL
              const downloadFileName = `出勤簿_${userInfo.user.name}_${formatMonth}.pdf`;
              saveAs(downloadUrl, downloadFileName);
            } else {
              const downloadFileName = `${formatMonth}.pdf`;
              const downloadPath = FileSystem.cacheDirectory + downloadFileName;
              const { uri: localUrl } = await FileSystem.downloadAsync(
                downloadUrl,
                downloadPath
              );
              // SPの場合はシェアリングでDLもしくは共有
              Sharing.shareAsync(localUrl, {
                mimeType: "application/pdf",
                dialogTitle: downloadFileName,
                UTI: "application/pdf",
              });
            }
          }
        });
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading({ isLoading: false });
    }
  };

  const onSelectSwitch = (index: number) => {
    if (index === 1) {
      selectionOnPress("request");
    } else if (index === 2) {
      selectionOnPress("attendanceHistory");
    }
  };

  const isVariousRequestGroup = () => {
    return ["request", "attendanceHistory"].includes(selectedButton);
  };

  const SelectedTable = ({
    selectedType,
  }: {
    selectedType: string;
  }): JSX.Element => {
    switch (selectedType) {
      case "request":
        return (
          <RequestTable
            tableList={requestList}
            onEditClick={(e: string) =>
              setEditRequest({ id: e, isEdit: true, type: "request" })
            }
            UserCollection={UserCollection ?? []}
          />
        );
      case "requestApprovalWait":
        return (
          <RequestApprovalWaitTable
            tableList={requestApprovalWaitList}
            onEditClick={(e: string) =>
              setEditRequest({
                id: e,
                isEdit: true,
                type: "requestApprovalWait",
              })
            }
            UserCollection={UserCollection ?? []}
          />
        );
      case "expense":
        return (
          <ExpenseTable
            tableList={expenseList}
            onEditClick={(e: string) =>
              setEditRequest({ id: e, isEdit: true, type: "expense" })
            }
            UserCollection={UserCollection ?? []}
          />
        );
      case "expenseApprovalWait":
        return (
          <ExpenseApprovalWaitTable
            tableList={expenseApprovalWaitList}
            onEditClick={(e: string) =>
              setEditRequest({
                id: e,
                isEdit: true,
                type: "expenseApprovalWait",
              })
            }
            UserCollection={UserCollection ?? []}
          />
        );
      case "attendanceHistory":
        return (
          <AttendanceHistoryTable
            tableList={requests}
            onEditClick={e =>
              setEditRequest({
                id: null,
                isEdit: false,
                type: "request",
                targetDate: targetMonth.date(e).toDate(),
              })
            }
          />
        );
      default:
        return <View></View>;
    }
  };

  if (isLoading.isLoading)
    return (
      <PageLoading isLoading={isLoading.isLoading} text={isLoading.text} />
    );

  return (
    <>
      <View
        style={[
          styles.container,
          windowHeight < 428 && {
            paddingBottom: 428 - windowHeight + 30 + (IsAndroid() ? 50 : 0),
          },
        ]}
      >
        <View style={[styles.headerButtonContainer]}>
          <Pressable
            style={[
              styles.processButton,
              {
                backgroundColor: isVariousRequestGroup()
                  ? "#707070"
                  : "#C0C0C0",
              },
            ]}
            onPress={() => {
              selectionOnPress("request");
              // refreshData();
            }}
          >
            <Text style={styles.buttonText}>勤怠申請</Text>
          </Pressable>
          <Pressable
            style={[
              styles.processButton,
              {
                backgroundColor:
                  selectedButton === "expense" ? "#707070" : "#C0C0C0",
              },
            ]}
            onPress={() => {
              selectionOnPress("expense");
              // refreshData();
            }}
          >
            <Text style={styles.buttonText}>経費申請</Text>
          </Pressable>

          {userInfo && isPresidentOrHeadOrAccounting(userInfo) ? (
            <Pressable
              style={[
                styles.processButton,
                {
                  backgroundColor:
                    selectedButton === "requestApprovalWait"
                      ? "#707070"
                      : "#C0C0C0",
                },
              ]}
              onPress={() => {
                selectionOnPress("requestApprovalWait");
                // refreshData();
              }}
            >
              <Text style={styles.buttonText}>勤怠承認待ち</Text>
            </Pressable>
          ) : (
            <></>
          )}

          {userInfo && isPresidentOrHeadOrAccounting(userInfo) ? (
            <Pressable
              style={[
                styles.processButton,
                {
                  backgroundColor:
                    selectedButton === "expenseApprovalWait"
                      ? "#707070"
                      : "#C0C0C0",
                },
              ]}
              onPress={() => selectionOnPress("expenseApprovalWait")}
            >
              <Text style={styles.buttonText}>経費承認待ち</Text>
            </Pressable>
          ) : (
            <></>
          )}
        </View>
      </View>

      {isVariousRequestGroup() ? (
        <View style={styles.toggleContainer}>
          <Toggle
            selectionMode={selectedButton === "request" ? 1 : 2}
            roundCorner={true}
            option1={"申請一覧"}
            option2={"勤怠履歴"}
            onSelectSwitch={onSelectSwitch}
            selectionColor={"black"}
          />
          <Pressable
            style={[styles.downloadButton, { backgroundColor: "#7879F1" }]}
            onPress={pdfDownload}
          >
            <Text style={styles.buttonText}>出勤簿ダウンロード</Text>
          </Pressable>
        </View>
      ) : (
        <></>
      )}

      {/* <View style={styles.monthContainer}> */}
      <View
        style={[
          styles.monthContainer,
          selectedButton === "attendanceHistory" &&
            windowHeight < 428 && { paddingBottom: 80 },
        ]}
      >
        <Pressable
          style={[styles.monthView]}
          onPress={() => {
            setTargetMonth(targetMonth.subtract(1, "M"));
          }}
        >
          <Text style={styles.monthViewValueText}>←前の月へ</Text>
        </Pressable>
        <Pressable
          style={[styles.monthView, IsWeb() ? { width: 150 } : { width: 90 }]}
        >
          <Text
            style={[
              styles.monthViewValueText,
              IsWeb()
                ? { fontSize: 30, lineHeight: 10 }
                : { fontSize: 20, lineHeight: 20 },
            ]}
          >
            {targetMonth.format("YYYY/MM")}
          </Text>
        </Pressable>
        <Pressable
          style={[styles.monthView]}
          onPress={() => {
            setTargetMonth(targetMonth.add(1, "M"));
          }}
        >
          <Text style={styles.monthViewValueText}>次の月へ→</Text>
        </Pressable>
      </View>
      <View style={styles.listContainer}>
        <SelectedTable selectedType={selectedButton} />
      </View>
      {CheckRequest(selectedButton) && (
        <AddButton
          onClick={() =>
            setEditRequest({ id: null, isEdit: false, type: selectedButton })
          }
        />
      )}
      <RequestModal
        modalStatus={editRequest}
        onCloseModal={() => setEditRequest(null)}
      />
    </>
  );
}

/**
 * 申請型のタブであればtrueを返します
 * @param status
 * @returns
 */
const CheckRequest = (status: string) => {
  return status === "request" || status === "expense";
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 10,
  },
  toggleContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "#fff",
  },
  monthContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  listContainer: {
    flex: 5,
    paddingBottom: IsMobile() ? 100 : 0,
    backgroundColor: "#fff",
  },
  footerContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headerButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    height: 100,
    justifyContent: IsMobile() ? "space-between" : undefined,
  },
  processButton: {
    top: 0,
    left: 0,
    right: 0,
    alignSelf: "center",
    borderRadius: 20,
    elevation: 3,
    width: 150,
    height: 30,
    margin: 5,
    textAlign: "center",
  },
  downloadButton: {
    top: 0,
    left: 0,
    right: 0,
    alignSelf: "center",
    borderRadius: 10,
    elevation: 5,
    width: 150,
    height: 35,
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
  monthView: {
    height: 30,
    margin: 15,
  },
  monthViewValueText: {
    textAlign: "center",
    color: "black",
    fontWeight: "900",
    fontSize: 15,
  },
});
