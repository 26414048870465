import firebase from "firebase/compat/app";
import { User, UserInfo } from "types";

export async function FetchUserInfo(): Promise<UserInfo | null> {
  try {
    const currentUser = firebase.auth().currentUser;
    if (currentUser === null) return null;
    const user = await firebase
      .firestore()
      .collection("user")
      .where("uid", "==", currentUser.uid)
      .get()
      .then(doc => {
        if (doc.docs.length > 0) {
          const r = doc.docs[0];
          return { ...r.data(), id: r.id } as User;
        }
        return null;
      });
    const authorityId = await firebase
      .functions()
      .httpsCallable("fetchCurrentUserCustomClaim")()
      .then(res => {
        return res.data;
      })
      .catch(e => {
        return null;
      });
    if (user === null || authorityId === null) return null;
    return { user: user, authorityId: authorityId };
  } catch (error: any) {
    console.log(error.message);
    return null;
  }
}
