import dayjs from "dayjs";
import {
  CreateCheckResult,
  ExpenseCollection,
  ExpenseLimitSettingCollection,
} from "types";
import { ExpenseData } from "types/Expense";
import { GetBalanceAmount } from "./GetBalanceAmount";

/**
 * 経費申請で現場経費以外の時に経費枠を判定するようにする
 * @param expense 登録・更新する申請情報
 * @param expenses 経費申請情報
 * @param expenseLimitSettings 経費枠情報
 */
export const CheckExpenseLimitSetting = (
  /** 登録・更新する申請情報 */
  expense: ExpenseData,
  /** 経費申請情報 */
  expenses: ExpenseCollection[],
  /** 経費枠情報 */
  expenseLimitSettings: ExpenseLimitSettingCollection[]
): CreateCheckResult => {
  const result = IsValidity(
    expense.expenseLimitSettingId,
    expense.reckoningAt,
    expenseLimitSettings
  );

  if (!result.isCheck) return result;

  const expenseLimitSetting = result.data;

  // 利用日から経費枠が使用かどうかをチェック
  if (expenseLimitSetting === undefined)
    return {
      isCheck: false,
      message: "データの取得に失敗しました。",
    };

  const useTotalAmount = GetBalanceAmount(
    expenses,
    expense.expenseLimitSettingId,
    expense.userId ?? "",
    expenseLimitSetting
  );

  if (expense.totalAmount > useTotalAmount) {
    return {
      isCheck: false,
      message: "利用予定の経費枠の上限金額を超えています。",
    };
  }

  return {
    isCheck: true,
    message: "",
  };
};

/**
 * 指定した経費枠が利用可能かどうかをチェックする
 * @param id expenseLimitSetting.documentId
 * @param targetDate 利用日
 * @param expenseLimitSettings 経費枠情報
 * @returns
 */
const IsValidity = (
  /** expenseLimitSetting.documentId */
  id: string,
  /** 利用日 */
  targetDate: FirebaseFirestore.Timestamp | null,
  /** 経費枠情報 */
  expenseLimitSettings: ExpenseLimitSettingCollection[]
): CreateCheckResult & { data?: ExpenseLimitSettingCollection } => {
  if (targetDate === null)
    return { isCheck: false, message: "利用日が指定されていません。" };

  var target = expenseLimitSettings
    .filter(f => f.id === id)
    .filter(f => !f.deleted);
  if (target.length === 0)
    return {
      isCheck: false,
      message:
        "経費枠が削除されたか、見つかりません。\n別の経費枠を選択するか、再度申請してください。",
    };

  target = target.filter(f =>
    dayjs(targetDate.toDate()).isBetween(
      f.grantDate?.toDate(),
      f.expirationDate?.toDate(),
      null,
      "[]"
    )
  );
  if (target.length === 0)
    return {
      isCheck: false,
      message: "利用日が経費枠の付与日前か、有効期限より後になっています。",
    };

  return {
    isCheck: true,
    message: "",
    data: target[0],
  };
};
