import { HomeButton } from "components";
import { StatusBar } from "expo-status-bar";
import { UserInfoContext } from "GlobalContext";
import { RootStackScreenProps } from "navigations";
import React, { useContext } from "react";
import { View } from "react-native";
import { pageStyle } from "styles";
import { isPresidentOrAccounting } from "utils";

export function ConfigurationScreen({
  navigation,
}: RootStackScreenProps<"Configuration">) {
  const { userInfo } = useContext(UserInfoContext);
  return (
    <View style={pageStyle.basic_container}>
      <HomeButton
        onClick={() => navigation.navigate("Profile")}
        title="プロフィール設定"
      />
      {/* 社長・経理権限の場合のみ表示 */}
      {isPresidentOrAccounting(userInfo) && (
        <>
          <HomeButton
            onClick={() => navigation.navigate("Holidays")}
            title="休日設定"
          />
          <HomeButton
            onClick={() => navigation.navigate("ExpenseLimitSetting")}
            title="経費枠設定"
          />
          <HomeButton
            onClick={() => navigation.navigate("PaidVacation")}
            title="有給設定"
          />
        </>
      )}
      <StatusBar style="auto" />
    </View>
  );
}
