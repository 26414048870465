import firebase from "firebase/compat/app";
import { CreateCheckResult, UserInfo } from "types";

/**
 * 有給有効期限設定の更新の書き込みを行います
 * @param reqUser userContextから取得した情報
 * @param expiration 有給有効期限
 * @returns
 */
export const UpdateExpiration = async (
  reqUser: UserInfo | null,
  expiration: number
): Promise<CreateCheckResult> => {
  const date = new Date();

  const doc = await firebase
    .firestore()
    .collection("company")
    .doc("company")
    .get();

  return await doc.ref
    .update({
      ...doc.data(),
      expiration: expiration,
    })
    .then(() => {
      return { isCheck: true, message: "更新しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "更新に失敗しました" };
    });
};
