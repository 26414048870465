/**
 * NULL・Undefinedかどうかをチェックします
 * @param target 判定を行う値
 * @param defaultValue 判定時にNULLもしくはUndefinedになったときにセットする値
 * @returns
 */
export const CheckNullOrUndefined = <T>(
  target: T | null | undefined
): boolean => {
  if (target === null) return false;
  if (target === undefined) return false;
  return true;
};
