import firebase from "firebase/compat/app";
import { CreateCheckResult, UserInfo } from "types";

/**
 * Wikiのページ削除を行います
 * @param userInfo userContextから取得したユーザー情報
 * @param ids 削除対象のIDの配列
 * @param noteBooks collectionContextから取得したWiki情報
 * @returns　CreateCheckResult
 */
export const DeleteNoteBookPage = async (
  userInfo: UserInfo | null,
  ids: string[],
  notebookId: string
): Promise<CreateCheckResult> => {
  const date = new Date();
  if (ids === null)
    return { isCheck: false, message: "削除対象が選択されていません" };

  const user = userInfo?.user;
  const detailRequest = await Promise.all(
    ids.map(async id => {
      return firebase
        .firestore()
        .collection("noteBook")
        .doc(notebookId)
        .collection("noteBookPage")
        .doc(id)
        .update({
          deleted: true,
          updateAt: date,
          updateUser: user?.id,
        })
        .then(() => true)
        .catch(() => false);
    })
  );

  if (detailRequest.find(f => f === false))
    return { isCheck: false, message: "削除に失敗しました" };

  return await firebase
    .firestore()
    .collection("noteBook")
    .doc(notebookId)
    .update({
      updateAt: date,
      updateUser: user?.id,
    })
    .then(() => ({ isCheck: true, message: "削除しました" }))
    .catch(() => ({ isCheck: false, message: "削除に失敗しました" }));
};
