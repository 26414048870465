import {
  ExpenseCollection,
  ExpenseData,
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  ImageData,
  UserInfo,
} from "types";
import { AscSort } from "utils";

/**
 * 指定の経費申請情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param expenses collectionContextから取得した経費情報
 * @param expenseLimitSetting collectionContextから取得した経費枠情報
 * @param expenseItem collectionContextから取得した経費枠情報
 * @param targetId 指定の経費ID
 * @returns ExpenseData
 */
export const FetchExpense = (
  userInfo: UserInfo | null,
  expenses: ExpenseCollection[] | null,
  expenseLimitSetting: ExpenseLimitSettingCollection[],
  expenseItem: ExpenseItemCollection[],
  targetId: string | null
): (ExpenseData & { images?: ImageData[] }) | null => {
  if (expenses === null || targetId === null) return null;

  const targetExpense = expenses
    .filter(request => request.id === targetId)
    .shift();

  if (targetExpense === undefined) return null;

  const sortExpenseHistory = targetExpense.expenseHistory
    .filter(history => history.status === "approval")
    .sort((a, b) => AscSort(a.updateAt ?? 0, b.updateAt ?? 0));

  const approveAt = sortExpenseHistory
    .filter(f => f.status === "approval")
    .splice(1, 1);
  const rejectAt = sortExpenseHistory
    .filter(f => f.status === "reject")
    .splice(1, 1);
  const receivedAt = sortExpenseHistory
    .filter(f => f.status === "received")
    .splice(1, 1);

  const sortPaymentHistory = targetExpense.paymentHistory.sort((a, b) =>
    AscSort(a.updateAt ?? 0, b.updateAt ?? 0)
  );

  let paymentAt = null as FirebaseFirestore.Timestamp | null;
  let paymentAmountTotal = 0;
  sortPaymentHistory.forEach(doc => {
    paymentAt = doc.paymentAt;
    paymentAmountTotal += doc.paymentAmount ?? 0;
  });

  const filterExpenseItem = expenseItem
    .filter(f => f.id === targetExpense.id)
    .shift();
  const isNoExpenseLimit = filterExpenseItem
    ? filterExpenseItem.isNoExpenseLimit
    : false;

  return {
    id: targetExpense.id,
    userId: targetExpense.userId,
    siteId: targetExpense.siteId,
    requestAuthorityId: targetExpense.requestAuthorityId,
    requestAt: targetExpense.requestAt,
    reckoningAt: targetExpense.reckoningAt,
    totalAmount: targetExpense.totalAmount,
    status: targetExpense.status,
    description: targetExpense.description,
    memo: targetExpense.memo,
    managerDescription: targetExpense.managerDescription,
    approveAt: approveAt[0] ? approveAt[0].updateAt : null,
    paymentAt: paymentAt,
    paymentAmount: paymentAmountTotal,
    paymentStatus: targetExpense.paymentStatus,
    expenseItemId: targetExpense.expenseItemId,
    isNoExpenseLimit: isNoExpenseLimit,
    expenseLimitSettingId: targetExpense.expenseLimitSettingId,
    images: targetExpense.images,
  } as ExpenseData & { images: ImageData[] };
};
