import dayjs from "dayjs";
import {
  AttendanceCollection,
  HolidayCollection,
  RequestCollection,
  UserCollection,
  UserDownLoad,
  UserInfo,
} from "types";
import { GetAttendanceHistory } from "../attendance";
import { GetDiffString, GetRequestString } from "../utils/downLoad";

/**
 *　最新の勤怠情報を返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param userInfo userContextから取得した情報
 * @param attendances collectionContextから取得した勤怠情報
 * @returns
 */
export const FetchUserDownLoadList = (
  userInfo: UserInfo | null,
  users: UserCollection[] | null,
  attendances: AttendanceCollection[] | null,
  requests: RequestCollection[] | null,
  holidays: HolidayCollection[] | null,
  targetDate: Date
): UserDownLoad | null => {
  if (
    attendances === null ||
    requests === null ||
    holidays === null ||
    users === null ||
    userInfo === null
  )
    return null;

  const baseAttendances = GetAttendanceHistory(
    userInfo.user.id,
    attendances,
    requests,
    holidays,
    users,
    targetDate
  );

  if (baseAttendances === null) {
    return null;
  }

  const redDays: number[] = [];
  const blueDays: number[] = [];
  /** 申請欄の文字列の長さ情報 */
  const requestStrings = [] as {
    index: number;
    length: number;
  }[];

  // データを変換
  const targets = baseAttendances.data.map((data, ii) => {
    if (data.dateColor === "red") redDays.push(ii);
    if (data.dateColor === "blue") blueDays.push(ii);
    const requestString = GetRequestString(data.request);
    // 申請系統の文字列の長さを取得
    requestStrings.push({ index: ii, length: requestString.length });

    return {
      // 日
      ["day" + ii]: data.date.toString(),
      // 曜日
      ["dw" + ii]: data.dayOfWeek,
      // 申請
      ["request" + ii]: requestString,
      // 出勤種類
      ["remark" + ii]: data.title,
      // 出勤時間
      ["atttendance_start" + ii]:
        data.startWork === null ? "" : dayjs(data.startWork).format("HH:mm"),
      // 退社時間
      ["atttendance_end" + ii]:
        data.endWork === null ? "" : dayjs(data.endWork).format("HH:mm"),
      // 休憩時間
      ["break" + ii]: GetDiffString(data.restTime),
      // 実働時間
      ["actual_work" + ii]: GetDiffString(data.workingTime),
      // 残業時間
      ["overtime" + ii]: GetDiffString(data.overWorkingTime),
      // 深夜時間
      ["late_night" + ii]: GetDiffString(data.midnightTime),
    };
  });

  // オブジェクト型に変換
  var result = {} as { [x: string]: string };
  targets.forEach(f => {
    result = { ...result, ...f };
  });

  return {
    name: userInfo.user.name,
    month: dayjs(targetDate).format("YYYY年MM月"),
    remark_sum: baseAttendances.workingDay,
    actual_work_sum: baseAttendances.workingTimeSum,
    overtime_sum: baseAttendances.overWorkingTimeSum,
    late_night_sum: baseAttendances.midnightTimeSum,
    redDays: redDays,
    blueDays: blueDays,
    otherData: result,
    requestStrings: requestStrings,
  };
};
