import dayjs from "dayjs";
import { RequestCollection } from "types";

/**
 *
 * @param requests 申請情報
 * @param id paidVacation.documentId
 */
export const GetBalancePaidVacation = (
  /** 申請情報 */
  requests: RequestCollection[],
  /** paidVacation.documentId */
  id: string
) => {
  var count = 0;
  requests
    .filter(f => f.paidVacationId === id)
    .filter(f => f.status === "approval")
    .forEach(m => {
      if (m.paidVacationType === 'allDay') return count += 1;
      if (m.paidVacationType === 'morning' || m.paidVacationType === 'afternoon') return count += 0.5;
      if (m.acquisitionStartAt && m.acquisitionEndAt) 
        // 既存のデータ処理
        count +=
          dayjs(m.acquisitionEndAt.toDate())
            .startOf('date')
            .diff(dayjs(m.acquisitionStartAt.toDate()).startOf('date'), 'day') + 1;
    });
  return count;
};
