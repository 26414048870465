import React from "react";
import { StyleSheet, View } from "react-native";

/**
 * モバイル用のテーブルの列を作成します
 * @param param0
 * @returns
 */
export const CreateRow = ({
  children,
}: {
  /** 子要素 */
  children?: React.ReactNode;
}) => <View style={styles.tableRow}>{children}</View>;

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: "row",
  },
});
