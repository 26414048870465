import {
  ACCOUNTING,
  EMPLOYEE,
  HEAD,
  PART_TYPE,
  PRESIDENT,
} from "constants/index";

/**
 * ユーザーの権限情報を取得します
 * @param type user.AuthorityType
 * @returns
 */
export const GetUserAuthority = (type: string) => {
  switch (type) {
    case PART_TYPE:
      return {
        id: PART_TYPE,
        text: "アルバイト",
        sort: 5,
      };
    case EMPLOYEE:
      return {
        id: EMPLOYEE,
        text: "社員",
        sort: 4,
      };
    case ACCOUNTING:
      return {
        id: ACCOUNTING,
        text: "経理",
        sort: 3,
      };
    case HEAD:
      return {
        id: HEAD,
        text: "部長",
        sort: 2,
      };
    case PRESIDENT:
      return {
        id: PRESIDENT,
        text: "社長",
        sort: 1,
      };
    default:
      return {
        id: "NONE",
        text: "",
        sort: 999,
      };
  }
};
