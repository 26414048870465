import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  ExpenseCollection,
  ExpenseData,
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  ImageDataSources,
  UserInfo,
} from "types";
import { imagePutStrage } from "utils";
import { CheckExpenseLimitSetting } from "./utils";

/**
 * 経費枠の新規作成の書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param expenseLimitSettingData 登録対象の現場情報
 * @returns　CreateCheckResult
 */

export const CreateExpense = async (
  reqUser: UserInfo | null,
  expenses: ExpenseCollection[] | null,
  expenseItems: ExpenseItemCollection[] | null,
  expenseLimitSettings: ExpenseLimitSettingCollection[] | null,
  expense: ExpenseData,
  imgDataSources: ImageDataSources
): Promise<CreateCheckResult> => {
  const date = new Date();
  const user = reqUser?.user ?? null;

  if (
    expenseItems === null ||
    expenseLimitSettings === null ||
    expenses === null
  ) {
    return { isCheck: false, message: "登録に失敗しました" };
  }

  try {
    // 現場経費以外の場合
    if (expense.expenseItemId) {
      const targetExpenseItem = expenseItems
        .filter(f => f.id === expense.expenseItemId)
        .shift();
      if (
        targetExpenseItem !== undefined &&
        !targetExpenseItem.isNoExpenseLimit
      ) {
        // 経費枠が利用可能かどうかをチェックする
        const result = CheckExpenseLimitSetting(
          expense,
          expenses,
          expenseLimitSettings
        );

        if (!result.isCheck) {
          return result;
        }
      }
    }
    const result: CreateCheckResult = await firebase
      .firestore()
      .collection("expense")
      .add({
        userId: expense.userId,
        siteId: expense.siteId,
        requestAuthorityId: expense.requestAuthorityId,
        requestAt: expense.requestAt.toDate(),
        reckoningAt: expense.reckoningAt?.toDate(),
        totalAmount: expense.totalAmount,
        status: "applying",
        description: expense.description,
        memo: expense.memo,
        paymentStatus: "unpaid",
        expenseItemId: expense.expenseItemId,
        expenseLimitSettingId: expense.expenseLimitSettingId,
        createAt: date,
        createUser: user?.id,
        updateAt: date,
        updateUser: user?.id,
      })
      .then(async requestDoc => {
        await firebase
          .firestore()
          .collection("expense")
          .doc(requestDoc.id)
          .collection("expenseHistory")
          .add({
            updateAt: date,
            updateUser: user?.id,
            status: "applying",
          });

        await imagePutStrage({
          docId: requestDoc.id,
          imgDataSources: imgDataSources,
          folderName: "expense",
          functionsName: "updateExpenseImage",
        });
        return { isCheck: true, message: "登録しました", id: requestDoc.id };
      })
      .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));

    if (!result.isCheck) return result;

    const lastResult = await firebase
      .firestore()
      .collection("expense")
      .doc(result.id ?? "")
      .update({
        updateAt: date,
        updateUser: user?.id,
      })
      .then(() => ({
        isCheck: true,
        message: "登録しました",
        id: result.id,
      }))
      .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));
    await new Promise(resolve => setTimeout(resolve, 1000));
    return lastResult;
  } catch (error: any) {
    return { isCheck: false, message: "登録に失敗しました" };
  }
};
