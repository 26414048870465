import React from "react";
import { StyleSheet, View } from "react-native";

/**
 * 左詰の列要素を作成します
 * @param param0
 * @returns
 */
export const CreateLeftCol = ({
  children,
}: {
  /** 子要素 */
  children?: React.ReactNode;
}) => {
  return <View style={styles.table_left_column}>{children}</View>;
};

/**
 * 右詰の列要素を作成します
 * @param param0
 * @returns
 */
export const CreateRightCol = ({
  children,
}: {
  /** 子要素 */
  children?: React.ReactNode;
}) => {
  return <View style={styles.table_right_column}>{children}</View>;
};

/**
 * 中央寄せの列要素を作成します
 * @param param0
 * @returns
 */
export const CreateCenterCol = ({
  children,
}: {
  /** 子要素 */
  children?: React.ReactNode;
}) => {
  return <View style={styles.table_center_column}>{children}</View>;
};

const styles = StyleSheet.create({
  table_left_column: {
    borderColor: "#fff",
    borderWidth: 1,
    flex: 2,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  table_right_column: {
    borderColor: "#fff",
    borderWidth: 1,
    flex: 2,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  table_center_column: {
    borderColor: "#fff",
    borderWidth: 1,
    flex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
});
