import { AddForm, ModalCloseButton } from "components";
import React from "react";
import { View } from "react-native";
import { Item } from "react-native-picker-select";
import { modalStyles } from "styles";
import { PaidVacationModalStatus } from "types";

import { ModalLibrary } from "utils";

type Props = {
  modalStatus: PaidVacationModalStatus;
  onCloseModal: () => void;
  expiration: number;
  userList: Item[];
};

/**
 * 有給休暇付与・編集モーダルを生成します
 * @param param0
 * @returns
 */
export function AddModal({
  modalStatus,
  onCloseModal,
  expiration,
  userList,
}: Props) {
  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={modalStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />

          <AddForm
            hideModal={onCloseModal}
            modalStatus={modalStatus}
            expiration={expiration}
            userList={userList}
          />
        </View>
      </ModalLibrary>
    </>
  );
}
