import React from "react";
import { Pressable, StyleSheet, View } from "react-native";
import { CheckBox, Icon, Text } from "react-native-elements";
import { IsWeb } from "utils";

type Props = {
  /** ボタンクリック時のアクション定義 */
  onClick: () => void;
  /** 削除操作中かどうか
   *
   * 削除処理：true
   */
  deleting?: boolean;
  /** 削除チェックをクリックした時の処理 */
  onDeleteClick?: (check: boolean) => void;
  /** 削除チェック
   *
   * チェックあり：true
   */
  isDelete?: boolean;
  /** アイコン下の説明テキスト */
  text?: string;
  /** アイコンの種類名 */
  iconName?: string;
  /** アイコンの種類 */
  iconType?: string;
  /** 背景色 */
  color?: string;
  /** アイコンの色 */
  iconColor?: string;
};

/**
 * アイコン付きのボックスを生成します
 * @param param0
 * @returns
 */
export const Box = ({
  onClick,
  onDeleteClick,
  isDelete,
  deleting,
  text,
  iconName,
  iconType,
  iconColor,
  color,
}: Props) => {
  return (
    <View style={{ padding: 20 }}>
      <Pressable
        onPress={() => {
          deleting ? onDeleteClick && onDeleteClick(!isDelete) : onClick();
        }}
      >
        <View style={deleting && styles.deleting_container}>
          {deleting && (
            <CheckBox
              containerStyle={[styles.checkbox]}
              checkedColor="red"
              uncheckedColor="white"
              checked={isDelete}
              onPress={() => onDeleteClick && onDeleteClick(!isDelete)}
            />
          )}
          <View
            style={[
              styles.boxContainer,
              { backgroundColor: color },
              styles.box_size,
            ]}
          >
            {iconName !== undefined && iconType !== undefined && (
              <Icon
                name={iconName}
                type={iconType}
                size={40}
                color={iconColor}
              />
            )}
          </View>
        </View>

        {text && (
          <View style={{ width: 100 }}>
            <Text style={styles.text} numberOfLines={2} ellipsizeMode="tail">
              {text}
            </Text>
          </View>
        )}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  deleting_container: {
    position: "relative",
  },
  box_size: {
    height: 100,
    width: 100,
  },
  mobile_icon_size: {},
  web_icon_size: {},
  boxContainer: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    zIndex: 10,
  },
  checkbox: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0,
    padding: 0,
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 20,
    marginLeft: -10,
    marginRight: 0,
  },
  text: {
    textAlign: "center",
    fontSize: IsWeb() ? 16 : undefined,
  },
});
