import dayjs from "dayjs";
import {
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  ExpenseLimitSettingList,
  UserCollection,
} from "types";
import { AscSort, GetUserName } from "utils";
import { GetItemName } from "utils/GetItemName";

/**
 * 整形した経費枠情報を返却します
 * @param expenseLimitSettings collectionContextから取得した経費枠情報
 * @param targetMonth 対象月
 * @returns ExpenseLimitSettingList[]
 */
export const FetchExpenseLimitSettings = (
  expenseLimitSettings: ExpenseLimitSettingCollection[] | null,
  targetMonth: Date,
  UserCollection: UserCollection[],
  ExpenseItemCollection: ExpenseItemCollection[]
): ExpenseLimitSettingList[] => {
  if (expenseLimitSettings === null) return [] as ExpenseLimitSettingList[];
  const targetExpenseLimitSettings = expenseLimitSettings
    .filter(f => f.deleted !== true)
    // 指定日の月以前に付与される
    .filter(
      t =>
        dayjs(t.grantDate?.toDate()).startOf("date").toDate() <=
        dayjs(targetMonth).endOf("month").toDate()
    )
    // 指定日以降に有効期限が切れる
    .filter(
      t =>
        dayjs(t.expirationDate?.toDate()).startOf("date").toDate() >=
        dayjs(targetMonth).startOf("month").toDate()
    );

  return targetExpenseLimitSettings
    .sort((a, b) => {
      const sort1 = UserCollection.filter(f => f.id === a.userId);
      const sort2 = UserCollection.filter(f => f.id === b.userId);

      return AscSort(sort1[0]?.sort ?? 99999, sort2[0]?.sort ?? 99999);
    })
    .map(t => {
      return {
        id: t.id,
        userName: GetUserName(UserCollection, t.userId ?? ""),
        itemName: GetItemName(ExpenseItemCollection, t.itemId ?? ""),
        amountLimit: t.amountLimit,
        grantDate: t.grantDate,
        expirationDate: t.expirationDate,
        updateAt: t.updateAt,
      } as ExpenseLimitSettingList;
    });
};
