import dayjs from "dayjs";
import {
  GetDiffString,
  GetRequestString,
} from "server/collectionConnect/utils/downLoad";
import {
  AttendanceCollection,
  AttendanceHistoryBase,
  FontColor,
  HolidayCollection,
  IsRequest,
  RequestCollection,
  RequestType,
  TargetAttendance,
  UserCollection,
} from "types";

type BaseAttendance = {
  scheduleStart: Date | null;
  scheduleEnd: Date | null;
  beforeOriginalStart: Date | null;
  beforeOriginalEnd: Date | null;
  originalStart: Date | null;
  originalStartColor: FontColor;
  originalEnd: Date | null;
  originalEndColor: FontColor;
  start: Date | null;
  startColor: FontColor;
  end: Date | null;
  endColor: FontColor;
  breakStart: Date | null;
  breakEnd: Date | null;
};
export const GetAttendanceHistory = (
  userId: string,
  attendances: AttendanceCollection[] | null,
  requests: RequestCollection[] | null,
  holidays: HolidayCollection[] | null,
  users: UserCollection[] | null,
  targetDate: Date
): TargetAttendance | null => {
  if (
    attendances === null ||
    requests === null ||
    holidays === null ||
    users === null
  )
    return null;

  /** ダウンロード指定開始日 */
  const monthBeginning = dayjs(targetDate).startOf("month").toDate();
  /** ダウンロード指定終了日 */
  const monthEnd = dayjs(targetDate).endOf("month").toDate();
  /** ダウンロード対象のユーザー */
  const user = users.filter(f => f.id === userId).shift();

  if (user === undefined) return null;

  /** 対象者の勤怠情報 */
  const targetAttendances = attendances
    .filter(f => f.userId === user.uid)
    .filter(f => (f.isCancel ?? false) === false);

  /** 対象者の承認済み申請情報 */
  const targetRequests = requests
    .filter(f => f.userId === userId)
    .filter(f => f.status === "approval");

  /** 対象月の休日情報 */
  const targetHolidays = GetTargetHoliday(holidays, monthBeginning, monthEnd);

  /** 勤務日数合計 */
  var workingDay = 0;
  /** 残業時間合計 */
  var overWorkingTimeSum = 0;
  /** 深夜時間合計 */
  var midnightTimeSum = 0;
  /** 休憩時間合計 */
  var restTimeSum = 0;
  /** 勤務時間合計 */
  var workingTimeSum = 0;
  /** 申請欄の文字列の長さ情報 */
  var requestStrings = [] as {
    index: number;
    length: number;
  }[];

  // 対象月の日数分繰り返し
  const targets = [...Array(monthEnd.getDate())].map((_, ii) => {
    const index = ii + 1;
    /** 開始対象日 */
    const targetStartDate = dayjs(targetDate)
      .date(index)
      .startOf("date")
      .toDate();

    /** 終了対象日 */
    const targetEndDate = dayjs(targetDate).date(index).endOf("date").toDate();

    /** 対象日の出退勤情報 */
    const targetAttendance = targetAttendances
      .filter(f =>
        dayjs(f.startWorkSchedule?.toDate()).isBetween(
          targetStartDate,
          targetEndDate,
          null,
          "[]"
        )
      )
      .shift();
    /** 対象日の申請情報 */
    const requestList = GetTargetRequestList(
      targetRequests,
      targetStartDate,
      targetEndDate
    );
    /** 対象日の休日情報 */
    const holidayList = GetTargetHoliday(
      targetHolidays,
      targetStartDate,
      targetEndDate
    );

    /** 出退勤情報 */
    var baseAttendance = null as BaseAttendance | null;
    baseAttendance = GetWorkingData(
      requestList,
      targetAttendance,
      user,
      targetStartDate
    );
    /** 対象日の勤務実績を表示するかどうかのフラグ */
    var isUserBreak = false;

    if (CheckUserHoliday(requestList, holidayList, user)) {
      isUserBreak = true;
    }

    /** 申請列の文字列 */
    const request = GetRequestString(requestList);
    /** 休日関連の情報 */
    const holiday = GetHoliday(
      holidayList,
      targetStartDate,
      user,
      baseAttendance
    );

    const working = GetWorking(
      baseAttendance === null ? null : baseAttendance.start,
      baseAttendance === null ? null : baseAttendance.end,
      baseAttendance === null ? null : baseAttendance.breakStart,
      baseAttendance === null ? null : baseAttendance.breakEnd,
      requestList.overtime
    );

    // アルバイトの場合は残業時間は申請ベースにする
    if (user.authorityId === "PART_TIME") {
      const startAt =
        requestList.overtime !== undefined
          ? requestList.overtime.acquisitionStartAt?.toDate()
          : null;
      const endAt =
        requestList.overtime !== undefined
          ? requestList.overtime.acquisitionEndAt?.toDate()
          : null;
      if (
        startAt !== undefined &&
        startAt !== null &&
        endAt !== undefined &&
        endAt !== null
      ) {
        working.overWorkingTime = GetDiffDate(startAt, endAt);
      } else {
        working.overWorkingTime = 0;
      }
      // 勤務日の加算
      if (holiday.title === "出勤") workingDay = workingDay + 1;
    } else {
      if (!holiday.isRed) workingDay = workingDay + 1;
    }
    overWorkingTimeSum = overWorkingTimeSum + working.overWorkingTime;
    midnightTimeSum = midnightTimeSum + working.midnightTime;
    restTimeSum = restTimeSum + working.restTime;
    workingTimeSum = workingTimeSum + working.workingTime;
    // 申請系統の文字列の長さを取得
    requestStrings.push({ index: index, length: request.length });

    return {
      id: targetAttendance && targetAttendance.id,
      date: parseInt(dayjs(targetStartDate).format("D")),
      dayOfWeek: dayjs(targetStartDate).format("dd"),
      beforeOriginalStartWork:
        baseAttendance === null ? null : baseAttendance.beforeOriginalStart,
      beforeOriginalEndWork:
        baseAttendance === null ? null : baseAttendance.beforeOriginalEnd,
      scheduleStartWork:
        baseAttendance === null ? null : baseAttendance.scheduleStart,
      scheduleEndWork:
        baseAttendance === null ? null : baseAttendance.scheduleEnd,
      originalStartWork:
        baseAttendance === null ? null : baseAttendance.originalStart,
      originalStartBreak: null,
      originalEndBreak: null,
      originalEndWork:
        baseAttendance === null ? null : baseAttendance.originalEnd,
      startWork: ExistAttendance(baseAttendance, isUserBreak)
        ? baseAttendance.start
        : null,
      startBreak: ExistAttendance(baseAttendance, isUserBreak)
        ? baseAttendance.breakStart
        : null,
      endBreak: ExistAttendance(baseAttendance, isUserBreak)
        ? baseAttendance.breakEnd
        : null,
      endWork: ExistAttendance(baseAttendance, isUserBreak)
        ? baseAttendance.end
        : null,
      openingAddress: targetAttendance && targetAttendance.openingAddress,
      terminationAddress:
        targetAttendance && targetAttendance.terminationAddress,
      request: requestList,
      dateColor: holiday.isRed ? "red" : holiday.isBlue ? "blue" : "default",
      startWorkColor: ExistAttendance(baseAttendance, isUserBreak)
        ? baseAttendance.startColor
        : "default",
      endWorkColor: ExistAttendance(baseAttendance, isUserBreak)
        ? baseAttendance.endColor
        : "default",
      title: holiday.title,
      overWorkingTime: ExistAttendance(baseAttendance, isUserBreak)
        ? working.overWorkingTime
        : null,
      midnightTime: ExistAttendance(baseAttendance, isUserBreak)
        ? working.midnightTime
        : null,
      restTime: ExistAttendance(baseAttendance, isUserBreak)
        ? working.restTime
        : null,
      workingTime: ExistAttendance(baseAttendance, isUserBreak)
        ? working.workingTime
        : null,
      userId: user.id,
      userName: user.name,
    } as AttendanceHistoryBase;
  });

  return {
    data: targets,
    workingDay: workingDay.toString(),
    workingTimeSum: GetDiffString(workingTimeSum),
    overWorkingTimeSum: GetDiffString(overWorkingTimeSum),
    midnightTimeSum: GetDiffString(midnightTimeSum),
    restTimeSum: "",
  };
};

/** 勤怠情報の表示の有無 */
const ExistAttendance = (attendance: BaseAttendance, isUserBreak: boolean) => {
  if (isUserBreak) return false;
  if (attendance === null) return false;
  return true;
};
const CheckUserHoliday = (
  requestList: IsRequest,
  holidayList: HolidayCollection[],
  user: UserCollection
): boolean => {
  // 欠勤申請がある時
  if (requestList.absence !== undefined) return true;
  // 振替休日申請がある時
  if (requestList.substituteHoliday !== undefined) return true;
  // 有給申請(全休)がある時
  if (
    requestList.paidVacation !== undefined &&
    requestList.paidVacation?.paidVacationType === "allDay"
  )
    return true;
  // 「会社設定で休日登録されている」且つ、「振替出勤申請がない」且つ、「休日出勤申請がない」且つ、「権限がアルバイト以外」の時
  if (
    requestList.substituteAttendance === undefined &&
    requestList.holidayWork === undefined &&
    holidayList.length > 0 &&
    user.authorityId !== "PART_TIME"
  )
    return true;

  return false;
};

/** 指定期間内の休日情報取得します */
const GetTargetHoliday = (
  holidays: HolidayCollection[],
  startDate: Date,
  endDate: Date
) => {
  return holidays.filter(f =>
    dayjs(f.targetAt?.toDate()).isBetween(startDate, endDate, null, "[]")
  );
};

/** 対象の日付の全ての申請を取得します */
const GetTargetRequestList = (
  requests: RequestCollection[],
  startDate: Date,
  endDate: Date
): IsRequest => {
  // 残業
  const overtime = GetTargetRequest(requests, startDate, endDate, "overtime");
  // 有給
  const paidVacation = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "paidVacation"
  );
  // 早退
  const leaveEarly = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "leaveEarly"
  );
  // 欠勤
  const absence = GetTargetRequest(requests, startDate, endDate, "absence");

  // 遅刻
  const lateness = GetTargetRequest(requests, startDate, endDate, "lateness");

  // 私用外出
  const privateOuting = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "privateOuting"
  );

  // 振替出勤
  const substituteAttendance = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "substituteAttendance"
  );

  //　振替休日
  const substituteHoliday = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "substituteHoliday"
  );

  //時差出勤
  const staggeredWorkHours = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "staggeredWorkHours"
  );
  //勤怠修正
  const attendanceCorrection = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "attendanceCorrection"
  );
  //休日出勤
  const holidayWork = GetTargetRequest(
    requests,
    startDate,
    endDate,
    "holidayWork"
  );
  return {
    overtime: overtime,
    paidVacation: paidVacation,
    leaveEarly: leaveEarly,
    absence: absence,
    lateness: lateness,
    privateOuting: privateOuting,
    substituteAttendance: substituteAttendance,
    substituteHoliday: substituteHoliday,
    staggeredWorkHours: staggeredWorkHours,
    attendanceCorrection: attendanceCorrection,
    holidayWork: holidayWork,
  };
};

/** 出退勤情報を取得します */
const GetWorkingData = (
  requestList: IsRequest,
  targetAttendance: AttendanceCollection | undefined,
  user: UserCollection,
  targetDate: Date
) => {
  /** 実際の出勤時間 */
  var workingStartDate: Date | null = null;
  /** 実際の退勤時間 */
  var workingEndDate: Date | null = null;
  var result = {
    scheduleStart: null,
    scheduleEnd: null,
    beforeOriginalStart: null,
    beforeOriginalEnd: null,
    originalStart: null,
    originalStartColor: "default",
    originalEnd: null,
    originalEndColor: "default",
    start: null,
    startColor: "default",
    end: null,
    endColor: "default",
    breakStart: null,
    breakEnd: null,
  } as BaseAttendance;

  // 実出勤時間の取得
  if (targetAttendance !== undefined) {
    if (targetAttendance.startWorkSchedule !== null)
      workingStartDate = DateCeil(targetAttendance.startWorkSchedule.toDate())
        .startOf("minute")
        .toDate();
    if (targetAttendance.endWorkSchedule !== null)
      workingEndDate = DateFloor(targetAttendance.endWorkSchedule.toDate())
        .startOf("minute")
        .toDate();
    result.beforeOriginalStart =
      targetAttendance.startWorkSchedule !== null
        ? targetAttendance.startWorkSchedule?.toDate()
        : null;
    result.beforeOriginalEnd =
      targetAttendance.endWorkSchedule !== null
        ? targetAttendance.endWorkSchedule.toDate()
        : null;
  }

  // 各種申請で日付が変わるのはアルバイト以外
  if (user.authorityId !== "PART_TIME") {
    const startResult = GetStartDate(
      workingStartDate,
      requestList,
      targetDate
    ) as BaseAttendance;
    const endResult = GetEndDate(
      workingStartDate,
      workingEndDate,
      requestList,
      targetDate
    ) as BaseAttendance;
    result = { ...result, ...startResult, ...endResult } as BaseAttendance;

    // 休憩時間を更新
    const breakTime = GetPersonBreakingTime(result, requestList);
    result.breakStart = breakTime.breakStart;
    result.breakEnd = breakTime.breakEnd;
  } else {
    result.start = workingStartDate;
    result.end = workingEndDate;
    // アルバイトの場合は、勤怠修正のみ対象として勤務時間の操作がされる
    if (requestList.attendanceCorrection !== undefined) {
      if (requestList.attendanceCorrection.isAttendanceCancel === true) {
        result.start = null;
        result.end = null;
      } else {
        result.start =
          requestList.attendanceCorrection.acquisitionStartAt?.toDate() ?? null;
        result.end =
          requestList.attendanceCorrection.acquisitionEndAt?.toDate() ?? null;
        result.endColor = "blue";
        result.startColor = "blue";
      }
    }

    if (result.start !== null) result.start = DateCeil(result.start).toDate();
    if (result.end !== null) result.end = DateFloor(result.end).toDate();
    // 勤務が存在していれば、休憩時間を算出
    if (result.start !== null && result.end !== null) {
      const breakTime = GetPartTimeBreakingTime(result);
      result.breakStart = breakTime.breakStart;
      result.breakEnd = breakTime.breakEnd;
    }
  }
  return result;
};

/** 社員の出勤時間を取得します */
const GetStartDate = (
  originalDate: Date | null,
  request: IsRequest,
  targetDate: Date
) => {
  /** 出勤予定時間
   *
   * @summary 基本の出勤時間は８時30分
   */
  var scheduleDate: Date | null =
    originalDate === null
      ? null
      : dayjs(originalDate).hour(8).startOf("hour").minute(30).toDate();
  /** 出勤時間の文字色を返却します */
  var color: FontColor = "default";

  // 1. （有給）午前休
  if (
    request.paidVacation !== undefined &&
    request.paidVacation.paidVacationType === "morning"
  ) {
    scheduleDate = dayjs(targetDate)
      .hour(13)
      .startOf("hour")
      .minute(30)
      .toDate();
    color = "blue";
  }
  // 2. 振替出勤
  if (request.substituteAttendance !== undefined) {
    scheduleDate =
      request.substituteAttendance.acquisitionStartAt?.toDate() ?? null;
    color = "blue";
  }
  // 3. 休日出勤
  if (request.holidayWork !== undefined) {
    scheduleDate = request.holidayWork.acquisitionStartAt?.toDate() ?? null;
    color = "blue";
  }
  // 4. 時差出勤
  if (request.staggeredWorkHours !== undefined) {
    scheduleDate =
      request.staggeredWorkHours.acquisitionStartAt?.toDate() ?? null;
    color = "blue";
  }

  // 出勤予定時間を保持
  const resultSchedule = scheduleDate;
  /** 出勤予定日の色付け
   *
   * @summary 出勤時間が同じ（申請・出勤時間共に１５分で丸めるため）場合はデフォルトカラー、それ以外は赤
   */
  const originalColor: FontColor =
    scheduleDate !== null &&
    originalDate !== null &&
    dayjs(scheduleDate).startOf("minute").toDate() ===
      dayjs(originalDate).startOf("minute").toDate()
      ? "default"
      : "red";

  // 3. 遅刻
  if (request.lateness !== undefined) {
    scheduleDate = request.lateness.acquisitionStartAt?.toDate() ?? null;
    color = "blue";
  }

  // 勤怠修正
  if (request.attendanceCorrection !== undefined) {
    // 勤怠修正で、出勤取消の場合は全てをクリア
    if (request.attendanceCorrection.isAttendanceCancel === true) {
      originalDate = null;
    } else {
      // 勤怠修正がある場合は、その日付で上書き
      originalDate =
        request.attendanceCorrection.acquisitionStartAt?.toDate() ?? null;
    }
  }
  // 出勤がない場合は、空白で返却
  if (originalDate === null)
    return {
      originalStart: originalDate,
      scheduleStart: resultSchedule,
      start: null,
      originalStartColor: originalColor,
      startColor: "default",
    };
  // 日付が後の方を取得
  const resultDate =
    (scheduleDate?.valueOf() ?? 0) >= (originalDate?.valueOf() ?? 0)
      ? scheduleDate
      : originalDate;

  // 予定時刻よりも出勤時刻のほうが遅い場合は異常値として赤文字を出力
  const isRed = (scheduleDate?.valueOf() ?? 0) < (originalDate?.valueOf() ?? 0);
  return {
    originalStart: originalDate,
    scheduleStart: resultSchedule,
    start: resultDate,
    originalStartColor: originalColor,
    startColor: isRed ? "red" : color,
  };
};

const GetEndDate = (
  startDate: Date | null,
  originalDate: Date | null,
  request: IsRequest,
  targetDate: Date
) => {
  /** 退勤予定時間
   *
   * @summary 基本の退勤時間は17時
   */
  var scheduleDate: Date | null =
    startDate === null
      ? null
      : dayjs(startDate)
          .startOf("date")
          .hour(17)
          .startOf("hour")
          .minute(30)
          .toDate();

  /** 出勤時間の文字色を返却します */
  var color: FontColor = "default";
  // 1. （有給）午後休
  if (
    request.paidVacation !== undefined &&
    request.paidVacation.paidVacationType === "afternoon"
  ) {
    scheduleDate = dayjs(targetDate)
      .hour(12)
      .startOf("hour")
      .minute(30)
      .toDate();
    color = "blue";
  }

  // 2. 振替出勤
  if (request.substituteAttendance !== undefined) {
    scheduleDate =
      request.substituteAttendance.acquisitionEndAt?.toDate() ?? null;
    color = "blue";
  }
  // 3. 休日出勤
  if (request.holidayWork !== undefined) {
    scheduleDate = request.holidayWork.acquisitionEndAt?.toDate() ?? null;
    color = "blue";
  }
  // 4. 時差出勤
  if (request.staggeredWorkHours !== undefined) {
    scheduleDate =
      request.staggeredWorkHours.acquisitionEndAt?.toDate() ?? null;
    color = "blue";
  }

  // 退勤予定時間を保持
  const resultSchedule = scheduleDate;

  /** 退勤予定日の色付け
   *
   * @summary 退勤時間が同じ（申請・出勤時間共に１５分で丸めるため）場合はデフォルトカラー、それ以外は赤
   */
  const originalColor: FontColor =
    scheduleDate !== null &&
    originalDate !== null &&
    dayjs(scheduleDate).startOf("minute").toDate() ===
      dayjs(originalDate).startOf("minute").toDate()
      ? "default"
      : "red";

  // 勤怠修正
  if (request.attendanceCorrection !== undefined) {
    // 勤怠修正で、出勤取消の場合は全てをクリア
    if (request.attendanceCorrection.isAttendanceCancel === true) {
      originalDate = null;
    } else {
      // 勤怠修正がある場合は、その日付で上書き
      originalDate =
        request.attendanceCorrection.acquisitionEndAt?.toDate() ?? null;
    }
  }
  // 出勤がない場合は、空白で返却
  if (originalDate === null)
    return {
      originalEnd: originalDate,
      scheduleEnd: resultSchedule,
      end: null,
      originalEndColor: originalColor,
      endColor: "default",
    };
  // 残業
  if (request.overtime !== undefined) {
    scheduleDate = request.overtime.acquisitionEndAt?.toDate() ?? null;
    color = "blue";
  }
  // 早退
  if (request.leaveEarly !== undefined) {
    scheduleDate = request.leaveEarly.acquisitionEndAt?.toDate() ?? null;
    color = "blue";
  }

  // 予定時刻よりも退勤時刻のほうが早い場合は異常値として赤文字を出力
  const isRed = (scheduleDate?.valueOf() ?? 0) > (originalDate?.valueOf() ?? 0);
  // 日付が前の方を取得
  const resultDate =
    (scheduleDate?.valueOf() ?? 0) <= (originalDate?.valueOf() ?? 0)
      ? scheduleDate
      : originalDate;

  return {
    originalEnd: originalDate,
    scheduleEnd: resultSchedule,
    end: resultDate,
    originalEndColor: originalColor,
    endColor: isRed ? "red" : color,
  };
};

/** 申請種類に応じた申請を一件取得します */
const GetTargetRequest = (
  requests: RequestCollection[],
  startDate: Date,
  endDate: Date,
  requestType: RequestType
) => {
  return requests
    .filter(f => f.requestType === requestType)
    .filter(f =>
      dayjs(f.acquisitionStartAt?.toDate()).isBetween(
        startDate,
        endDate,
        null,
        "[]"
      )
    )
    .shift();
};

/** 半休申請があるかどうかを判定します */
const CheckHalfPaidVacation = (requestList: IsRequest): boolean => {
  // 有給申請がない場合はfalse
  if (requestList.paidVacation === undefined) return false;
  // 全休申請の場合はfalse
  if (requestList.paidVacation.paidVacationType === "allDay") return false;
  return true;
};
/** 出勤種類の情報・日付の色付け情報を取得します */
const GetHoliday = (
  holidays: HolidayCollection[],
  targetDate: Date,
  user: UserCollection,
  baseAttendance: BaseAttendance | null
): {
  /** 日付の文字色を赤にするかどうか */
  isRed: boolean;
  /** 日付の文字色を青にするかどうか */
  isBlue: boolean;
  /** 出勤種類の名称 */
  title: string;
} => {
  var isRed = false;
  var title = "出勤";
  // アルバイトの時は出勤時間がある時以外は休日とする
  if (
    user.authorityId === "PART_TIME" &&
    (baseAttendance === null || (baseAttendance?.start ?? null) === null)
  ) {
    title = "";
  }

  if (holidays.length > 0) {
    isRed = true;
    title = holidays[0].title ?? "";
  }
  return {
    isRed: isRed,
    isBlue: isRed ? false : dayjs(targetDate).day() == 6,
    title: title,
  };
};

/** アルバイトの休憩時間を計算します
 *
 * @return 分単位で休憩時間を返却
 */
const GetPartTimeBreakingTime = (
  attendance: BaseAttendance
): {
  breakStart: Date | null;
  breakEnd: Date | null;
} => {
  if (attendance.start !== null && attendance.end !== null) {
    // 勤務が存在していれば、休憩時間を算出
    const partTimeDiff = GetDiffDate(attendance.start, attendance.end);
    return {
      breakStart: DateCeil(attendance.start).toDate(),
      breakEnd: DateFloor(
        dayjs(attendance.start).add(
          GetPartBreakTimeTable(partTimeDiff),
          "minute"
        )
      ).toDate(),
    };
  }

  return {
    breakStart: attendance.breakStart,
    breakEnd: attendance.breakEnd,
  };
};

const GetPartBreakTimeTable = (workingTime: number) => {
  switch (true) {
    case workingTime <= 405:
      return 0;
    case workingTime <= 540:
      return 45;
    default:
      return 60;
  }
};

/** 社員の休憩時間を計算します
 *
 * @return
 */
const GetPersonBreakingTime = (
  attendance: BaseAttendance,
  requestList: IsRequest
): {
  breakStart: Date | null;
  breakEnd: Date | null;
} => {
  // 使用外出申請がある時
  if (
    requestList.privateOuting !== undefined &&
    requestList.privateOuting.acquisitionStartAt !== null &&
    requestList.privateOuting.acquisitionEndAt !== null
  ) {
    // 半休の時は休憩０h,それ以外は１時間とする
    const defaultBreakTime = CheckHalfPaidVacation(requestList) ? 0 : 1;
    return {
      breakStart:
        requestList.privateOuting.acquisitionStartAt?.toDate() ?? null,
      breakEnd:
        requestList.privateOuting.acquisitionEndAt === null
          ? null
          : dayjs(requestList.privateOuting.acquisitionEndAt.toDate())
              .add(defaultBreakTime, "hours")
              .startOf("hours")
              .toDate(),
    };
  }

  // 半休の時は０hとする
  if (CheckHalfPaidVacation(requestList))
    return {
      breakStart: null,
      breakEnd: null,
    };
  // 出退勤があり、休憩時間が未定義の時は、出勤後4時間で休憩とする
  if (
    attendance.start &&
    attendance.end &&
    (attendance.breakStart === null || attendance.breakEnd === null)
  ) {
    return {
      breakStart: DateCeil(attendance.start).add(4, "hours").toDate(),
      breakEnd: DateCeil(attendance.start).add(5, "hours").toDate(),
    };
  }

  return {
    breakStart: attendance.breakStart,
    breakEnd: attendance.breakEnd,
  };
};

/** 表示する勤務時間関係の情報を返却します */
const GetWorking = (
  workingStartDate: Date | null,
  workingEndDate: Date | null,
  breakingStartDate: Date | null,
  breakingEndDate: Date | null,
  overTimeRequest: RequestCollection | undefined
): {
  /** 残業時間 */
  overWorkingTime: number;
  /** 深夜時間 */
  midnightTime: number;
  /** 休憩時間 */
  restTime: number;
  /** 勤務時間 */
  workingTime: number;
} => {
  /** 残業時間 */
  var overWorkingTime = 0;
  /** 深夜時間 */
  var midnightTime = 0;
  /** 休憩時間 */
  var restTime = 0;
  /** 勤務時間 */
  var workingTime = 0;
  /** 休憩時間の差分 */
  if (breakingStartDate !== null && breakingEndDate !== null) {
    restTime = GetDiffDate(breakingStartDate, breakingEndDate);
    if (workingTime < 0) workingTime = 0;
  }

  if (workingStartDate !== null && workingEndDate !== null) {
    /** 勤務時間の差分 */
    workingTime = GetDiffDate(workingStartDate, workingEndDate) - restTime;
    if (workingTime < 0) workingTime = 0;
    /** 深夜時間の差分 */
    midnightTime = GetMidnightTime(workingStartDate, workingEndDate);
    /** 残業時間の差分 */
    if (
      overTimeRequest !== undefined &&
      overTimeRequest.acquisitionStartAt !== null
    ) {
      const diff = GetDiffDate(
        overTimeRequest.acquisitionStartAt.toDate(),
        workingEndDate
      );
      overWorkingTime = diff;
    }

    if (overWorkingTime < 0) overWorkingTime = 0;
  }

  return {
    overWorkingTime: isNaN(overWorkingTime) ? 0 : overWorkingTime,
    midnightTime: isNaN(midnightTime) ? 0 : midnightTime,
    restTime: isNaN(restTime) ? 0 : restTime,
    workingTime: isNaN(workingTime) ? 0 : workingTime,
  };
};

/** 日付から時間の差分を計算
 *
 * 分単位で返却する
 */
const GetDiffDate = (startDate: Date, endDate: Date): number => {
  const result = dayjs(endDate).diff(startDate, "minute");
  if (result < 0) return 0;
  return result;
};

/** 深夜の時間計算 */
const GetMidnightTime = (workingStartDate: Date, workingEndDate: Date) => {
  var beforeMidnightTime = 0;
  var afterMidnightTime = 0;
  const beforeMidnight = dayjs(workingStartDate)
    .startOf("date")
    .hour(5)
    .toDate();
  const afterMidnight = dayjs(workingEndDate).startOf("date").hour(22).toDate();
  // 勤務開始時間が５時よりも前の場合
  if (workingStartDate < beforeMidnight) {
    // ５時以前の出勤の場合は、０〜５時出勤で、労基法上16時間までの勤務となるため、日付を跨ぐことはない
    beforeMidnightTime = GetDiffDate(workingStartDate, beforeMidnight);
    afterMidnightTime = GetDiffDate(afterMidnight, workingEndDate);
  } else {
    // 勤務開始時間が22時よりも前の場合
    if (afterMidnight > workingStartDate) {
      // 22時以前が出勤の場合、22時~翌5時までが対象の時間となる

      const tmpAfterMidnight = dayjs(workingStartDate)
        .add(1, "day")
        .startOf("date")
        .hour(5)
        .toDate();
      // 勤務終了が5時以前かどうか
      if (workingEndDate <= tmpAfterMidnight) {
        const tmpAfterMidnight = dayjs(workingStartDate)
          .startOf("date")
          .hour(22)
          .toDate();
        // 勤務開始時間が深夜ではないため、22時〜勤務終了時間が残業時間
        afterMidnightTime = GetDiffDate(tmpAfterMidnight, workingEndDate);
      } else {
        // もし５時を過ぎていた場合は、全て深夜のため22~5の分の7時間（420分）とする
        afterMidnightTime = 420;
      }
    } else {
      const tmpAfterMidnight = dayjs(workingEndDate)
        .add(1, "day")
        .startOf("date")
        .hour(5)
        .toDate();
      // 22時以降出勤の場合の深夜残業は、労基法上16時間までの勤務となるため、勤務開始〜５時までの残業時間となる
      beforeMidnightTime = GetDiffDate(workingStartDate, tmpAfterMidnight);
    }
  }
  if (beforeMidnightTime < 0) beforeMidnightTime = 0;
  if (afterMidnightTime < 0) afterMidnightTime = 0;

  /** 深夜時間の差分 */
  return beforeMidnightTime + afterMidnightTime;
};

/** 15分ごとに切り捨てます */
const DateFloor = (target: Date | dayjs.Dayjs) => {
  return dayjs(target).floor("minute", 15);
};

/** 15分ごとに切り上げます */
const DateCeil = (target: Date | dayjs.Dayjs) => {
  return dayjs(target).ceil("minute", 15);
};
