import { CollectionContext, DialogContext } from "GlobalContext";
import {
  CalendarModal,
  MonthCalendar,
  PageLoading,
  WeeklyCalendar,
} from "components";
import dayjs from "dayjs";
import { StatusBar } from "expo-status-bar";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import {
  FetchCalendarHolidayTargetMonth,
  FetchCalendarTargetMonth,
} from "server/collectionConnect";
import {
  CalendarData,
  CalendarModalStatus,
  HolidayCollection,
  User,
} from "types";
import { AscSort, IsWeb } from "utils";

export function CalendarScreen({
  navigation,
}: RootStackScreenProps<"Calendar">) {
  const [editModal, setEditModal] = useState<CalendarModalStatus>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setAlert } = useContext(DialogContext);
  const {
    UserCollection,
    CalendarCollection,
    RequestCollection,
    HolidayCollection,
    onSnapShot,
  } = useContext(CollectionContext);
  const [visibleDate, setVisibleDate] = useState<dayjs.Dayjs>(dayjs());
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [allCalendars, setAllCalendars] = useState<CalendarData[]>([]);
  const [holidays, setHolidays] = useState<HolidayCollection[]>([]);
  useEffect(() => {
    if (
      UserCollection === null ||
      CalendarCollection === null ||
      RequestCollection === null ||
      HolidayCollection === null
    ) {
      onSnapShot("user");
      onSnapShot("calendar");
      onSnapShot("request");
      onSnapShot("holiday");
      setIsLoading(true);
      return;
    }
    fetchTargetMonthCalendars();
    fetchUserList();
    fetchHolidays();
    setIsLoading(false);
  }, [
    UserCollection,
    visibleDate,
    CalendarCollection,
    RequestCollection,
    HolidayCollection,
  ]);

  const fetchHolidays = () => {
    const targetDate = visibleDate.toDate();
    const result = FetchCalendarHolidayTargetMonth(
      HolidayCollection,
      targetDate
    );
    setHolidays(result);
  };
  const fetchUserList = () => {
    if (UserCollection === null) return;
    const users = UserCollection.sort((a, b) => AscSort(a.sort, b.sort)).map(
      user =>
        ({
          id: user.id,
          name: user.name,
          uid: user.uid,
          authorityId: user.authorityId,
          email: user.email,
          retirementAt: user.retirementAt,
        } as User)
    );
    const activeDutyUsers = users.filter((user: User) => !user.retirementAt);
    setAllUsers(activeDutyUsers);
  };

  const fetchTargetMonthCalendars = () => {
    if (CalendarCollection === null || RequestCollection === null) return;
    const targetDate = visibleDate.toDate();
    const calendars = FetchCalendarTargetMonth(
      CalendarCollection,
      RequestCollection,
      targetDate
    );
    setAllCalendars(calendars);
  };

  const handlePrev = () => {
    IsWeb()
      ? setVisibleDate(visibleDate.subtract(1, "month"))
      : setVisibleDate(visibleDate.subtract(1, "week"));
  };

  const handleNext = () => {
    IsWeb()
      ? setVisibleDate(visibleDate.add(1, "month"))
      : setVisibleDate(visibleDate.add(1, "week"));
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <View style={styles.container}>
      <View style={styles.calendarHeader}>
        <Pressable
          style={[styles.button, styles.smallBtn]}
          onPress={() => {
            setIsLoading(true);
            handlePrev();
          }}
        >
          <Text style={styles.buttonTxt}>
            &lt; &thinsp;
            {IsWeb() ? "前月" : "前週"}
          </Text>
        </Pressable>
        <Text style={styles.month}>{visibleDate.format("YYYY年M月")}</Text>
        <Pressable
          style={[styles.button, styles.smallBtn]}
          onPress={() => {
            setIsLoading(true);
            handleNext();
          }}
        >
          <Text style={styles.buttonTxt}>
            {IsWeb() ? "次月" : "次週"}
            &thinsp; &gt;
          </Text>
        </Pressable>
      </View>
      <View style={{ alignItems: "flex-start" }}>
        <Pressable
          style={styles.button}
          onPress={() => setEditModal({ id: null, isEdit: false })}
        >
          <Text style={styles.buttonTxt}>タスクの追加</Text>
        </Pressable>
      </View>

      {IsWeb() ? (
        <MonthCalendar
          visibleDate={visibleDate}
          allUsers={allUsers}
          allCalendars={allCalendars}
          holidays={holidays}
          DailyClick={date =>
            navigation.navigate("CalendarDaily", {
              date: date,
            })
          }
          UserClick={user =>
            navigation.navigate("CalendarPersonal", {
              user: user,
            })
          }
          DailyUserClick={(date, user) =>
            navigation.navigate("CalendarDaily", {
              date: date,
              user: user,
            })
          }
        />
      ) : (
        <WeeklyCalendar
          visibleDate={visibleDate}
          allUsers={allUsers}
          allCalendars={allCalendars}
          holidays={holidays}
          DailyClick={date =>
            navigation.navigate("CalendarDaily", {
              date: date,
            })
          }
          UserClick={user =>
            navigation.navigate("CalendarPersonal", {
              user: user,
            })
          }
          DailyUserClick={(date, user) =>
            navigation.navigate("CalendarDaily", {
              date: date,
              user: user,
            })
          }
        />
      )}
      <StatusBar style="auto" />
      <CalendarModal
        modalStatus={editModal}
        onCloseModal={() => setEditModal(null)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    paddingTop: 32,
  },
  calendarHeader: {
    flexDirection: "row",
    marginBottom: 24,
  },
  month: {
    fontSize: 21,
    paddingHorizontal: 32,
    paddingTop: 4,
  },
  button: {
    padding: 16,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
  },
  smallBtn: {
    paddingVertical: 8,
    backgroundColor: "#4289cb",
  },
  buttonTxt: {
    fontWeight: "bold",
  },
});
