import firebase from "firebase/compat/app";
import { ExpenseItemCollection } from "types";

/**
 * expenseItem Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const ExpenseItemOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<ExpenseItemCollection[] | null>>
) => {
  return firebase
    .firestore()
    .collection("expenseItem")
    .onSnapshot(
      docs => {
        const collectionList = docs.docs.map(
          doc => ({ ...doc.data(), id: doc.id } as ExpenseItemCollection)
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};
