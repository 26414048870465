import React from "react";
import { StyleSheet, Text, TouchableHighlight } from "react-native";
import { IsMobile } from "utils";

type Props = {
  /** クリック時の処理を定義します */
  onClick: () => void;
};

/**
 * 右下の追加ボタンを作成します
 * @param param0
 * @returns
 */
export const AddButton = ({ onClick }: Props): JSX.Element => {
  return (
    <>
      <TouchableHighlight
        style={[addButtonStyles.addButton, ,]}
        underlayColor="#7879F1"
        onPress={onClick}
      >
        <Text style={{ fontSize: 30, color: "white" }}>+</Text>
      </TouchableHighlight>
    </>
  );
};

/**
 * 右下にある追加ボタンのレイアウト指定です
 */
export const addButtonStyles = StyleSheet.create({
  addButton: {
    backgroundColor: "#7879F1",
    bottom: IsMobile() ? 30 : 10,
    height: 50,
    width: 50,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: 20,
    shadowColor: "#000000",
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0,
    },
  },
});
