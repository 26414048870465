import { TableCell } from "components";
import React from "react";
import { DimensionValue, Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { PaidVacationList, PaidVacationSettingType, UserCollection } from "types";
import { ConvertTimeStampFromDate, GetUserName } from "utils";

const tableHead = ["", "名前", "付与日", "失効予定日", "付与日数"];

export const WebValidTable = ({
  onEditClick,
  tableList,
  UserCollection,
}: {
  onEditClick: (paidVacation: PaidVacationList) => void;
  tableList: PaidVacationSettingType | null;
  UserCollection: UserCollection[];
}) => {
  if (
    tableList?.paidVacationList === null ||
    tableList?.paidVacationList?.length === 0
  )
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );

  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "500px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(
              tableList?.paidVacationList ?? [],
              UserCollection,
              onEditClick
            )}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

/**
 *
 * @param tableList テーブルの行をレンダリングします
 * @param onEditClick 編集ボタンを押した時のアクションを渡します
 * @param UserCollection ユーザー名を検索するための情報を渡します。
 * @returns
 */
const TableBody = (
  tableList: PaidVacationList[],
  UserCollection: UserCollection[],
  onEditClick: (paidVacation: PaidVacationList) => void
) => {
  const table = tableList.map(tableData => {
    var tempArr = [];
    var userName = GetUserName(UserCollection, tableData.userId ?? "");
    tempArr.push(
      <View
        style={[
          styles.textContainer,
          {
            padding: 2,
          },
        ]}
      >
        <Pressable
          style={[styles.button, { backgroundColor: "#7879F1" }]}
          onPress={() => onEditClick(tableData)}
        >
          <Text style={styles.buttonText}>編集</Text>
        </Pressable>
      </View>
    );
    //名前
    tempArr.push(<TableCell dataType="string" value={userName} />);
    // 付与日
    tempArr.push(
      <TableCell
        dataType="date"
        value={
          tableData.grantAt ? ConvertTimeStampFromDate(tableData.grantAt) : null
        }
      />
    );
    // 失効予定日
    tempArr.push(
      <TableCell
        dataType="date"
        value={
          tableData.lapseAt ? ConvertTimeStampFromDate(tableData.lapseAt) : null
        }
      />
    );
    // 付与日数
    tempArr.push(
      <TableCell dataType="number" value={tableData.grantCount ?? 0} />
    );

    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return table.filter(m => m !== undefined);
};

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
  },
  button: {
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 20,
    elevation: 5,
    width: 100,
    height: 35,
    textAlign: "center",
  },

  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
});
