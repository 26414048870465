import { TableCell } from "components";
import { paymentStatusType } from "constants/index";
import React from "react";
import { DimensionValue, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { ExpenseList, UserCollection } from "types";
import { GetUserName } from "utils";

const tableHead = [
  "",
  "申請状況",
  "支払状況",
  "現場名",
  "申請金額",
  "支払金額",
  "申請日",
  "利用日",
  "承認日",
  "否決日",
  "支払日",
  "更新者",
];

export const WebExpenseTable = ({
  onEditClick,
  tableList,
  UserCollection,
}: {
  onEditClick: (id: string) => void;
  tableList: ExpenseList[];
  UserCollection: UserCollection[];
}) => {
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );

  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "500px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList, onEditClick, UserCollection)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

const TableBody = (
  tableList: ExpenseList[],
  onEditClick: (id: string) => void,
  UserCollection: UserCollection[]
) => {
  const tableBody = tableList.map(tableData => {
    var tempArr = [];
    var userName = GetUserName(UserCollection, tableData.updateUser ?? "");
    if (userName === null) return;

    // 編集ボタン
    tempArr.push(
      <TableCell
        dataType="button"
        buttonText="編集"
        onEditClick={() => onEditClick(tableData.id)}
      />
    );
    // 進捗状況の表示
    tempArr.push(
      <TableCell dataType="requestStatus" value={tableData.status} />
    );
    // 支払状況
    tempArr.push(
      <TableCell
        dataType="string"
        value={paymentStatusType[tableData.paymentStatus ?? ""]?.text ?? ""}
      />
    );
    // 現場名
    tempArr.push(<TableCell dataType="string" value={tableData.siteName} />);
    // 申請金額
    tempArr.push(<TableCell dataType="money" value={tableData.totalAmount} />);
    // 支払金額
    tempArr.push(
      <TableCell dataType="money" value={tableData.paymentAmount} />
    );
    // 申請日
    tempArr.push(<TableCell dataType="date" value={tableData.requestAt} />);
    // 利用日
    tempArr.push(<TableCell dataType="date" value={tableData.reckoningAt} />);
    // 承認日
    tempArr.push(<TableCell dataType="date" value={tableData.approveAt} />);
    // 否決日
    tempArr.push(<TableCell dataType="date" value={tableData.rejectAt} />);
    // 支払日
    tempArr.push(<TableCell dataType="date" value={tableData.paymentAt} />);
    // 更新者
    tempArr.push(<TableCell dataType="string" value={userName} />);
    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return tableBody.filter(m => m !== undefined);
};
