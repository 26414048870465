import { AttendanceCollection } from "types";
import dayjs from "utils/dayjs";

/**
 *　指定の日付に該当する勤怠情報を返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param targetDate 対象日付
 * @param attendances collectionContextから取得した勤怠情報
 * @returns
 */
export const FetchLatestAttendanceByDate = (
  userId: string,
  targetDate: Date,
  attendance: AttendanceCollection[] | null
): string | null => {
  if (attendance === null || userId === null) return null;

  const targetStartDate = dayjs(targetDate).startOf("date").toDate();

  /** 終了対象日 */
  const targetEndDate = dayjs(targetDate).endOf("date").toDate();

  const result = attendance
    .filter(f => f.userId === userId)
    .filter(f =>
      dayjs(f.startWorkSchedule?.toDate()).isBetween(
        targetStartDate,
        targetEndDate,
        null,
        "[]"
      )
    )
    .map(m => m.id);

  return result.shift() ?? null;
};
