import {
  CreateLeftCol,
  CreateRightCol,
  CreateRow,
  MobileTableEditButton,
} from "components";
import dayjs from "dayjs";
import React from "react";
import { ScrollView, Text, View } from "react-native";
import { mobileTableStyles } from "styles";
import { ExpenseLimitSettingList } from "types";
import { GetMoneyText } from "utils";

/** Mobile用のテーブルを返却します */
export const MobileExpenseLimitSettingTable = ({
  tableList,
  onEditClick,
}: {
  tableList: ExpenseLimitSettingList[];
  onEditClick: (id: string) => void;
}) => {
  return (
    <ScrollView
      style={mobileTableStyles.scroll_view_style}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <View style={mobileTableStyles.list_line_view} />
        {tableList?.map((data, i) => (
          <View key={i} style={mobileTableStyles.list_line_view}>
            <View style={mobileTableStyles.contents_container}>
              <CreateRow>
                <CreateLeftCol>
                  <MobileTableEditButton
                    onEditClick={() => onEditClick(data.id)}
                    buttonText="詳細"
                  />
                </CreateLeftCol>
                <CreateRightCol>
                  <Text style={mobileTableStyles.base_text}>
                    {data.userName}
                  </Text>
                </CreateRightCol>
              </CreateRow>
              <CreateRow>
                <CreateLeftCol></CreateLeftCol>
                <CreateRightCol>
                  <View>
                    <Text style={mobileTableStyles.small_text}>
                      {`期限：${dayjs(data.grantDate.toDate()).format(
                        "YYYY/MM/DD"
                      )}
                      〜${dayjs(data.expirationDate.toDate()).format(
                        "YYYY/MM/DD"
                      )}`}
                    </Text>
                    <Text style={mobileTableStyles.small_text}>{`${
                      data.itemName
                    }：${GetMoneyText(data.amountLimit)}`}</Text>
                  </View>
                </CreateRightCol>
              </CreateRow>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};
