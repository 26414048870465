import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  ModalDeleteButton,
  SelectForm,
} from "components";
import { siteStatusList } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import {
  CheckRepetitionSite,
  CreateSite,
  FetchSite,
  UpdateSite,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { SiteData, SiteModalStatus } from "types";
type Props = {
  hideModal: Function;
  modalStatus: SiteModalStatus;
};

export function SiteForm({ hideModal, modalStatus }: Props) {
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { SiteCollection, RequestCollection, CalendarCollection } =
    useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [site, setSite] = useState<SiteData>(defaultSite());

  useEffect(() => {
    if (modalStatus === null) {
      setSite(defaultSite());
      return;
    }
    const result = FetchSite(SiteCollection, modalStatus.id ?? "");
    if (result === null) {
      setSite(defaultSite());
      return;
    }
    setSite(result);
  }, [modalStatus]);

  const validationCheck = (): boolean => {
    if (!site.name) {
      setAlert({ isAlert: true, msg: "現場名を入力してください" });
      return false;
    }
    if (!site.progressStatus) {
      setAlert({ isAlert: true, msg: "ステータスを入力してください" });
      return false;
    }
    return true;
  };

  const createSite = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);
    const isCheck = CheckRepetitionSite(
      userInfo,
      SiteCollection,
      CalendarCollection,
      RequestCollection,
      site,
      false
    );
    if (!isCheck.isCheck) {
      setAlert({ isAlert: true, msg: isCheck.message });
      setIsLoading(false);
      return;
    }

    const result = await CreateSite(userInfo, SiteCollection, site);

    if (result.isCheck) {
      setAlert({
        isAlert: true,
        msg: result.message,
        confTxt: "OK",
        afterExec: () => {
          hideModal();
        },
      });
    } else {
      setAlert({ isAlert: true, msg: result.message });
    }
    setIsLoading(false);
  };

  const updateSite = async (deleted?: boolean): Promise<void> => {
    if (!validationCheck()) {
      return;
    }
    if (deleted ?? false) site.deleted = true;

    setIsLoading(true);
    const isCheck = CheckRepetitionSite(
      userInfo,
      SiteCollection,
      CalendarCollection,
      RequestCollection,
      site,
      true
    );
    if (!isCheck.isCheck) {
      setSite({ ...site, deleted: false });
      setAlert({ isAlert: true, msg: isCheck.message });
      setIsLoading(false);
      return;
    }
    const result = await UpdateSite(userInfo, site);

    if (result.isCheck) {
      setAlert({
        isAlert: true,
        msg: result.message,
        confTxt: "OK",
        afterExec: () => {
          hideModal();
        },
      });
    } else {
      setAlert({ isAlert: true, msg: result.message });
    }
    setIsLoading(false);
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>
          {modalStatus?.isEdit ? "現場編集" : "現場登録"}
        </Text>
        <Loading isLoading={isLoading} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={modalFormStyles.scrollContainer}
        >
          <View style={[formWrap(windowWidth).formWrap]}>
            <InputForm
              label="現場名"
              value={site.name}
              onChange={e => setSite({ ...site, name: e })}
              disabled={true}
            />
            <InputForm
              label="案件番号"
              value={site.matterNumber}
              onChange={e => setSite({ ...site, matterNumber: e })}
              disabled={true}
            />
            <InputForm
              label="契約番号"
              value={site.agreementNumber}
              onChange={e => setSite({ ...site, agreementNumber: e })}
              disabled={true}
            />
            <SelectForm
              label="ステータス"
              placeholderLabel="選択してください"
              value={site.progressStatus}
              onChange={e => setSite({ ...site, progressStatus: e })}
              items={siteStatusList}
            />

            <View style={modalFormStyles.formItem}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => hideModal()}
              />

              {modalStatus?.isEdit ?? false ? (
                <ModalApprovalButton
                  buttonText="更新"
                  onClick={() => updateSite()}
                />
              ) : (
                <ModalApprovalButton
                  buttonText="登録"
                  onClick={() => createSite()}
                />
              )}
            </View>

            {modalStatus?.isEdit ?? false ? (
              <View style={modalFormStyles.formItemCenter}>
                <ModalDeleteButton
                  buttonText="削除"
                  onClick={() => updateSite(true)}
                />
              </View>
            ) : (
              <></>
            )}
          </View>
        </ScrollView>
      </View>
      <Dialog />
    </>
  );
}

/** 現場登録の初期値を登録します */
const defaultSite = (): SiteData => {
  return {
    id: "",
    name: "",
    matterNumber: "",
    agreementNumber: "",
    progressStatus: "",
    deleted: false,
  };
};
