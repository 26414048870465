import firebase from "firebase/compat/app";
import { CreateCheckResult, UserData, UserInfo } from "types";

/**
 * 現場の新規作成の書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param site collectionContextから取得した現場情報
 * @param siteData 更新・登録対象の現場情報
 * @returns　CreateCheckResult
 */
export const CreateUser = async (
  reqUser: UserInfo | null,
  userData: UserData
): Promise<CreateCheckResult> => {
  const date = new Date();

  const uid = await firebase
    .functions()
    .httpsCallable("createUser")({
      userData: userData,
    })
    .then(_ => _.data as string)
    .catch(_ => null);

  if (uid === null) return { isCheck: false, message: "登録に失敗しました。" };

  return await firebase
    .firestore()
    .collection("user")
    .add({
      uid: uid,
      email: userData.email,
      name: userData.name,
      entryAt: new Date(userData.entryAt),
      sort: userData.sort,
      createAt: date,
      createUser: reqUser?.user.id,
      updateAt: date,
      updateUser: reqUser?.user.id,
    })
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
