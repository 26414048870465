import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  DateForm,
  Dialog,
  ImageDropArea,
  ImageSlider,
  ImageUpload,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  ModalDeleteButton,
  MultipleInputForm,
  PageLoading,
  SelectForm,
} from "components";
import { expenseRequestTypeList } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, ScrollView, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  CreateExpense,
  FetchExpense,
  FetchExpenseLimits,
  UpdateExpense,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import {
  ExpenseData,
  ExpenseItemCollection,
  ExpenseStatus,
  ImageData,
  ImageDataSources,
  RequestModalStatus,
  UserInfo,
} from "types";
import { ConvertTimeStampFromDate, numberFormat } from "utils";
type Props = {
  hideModal: Function;
  siteSelect: Item[];
  modalStatus: RequestModalStatus;
};

export function ExpenseForm({ hideModal, siteSelect, modalStatus }: Props) {
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const {
    ExpenseLimitSettingCollection,
    ExpenseItemCollection,
    ExpenseCollection,
  } = useContext(CollectionContext);
  if (userInfo == null) {
    setAlert({ isAlert: true, msg: "ユーザー情報が取得できませんでした" });
    return <></>;
  }
  const [imgDataSources, setImgDataSources] = useState<ImageDataSources>({
    data: [],
  });
  const [expense, setExpense] = useState<ExpenseData>(
    defaultStateValue(userInfo)
  );
  const [expenseItem, setExpenseItem] = useState<ExpenseItemCollection[]>([]);
  const [expenseItemSelect, setExpenseItemSelect] = useState<Item[]>([]);
  const [expenseLimitSelect, setExpenseLimitSelect] = useState<Item[]>([]);

  const updateImgDataSources = (
    images: React.SetStateAction<ImageDataSources>
  ): void => {
    setImgDataSources(images);
  };

  const onReckoningAtChangeHandler = (evt: Date): void => {
    setExpense({
      ...expense,
      reckoningAt: ConvertTimeStampFromDate(evt),
    });
  };

  // 経費種類情報の取得
  const fetchExpenseItems = async (): Promise<void> => {
    try {
      if (ExpenseItemCollection === null) return;
      setExpenseItem(ExpenseItemCollection);
      const itemSelect = ExpenseItemCollection.map(m => {
        return {
          key: m.id,
          label: m.name,
          value: m.id,
        } as Item;
      });
      setExpenseItemSelect(itemSelect);
    } catch {
      setAlert({ isAlert: true, msg: "経費種類情報の取得に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  // 経費枠情報の取得
  const fetchExpenseLimits = async (): Promise<void> => {
    try {
      const result = FetchExpenseLimits(
        expense.userId ?? "",
        ExpenseCollection,
        ExpenseLimitSettingCollection,
        expense.expenseItemId
      );
      if (result === null) {
        setAlert({ isAlert: true, msg: "経費枠情報の取得に失敗しました" });
        return;
      }

      if (ExpenseItemCollection === null) {
        setAlert({ isAlert: true, msg: "経費枠情報の取得に失敗しました" });
        return;
      }
      const result2 = result.map(r => {
        const matchExpenseItem = ExpenseItemCollection.find(
          u => u.id === r.itemId
        );
        return {
          key: r.id,
          label: `${matchExpenseItem?.name}:${numberFormat(
            r.amountLimit,
            ""
          )}(残額:${numberFormat(r.balanceAmount, "")})`,
          value: r.id,
        };
      });

      setExpenseLimitSelect(result2);
    } catch {
      setAlert({ isAlert: true, msg: "経費枠情報の取得に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenseItems();
  }, []);

  useEffect(() => {
    if (expense.expenseItemId) {
      fetchExpenseLimits();
    }
  }, [expense]);

  useEffect(() => {
    if (modalStatus === null) {
      setExpense(defaultStateValue(userInfo));
      return;
    }
    if (modalStatus.type === "expense") {
      const result = FetchExpense(
        userInfo,
        ExpenseCollection,
        ExpenseLimitSettingCollection ?? [],
        ExpenseItemCollection ?? [],
        modalStatus.id
      );
      if (result === null) {
        setExpense(defaultStateValue(userInfo));
        return;
      }
      if (result.images !== undefined && result.images.length > 0) {
        setImgDataSources({ ...imgDataSources, data: result.images });
      }

      delete result.images;
      setExpense(result);
    }
  }, [modalStatus]);

  useEffect(() => {
    const matchExpenseItem = expenseItem.find(
      u => u.id === expense.expenseItemId
    );
    if (
      matchExpenseItem &&
      !matchExpenseItem.isNoExpenseLimit &&
      expense.reckoningAt
    ) {
      // 経費種類が現場経費でない、かつ利用日が入力されている場合
      fetchExpenseLimits();
    }
  }, [expense.reckoningAt, expense.expenseItemId]);

  const validationCheck = (): boolean => {
    if (
      CheckIsNoExpenseLimit(
        ExpenseItemCollection ?? [],
        expense.expenseItemId
      ) &&
      !expense.siteId
    ) {
      setAlert({ isAlert: true, msg: "現場を選択してください" });
      return false;
    }

    if (!expense.reckoningAt) {
      setAlert({ isAlert: true, msg: "利用日を入力してください" });
      return false;
    }
    if (!Number.isInteger(expense.totalAmount)) {
      setAlert({ isAlert: true, msg: "金額は数値で入力してください" });
      return false;
    }
    if (!expense.expenseItemId) {
      setAlert({ isAlert: true, msg: "経費種類を選択してください" });
      return false;
    }
    const matchExpenseItem = expenseItem.find(
      u => u.id === expense.expenseItemId
    );
    if (!matchExpenseItem?.isNoExpenseLimit && !expense.expenseLimitSettingId) {
      setAlert({ isAlert: true, msg: "経費枠を選択してください" });
      return false;
    }
    return true;
  };

  const createExpense = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);
    try {
      const result = await CreateExpense(
        userInfo,
        ExpenseCollection,
        ExpenseItemCollection,
        ExpenseLimitSettingCollection,
        expense,
        imgDataSources
      );
      if (result.isCheck) {
        setImgDataSources({ data: [] });
        setIsLoading(false);
        setAlert({
          isAlert: true,
          msg: "登録しました",
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "登録に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateExpense = async (status?: ExpenseStatus): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);

    const targetExpense = expense;
    targetExpense.status = status !== undefined ? status : targetExpense.status;

    try {
      const result = await UpdateExpense(
        userInfo,
        ExpenseCollection,
        ExpenseItemCollection,
        ExpenseLimitSettingCollection,
        targetExpense,
        imgDataSources
      );
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: "更新しました",
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDrop = async (e: any) => {
    const imageDataArray: ImageData[] = [];
    let count = 0;
    const traverseFileTree = async (entry: any, path: any) => {
      const _path = path || "";

      if (entry.isFile) {
        const file: any = await new Promise(resolve => {
          entry.file((file: any) => {
            resolve(file);
          });
        });

        let extension = file.name.split(".").pop();
        if (extension && !["png", "PNG", "jpg", "jpeg"].includes(extension)) {
          setAlert({
            isAlert: true,
            msg: "ファイル形式はpngまたはjpegを指定してください",
          });
          return;
        }

        const reader = new FileReader();
        reader.onload = (event: any) => {
          imageDataArray.push({
            docId: "",
            id: String(count),
            url: event.currentTarget.result,
            title: "image" + (count + 1),
            width: 0,
            height: 0,
          });
          setImgDataSources({ ...imgDataSources.data, data: imageDataArray });
          count++;
        };
        reader.readAsDataURL(file);
      } else if (entry.isDirectory) {
        const directoryReader = entry.createReader();
        const entries: any = await new Promise(resolve => {
          directoryReader.readEntries((entries: any) => {
            resolve(entries);
          });
        });

        for (let i = 0; i < entries.length; i++) {
          await traverseFileTree(entries[i], _path + entry.name + "/");
        }
      }
    };

    const items = e.dataTransfer.items;
    for (let i = 0; i < items.length; i++) {
      const entry = items[i].webkitGetAsEntry();
      traverseFileTree(entry, "");
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>経費申請</Text>
        <Loading isLoading={isLoading} />
        <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={modalFormStyles.scrollContainer}
          >
            <ImageDropArea onDrop={handleDrop}>
              <View style={[formWrap(windowWidth).formWrap]}>
                <ImageUpload
                  parentImgDataSources={imgDataSources}
                  setParentImgDataSources={updateImgDataSources}
                  setImageString={() => {}}
                  dispIconText={true}
                />

                {imgDataSources.data.length > 0 ? (
                  <ImageSlider
                    editable={true}
                    parentImgDataSources={imgDataSources}
                    setParentImgDataSources={updateImgDataSources}
                  />
                ) : (
                  <></>
                )}

                <SelectForm
                  label="申請種類"
                  placeholderLabel="選択してください"
                  value="expenseRequest"
                  onChange={e => setExpense({ ...expense, requestType: e })}
                  items={expenseRequestTypeList}
                  disabled={true}
                />
                <InputForm
                  label="申請者名"
                  value={userInfo.user.name}
                  onChange={() => {}}
                  disabled={false}
                />
                <DateForm
                  value={expense.requestAt.toDate()}
                  disabled={true}
                  label={"申請日"}
                  type={"date"}
                />
                <DateForm
                  value={expense?.reckoningAt?.toDate() ?? new Date()}
                  onChange={onReckoningAtChangeHandler}
                  disabled={!CheckCanEditIsNewOrIdRequest(expense.status)}
                  type={"date"}
                  label={"利用日"}
                />
                <SelectForm
                  label="現場名"
                  placeholderLabel="選択してください"
                  value={expense.siteId ?? ""}
                  onChange={e => setExpense({ ...expense, siteId: e })}
                  items={siteSelect}
                  disabled={!CheckCanEditIsNewOrIdRequest(expense.status)}
                />
                <InputForm
                  label="金額"
                  value={expense.totalAmount}
                  onChange={e => {
                    const input = Number(e.replace(/,/g, ""));
                    if (isNaN(input)) {
                      setAlert({
                        isAlert: true,
                        msg: "数値で入力してください",
                      });
                      return;
                    }
                    setExpense({
                      ...expense,
                      totalAmount: input,
                    });
                  }}
                  disabled={CheckCanEditIsNewOrIdRequest(expense.status)}
                />
                <SelectForm
                  label="経費種類"
                  placeholderLabel="選択してください"
                  value={expense.expenseItemId ?? ""}
                  onChange={e => setExpense({ ...expense, expenseItemId: e })}
                  items={expenseItemSelect}
                  disabled={!CheckCanEditIsNewOrIdRequest(expense.status)}
                />
                <SelectForm
                  label="経費枠"
                  placeholderLabel="選択してください"
                  value={expense.expenseLimitSettingId ?? ""}
                  onChange={e =>
                    setExpense({ ...expense, expenseLimitSettingId: e })
                  }
                  items={expenseLimitSelect}
                  disabled={!CheckCanEditIsNewOrIdRequest(expense.status)}
                />

                <MultipleInputForm
                  label="申請事由"
                  value={expense.description}
                  onChange={e => {
                    setExpense({ ...expense, description: e });
                  }}
                  disabled={CheckCanEditIsNewOrIdRequest(expense.status)}
                />
                <MultipleInputForm
                  label="備考"
                  value={expense.memo}
                  onChange={e => {
                    setExpense({ ...expense, memo: e });
                  }}
                  disabled={CheckCanEditIsNewOrIdRequest(expense.status)}
                />

                {!CheckCanEditIsNewOrIdRequest(expense.status) && (
                  <MultipleInputForm
                    label="コメント"
                    value={expense.memo}
                    onChange={e => {}}
                    disabled={false}
                  />
                )}

                <View style={modalFormStyles.formItem}>
                  <ModalCancelButton
                    buttonText="キャンセル"
                    onClick={() => hideModal()}
                  />

                  {CheckCanEditIsNew(expense.status) ? (
                    <ModalApprovalButton
                      onClick={() => createExpense()}
                      buttonText="登録"
                    />
                  ) : CheckCanEditIsRequest(expense.status) ? (
                    <ModalApprovalButton
                      onClick={() => updateExpense()}
                      buttonText="更新"
                    />
                  ) : (
                    <></>
                  )}
                </View>

                {CheckCanEditIsRequest(expense.status) ? (
                  <View style={modalFormStyles.formItemCenter}>
                    <ModalDeleteButton
                      buttonText="申請を取り下げる"
                      onClick={() => updateExpense("withdrawal")}
                    />
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </ImageDropArea>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
      <Dialog />
    </>
  );
}
/**
 * 指定された経費種類が現場経費かどうかを判定します
 * @param list
 * @param targetId
 * @returns
 */
const CheckIsNoExpenseLimit = (
  list: ExpenseItemCollection[],
  targetId: string
) => {
  return list.some(f => f.id === targetId && f.isNoExpenseLimit === true);
};
/**
 *
 * @param status 新規申請の時のみtrueを返却します
 * @returns
 */
const CheckCanEditIsNew = (status: string) => {
  return status === "";
};

/**
 * 申請状況が申請中の時のみtrueを返却します
 * @param status 申請ステータス
 */
const CheckCanEditIsRequest = (status: string) => {
  return status === "applying";
};

/**
 *
 * @param status 新規申請・申請中の時のみtrueを返却します
 * @returns
 */
const CheckCanEditIsNewOrIdRequest = (status: string) => {
  return status === "" || status === "applying";
};

/**
 * モーダル内のデフォルトのデータ
 * @param userInfo
 * @returns
 */
const defaultStateValue = (userInfo: UserInfo): ExpenseData => {
  return {
    id: "",
    userId: userInfo.user.id,
    siteId: "",
    requestType: "expenseRequest",
    requestAuthorityId: userInfo.authorityId,
    requestAt: ConvertTimeStampFromDate(new Date()),
    reckoningAt: ConvertTimeStampFromDate(new Date()),
    totalAmount: 0,
    status: "",
    description: "",
    memo: "",
    managerDescription: "",
    approveAt: null,
    paymentAt: null,
    paymentAmount: null,
    paymentStatus: "unpaid",
    expenseItemId: "",
    isNoExpenseLimit: false,
    expenseLimitSettingId: "",
  };
};
