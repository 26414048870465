import Constants from "expo-constants";

export default {
  apiKey: Constants.expoConfig?.extra?.firebaseConfig.apiKey,
  authDomain: Constants.expoConfig?.extra?.firebaseConfig.authDomain,
  projectId: Constants.expoConfig?.extra?.firebaseConfig.projectId,
  storageBucket: Constants.expoConfig?.extra?.firebaseConfig.storageBucket,
  messagingSenderId:
    Constants.expoConfig?.extra?.firebaseConfig.messagingSenderId,
  appId: Constants.expoConfig?.extra?.firebaseConfig.appId,
};
