import { SwitchButton } from "components";
import React from "react";
import { StyleSheet, View } from "react-native";
import { SwitchButtonListType } from "types";
import { IsMobile } from "utils";

type Props = {
  /** ボタン情報を定義します */
  buttonList: SwitchButtonListType[];
  /** 現在のキーを取得します */
  currentKey: string;
  /** クリック時の動作を定義します */
  onEditClick: (id: string) => void;
};

/** 切り替えボタン群を描写します */
export const SwitchButtonList = ({
  buttonList,
  currentKey,
  onEditClick,
}: Props) => {
  return (
    <View style={[styles.headerButtonContainer]}>
      {buttonList.map(m => (
        <SwitchButton
          key={m.key}
          label={m.label}
          selected={m.key === currentKey}
          onClick={() => onEditClick(m.key)}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  headerButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    height: 100,
    justifyContent: IsMobile() ? "space-between" : undefined,
  },
});
