import {
  CreateCenterCol,
  CreateLeftCol,
  CreateRightCol,
  CreateRow,
  MobileTableEditButton,
  MobileTableViewStatus,
} from "components";
import { authorityType } from "constants/index";
import dayjs from "dayjs";
import React from "react";
import { ScrollView, Text, View } from "react-native";
import { mobileTableStyles } from "styles";
import { UserList } from "types";
import { CheckTimeStampIsNaN } from "utils";

export const MobileUserTable = ({
  tableList,
  onEditClick,
}: {
  tableList: UserList[];
  onEditClick: (id: string) => void;
}) => {
  return (
    <ScrollView
      style={mobileTableStyles.scroll_view_style}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <View style={mobileTableStyles.list_line_view} />
        {tableList?.map((data, i) => (
          <View key={i} style={mobileTableStyles.list_line_view}>
            <View style={mobileTableStyles.contents_container}>
              <CreateRow>
                <CreateLeftCol>
                  <MobileTableEditButton
                    onEditClick={() => onEditClick(data.id)}
                    buttonText="編集"
                  />
                </CreateLeftCol>
                <CreateRightCol>
                  <MobileTableViewStatus
                    buttonColor={authorityType[data.authorityTitle].color}
                    buttonText={data.authorityTitle}
                  />
                </CreateRightCol>
              </CreateRow>
              <CreateRow>
                <CreateLeftCol>
                  <Text style={mobileTableStyles.base_text}>{data.name}</Text>
                </CreateLeftCol>
                <CreateCenterCol>
                  <Text
                    style={mobileTableStyles.small_text}
                  >{`最終ログイン日：${
                    CheckTimeStampIsNaN(data.signedAt)
                      ? "未ログイン"
                      : dayjs(data.signedAt.toDate()).format("YYYY/MM/DD")
                  }`}</Text>
                </CreateCenterCol>
              </CreateRow>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};
