import firebase from "firebase/compat/app";
import { CreateCheckResult, PaidVacationList } from "types";

/**
 * 有給管理の新規作成の書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param paidVacationData 登録対象の有給情報
 * @returns　CreateCheckResult
 */
export const CreatePaidVacation = async (
  paidVacationData: PaidVacationList | null
): Promise<CreateCheckResult> => {
  if (paidVacationData === null)
    return { isCheck: false, message: "登録に失敗しました" };

  return await firebase
    .firestore()
    .collection("paidVacation")
    .add(paidVacationData)
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
