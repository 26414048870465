import { DialogContext } from "GlobalContext";
import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import AwesomeAlert from "react-native-awesome-alerts";
import { AlertInfo } from "types";

/** ページ全体で使用するアラートを描写します */
export function Alert({ isAlert, msg, confTxt, afterExec }: AlertInfo) {
  const { setAlert } = useContext(DialogContext);

  const initAlertContext = () => {
    setAlert({ isAlert: false, msg: "", confTxt: "", afterExec: () => {} });
  };

  if (!isAlert) return <></>;

  return (
    <AwesomeAlert
      show={isAlert}
      message={msg}
      showConfirmButton={true}
      closeOnTouchOutside={false}
      closeOnHardwareBackPress={false}
      messageStyle={styles.alertText}
      onConfirmPressed={() => {
        afterExec && afterExec();
        initAlertContext();
      }}
      confirmButtonStyle={styles.alertButton}
      confirmText={confTxt ? confTxt : "確認"}
    />
  );
}

const styles = StyleSheet.create({
  alertText: {
    fontSize: 14,
    color: "black",
    alignItems: "center",
  },
  alertButton: {
    borderRadius: 4,
    backgroundColor: "#4169e1",
  },
});
