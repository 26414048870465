import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  NoteBookCollection,
  NoteBookPageData,
  UserInfo,
} from "types";

/**
 * Wikiのページ新規作成の書き込みを行います
 * @param userInfo userContextから取得したユーザー情報
 * @param noteBooks collectionContextから取得したWiki情報
 * @param noteBookData 登録対象のWiki情報
 * @returns　CreateCheckResult
 */
export const CreateNoteBookPage = async (
  userInfo: UserInfo | null,
  noteBooks: NoteBookCollection[] | null,
  noteBookPageData: NoteBookPageData
): Promise<CreateCheckResult> => {
  if (noteBooks === null)
    return { isCheck: false, message: "登録に失敗しました" };

  try {
    const user = userInfo?.user;
    const date = new Date();

    const targetNoteBook = noteBooks
      .filter(f => f.id === noteBookPageData.noteBookId)
      .shift();

    if (targetNoteBook === undefined)
      return { isCheck: false, message: "登録に失敗しました" };

    const duplicationNotebooks = targetNoteBook.notebookPage
      .filter(f => f.deleted === false)
      .filter(f => f.pageTitle === noteBookPageData.pageTitle);

    if (duplicationNotebooks.length > 0)
      return {
        isCheck: false,
        message: `既にページタイトル「${noteBookPageData.pageTitle}」は登録されています`,
      };

    const detailResult = await firebase
      .firestore()
      .collection("noteBook")
      .doc(noteBookPageData.noteBookId)
      .collection("noteBookPage")
      .add({
        pageTitle: noteBookPageData.pageTitle,
        sort: noteBookPageData.sort,
        text: noteBookPageData.text,
        deleted: false,
        updateAt: date,
        updateUser: user?.id,
      })
      .then(async noteBookPage => ({ isCheck: true, id: noteBookPage.id }))
      .catch(() => ({ isCheck: false, id: "" }));

    if (detailResult.isCheck === false)
      return { isCheck: false, message: "登録に失敗しました" };

    const doc = await firebase
      .firestore()
      .collection("noteBook")
      .doc(noteBookPageData.noteBookId)
      .get();

    return await doc.ref
      .update({
        updateAt: date,
        updateUser: user?.id,
      })
      .then(() => ({
        isCheck: true,
        message: "登録しました",
        id: detailResult.id,
      }))
      .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));
  } catch (error) {
    return { isCheck: false, message: "登録に失敗しました" };
  }
};
