import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  ChangeEmailModal,
  CheckBoxForm,
  DateForm,
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  SelectForm,
} from "components";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import { passwordReset } from "server/Firebase";
import {
  CreateUser,
  FetchUser,
  UpdateUser,
  UpdateUserSort,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { SiteModalStatus, UserData } from "types";
import { IsWeb } from "utils";
type Props = {
  hideModal: Function;
  maxSortNo?: number;
  modalStatus: SiteModalStatus;
  authoritySelect: Item[];
};

export function UserForm({
  hideModal,
  modalStatus,
  maxSortNo,
  authoritySelect,
}: Props) {
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { setAlert, setConfirm } = useContext(DialogContext);
  const { UserCollection, onSnapShot, clearSnapShot } =
    useContext(CollectionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRetirement, setIsRetirement] = useState<boolean>(false);
  const [user, setUser] = useState<UserData>(defaultUser(maxSortNo ?? 0));
  const [isVisibleChangeEmailModal, setIsVisibleChangeEmailModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (modalStatus === null) {
      setIsRetirement(false);
      setUser(defaultUser(maxSortNo ?? 0));
      return;
    }
    const result = FetchUser(UserCollection, modalStatus.id ?? "");
    if (result === null) {
      setIsRetirement(false);
      setUser(defaultUser(maxSortNo ?? 0));
      return;
    }
    setIsRetirement(result.retirementAt !== null);
    setUser(result);
  }, [modalStatus]);

  const onEntryAtChangeHandler = (evt: Date): void => {
    setUser({
      ...user,
      entryAt: evt,
    });
  };

  const onRetirementAtChangeHandler = (evt: Date): void => {
    setUser({
      ...user,
      retirementAt: evt,
    });
  };

  const switchVisibleChangeEmailModal = (): void => {
    setIsVisibleChangeEmailModal(!isVisibleChangeEmailModal);
  };

  const validationCheck = (): boolean => {
    if (!user.name) {
      setAlert({ isAlert: true, msg: "社員名を入力してください" });
      return false;
    }
    if (!user.email) {
      setAlert({ isAlert: true, msg: "メールアドレスを入力してください" });
      return false;
    } else {
      if (
        !user.email.match(
          /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/
        )
      ) {
        setAlert({
          isAlert: true,
          msg: "メールアドレスの形式が正しくありません",
        });
        return false;
      }
    }

    if (!modalStatus?.isEdit) {
      if (!user.password) {
        setAlert({ isAlert: true, msg: "パスワードを入力してください" });
        return false;
      } else {
        if (!user.password.match("^(?=.*[0-9a-zA-Z -~]).{6,}$")) {
          setAlert({
            isAlert: true,
            msg: "パスワードは、半角英数字記号のいずれかを含んだ6文字以上で入力してください",
          });
          return false;
        }
      }
    }

    if (!user.authorityId) {
      setAlert({ isAlert: true, msg: "権限を選択してください" });
      return false;
    }
    if (!user.entryAt) {
      setAlert({ isAlert: true, msg: "入社日を入力してください" });
      return false;
    }
    return true;
  };

  const createUser = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);
    try {
      clearSnapShot("user");
      const result = await CreateUser(userInfo, user);
      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("user");
          },
        });
        return;
      }
      const result2 = await UpdateUserSort(user);
      if (result2.isCheck) {
        setAlert({
          isAlert: true,
          msg: result2.message,
          confTxt: "OK",
          afterExec: () => {
            // 更新を再開
            onSnapShot("user");
            hideModal();
          },
        });
      } else {
        setAlert({
          isAlert: true,
          msg: result2.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("user");
          },
        });
      }
    } catch {
      setAlert({
        isAlert: true,
        msg: "登録に失敗しました",
        afterExec: () => {
          // 更新を再開
          onSnapShot("user");
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  /** 更新時に権限を変更したかどうかをチェックするメソッド */
  const isAuthChange = () => {
    // 現在のユーザーコレクションから同じID、同じ権限を取得
    const target = UserCollection?.filter(
      f => f.id === user.id && f.authorityId === user.authorityId
    ).shift();
    // 存在していなければ編集
    return target === undefined;
  };

  /** 更新時に並びを変更したかどうかをチェックするメソッド */
  const isSortChange = () => {
    const target = UserCollection?.filter(
      f => f.id === user.id && f.sort === user.sort
    ).shift();
    return target !== undefined;
  };

  const updateSortNumber = () => {
    const target = UserCollection?.filter(f => f.id === user.id).shift();
    if (target === undefined) return user.sort;
    if (target.sort < user.sort) return user.sort + 0.1;
    return user.sort - 0.1;
  };

  const updateUser = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    if (!isRetirement) {
      // 退職が選択されていない場合は「退職日」をクリア
      user.retirementAt = null;
    } else {
      // 退職チェックがあり、退職日が未指定の場合は当日を入れる
      if (user.retirementAt === null) {
        user.retirementAt = new Date();
        user.sort = user.sort + 10000;
      }
    }
    setIsLoading(true);
    try {
      clearSnapShot("user");
      const target = user;
      // 並びを一時的に更新
      if (!isSortChange()) target.sort = updateSortNumber();

      const result = await UpdateUser(userInfo, target, isAuthChange());
      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("user");
          },
        });
        return;
      }
      const result2 = await UpdateUserSort(target);
      if (result2.isCheck) {
        setAlert({
          isAlert: true,
          msg: result2.message,
          confTxt: "OK",
          afterExec: () => {
            // 更新を再開
            onSnapShot("user");
            hideModal();
          },
        });
      } else {
        setAlert({
          isAlert: true,
          msg: result2.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("user");
          },
        });
      }
    } catch {
      setAlert({
        isAlert: true,
        msg: "更新に失敗しました",
        afterExec: () => {
          // 更新を再開
          onSnapShot("user");
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>
          {!modalStatus?.isEdit ? "社員追加" : "社員編集"}
        </Text>
        <Loading isLoading={isLoading} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={modalFormStyles.scrollContainer}
        >
          <View style={[formWrap(windowWidth).formWrap]}>
            <InputForm
              label="社員名"
              value={user.name}
              onChange={e => {
                setUser({ ...user, name: e });
              }}
              disabled={true}
            />

            {modalStatus?.isEdit && (
              <View style={{ paddingBottom: 20 }}>
                <Text style={[styles.label]}>メール</Text>
                <Pressable
                  onPress={() => {
                    setIsVisibleChangeEmailModal(true);
                  }}
                  style={styles.button}
                >
                  <Text style={[styles.resetButtonText]}>メール再設定</Text>
                </Pressable>
              </View>
            )}
            {!modalStatus?.isEdit && (
              <InputForm
                label="メールアドレス"
                value={user.email}
                onChange={e => {
                  setUser({ ...user, email: e });
                }}
                disabled={true}
              />
            )}

            {modalStatus?.isEdit && (
              <View style={{ paddingBottom: 20 }}>
                <Text style={[styles.label]}>パスワード</Text>
                <Pressable
                  style={[styles.button, { backgroundColor: "#4169e1" }]}
                  onPress={() => {
                    setConfirm({
                      isConfirm: true,
                      msg: "パスワード再設定メールを送信しますか？",
                      afterExec: () => {
                        passwordReset(user.email);
                        setAlert({
                          isAlert: true,
                          msg: "パスワード再設定メールを送信しました",
                        });
                      },
                    });
                  }}
                >
                  <Text style={[styles.resetButtonText]}>パスワード再設定</Text>
                </Pressable>
              </View>
            )}

            {!modalStatus?.isEdit && (
              <InputForm
                label="パスワード"
                value={user.password}
                onChange={e => {
                  setUser({ ...user, password: e });
                }}
                disabled={true}
              />
            )}

            <SelectForm
              label="権限"
              placeholderLabel="選択してください"
              value={user.authorityId}
              onChange={e => setUser({ ...user, authorityId: e })}
              items={authoritySelect}
            />
            <DateForm
              value={user.entryAt ?? new Date()}
              onChange={onEntryAtChangeHandler}
              disabled={false}
              label={"入社日"}
              type={"date"}
            />

            {modalStatus?.isEdit ? (
              <>
                <CheckBoxForm
                  label="退職"
                  checked={isRetirement}
                  onChange={() => {
                    setIsRetirement(!isRetirement);
                  }}
                />

                {isRetirement && (
                  <DateForm
                    value={user.retirementAt ?? new Date()}
                    onChange={onRetirementAtChangeHandler}
                    disabled={false}
                    label={"退職日"}
                    type={"date"}
                  />
                )}
              </>
            ) : (
              <></>
            )}

            <InputForm
              label="並び順"
              value={String(user.sort)}
              onChange={e => {
                const sort = parseInt(e.replace(/[^0-9]/g, ""));
                setUser({
                  ...user,
                  sort: isNaN(sort) ? 0 : sort,
                });
              }}
              disabled={true}
            />

            <View style={modalFormStyles.formItem}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => hideModal()}
              />

              {!modalStatus?.isEdit ? (
                <ModalApprovalButton
                  buttonText="登録"
                  onClick={() => createUser()}
                />
              ) : (
                <ModalApprovalButton
                  buttonText="更新"
                  onClick={() => updateUser()}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </View>
      <Dialog />
      <ChangeEmailModal
        isVisible={isVisibleChangeEmailModal}
        currentEmail={user.email}
        closeModal={switchVisibleChangeEmailModal}
      />
    </>
  );
}

/** 現場登録の初期値を登録します */
const defaultUser = (maxSortNo: number): UserData => {
  return {
    id: "",
    uid: "",
    email: "",
    password: "",
    authorityId: "",
    name: "",
    entryAt: new Date(),
    retirementAt: new Date(),
    sort: maxSortNo ? maxSortNo + 1 : 1,
  };
};

const styles = StyleSheet.create({
  label: {
    fontSize: 20,
  },

  button: {
    width: "100%",
    paddingVertical: 10,
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
    padding: 10,
    backgroundColor: "#4169e1",
  },
  resetButtonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: IsWeb() ? 12 : 14,
  },
});
