import firebase from "firebase/compat/app";
import { CalendarData, CreateCheckResult, UserInfo } from "types";

/**
 * カレンダーにシフト情報の書き込みを行います
 * @param reqUser userContextから取得した情報
 * @param CalendarData 登録予定のカレンダーデータ
 * @returns
 */
export const FixShift = async (
  reqUser: UserInfo | null,
  calendarData: CalendarData[]
): Promise<CreateCheckResult> => {
  const date = new Date();
  const deleteCalendar = calendarData.filter(f => f.uid !== "");
  const addCalendar = calendarData.filter(f => f.uid === "");

  const delResult = await Promise.all(
    deleteCalendar.map(async elem => {
      return await firebase
        .firestore()
        .collection("calendar")
        .doc(elem.uid)
        .delete()
        .then(() => true)
        .catch(() => false);
    })
  );

  const addResult = await Promise.all(
    addCalendar.map(async elem => {
      return await firebase
        .firestore()
        .collection("calendar")
        .add({
          title: elem.title,
          startDate: elem.startDate.toDate(),
          endDate: elem.endDate.toDate(),
          isHoliday: elem.isHoliday,
          memo: elem.memo,
          userId: elem.userId,
          isAllDay: elem.isAllDay,
        })
        .then(() => true)
        .catch(() => false);
    })
  );

  if (addResult.some(s => !s) || delResult.some(s => !s)) {
    return { isCheck: false, message: "更新に失敗しました" };
  }
  return { isCheck: true, message: "更新しました" };
};
