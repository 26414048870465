import { DialogContext, UserInfoContext, WindowContext } from "GlobalContext";
import {
  CheckBoxForm,
  DateForm,
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  MultipleInputForm,
  SelectForm,
} from "components";
import { paidVacationTypeList, requestTypeList } from "constants/index";

import { CollectionContext } from "GlobalContext";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, ScrollView, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  FetchLatestAttendanceById,
  FetchRequest,
  FetchWorkScheduleList,
  UpdateRequest,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import {
  AttendanceCollection,
  PaidVacationType,
  RequestCollection,
  RequestData,
  RequestModalStatus,
  RequestStatusList,
  RequestType,
  UserInfo,
} from "types";
import {
  ConvertNullOrUndefined,
  ConvertTimeStampFromDate,
  GetUserName,
} from "utils";

type Props = {
  hideModal: Function;
  modalStatus: RequestModalStatus;
  RequestCollection: RequestCollection[];
  AttendanceCollection: AttendanceCollection[];
};

export function RequestApprovalForm({
  hideModal,
  modalStatus,
  RequestCollection,
  AttendanceCollection,
}: Props) {
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<"date" | "datetime">("datetime");
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { PaidVacationCollection, UserCollection } =
    useContext(CollectionContext);
  if (userInfo == null) {
    setAlert({ isAlert: true, msg: "ユーザー情報が取得できませんでした" });
    return <></>;
  }
  const [request, setRequest] = useState<RequestData>(
    defaultStateValue(userInfo)
  );
  const [isApproval, setIsApproval] = useState<boolean>(true);
  const [workScheduleList, setWorkScheduleList] = useState<Item[]>([]);

  const onTypeChangeHandler = (e: string) => {
    const type = e as RequestType;
    if (IsPaidVacation(type)) setMode("date");
    if (IsOverTime(type)) setMode("datetime");
    if (IsLeaveEarly(type)) setMode("datetime");
    if (IsAbsence(type)) setMode("date");
    if (IsLateness(type)) setMode("datetime");
    if (IsPrivateOuting(type)) setMode("datetime");
    if (IsSubstituteAttendance(type)) setMode("datetime");
    if (IsHolidayWork(type)) setMode("datetime");
    if (IsSubstituteHoliday(type)) setMode("date");
    if (IsStaggeredWorkHours(type)) setMode("datetime");
    if (IsAttendanceCorrection(type)) setMode("datetime");
    if (IsPaidVacation(type)) setMode("date");
  };

  useEffect(() => {
    if (modalStatus === null) {
      setRequest(defaultStateValue(userInfo));
      return;
    }
    if (modalStatus.type === "requestApprovalWait") {
      const result = FetchRequest(RequestCollection, modalStatus.id ?? "");
      if (result === null) {
        const defaultResult = defaultStateValue(userInfo);
        getWorkScheduleList(defaultResult);
        onTypeChangeHandler(defaultResult.requestType);
        setRequest(defaultResult);
        return;
      }
      onTypeChangeHandler(result.requestType);
      getWorkScheduleList(result);
      setRequest(result);
    }
  }, [modalStatus]);

  // useEffect(() => {
  //   if (request.requestType === "attendanceCorrection") {
  //     getWorkScheduleList();
  //   }
  // }, [request.requestType]);

  const validationCheck = (): boolean => {
    if (!request.requestType) {
      setAlert({ isAlert: true, msg: "申請種類を選択してください" });
      return false;
    }

    if (request.acquisitionStartAt > request.acquisitionEndAt) {
      setAlert({
        isAlert: true,
        msg: "開始予定日が終了予定日より大きくなっています。",
      });
      return false;
    }
    return true;
  };

  const updateRequest = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }
    setIsLoading(true);
    request.status = isApproval ? "approval" : "reject";
    try {
      const result = await UpdateRequest(
        userInfo,
        request,
        PaidVacationCollection,
        RequestCollection
      );
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const getWorkScheduleList = async (data: RequestData): Promise<void> => {
    setIsLoading(true);
    try {
      const user = UserCollection?.filter(f => f.id === data.userId).shift();
      const result = FetchWorkScheduleList(
        user === undefined ? "" : user.uid,
        AttendanceCollection,
        data.acquisitionStartAt.toDate(),
        data.isAttendanceCancel
      );
      setWorkScheduleList(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const isInvalidDate = (date: string) =>
    Number.isNaN(new Date(date).getTime());

  const onChangeCurrentAttendanceCorrection = (id: string) => {
    if (id === "") return;
    //　勤怠情報がある場合
    if (isInvalidDate(id)) {
      var target = FetchLatestAttendanceById(id, AttendanceCollection);
      if (target === null) {
        setAlert({
          isAlert: true,
          msg: "指定された勤怠情報の取得に失敗しました。",
        });
        return;
      }

      if (
        !request.correctionTargetAttendanceId ||
        request.correctionTargetAttendanceId != target.id
      ) {
        setRequest({
          ...request,
          acquisitionStartAt: ConvertTimeStampFromDate(
            dayjs(target.startWorkSchedule?.toDate()).toDate()
          ),
          acquisitionEndAt: ConvertTimeStampFromDate(
            dayjs(target.endWorkSchedule?.toDate()).toDate()
          ),
          correctionTargetAttendanceId: target.id,
        });
      }
    } else {
      setRequest({
        ...request,
        acquisitionStartAt: ConvertTimeStampFromDate(dayjs(id).toDate()),
        acquisitionEndAt: ConvertTimeStampFromDate(dayjs(id).toDate()),
        correctionTargetAttendanceId: "",
      });
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>勤怠申請承認</Text>
        <Loading isLoading={isLoading} />
        <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
          <ScrollView
            style={modalFormStyles.scrollContainer}
            showsVerticalScrollIndicator={false}
          >
            <View style={[formWrap(windowWidth).formWrap]}>
              <SelectForm
                label="申請種類"
                placeholderLabel="選択してください"
                value={request.requestType}
                onChange={e =>
                  setRequest({ ...request, requestType: e as RequestType })
                }
                items={requestTypeList}
                disabled={true}
              />
              <InputForm
                label="申請者名"
                value={GetUserName(UserCollection ?? [], request.userId ?? "")}
                onChange={() => {}}
                disabled={false}
              />
              <DateForm
                value={ConvertNullOrUndefined(
                  request.requestAt,
                  ConvertTimeStampFromDate(new Date())
                ).toDate()}
                disabled={true}
                label={"申請日"}
                type={"date"}
              />
              <PaidVacationTypeSelect
                label="有給種類"
                value={request.paidVacationType ?? ""}
                onChange={() => {}}
                disabled={true}
                items={paidVacationTypeList}
                type={request.requestType}
              />
              <AttendanceCancelCheckBox
                checked={request.isAttendanceCancel}
                onChange={() => {}}
                disabled={true}
                type={request.requestType}
              />
              <CorrectionTargetSelect
                label="修正対象"
                value={
                  request.correctionTargetAttendanceId ??
                  dayjs(
                    request?.acquisitionStartAt.toDate() ?? new Date()
                  ).format("YYYY/MM/DD")
                }
                onChange={e => onChangeCurrentAttendanceCorrection(e)}
                items={workScheduleList}
                disabled={true}
                type={request.requestType}
              />
              <AcquisitionAt
                value={request?.acquisitionStartAt.toDate() ?? new Date()}
                onChange={() => {}}
                disabled={true}
                type={request.requestType}
                mode={mode}
              />
              <AcquisitionStartAt
                value={request?.acquisitionStartAt.toDate() ?? new Date()}
                onChange={() => {}}
                disabled={true}
                type={request.requestType}
                mode={mode}
                visible={
                  !(
                    IsAttendanceCorrection(request.requestType) &&
                    request.isAttendanceCancel
                  )
                }
              />
              <AcquisitionEndAt
                value={request?.acquisitionEndAt.toDate() ?? new Date()}
                onChange={() => {}}
                disabled={true}
                type={request.requestType}
                mode={mode}
                visible={
                  !(
                    IsAttendanceCorrection(request.requestType) &&
                    request.isAttendanceCancel
                  )
                }
              />
              <MultipleInputForm
                label="申請事由"
                value={request.description}
                onChange={e => setRequest({ ...request, description: e })}
                disabled={false}
              />
              <MultipleInputForm
                label="備考"
                value={request.memo}
                onChange={e => setRequest({ ...request, memo: e })}
                disabled={false}
              />
              <CheckBoxForm
                label="承認"
                checked={isApproval}
                onChange={() => {
                  setIsApproval(true);
                  setRequest({
                    ...request,
                    status: "approval",
                  });
                }}
                disabled={
                  (
                    ["applying", "approval", "reject"] as RequestStatusList[]
                  ).includes(request.status)
                    ? false
                    : true
                }
              />
              <CheckBoxForm
                label="却下"
                checked={!isApproval}
                onChange={() => {
                  setIsApproval(false);
                  setRequest({
                    ...request,
                    status: "reject",
                  });
                }}
                disabled={
                  (
                    ["applying", "approval", "reject"] as RequestStatusList[]
                  ).includes(request.status)
                    ? false
                    : true
                }
              />
              <DateForm
                value={new Date()}
                disabled={true}
                label={"承認日"}
                type={"date"}
              />
              <MultipleInputForm
                label="コメント"
                value={request.managerDescription}
                onChange={e =>
                  setRequest({ ...request, managerDescription: e })
                }
                disabled={true}
              />
              <View style={modalFormStyles.formItem}>
                <ModalCancelButton
                  buttonText="キャンセル"
                  onClick={() => hideModal()}
                />

                {modalStatus?.isEdit && (
                  <ModalApprovalButton
                    buttonText="更新"
                    onClick={() => updateRequest()}
                  />
                )}
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
      <Dialog />
    </>
  );
}

type AttendanceCancelProps = {
  checked: boolean;
  onChange: () => void;
  disabled: boolean;
  type: RequestType;
};
const AttendanceCancelCheckBox = ({
  checked,
  onChange,
  disabled,
  type,
}: AttendanceCancelProps) => {
  return (
    <>
      {/* 勤怠修正申請のみ表示 */}
      {!IsBlanc(type) && IsAttendanceCorrection(type) && (
        <CheckBoxForm
          label="出勤を取り消す"
          checked={checked}
          onChange={() => onChange()}
          disabled={disabled}
        />
      )}
    </>
  );
};

type CorrectionTargetProps = {
  label: string;
  value: string;
  onChange: (e: string) => void;
  disabled: boolean;
  items: Item[];
  type: RequestType;
};
const CorrectionTargetSelect = ({
  label,
  value,
  onChange,
  disabled,
  items,
  type,
}: CorrectionTargetProps) => {
  return (
    <>
      {type === "attendanceCorrection" && (
        <SelectForm
          label={label}
          placeholderLabel="選択してください"
          value={value}
          onChange={onChange}
          items={items}
          disabled={disabled}
        />
      )}
    </>
  );
};

type PaidVacationTypeProps = {
  label: string;
  value: PaidVacationType;
  onChange: (e: PaidVacationType) => void;
  disabled: boolean;
  items: Item[];
  type: RequestType;
};
const PaidVacationTypeSelect = ({
  label,
  value,
  onChange,
  disabled,
  items,
  type,
}: PaidVacationTypeProps) => {
  return (
    <>
      {type === "paidVacation" && (
        <SelectForm
          label={label}
          placeholderLabel="選択してください"
          value={value}
          onChange={onChange}
          disabled={disabled}
          items={items}
        />
      )}
    </>
  );
};

type AcquisitionAtProps = {
  value: Date;
  onChange: (e: Date) => void;
  disabled: boolean;
  mode: "date" | "datetime";
  type: RequestType;
};
const AcquisitionAt = ({
  value,
  onChange,
  disabled,
  mode,
  type,
}: AcquisitionAtProps) => {
  var label = "取得日";
  return (
    <>
      {/* 有給申請は表示 */}
      {IsPaidVacation(type) && (
        <DateForm
          value={value}
          onChange={e => onChange(e)}
          disabled={disabled}
          label={label}
          type={mode}
          hourLabel={undefined}
        />
      )}
    </>
  );
};

type AcquisitionStartAtProps = {
  value: Date;
  onChange: (e: Date) => void;
  disabled: boolean;
  mode: "date" | "datetime";
  type: RequestType;
  visible?: boolean;
};
const AcquisitionStartAt = ({
  value,
  onChange,
  disabled,
  mode,
  type,
  visible,
}: AcquisitionStartAtProps) => {
  var label = "取得開始日";
  if (IsOverTime(type)) label = "開始日";
  if (IsLateness(type)) label = "遅刻日";
  if (IsAttendanceCorrection(type)) label = "出勤日";
  if (IsSubstituteAttendance(type)) label = "振替出勤日";
  if (IsHolidayWork(type)) label = "休日出勤日";
  if (IsStaggeredWorkHours(type)) label = "時差出勤日";
  return (
    <>
      {/* 未選択・残業・早退・有給申請以外は表示 */}
      {!IsBlanc(type) &&
        !IsLeaveEarly(type) &&
        !IsPaidVacation(type) &&
        (visible ?? true) && (
          <DateForm
            value={value}
            onChange={e => onChange(e)}
            disabled={disabled}
            label={label}
            type={mode}
            hourLabel={IsLateness(type) ? "出勤時間" : undefined}
          />
        )}
    </>
  );
};

type AcquisitionEndAtProps = {
  value: Date;
  onChange: (e: Date) => void;
  disabled: boolean;
  mode: "date" | "datetime";
  type: RequestType;
  visible?: boolean;
};
const AcquisitionEndAt = ({
  value,
  onChange,
  disabled,
  mode,
  type,
  visible,
}: AcquisitionEndAtProps) => {
  var label = "取得終了日";
  if (IsOverTime(type)) label = "退勤日";
  if (IsAttendanceCorrection(type)) label = "退勤日";
  if (IsLeaveEarly(type)) label = "早退日";
  if (IsSubstituteAttendance(type)) label = "退勤日";
  if (IsHolidayWork(type)) label = "退勤日";
  if (IsStaggeredWorkHours(type)) label = "退勤日";
  return (
    <>
      {/* 未選択・遅刻・有給申請以外は表示 */}
      {!IsBlanc(type) &&
        !IsLateness(type) &&
        !IsPaidVacation(type) &&
        (visible ?? true) && (
          <DateForm
            value={value}
            onChange={e => onChange(e)}
            disabled={disabled}
            label={label}
            type={mode}
          />
        )}
    </>
  );
};

/** 申請タイプが未選択 */
const IsBlanc = (e: RequestType) => e === "";
/** 申請タイプが有給休暇 */
const IsPaidVacation = (e: RequestType) => e === "paidVacation";
/** 申請タイプが残業 */
const IsOverTime = (e: RequestType) => e === "overtime";
/** 申請タイプが早退 */
const IsLeaveEarly = (e: RequestType) => e === "leaveEarly";
/** 申請タイプが欠勤 */
const IsAbsence = (e: RequestType) => e === "absence";
/** 申請タイプが遅刻 */
const IsLateness = (e: RequestType) => e === "lateness";
/** 申請タイプが私用外出 */
const IsPrivateOuting = (e: RequestType) => e === "privateOuting";
/** 申請タイプが振替出勤 */
const IsSubstituteAttendance = (e: RequestType) => e === "substituteAttendance";
/** 申請タイプが振替休日 */
const IsSubstituteHoliday = (e: RequestType) => e === "substituteHoliday";
/** 申請タイプが時差出勤 */
const IsStaggeredWorkHours = (e: RequestType) => e === "staggeredWorkHours";
/** 申請タイプが勤怠修正 */
const IsAttendanceCorrection = (e: RequestType) => e === "attendanceCorrection";
/** 申請タイプが休日出勤 */
const IsHolidayWork = (e: RequestType) => e === "holidayWork";
/**
 * モーダル内のデフォルトのデータ
 * @param userInfo
 * @returns
 */
const defaultStateValue = (userInfo: UserInfo): RequestData => {
  return {
    id: "",
    userId: userInfo.user.id,
    requestType: "",
    requestAuthorityId: userInfo.authorityId,
    requestAt: ConvertTimeStampFromDate(new Date()),
    acquisitionStartAt: ConvertTimeStampFromDate(
      dayjs().startOf("hour").toDate()
    ),
    acquisitionEndAt: ConvertTimeStampFromDate(
      dayjs().startOf("hour").add(1, "hour").toDate()
    ),
    status: "applying",
    description: "",
    memo: "",
    managerDescription: "",
    approveAt: null,
    isAttendanceCancel: false,
    correctionTargetAttendanceId: "",
  };
};
