/**
 * 分から表示する文字列に変換
 * @param minutes 対象の時間（分単位）
 * @returns
 */
export const GetDiffString = (minutes: number) => {
  if (minutes <= 0) return "";
  const hour = Math.floor(minutes / 60);
  const minute = Math.floor(minutes % 60);
  return `${hour}:${minute.toString().padStart(2, "0")}`;
};
