import {
  MobileExpenseApprovalWaitTable,
  WebExpenseApprovalWaitTable,
} from "components";
import React from "react";
import { View } from "react-native";
import { ExpenseApprovalWaitList, UserCollection } from "types";
import { IsWeb } from "utils";

export const ExpenseApprovalWaitTable = ({
  tableList,
  onEditClick,
  UserCollection,
}: {
  tableList: ExpenseApprovalWaitList[] | null;
  onEditClick: (id: string) => void;
  UserCollection: UserCollection[];
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : IsWeb() ? (
        <WebExpenseApprovalWaitTable
          onEditClick={onEditClick}
          tableList={tableList}
          UserCollection={UserCollection}
        />
      ) : (
        <MobileExpenseApprovalWaitTable
          onEditClick={onEditClick}
          tableList={tableList}
          UserCollection={UserCollection}
        />
      )}
    </>
  );
};
