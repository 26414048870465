import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  CheckBoxForm,
  DateForm,
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  ModalDeleteButton,
  MultipleInputForm,
  SelectForm,
} from "components";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, ScrollView, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  CreateCalendar,
  DeleteCalendar,
  FetchCalendar,
  UpdateCalendar,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { CalendarData, CalendarModalStatus, User } from "types";
import {
  AscSort,
  ConvertTimeStampFromDate,
  isPresidentOrAccounting,
} from "utils";
type Props = {
  /** ボタンを閉じる時の挙動を定義します */
  hideModal: Function;
  /** モーダルの情報を定義します */
  modalStatus: CalendarModalStatus;
};

/** カレンダー登録用のモーダルの入力欄を定義します */
export function CalendarForm({ hideModal, modalStatus }: Props) {
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { SiteCollection, CalendarCollection, UserCollection, onSnapShot } =
    useContext(CollectionContext);
  const [mode, setMode] = useState<"date" | "datetime">("datetime");
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<CalendarData>(
    defaultState(userInfo?.user.id ?? "")
  );
  const [siteSelects, setSiteSelects] = useState<Item[]>([]);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  useEffect(() => {
    if (UserCollection === null) {
      onSnapShot("user");
      return;
    }
    fetchAllUsers();
  }, [UserCollection]);

  // モーダルを開閉した時（modalStatusが変更された時）
  useEffect(() => {
    if (modalStatus === null) {
      setCalendar(defaultState(userInfo?.user.id ?? ""));
      return;
    }

    if (CalendarCollection === null) {
      onSnapShot("calendar");
      return;
    }
    const result = FetchCalendar(CalendarCollection, modalStatus.id ?? "");

    if (result === null) {
      setIsReadOnly(false);
      setCalendar(
        defaultState(
          userInfo?.user.id ?? "",
          modalStatus.defaultDate ?? new Date()
        )
      );
      return;
    }
    setIsReadOnly(
      (result.userId !== userInfo?.user.id &&
        !isPresidentOrAccounting(userInfo)) ||
        result.isHoliday
    );
    setCalendar(result);
  }, [modalStatus]);

  // 現場のプルダウンを取得
  useEffect(() => {
    if (SiteCollection === null) {
      onSnapShot("site");
      return;
    }
    const siteSelects = SiteCollection.filter(
      f =>
        (f.deleted === false && f.progressStatus === "inProgress") ||
        f.id === calendar.siteId
    )
      .sort((a, b) => AscSort(a.matterNumber, b.matterNumber))
      .map(m => {
        return {
          label: m.name,
          value: m.id,
        } as Item;
      });
    setSiteSelects(siteSelects);
  }, [SiteCollection]);

  /** ユーザー一覧を取得します */
  const fetchAllUsers = () => {
    if (UserCollection === null) return;
    const users = UserCollection.map(
      user =>
        ({
          id: user.id,
          name: user.name,
          uid: user.uid,
          authorityId: user.authorityId,
          email: user.email,
          retirementAt: user.retirementAt,
        } as User)
    );
    const activeDutyUsers = users.filter((user: User) => !user.retirementAt);
    setAllUsers(activeDutyUsers);
  };

  /** 日付モードの切り替えを行います */
  const switchDatePickerMode = (mode: boolean): void => {
    if (mode) {
      setMode("date");
    } else {
      setMode("datetime");
    }
  };

  /** 開始日を変更した時
   * @param evt 変更した日付
   */
  const onStartDateChangeHandler = (evt: Date): void => {
    setCalendar({
      ...calendar,
      startDate: ConvertTimeStampFromDate(evt),
    });
  };

  /** 終了日を変更した時
   * @param evt 変更後の日付
   */
  const onEndDateChangeHandler = (evt: Date): void => {
    setCalendar({
      ...calendar,
      endDate: ConvertTimeStampFromDate(evt),
    });
  };

  /** 登録・編集時のバリデーションを定義します */
  const validationCheck = (): boolean => {
    if (!calendar.userId || calendar.userId === "") {
      setAlert({ isAlert: true, msg: "社員を選択してください" });
      return false;
    }
    if (!calendar.title) {
      setAlert({ isAlert: true, msg: "タイトルを入力してください" });
      return false;
    }
    if (!calendar.siteId) {
      setAlert({ isAlert: true, msg: "現場を選択してください" });
      return false;
    }
    if (calendar.startDate > calendar.endDate) {
      setAlert({
        isAlert: true,
        msg: "開始日時が終了日時より大きくなっています。",
      });
      return false;
    }
    return true;
  };

  /** カレンダー情報の新規追加を行います */
  const createCalendar = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await CreateCalendar(userInfo, calendar);

      if (result.isCheck) setAlert({ isAlert: true, msg: "登録しました" });
      else setAlert({ isAlert: true, msg: "登録に失敗しました" });
      setIsLoading(false);
      hideModal();
    } catch {
      setAlert({ isAlert: true, msg: "登録に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * カレンダー情報の更新を行います
   * @param deleted 削除フラグ
   * @returns
   */
  const updateCalendar = async (deleted?: boolean): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);
    try {
      const result = await UpdateCalendar(userInfo, calendar);
      if (result.isCheck) setAlert({ isAlert: true, msg: "更新しました" });
      else setAlert({ isAlert: true, msg: "更新に失敗しました" });
      setIsLoading(false);
      hideModal();
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * カレンダーを削除します
   * @param id 削除対象のdocumentId
   */
  const deleteCalendar = async (id: string): Promise<void> => {
    setIsLoading(true);
    try {
      const result = await DeleteCalendar(id);
      if (result.isCheck) setAlert({ isAlert: true, msg: "削除しました" });
      else setAlert({ isAlert: true, msg: "削除に失敗しました" });
      setIsLoading(false);
      hideModal();
    } catch {
      setAlert({ isAlert: true, msg: "削除に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>
          {GetModalTitle("タスク", modalStatus?.isEdit ?? false, isReadOnly)}
        </Text>
        <Loading isLoading={isLoading} />
        <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={modalFormStyles.scrollContainer}
          >
            <View style={[formWrap(windowWidth).formWrap]}>
              {isPresidentOrAccounting(userInfo) && (
                <SelectForm
                  label="社員選択"
                  placeholderLabel="選択してください"
                  value={calendar.userId ?? ""}
                  onChange={e => setCalendar({ ...calendar, userId: e })}
                  items={allUsers.map(m => ({ label: m.name, value: m.id }))}
                />
              )}

              <InputForm
                label="タイトル"
                value={calendar.title}
                onChange={e => setCalendar({ ...calendar, title: e })}
                disabled={!isReadOnly}
              />
              {isPresidentOrAccounting(userInfo) && (
                <SelectForm
                  label="種類を選択"
                  placeholderLabel="選択してください"
                  value={calendar.isHoliday ? "2" : "1"}
                  onChange={e =>
                    setCalendar({
                      ...calendar,
                      isHoliday: e === "2",
                    })
                  }
                  items={[
                    { label: "予定", value: "1" },
                    { label: "休み", value: "2" },
                  ]}
                />
              )}
              <CheckBoxForm
                label="終日"
                checked={calendar.isAllDay}
                onChange={() => {
                  setCalendar({ ...calendar, isAllDay: !calendar.isAllDay });
                  switchDatePickerMode(!calendar.isAllDay);
                }}
                disabled={isReadOnly}
              />
              <DateForm
                value={calendar.startDate.toDate()}
                onChange={onStartDateChangeHandler}
                disabled={isReadOnly}
                label={"開始日"}
                type={calendar.isAllDay ? "date" : "datetime"}
              />

              <DateForm
                value={calendar.endDate.toDate()}
                onChange={onEndDateChangeHandler}
                disabled={isReadOnly}
                label={"終了日"}
                type={calendar.isAllDay ? "date" : "datetime"}
              />
              <SelectForm
                label="現場を選択"
                placeholderLabel="選択してください"
                value={calendar.siteId ?? ""}
                onChange={e =>
                  setCalendar({ ...calendar, siteId: e as string })
                }
                items={siteSelects}
                disabled={isReadOnly}
              />

              <MultipleInputForm
                label="メモ"
                value={calendar.memo}
                onChange={e => setCalendar({ ...calendar, memo: e })}
                disabled={!isReadOnly}
              />

              <View style={modalFormStyles.formItem}>
                <ModalCancelButton
                  buttonText="キャンセル"
                  onClick={() => hideModal()}
                />
                {!isReadOnly ? (
                  <>
                    {modalStatus?.isEdit ?? false ? (
                      <ModalApprovalButton
                        buttonText="更新"
                        onClick={() => updateCalendar()}
                      />
                    ) : (
                      <ModalApprovalButton
                        buttonText="登録"
                        onClick={() => createCalendar()}
                      />
                    )}
                  </>
                ) : (
                  <View
                    style={{
                      marginTop: 32,
                      paddingVertical: 16,
                      width: "40%",
                      alignItems: "center",
                      borderRadius: 4,
                      elevation: 3,
                    }}
                  />
                )}
              </View>

              {(modalStatus?.isEdit ?? false) && !isReadOnly && (
                <View style={modalFormStyles.formItemCenter}>
                  <ModalDeleteButton
                    buttonText="削除"
                    onClick={() => deleteCalendar(calendar.uid)}
                  />
                </View>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
      <Dialog />
    </>
  );
}

/**
 * モーダルのタスクタイトルを取得します
 * @param title タイトル
 * @param isEdit 編集かどうか
 * @param isReadOnly 読み取り専用の場合
 * @returns タスクタイトルの最後の部分を返却します
 */
const GetModalTitle = (
  title: string,
  isEdit: boolean,
  isReadOnly: boolean = false
) => {
  var joinText = "登録";
  if (isReadOnly) {
    joinText = "閲覧";
  } else if (isEdit) joinText = "編集";
  return title + joinText;
};

/** 初期値を返却します */
const defaultState = (userId: string, defaultDate?: Date): CalendarData => {
  return {
    uid: "",
    title: "",
    startDate: ConvertTimeStampFromDate(
      dayjs(defaultDate ?? new Date())
        .ceil("minute", 15)
        .toDate()
    ),
    endDate: ConvertTimeStampFromDate(
      dayjs(defaultDate).add(1, "hours").ceil("minute", 15).toDate() ??
        dayjs(new Date()).ceil("minute", 15).toDate()
    ),
    isHoliday: false,
    isAllDay: false,
    memo: "",
    userId: userId,
  };
};
