import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { PageLoading } from "components";
import dayjs from "dayjs";
import { StatusBar } from "expo-status-bar";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Calendar } from "react-native-calendars";
import {
  CreateHoliday,
  DeleteHoliday,
  FetchHolidayTargetMonth,
} from "server/collectionConnect";
import { ViewHolidays } from "types";

export const HolidaysScreen = ({
  navigation,
}: RootStackScreenProps<"Holidays">) => {
  const { userInfo } = useContext(UserInfoContext);
  const [holidayDates, setHolidayDates] = useState<ViewHolidays>({});
  const { HolidayCollection, onSnapShot } = useContext(CollectionContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setAlert } = useContext(DialogContext);
  const [visibleDate, setVisibleDate] = useState<dayjs.Dayjs>(dayjs());
  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(false);

  useEffect(() => {
    if (HolidayCollection === null) {
      onSnapShot("holiday");
      setIsLoading(true);
      return;
    }
    fetchHolidays(visibleDate);
    setIsLoading(false);
  }, [visibleDate, HolidayCollection]);

  const fetchHolidays = (visibleDate: dayjs.Dayjs) => {
    try {
      const result = FetchHolidayTargetMonth(
        HolidayCollection,
        visibleDate.toDate()
      );
      setHolidayDates(result);
    } catch {
      setAlert({
        isAlert: true,
        msg: "データが取得できませんでした",
      });
    }
  };

  const selectedHolidays = async (day: string) => {
    // 更新中は操作しないようにする
    if (isCalendarLoading) return;

    setIsCalendarLoading(true);

    if (day in holidayDates) {
      const result = await DeleteHoliday(
        HolidayCollection,
        dayjs(day).toDate()
      );
      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
        });
      }
    } else {
      const result = await CreateHoliday(userInfo, dayjs(day).toDate());
      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
        });
      }
    }
    setIsCalendarLoading(false);
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={styles.container}>
        <View>
          <Calendar
            theme={{ selectedDayBackgroundColor: "tomato" }}
            markedDates={holidayDates}
            onDayPress={day => {
              selectedHolidays(day.dateString);
            }}
            onMonthChange={month => {
              setVisibleDate(dayjs(month.dateString));
            }}
            firstDay={1}
            monthFormat={"yyyy年M月"}
            renderArrow={direction => {
              if (direction === "left") {
                return (
                  <View style={[styles.button, { paddingVertical: 6 }]}>
                    <Text style={styles.buttonTxt}>&lt;</Text>
                  </View>
                );
              } else {
                return (
                  <View style={[styles.button, { paddingVertical: 6 }]}>
                    <Text style={styles.buttonTxt}>&gt;</Text>
                  </View>
                );
              }
            }}
            displayLoadingIndicator={isCalendarLoading}
            aria-disabled={false}
          />
          <StatusBar style="auto" />
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingTop: 32,
  },
  bottomArea: {
    flexDirection: "row",
    justifyContent: "space-around",
    paddingTop: 60,
  },
  button: {
    padding: 16,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
  },
  buttonTxt: {
    fontWeight: "bold",
    color: "#FFF",
  },
  grayButton: {
    backgroundColor: "#C0C0C0",
  },
});
