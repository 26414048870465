import {
  ModalApprovalButton,
  ModalCancelButton,
  PageLoading,
} from "components";
import firebase from "firebase/compat/app";
import { DialogContext } from "GlobalContext";
import React, { useContext, useState } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { ModalLibrary } from "utils";

type ChangeEmailModalProps = {
  isVisible: boolean;
  currentEmail: string;
  closeModal: () => Promise<void> | void;
};

export const ChangeEmailModal = ({
  isVisible,
  currentEmail,
  closeModal,
}: ChangeEmailModalProps) => {
  const [newEmail, setNewEmail] = useState<string>("");
  const [isEmailFormatValidation, setIsEmailFormatValidation] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setAlert } = useContext(DialogContext);

  const changeNewEmailText = (email: string) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(email) === false) {
      setIsEmailFormatValidation(true);
      setNewEmail(email);
      return false;
    } else {
      setNewEmail(email);
      setIsEmailFormatValidation(false);
    }
  };

  const updateEmail = async (): Promise<void> => {
    setIsLoading(true);
    if (newEmail.trim().length === 0) {
      setIsLoading(false);
      setAlert({
        isAlert: true,
        msg: "メールアドレスを入力してください",
      });
      return;
    }
    try {
      await firebase.functions().httpsCallable("changeEmail")({
        currentEmail: currentEmail,
        newEmail: newEmail,
      });
      setAlert({
        isAlert: true,
        msg: "メールアドレスの変更が完了しました",
      });
    } catch {
      setAlert({
        isAlert: true,
        msg: "変更できませんでした",
      });
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <ModalLibrary animationType="slide" visible={isVisible} ariaHideApp={false}>
      <View style={styles.modalWrap}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.title}>メールアドレス再設定</Text>
            <View>
              <View style={[styles.flex, { paddingBottom: 30 }]}>
                <Text style={styles.label}>旧メール</Text>
                <TextInput
                  inputAccessoryViewID={"1"}
                  editable={false}
                  value={currentEmail}
                  style={styles.input}
                />
              </View>
              <View style={[styles.flex, { paddingBottom: 30 }]}>
                <Text style={styles.label}>新メール</Text>
                <TextInput
                  onChangeText={e => {
                    changeNewEmailText(e);
                  }}
                  inputAccessoryViewID={"1"}
                  autoCapitalize="none"
                  clearTextOnFocus={true}
                  value={newEmail}
                  style={[styles.input, { backgroundColor: "#FFF" }]}
                  keyboardType="email-address"
                />
              </View>
              {isEmailFormatValidation && (
                <View style={{ paddingBottom: 30 }}>
                  <Text style={[styles.baseFont, styles.errorMessage]}>
                    メールアドレスの形式で入力してください
                  </Text>
                </View>
              )}
            </View>

            <View style={styles.flex}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => closeModal()}
              />
              <ModalApprovalButton
                disabled={isEmailFormatValidation}
                buttonText="再設定"
                onClick={() => updateEmail()}
              />
            </View>
          </View>
        </View>
      </View>
    </ModalLibrary>
  );
};

const styles = StyleSheet.create({
  modalWrap: { flex: 1, backgroundColor: "#C0C0C0" },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignSelf: "center",
    width: "85%",
  },
  modalContent: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: 10,
  },
  title: {
    fontSize: 24,
    paddingTop: 20,
    paddingBottom: 40,
    textAlign: "center",
  },
  label: { fontSize: 18, paddingVertical: 4 },
  input: {
    fontSize: 18,
    borderRadius: 5,
    backgroundColor: "#C0C0C0",
    width: "70%",
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderColor: "#000",
    borderWidth: 1,
  },
  flex: { flexDirection: "row", justifyContent: "space-between" },
  baseFont: { fontSize: 18, color: "#FFF" },
  errorMessage: { color: "red", fontWeight: "bold" },
});
