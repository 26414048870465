import { CalendarCollection, CalendarData } from "types";

/**
 * 指定したカレンダー情報を返却します
 * @param calendars collectionContextから取得したカレンダー情報
 * @param targetId 指定のカレンダーID
 * @returns CalendarData
 */
export const FetchCalendar = (
  calendars: CalendarCollection[] | null,
  targetId: string
): CalendarData | null => {
  if (calendars === null) return null;

  const target = calendars.filter(f => f.id === targetId).shift();
  if (target === undefined) return null;

  return {
    uid: target.id,
    title: target.title,
    startDate: target.startDate,
    endDate: target.endDate,
    isHoliday: target.isHoliday,
    isAllDay: target.isAllDay,
    memo: target.memo,
    userId: target.userId,
    siteId: target.site && (target.site.id ?? ""),
  } as CalendarData;
};
