import {
  ExpenseCollection,
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  ExpenseLimitSettingList,
  UserCollection,
  UserInfo,
} from "types";
import { DescSort, GetUserName } from "utils";
import { GetItemName } from "utils/GetItemName";
import { GetBalanceAmount } from "../expense/utils";

/**
 * 整形したダッシュボード用経費枠情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param expenseLimitSettings collectionContextから取得した経費枠情報
 * @param dataLoadType 読み込み予定件数
 * @returns ExpenseLimitSettingList[]
 */
export const FetchDashBoardExpenseLimit = (
  userInfo: UserInfo | null,
  expenses: ExpenseCollection[] | null,
  expenseLimitSettings: ExpenseLimitSettingCollection[] | null,
  UserCollection: UserCollection[],
  ExpenseItemCollection: ExpenseItemCollection[],
  dataLoadType: number
): ExpenseLimitSettingList[] => {
  if (expenseLimitSettings === null || expenses === null)
    return [] as ExpenseLimitSettingList[];

  const expenseLimitSettingList = expenseLimitSettings
    .filter(request => request.userId === userInfo?.user.id)
    .sort((a, b) => DescSort(a.updateAt?.toDate(), b.updateAt?.toDate()))
    .slice(0, dataLoadType);

  return expenseLimitSettingList.map(t => {
    return {
      id: t.id,
      userName: GetUserName(UserCollection, t.userId ?? ""),
      itemName: GetItemName(ExpenseItemCollection, t.itemId ?? ""),
      amountLimit: t.amountLimit,
      balanceAmount: GetBalanceAmount(
        expenses,
        t.id,
        userInfo?.user.id ?? "",
        t
      ),
      grantDate: t.grantDate,
      expirationDate: t.expirationDate,
      updateAt: t.updateAt,
    } as ExpenseLimitSettingList;
  });
};
