import dayjs from "dayjs";
import { CalendarCollection, UserInfo } from "types";
import { AscSort } from "utils";

/**
 * 整形したダッシュボード用カレンダー情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param calendars collectionContextから取得したカレンダー情報
 * @param dataLoadType 読み込み予定件数
 * @returns CalendarCollection[]
 */
export const FetchDashboardCalendars = (
  userInfo: UserInfo | null,
  calendars: CalendarCollection[] | null,
  dataLoadType: number
): CalendarCollection[] => {
  if (calendars === null) return [] as CalendarCollection[];
  const today = dayjs(new Date()).toDate();

  const dateObj = calendars
    .filter(calendar => calendar.isAllDay === false)
    .filter(calendar => calendar.userId === userInfo?.user.id)
    .filter(calendar => calendar.endDate?.toDate() >= today);

  const allDateObj = calendars
    .filter(calendar => calendar.userId === userInfo?.user.id)
    .filter(calendar => calendar.isAllDay === true)
    .filter(
      calendar =>
        calendar.startDate?.toDate() >= dayjs(today).startOf("day").toDate() ||
        (calendar.startDate?.toDate() <= dayjs(today).endOf("day").toDate() &&
          calendar.endDate?.toDate() >= dayjs(today).startOf("day").toDate())
    );

  const result = dateObj.concat(allDateObj);

  // 自分のカレンダーで、開始日付が現時点以降のものを指定件数取得
  return result
    .sort((a, b) => AscSort(a.startDate, b.startDate))
    .slice(0, dataLoadType);
};
