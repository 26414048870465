import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  DateForm,
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  SelectForm,
} from "components";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  CreatePaidVacation,
  FetchPaidVacation,
  GetBalancePaidVacation,
  UpdatePaidVacation,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { PaidVacationList, PaidVacationModalStatus } from "types";
type Props = {
  hideModal: Function;
  modalStatus: PaidVacationModalStatus;
  expiration: number;
  userList: Item[];
};

export function AddForm({
  hideModal,
  modalStatus,
  expiration,
  userList,
}: Props) {
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { RequestCollection, PaidVacationCollection } =
    useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paidVacation, setPaidVacation] = useState<PaidVacationList>(
    defaultSite(2)
  );

  useEffect(() => {
    if (modalStatus === null) {
      setPaidVacation(defaultSite(2));
      return;
    }
    const result = FetchPaidVacation(
      PaidVacationCollection,
      modalStatus.id ?? ""
    );
    if (result === null) {
      setPaidVacation(defaultSite(expiration));
      return;
    }
    setPaidVacation(result);
  }, [modalStatus]);

  const onDateChangeHandler = (evt: Date): void => {
    var grantAt = dayjs(evt).startOf("date").toDate();

    var lapseAt = dayjs(evt).add(expiration, "y").startOf("date").toDate();

    setPaidVacation({
      ...paidVacation,
      grantAt: grantAt,
      lapseAt: lapseAt,
    });
  };

  const validationCheck = (isEdit: boolean = false): boolean => {
    if (!paidVacation?.userId) {
      setAlert({ isAlert: true, msg: "社員を入力してください" });
      return false;
    }
    if (!paidVacation?.grantAt) {
      setAlert({ isAlert: true, msg: "付与日を入力してください" });
      return false;
    }
    if (!paidVacation?.grantCount) {
      setAlert({ isAlert: true, msg: "日数を入力してください" });
      return false;
    }

    if (
      isEdit &&
      GetBalancePaidVacation(RequestCollection ?? [], paidVacation.id ?? "") >
        paidVacation.grantCount
    ) {
      setAlert({
        isAlert: true,
        msg: "指定した日数がすでに使用済みの有給日数を下回っています",
      });
      return false;
    }
    return true;
  };

  const createPaidVacation = async (): Promise<void> => {
    if (!validationCheck()) return;

    setIsLoading(true);
    paidVacation.remainingCount = paidVacation.grantCount;

    const result = await CreatePaidVacation(paidVacation);

    if (result.isCheck) {
      setAlert({
        isAlert: true,
        msg: result.message,
        confTxt: "OK",
        afterExec: () => {
          hideModal();
        },
      });
    } else {
      setAlert({ isAlert: true, msg: result.message });
    }
    setIsLoading(false);
  };

  const updatePaidVacation = async (deleted?: boolean): Promise<void> => {
    if (!validationCheck(true)) return;
    if (paidVacation === null) return;

    const result = await UpdatePaidVacation(paidVacation);

    if (result.isCheck) {
      setAlert({
        isAlert: true,
        msg: result.message,
        confTxt: "OK",
        afterExec: () => {
          hideModal();
        },
      });
    } else {
      setAlert({ isAlert: true, msg: result.message });
    }
    setIsLoading(false);
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>
          {modalStatus?.isEdit ? "有給休暇編集" : "有給休暇付与"}
        </Text>
        <Loading isLoading={isLoading} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={modalFormStyles.scrollContainer}
        >
          <View style={[formWrap(windowWidth).formWrap]}>
            <SelectForm
              label="社員"
              placeholderLabel="選択してください"
              value={paidVacation?.userId ?? ""}
              onChange={e =>
                setPaidVacation({
                  ...paidVacation,
                  userId: e as string,
                })
              }
              items={userList}
            />
            <DateForm
              value={paidVacation?.grantAt ?? new Date()}
              onChange={onDateChangeHandler}
              disabled={false}
              label={"付与日"}
              type={"date"}
            />
            <InputForm
              label="日数"
              value={paidVacation?.grantCount?.toString()}
              onChange={e => {
                // 小数点以下が .の場合、または整数のみを許容する
                const validPattern = /^\d*(\.)?$/;
                if (validPattern.test(e)) {
                  const count = e.endsWith('.') ? e + '5' : e; // . で終わる場合は .5 に変換
                  setPaidVacation({
                    ...paidVacation,
                    grantCount: isNaN(parseFloat(count)) ? 0 : parseFloat(count),
                  });
                } else if (e === '') {
                  setPaidVacation({
                    ...paidVacation,
                    grantCount: 0,
                  });
                }
              }}
              disabled={true}
            />
            <DateForm
              value={
                paidVacation?.lapseAt ??
                dayjs(new Date()).add(expiration, "years").toDate()
              }
              onChange={onDateChangeHandler}
              disabled={true}
              label={"失効日"}
              type={"date"}
            />

            <View style={modalFormStyles.formItem}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => hideModal()}
              />

              {modalStatus?.isEdit ?? false ? (
                <ModalApprovalButton
                  buttonText="更新"
                  onClick={() => updatePaidVacation()}
                />
              ) : (
                <ModalApprovalButton
                  buttonText="登録"
                  onClick={() => createPaidVacation()}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </View>
      <Dialog />
    </>
  );
}

/** 現場登録の初期値を登録します */
const defaultSite = (expiration: number): PaidVacationList => {
  return {
    id: "",
    userId: "",
    grantAt: new Date(),
    lapseAt: dayjs(new Date()).add(expiration, "years").toDate(),
  };
};
