import { TableCell } from "components";
import React from "react";
import { DimensionValue, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { ExpenseLimitSettingList } from "types";

const tableHead = ["", "社員", "経費種類", "金額", "付与日", "有効期限"];

/** Web用のテーブルを返却します */
export const WebExpenseLimitSettingTable = ({
  onEditClick,
  tableList,
}: {
  onEditClick: (id: string) => void;
  tableList: ExpenseLimitSettingList[];
}) => {
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );

  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "600px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList, onEditClick)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

const TableBody = (
  tableList: ExpenseLimitSettingList[],
  onEditClick: (id: string) => void
) => {
  const tableBody = tableList.map(tableData => {
    var tempArr = [];

    // 編集ボタン
    tempArr.push(
      <TableCell
        dataType="button"
        buttonText="編集"
        onEditClick={() => onEditClick(tableData.id)}
      />
    );
    // 社員
    tempArr.push(<TableCell dataType="string" value={tableData.userName} />);
    // 経費種類
    tempArr.push(<TableCell dataType="string" value={tableData.itemName} />);
    // 金額
    tempArr.push(<TableCell dataType="money" value={tableData.amountLimit} />);
    // 付与日
    tempArr.push(<TableCell dataType="date" value={tableData.grantDate} />);
    // 有効期限
    tempArr.push(
      <TableCell dataType="date" value={tableData.expirationDate} />
    );

    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return tableBody.filter(m => m !== undefined);
};
