import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { DateForm, PageLoading } from "components";
import dayjs from "dayjs";
import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";
import firebase from "firebase/compat/app";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { FetchAllUsersDownLoadList } from "server/collectionConnect";
import { pageStyle } from "styles";
import { IsMobile, IsWeb } from "utils";

const saveAs = IsMobile() ? null : require("file-saver").saveAs;

export function AttendanceDownloadScreen({
  navigation,
}: RootStackScreenProps<"AttendanceDownload">) {
  const { userInfo } = useContext(UserInfoContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [targetMonth, setTargetMonth] = useState<Date>(new Date());
  const {
    RequestCollection,
    UserCollection,
    SiteCollection,
    ExpenseCollection,
    ExpenseItemCollection,
    ExpenseLimitSettingCollection,
    AttendanceCollection,
    HolidayCollection,
    onSnapShot,
  } = useContext(CollectionContext);

  useEffect(() => {
    if (
      RequestCollection === null ||
      UserCollection === null ||
      SiteCollection === null ||
      AttendanceCollection === null ||
      ExpenseCollection === null ||
      ExpenseLimitSettingCollection === null ||
      ExpenseItemCollection === null ||
      HolidayCollection === null
    ) {
      onSnapShot("request");
      onSnapShot("user");
      onSnapShot("site");
      onSnapShot("attendance");
      onSnapShot("expense");
      onSnapShot("expenseLimit");
      onSnapShot("expenseItem");
      onSnapShot("holiday");

      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }, [
    RequestCollection,
    UserCollection,
    SiteCollection,
    ExpenseCollection,
    ExpenseItemCollection,
    ExpenseLimitSettingCollection,
    targetMonth,
  ]);

  const onTargetMonthChangeHandler = (evt: Date): void => setTargetMonth(evt);

  const download = async (): Promise<void> => {
    if (userInfo == null) {
      setAlert({ isAlert: true, msg: "ユーザー情報の取得に失敗しました" });
      return;
    }

    if (!setTargetMonth) {
      setAlert({ isAlert: true, msg: "該当年月を選択してください" });
      return;
    }
    setIsLoading(true);

    try {
      const result = FetchAllUsersDownLoadList(
        userInfo,
        UserCollection,
        AttendanceCollection,
        RequestCollection,
        HolidayCollection,
        targetMonth
      );
      if (result.length === 0) {
        setAlert({ isAlert: true, msg: "対象データがありません。" });
        return;
      }
      await firebase
        .functions()
        .httpsCallable("targetMonthAttendanceExcelOutput")({
          user: userInfo.user,
          targetMonth: dayjs(targetMonth).format(),
          data: result,
        })
        .then(async res => {
          const formatMonth = dayjs(targetMonth).format("YYYY-MM");
          const downloadUrl = await firebase
            .storage()
            .ref(`allAttendance/${formatMonth}.xlsx`)
            .getDownloadURL();

          if (IsWeb()) {
            // WEBの場合は通常のDL
            const downloadFileName = `勤務実績_${formatMonth}.xlsx`;
            saveAs(downloadUrl, downloadFileName);
          } else {
            const downloadFileName = `${formatMonth}.xlsx`;
            const downloadPath = FileSystem.cacheDirectory + downloadFileName;
            const { uri: localUrl } = await FileSystem.downloadAsync(
              downloadUrl,
              downloadPath
            );
            // SPの場合はシェアリングでDLもしくは共有
            Sharing.shareAsync(localUrl, {
              mimeType: "application/octet-binary",
              dialogTitle: downloadFileName,
              UTI: "application/octet-binary",
            });
          }
        });
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <PageLoading isLoading={isLoading} />
      ) : (
        <View style={pageStyle.container}>
          <DateForm
            label="該当年月"
            type="month"
            value={targetMonth}
            onChange={onTargetMonthChangeHandler}
            disabled={false}
          />
          <Pressable
            style={[styles.processButton, { backgroundColor: "#7879F1" }]}
            onPress={download}
          >
            <Text style={styles.buttonText}>ダウンロード</Text>
          </Pressable>
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  processButton: {
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 5,
    elevation: 3,
    width: 100,
    height: 50,
    margin: 20,
    alignSelf: "center",
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 50,
  },
});
