import React from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";

type Props = {
  /** フォームの名前を指定します */
  label: string;
  /** 選択の値を定義します */
  value: any;
  /** 変更の処理を定義します */
  onChange?: (e: string) => void;
  /**
   *  デフォルト：false
   *
   * 操作可能：false 操作不可：true */
  disabled?: boolean;
};

/**
 * 複数行のテキストフォームを作成します
 * @param param0
 * @returns
 */
export const MultipleInputForm = ({
  value,
  onChange,
  disabled,
  label,
}: Props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
      <TextInput
        value={value}
        multiline={true}
        editable={disabled ?? false}
        selectTextOnFocus={false}
        style={[styles.input, disabled ?? false ? styles.able : styles.disable]}
        onChangeText={onChange}
        inputAccessoryViewID={"1"}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  label: {
    fontSize: 20,
  },
  input: {
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
    paddingVertical: 55,
  },
  able: {
    color: "black",
    backgroundColor: "white",
  },
  disable: {
    color: "black",
    backgroundColor: "darkgray",
  },
});
