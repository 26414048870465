import dayjs from "dayjs";
import firebase from "firebase/compat/app";
import { CreateCheckResult, HolidayCollection } from "types";

/**
 * 休日の削除を行います
 * @param holidays userContextから取得した情報
 * @param targetDate 削除予定の対象日
 * @returns
 */
export const DeleteHoliday = async (
  holidays: HolidayCollection[] | null,
  targetDate: Date
): Promise<CreateCheckResult> => {
  if (holidays === null)
    return { isCheck: false, message: "削除に失敗しました" };

  const target = holidays
    .filter(f =>
      dayjs(f.targetAt?.toDate()).isBetween(
        dayjs(targetDate).startOf("day").toDate(),
        dayjs(targetDate).endOf("day").toDate(),
        null,
        "[]"
      )
    )
    .shift();

  if (target === undefined)
    return { isCheck: false, message: "削除に失敗しました" };

  return await firebase
    .firestore()
    .collection("holiday")
    .doc(target.id)
    .delete()
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
