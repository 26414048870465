import firebase from "firebase/compat/app";
import { CreateCheckResult, PaidVacationList } from "types";
/**
 * 有給管理の更新書き込みを行います
 * @param paidVacationData 更新対象の有給情報
 * @returns
 */
export const UpdatePaidVacation = async (
  paidVacationData: PaidVacationList | null
): Promise<CreateCheckResult> => {
  if (paidVacationData === null)
    return { isCheck: false, message: "更新に失敗しました" };

  const date = new Date();
  const docRef = await firebase
    .firestore()
    .collection("paidVacation")
    .doc(paidVacationData.id)
    .get();

  return await docRef.ref
    .update({
      ...docRef.data(),
      userId: paidVacationData.userId,
      grantAt: paidVacationData.grantAt,
      lapseAt: paidVacationData.lapseAt,
      grantCount: paidVacationData.grantCount,
      remainingCount: paidVacationData.remainingCount,
    })
    .then(() => {
      return { isCheck: true, message: "更新しました" };
    })
    .catch(() => {
      return { isCheck: false, message: "更新に失敗しました" };
    });
};
