import firebase from "firebase/compat/app";
import { AttendanceCollection, UserInfo } from "types";
/**
 *　最新の勤怠情報を返却します
 * @param userInfo userContextから取得した情報
 * @returns
 */
export const FetchLatestAttendance = async (userInfo: UserInfo | null) => {
  return await firebase
    .firestore()
    .collection("attendance")
    .where("userId", "==", userInfo?.user.uid)
    .orderBy("startWorkSchedule", "desc")
    .limit(1)
    .get()
    .then(snapshot => {
      const result = snapshot.docs.map(
        doc => ({ ...doc.data(), id: doc.id } as AttendanceCollection)
      );
      if (result.length < 1) return null;
      return result[0];
    });
};
