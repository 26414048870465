import dayjs from "dayjs";
import firebase from "firebase/compat/app";
import { CreateCheckResult } from "types";
import {
  fetchGeoLocationAddress,
  GeoPoint,
} from "./utils/FetchGeoLocationAddress";

/**
 * 勤怠の更新書き込みを行います
 * @param docId 対象の更新ID
 * @param location 位置情報
 * @returns　CreateCheckResult
 */
export const UpdateAttendance = async (
  docId: string,
  location: GeoPoint
): Promise<CreateCheckResult> => {
  // 勤務終了時の住所を取得
  const locationAddress = await fetchGeoLocationAddress(location);
  try {
    const date = dayjs();
    const targetRef = await firebase
      .firestore()
      .collection("attendance")
      .doc(docId)
      .get();

    return await targetRef.ref
      .update({
        ...targetRef.data(),
        startBreakSchedule: date.hour(12).minute(0).second(0).toDate(),
        endBreakSchedule: date.hour(13).minute(0).second(0).toDate(),
        endWorkSchedule: date.toDate(),
        terminationAddress: locationAddress,
      })
      .then(() => ({ isCheck: true, message: "退勤登録が完了しました" }))
      .catch(() => ({ isCheck: false, message: "退勤登録に失敗しました" }));
  } catch (error: any) {
    return { isCheck: false, message: "登録に失敗しました" };
  }
};
