import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import {
  AddButton,
  CalendarModal,
  PageLoading,
  RequestModal,
  SelectForm,
} from "components";
import dayjs from "dayjs";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import {
  DimensionValue,
  Dimensions,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { Item } from "react-native-picker-select";
import { FetchCalendarTargetMonth } from "server/collectionConnect";
import {
  CalendarDataWithRequest,
  CalendarModalStatus,
  RequestModalStatus,
  User,
} from "types";
import { IsAndroid, IsIOS, IsWeb } from "utils";

const getScreenWith = () => {
  const d = Dimensions.get("window");
  return d.width;
};

export function CalendarDailyScreen({
  navigation,
  route,
}: RootStackScreenProps<"CalendarDaily">) {
  const [editModal, setEditModal] = useState<CalendarModalStatus>(null);
  const [requestModal, setRequestModal] = useState<RequestModalStatus>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { userInfo } = useContext(UserInfoContext);
  const { CalendarCollection, UserCollection, RequestCollection, onSnapShot } =
    useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const [visibleDate, setVisibleDate] = useState<dayjs.Dayjs>(
    route.params.date
  );
  const [users, setUsers] = useState<Item[]>([]);
  const [allCalendars, setAllCalendars] = useState<CalendarDataWithRequest[]>(
    []
  );
  const [screenWidth, setScreenWidth] = useState<number>(getScreenWith);
  const viewCount = IsWeb()
    ? Math.floor(screenWidth / 240)
    : Math.floor(screenWidth / 120);
  const itemWidth = Math.floor(100 / viewCount) + "%";

  // 初期選択のユーザーを取得
  const selectUserId = (route.params.user as User | undefined)?.id;
  const selectUserIds: string[] = userInfo?.user.id ? [userInfo?.user.id] : [];
  selectUserId &&
    userInfo?.user.id !== selectUserId &&
    selectUserIds.push(selectUserId);
  // 選択中ユーザー
  const [selectUsers, setSelectUsers] = useState<string[]>(selectUserIds);

  const fetchAllUsers = () => {
    if (UserCollection === null) {
      onSnapShot("user");
      return;
    }
    const result = UserCollection.filter(user => !user.retirementAt).map(
      user => ({
        value: user.id,
        label: user.name,
      })
    );
    setUsers(result);
  };
  const fetchCalendarTargetMonth = async () => {
    const targetDate = visibleDate.toDate();
    const result = await FetchCalendarTargetMonth(
      CalendarCollection,
      RequestCollection,
      targetDate
    );
    setAllCalendars(result);
    setIsLoading(false);
  };
  useEffect(() => {
    if (CalendarCollection === null || RequestCollection === null) {
      onSnapShot("calendar");
      onSnapShot("request");
      return;
    }
    fetchCalendarTargetMonth();
  }, [visibleDate, CalendarCollection, RequestCollection]);

  useEffect(() => {
    if (UserCollection === null) {
      onSnapShot("user");
      return;
    }
    fetchAllUsers();
  }, [UserCollection]);

  // 画面サイズの変更
  useEffect(() => {
    const listener = Dimensions.addEventListener("change", function () {
      setScreenWidth(getScreenWith());
    });
    return () => listener.remove();
  }, []);

  /** 表示ユーザーの選択 */
  const changeSelectUser = (uid: string, index: number) => {
    const temporary =
      index > selectUsers.length
        ? Array.from({ length: index }, (_, i) => selectUsers[i])
        : [...selectUsers];

    temporary[index] = uid;
    setSelectUsers(temporary);
  };

  const handlePrev = () => {
    setVisibleDate(visibleDate.subtract(1, "day"));
  };

  const handleNext = () => {
    setVisibleDate(visibleDate.add(1, "day"));
  };

  // 当日のものを絞る
  const currentDayCalendars = allCalendars.filter(x =>
    visibleDate.isBetween(
      dayjs(x.startDate.toDate()).startOf("day").subtract(1, "second"),
      dayjs(x.endDate.toDate()).endOf("day")
    )
  );

  /** 編集画面を開く */
  const openDetail = (data: CalendarDataWithRequest) => {
    if (data.isRequest) {
      setRequestModal({ isEdit: true, id: data.uid, type: "request" });
    } else {
      setEditModal({ isEdit: true, id: data.uid });
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <View style={styles.container}>
      <View style={styles.calendarHeader}>
        <View style={styles.calendarHeaderCurrentDate}>
          <Text style={styles.currentDate}>
            {visibleDate.format("YYYY年M月D日")}
          </Text>
        </View>

        <View style={styles.calendarHeaderControlPanel}>
          <Pressable
            onPress={() => {
              setIsLoading(true);
              handlePrev();
            }}
          >
            <Text style={styles.month}>
              {visibleDate.subtract(1, "day").format("YYYY年M月D日")}へ
            </Text>
          </Pressable>
          <Pressable
            onPress={() => {
              setIsLoading(true);
              handleNext();
            }}
          >
            <Text style={styles.month}>
              {visibleDate.add(1, "day").format("YYYY年M月D日")}へ
            </Text>
          </Pressable>
        </View>
      </View>

      <ScrollView
        style={{ width: "100%" }}
        horizontal={false}
        directionalLockEnabled={true}
      >
        <View style={styles.calendarArea}>
          {Array.from({ length: viewCount }, (_, index) => (
            <View
              style={{
                ...styles.calendarPersonal,
                width: itemWidth as DimensionValue,
              }}
              key={"calender" + index}
            >
              <View style={styles.calendarSelectUser}>
                <SelectForm
                  label=""
                  placeholderLabel="選択してください"
                  value={selectUsers[index] ?? ""}
                  onChange={e => changeSelectUser(e, index)}
                  items={users}
                />
              </View>

              {/* カレンダーの予定を表示する */}
              {selectUsers[index] ? (
                currentDayCalendars
                  .filter(x => x.userId == selectUsers[index])
                  .map(x => (
                    <View style={styles.calendarItem} key={x.uid}>
                      <Pressable onPress={() => openDetail(x)}>
                        {x.paidVacationType === "allDay" ? (
                          <Text>終日</Text>
                        ) : x.paidVacationType === "morning" ? (
                          <Text>午前</Text>
                        ) : x.paidVacationType === "afternoon" ? (
                          <Text>午後</Text>
                        ) : x.isAllDay ? (
                          <Text>終日</Text>
                        ) : (
                          <Text>
                            {dayjs(x.startDate?.toDate())
                              .ceil("minute", 15)
                              .format("H:mm")}
                            〜
                            {dayjs(x.endDate?.toDate())
                              .ceil("minute", 15)
                              .format("H:mm")}
                          </Text>
                        )}
                        <Text>{x.title}</Text>
                      </Pressable>
                    </View>
                  ))
              ) : (
                <></>
              )}
            </View>
          ))}
        </View>
      </ScrollView>
      <AddButton
        onClick={() =>
          setEditModal({
            id: null,
            isEdit: false,
            defaultDate: visibleDate.toDate(),
          })
        }
      />

      <CalendarModal
        modalStatus={editModal}
        onCloseModal={() => setEditModal(null)}
      />
      <RequestModal
        modalStatus={requestModal}
        onCloseModal={() => setRequestModal(null)}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    paddingTop: 32,
    paddingBottom: 80,
  },
  calendarHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginBottom: 24,
    textAlign: "center",
    // borderWidth: 1,
    width: "100%",
  },
  calendarHeaderCurrentDate: {
    alignItems: "center",
    marginBottom: 24,
    textAlign: "center",
  },
  currentDate: {
    fontSize: 32,
  },
  calendarHeaderControlPanel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    width: "100%",
  },
  month: {
    fontSize: 12,
  },
  button: {
    padding: 16,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
  },
  smallBtn: {
    paddingVertical: 8,
    backgroundColor: "#4289cb",
  },
  buttonTxt: {
    fontWeight: "bold",
  },

  calendarArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 8,
    width: "100%",
  },

  calendarPersonal: {
    width: "100%",
  },

  calendarSelectUser: {
    height: IsAndroid() ? 40 : 28,
    marginHorizontal: 12,
    marginBottom: IsIOS() ? 20 : IsAndroid() ? 40 : 10,
  },

  calendarItem: {
    alignItems: "center",
    borderColor: "#333",
    borderStyle: "solid",
    borderWidth: 1,
    // height: 60,
    padding: 8,
    textAlign: "center",
  },
});
