/**
 * NULL・Undefinedかどうかをチェックします
 * @param target 判定を行う値
 * @param defaultValue 判定時にNULLもしくはUndefinedになったときにセットする値
 * @returns
 */
export const ConvertNullOrUndefined = <T>(
  target: T | null | undefined,
  defaultValue: T
) => {
  if (target === null) return defaultValue;
  if (target === undefined) return defaultValue;
  return target;
};
