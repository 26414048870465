import dayjs from "dayjs";
import { HolidayCollection } from "types";

/**
 * 整形したカレンダー用休暇設定表示用情報を返却します
 * @param holidays collectionContextから取得した休暇情報
 * @param targetDate 対象の日付
 * @returns ViewHolidays
 */
export const FetchCalendarHolidayTargetMonth = (
  holidays: HolidayCollection[] | null,
  targetDate: Date
): HolidayCollection[] => {
  if (holidays === null) return [] as HolidayCollection[];

  const monthBeginning = dayjs(targetDate)
    .startOf("month")
    .subtract(1, "week")
    .toDate();
  const monthEnd = dayjs(targetDate).endOf("month").add(1, "week").toDate();

  const targetHolidays = holidays.filter(f =>
    dayjs(f.targetAt?.toDate()).isBetween(monthBeginning, monthEnd, null, "[]")
  );
  return targetHolidays;
};
