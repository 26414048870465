import { Loading } from "components";
import * as Location from "expo-location";
import * as Permissions from "expo-permissions";
import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";

import { Platform, Pressable, StyleSheet, Text, View } from "react-native";
import {
  CreateAttendance,
  FetchLatestAttendance,
  UpdateAttendance,
} from "server/collectionConnect";
import { pageStyle } from "styles";
import { IsAndroid } from "utils";

const weekday = ["日", "月", "火", "水", "木", "金", "土"];

type attendanceViewDateType = {
  year: number;
  month: number;
  date: number;
  week: string;
  hours: number;
  minutes: number;
  seconds: number;
};

export function AttendanceScreen({}: RootStackScreenProps<"Attendance">) {
  const { setAlert, setConfirm } = useContext(DialogContext);
  const { userInfo } = useContext(UserInfoContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<attendanceViewDateType>(
    getCurrentDateTime()
  );
  const [docRefId, setDocRefId] = useState<string | null>(null);
  const [buttonText, setButtonText] = useState<string>("出勤");
  const [buttonColor, setButtonColor] = useState<string>("#4169e1");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const { AttendanceCollection, onSnapShot } = useContext(CollectionContext);

  let PermissionsAndroid: {
    check: (arg0: any) => any;
    PERMISSIONS: { ACCESS_FINE_LOCATION: any };
  };
  if (Platform.OS === "android") {
    PermissionsAndroid = require("react-native").PermissionsAndroid;
  }

  const authorityCheck = async (): Promise<void> => {
    try {
      if (IsAndroid()) {
        const isAuthorized = await PermissionsAndroid.check(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
        );
        if (!isAuthorized) {
          setConfirm({
            isConfirm: true,
            msg: "このアプリは、どの地点で勤怠登録を行ったかを確認するために位置情報を収集します。",
            confTxt: "許可する",
            cancelTxt: "許可しない",
            afterExec: async () => {
              await askAsyncPermissionLocation();
            },
          });
        } else {
          await askAsyncPermissionLocation();
        }
      } else {
        await askAsyncPermissionLocation();
      }
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    }
  };

  const askAsyncPermissionLocation = async (): Promise<void> => {
    let { status } = await Permissions.askAsync(
      Permissions.LOCATION_FOREGROUND
    );
    if (status === "granted") {
      await saveAttendance();
    }
  };

  const saveAttendance = async (): Promise<void> => {
    try {
      setIsSubmit(false);
      setIsLoading(true);

      // 位置情報の取得
      const currentLocation = await Location.getCurrentPositionAsync({});
      const location = {
        latitude: JSON.stringify(currentLocation.coords.latitude),
        longitude: JSON.stringify(currentLocation.coords.longitude),
      };
      var result =
        docRefId !== null
          ? await UpdateAttendance(docRefId, location)
          : await CreateAttendance(userInfo, "normal", location);
      setAlert({
        isAlert: true,
        msg: result.message,
        afterExec: () => {
          fetchAttendance();
        },
      });
    } catch (error: any) {
      setAlert({
        isAlert: true,
        msg: error.message,
        afterExec: () => setIsLoading(false),
      });
    }
  };

  // 出退勤取得
  const fetchAttendance = async () => {
    try {
      const latestAttendance = await FetchLatestAttendance(userInfo);
      if (latestAttendance && latestAttendance.endWorkSchedule === null) {
        setDocRefId(latestAttendance.id);
        setButtonText("退勤");
        setButtonColor("#FF7F7F");
      } else {
        setDocRefId(null);
        setButtonText("出勤");
        setButtonColor("#4169e1");
      }
      setIsSubmit(true);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (AttendanceCollection === null) {
      onSnapShot("attendance");
      setIsLoading(true);
      return;
    }
    fetchAttendance();
  }, [AttendanceCollection]);

  useEffect(() => {
    const timer = setInterval(() => setDateTime(getCurrentDateTime()), 1000);
    return () => clearInterval(timer);
  }, []);

  if (isLoading) return <Loading isLoading={isLoading} />;

  return (
    <>
      <View style={pageStyle.basic_container}>
        <View style={pageStyle.row_center_contents}>
          <Text style={pageStyle.text_bold}>
            <Text style={styles.year}>{dateTime.year} 年</Text>
            <Text style={styles.month}>{dateTime.month}月</Text>
            <Text style={styles.date}>{dateTime.date}日</Text>
            <Text style={styles.week}>({dateTime.week})</Text>
          </Text>
        </View>

        <View style={pageStyle.row_center_contents}>
          <Text style={pageStyle.text_bold}>
            <Text style={styles.hours}>
              {dateTime.hours.toString().padStart(2, "0")}：
            </Text>
            <Text style={styles.minutes}>
              {dateTime.minutes.toString().padStart(2, "0")}
            </Text>
            <Text style={styles.seconds}>
              ：{dateTime.seconds.toString().padStart(2, "0")}
            </Text>
          </Text>
        </View>

        <Pressable
          disabled={!isSubmit}
          style={[styles.button, { backgroundColor: buttonColor }]}
          onPress={() => {
            authorityCheck();
          }}
        >
          <Text style={styles.buttonText}>{buttonText}</Text>
        </Pressable>
      </View>
    </>
  );
}

const getCurrentDateTime = (): attendanceViewDateType => {
  return {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    date: new Date().getDate(),
    week: weekday[new Date().getDay()],
    hours: new Date().getHours(),
    minutes: new Date().getMinutes(),
    seconds: new Date().getSeconds(),
  };
};

const styles = StyleSheet.create({
  year: {
    margin: 10,
    fontSize: 24,
  },
  month: {
    margin: 10,
    fontSize: 44,
  },
  date: {
    margin: 10,
    fontSize: 44,
  },
  week: {
    margin: 10,
    fontSize: 34,
  },
  hours: {
    margin: 10,
    fontSize: 54,
  },
  minutes: {
    margin: 10,
    fontSize: 54,
  },
  seconds: {
    margin: 10,
    fontSize: 34,
  },
  button: {
    marginTop: 32,
    paddingVertical: 16,
    paddingHorizontal: 32,
    borderRadius: 4,
    elevation: 3,
    width: 300,
  },
  buttonText: {
    fontSize: 40,
    textAlign: "center",
    color: "#000",
    fontWeight: "bold",
  },
});
