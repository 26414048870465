import {
  ExpenseCollection,
  ExpenseLimit,
  ExpenseLimitSettingCollection,
} from "types";
import { GetBalanceAmount } from "../expense/utils";

/**
 * 整形した経費枠情報を返却します
 * @param userId 指定のユーザーID
 * @param collection collectionContextから取得した経費枠情報
 * @param expenseItemId 指定されているID
 * @returns ExpenseLimit[] | null
 */
export const FetchExpenseLimits = (
  userId: string,
  expenses: ExpenseCollection[] | null,
  collection: ExpenseLimitSettingCollection[] | null,
  expenseItemId: string
): ExpenseLimit[] | null => {
  if (collection === null || expenses === null) return [] as ExpenseLimit[];

  return collection
    .filter(f => f.deleted === false)
    .filter(f => f.userId === userId)
    .filter(f => f.itemId === expenseItemId)
    .map(
      m =>
        ({
          id: m.id,
          amountLimit: m.amountLimit,
          balanceAmount: GetBalanceAmount(expenses, m.id, userId, m),
          itemId: m.itemId,
        } as ExpenseLimit)
    );
};
