import { DayElement } from "components";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { CalendarData, HolidayCollection, User } from "types";
import { IsWeb } from "utils";

type Props = {
  visibleDate: dayjs.Dayjs;
  user: User;
  allCalendars: CalendarData[];
  holidays: HolidayCollection[];
  hoverTop?: boolean;
  UserClick?: (user: User) => void;
  DailyUserClick?: (date: dayjs.Dayjs, user: User) => void;
};

/** ユーザーの１ヶ月のカレンダーリストを描写します */
export function UserSchedule({
  visibleDate,
  user,
  allCalendars,
  UserClick,
  DailyUserClick,
  holidays,
  hoverTop,
}: Props) {
  const [isOverlap, setIsOverlap] = useState<boolean>(false);

  const schedules = allCalendars.filter(
    calendar => calendar.userId === user.id
  );

  const switchOverlap = (bool: boolean) => {
    setIsOverlap(bool);
  };

  const calendarType = IsWeb() ? "month" : "week";
  const dayElement: JSX.Element[] = [];
  const endMonthOfDate: dayjs.Dayjs = visibleDate.endOf(calendarType);
  for (
    let startDate = visibleDate.startOf(calendarType);
    startDate <= endMonthOfDate;
    startDate = startDate.add(1, "day")
  ) {
    if (
      isTargetCondition(schedules, startDate) ||
      isCompanyHoliday(holidays, startDate)
    ) {
      const targetDays = schedules.filter(schedule => {
        return startDate.isBetween(
          dayjs(schedule.startDate.toDate())
            .startOf("day")
            .subtract(1, "second"),
          dayjs(schedule.endDate.toDate()).endOf("day")
        )
          ? schedule
          : null;
      });

      const targetHolidays = holidays.filter(f =>
        dayjs(f.targetAt?.toDate()).isBetween(
          dayjs(startDate.toDate()).startOf("day").subtract(1, "second"),
          dayjs(startDate.toDate()).endOf("day"),
          null,
          "[]"
        )
      );

      dayElement.push(
        <DayElement
          key={startDate.format()}
          propsDate={startDate}
          user={user}
          schedules={targetDays}
          isHoliday={
            isIncludeHoliday(schedules, startDate) ||
            isCompanyHoliday(holidays, startDate)
          }
          switchOverlap={switchOverlap}
          DailyUserClick={DailyUserClick}
          holidays={targetHolidays}
          hoverLeft={endMonthOfDate.date() / 2 < startDate.date()}
          hoverTop={hoverTop}
        />
      );
    } else {
      dayElement.push(
        <Pressable
          onPress={() => DailyUserClick && DailyUserClick(startDate, user)}
          style={[IsWeb() ? styles.day : styles.flexItem]}
        >
          <Text key={startDate.format()} />
        </Pressable>
      );
    }
  }

  if (IsWeb()) {
    return (
      <View style={[styles.flex, isOverlap ? { zIndex: 10 } : { zIndex: -10 }]}>
        <Pressable onPress={() => UserClick && UserClick(user)}>
          <Text numberOfLines={1} ellipsizeMode="tail" style={styles.staffName}>
            {user.name}
          </Text>
        </Pressable>
        {dayElement}
      </View>
    );
  }

  return (
    <View style={styles.flex}>
      <View style={[styles.flexItem, { width: "16%" }]}>
        <View style={styles.flexItemContent}>
          <Pressable onPress={() => UserClick && UserClick(user)}>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={styles.flexItemText}
            >
              {user.name}
            </Text>
          </Pressable>
        </View>
      </View>
      {dayElement}
    </View>
  );
}

const isIncludeHoliday = (
  schedules: CalendarData[],
  targetDay: dayjs.Dayjs
): boolean => {
  if (schedules) {
    const targetDays = schedules.filter(schedule => {
      return targetDay.isBetween(
        dayjs(schedule.startDate.toDate()).startOf("day").subtract(1, "second"),
        dayjs(schedule.endDate.toDate()).endOf("day")
      )
        ? schedule
        : null;
    });
    return targetDays.some(day => day.isHoliday);
  }
  return false;
};

const isTargetCondition = (
  schedules: CalendarData[],
  targetDay: dayjs.Dayjs
): boolean => {
  return schedules.some(schedule =>
    targetDay.isBetween(
      dayjs(schedule.startDate.toDate()).startOf("day").subtract(1, "second"),
      dayjs(schedule.endDate.toDate()).endOf("day")
    )
  );
};

const isCompanyHoliday = (
  holidays: HolidayCollection[],
  targetDay: dayjs.Dayjs
) => {
  return holidays.some(s =>
    dayjs(s.targetAt?.toDate()).isBetween(
      dayjs(targetDay.toDate()).startOf("day"),
      dayjs(targetDay.toDate()).endOf("day"),
      null,
      "[]"
    )
  );
};

const styles = StyleSheet.create({
  flex: {
    flexDirection: "row",
  },
  day: {
    borderWidth: 1,
    height: 40,
    width: 56,
    textAlign: "center",
  },
  staffName: {
    width: 72,
  },
  flexItem: {
    width: "12%",
    height: 32,
    borderWidth: 1,
    borderColor: "gray",
  },
  flexItemContent: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  flexItemText: {
    fontWeight: "bold",
    fontSize: 12,
  },
});
