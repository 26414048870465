import { isPointWithinRadius } from "geolib";

export interface GeoPoint {
  latitude: string;
  longitude: string;
}

export const fetchGeoLocationAddress = async (
  geoPoint: GeoPoint
): Promise<string> => {
  if (!geoPoint) {
    throw new Error("Location information could not be obtained");
  }

  const inCircle = isPointWithinRadius(
    { latitude: geoPoint.latitude, longitude: geoPoint.longitude }, //判別対象
    { latitude: 34.455785, longitude: 132.47161 }, //addgreenの緯度経度
    30 //半径(m)
  );

  if (inCircle) {
    return "会社";
  }

  // 緯度経度から付近の町域情報一覧を取得
  return await fetch(
    `https://geoapi.heartrails.com/api/json?method=searchByGeoLocation&x=${geoPoint.longitude}&y=${geoPoint.latitude}`
  )
    .then((response: any) => response.json())
    .then((responseJson: any) => {
      // 複数候補があるため先頭のみ取得
      const location = responseJson.response.location[0];
      return location.city + location.town;
    })
    .catch((error: any) => {
      console.error(error);
    });
};
