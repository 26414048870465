import firebase from "firebase/compat/app";
import { SiteCollection } from "types";

/**
 * site Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const SiteOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<SiteCollection[] | null>>
) => {
  return firebase
    .firestore()
    .collection("site")
    .onSnapshot(
      docs => {
        const collectionList = docs.docs.map(
          doc => ({ ...doc.data(), id: doc.id } as SiteCollection)
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};
