import { UserSchedule } from "components";
import dayjs from "dayjs";
import React from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { CalendarData, HolidayCollection, User } from "types";
import { GetIsCurrentDateHoliday, IsWeb } from "utils";

type Props = {
  visibleDate: dayjs.Dayjs;
  allUsers: User[];
  allCalendars: CalendarData[];
  holidays: HolidayCollection[];
  UserClick?: (user: User) => void;
  DailyUserClick?: (date: dayjs.Dayjs, user: User) => void;
  DailyClick?: (date: dayjs.Dayjs) => void;
};

/** Web用の月ごとのカレンダーを描写します */
export function MonthCalendar({
  visibleDate,
  allUsers,
  allCalendars,
  holidays,
  DailyClick,
  UserClick,
  DailyUserClick,
}: Props) {
  return (
    <View style={styles.container}>
      <ScrollView>
        <ScrollView
          horizontal={true}
          contentContainerStyle={
            IsWeb() ? { paddingRight: 0 } : { paddingRight: 32 }
          }
        >
          <View style={[styles.wrap, IsWeb() && styles.wrapForWeb]}>
            <View style={styles.calendar}>
              <Text style={styles.gridTitle}>社員名</Text>
              {holidays &&
                [...Array(visibleDate.endOf("month").get("date"))].map(
                  (_, i) => (
                    <DayElement
                      key={i}
                      visibleDate={visibleDate.set("date", i + 1)}
                      holidays={holidays}
                      DailyClick={DailyClick}
                    />
                  )
                )}
            </View>

            {allUsers.map((user: User, index) => (
              <UserSchedule
                key={user.uid}
                visibleDate={visibleDate}
                user={user}
                allCalendars={allCalendars}
                UserClick={UserClick}
                DailyUserClick={DailyUserClick}
                holidays={holidays}
                hoverTop={allUsers.length / 2 < index}
              />
            ))}
          </View>
        </ScrollView>
      </ScrollView>
    </View>
  );
}

type DayElementProps = {
  visibleDate: dayjs.Dayjs;
  holidays: HolidayCollection[];
  DailyClick?: (date: dayjs.Dayjs) => void;
};
const DayElement = ({
  visibleDate,
  holidays,
  DailyClick,
}: DayElementProps): JSX.Element => {
  if (visibleDate.format("dd") === "日") {
    return (
      <Pressable onPress={() => DailyClick && DailyClick(visibleDate)}>
        <Text style={[styles.day, styles.sunday]}>
          {visibleDate.format("D")}
        </Text>
      </Pressable>
    );
  }

  if (visibleDate.format("dd") === "土") {
    const currentDate = visibleDate;
    return (
      <Pressable onPress={() => DailyClick && DailyClick(currentDate)}>
        <Text style={[styles.day, styles.saturday]}>
          {currentDate.format("D")}
        </Text>
      </Pressable>
    );
  }

  const holiday = GetIsCurrentDateHoliday(holidays, visibleDate.toDate());

  return (
    <Pressable onPress={() => DailyClick && DailyClick(visibleDate)}>
      <Text style={[styles.day, holiday && styles.sunday]}>
        {visibleDate.format("D")}
      </Text>
    </Pressable>
  );
};
const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  wrap: {
    paddingTop: 32,
    paddingLeft: 32,
  },
  wrapForWeb: {
    height: "100%",
  },
  calendar: {
    flexDirection: "row",
  },
  gridTitle: {
    width: 72,
    fontWeight: "500",
  },
  day: {
    borderWidth: 1,
    height: 40,
    width: 56,
    textAlign: "center",
  },
  sunday: {
    color: "red",
  },
  saturday: {
    color: "blue",
  },
});
