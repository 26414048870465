import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  NoteBookCollection,
  NoteBookData,
  UserInfo,
} from "types";

/**
 * Wikiの新規作成の書き込みを行います
 * @param userInfo userContextから取得したユーザー情報
 * @param noteBooks collectionContextから取得したWiki情報
 * @param noteBookData 登録対象のWiki情報
 * @returns　CreateCheckResult
 */
export const CreateNoteBook = async (
  userInfo: UserInfo | null,
  noteBooks: NoteBookCollection[] | null,
  noteBookData: NoteBookData
): Promise<CreateCheckResult> => {
  const date = new Date();
  if (noteBooks === null)
    return { isCheck: false, message: "登録に失敗しました" };
  return await firebase
    .firestore()
    .collection("noteBook")
    .add({
      bookName: noteBookData.bookName,
      sort: noteBookData.sort,
      deleted: false,
      isManual: false,
      updateAt: date,
      updateUser: userInfo?.user.id,
    })
    .then(async () => ({ isCheck: true, message: "登録しました" }))
    .catch(error => ({ isCheck: false, message: "登録に失敗しました" }));
};
