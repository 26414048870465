import {
  CreateCenterCol,
  CreateLeftCol,
  CreateRightCol,
  CreateRow,
  MobileTableEditButton,
  MobileTableRequestStatusText,
} from "components";
import React from "react";
import { ScrollView, Text, View } from "react-native";
import { mobileTableStyles } from "styles";
import { ExpenseList } from "types";
import { GetMoneyText } from "utils";

export const MobileExpenseTable = ({
  tableList,
  onEditClick,
}: {
  tableList: ExpenseList[];
  onEditClick: (id: string) => void;
}) => {
  return (
    <ScrollView
      style={mobileTableStyles.scroll_view_style}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <View style={mobileTableStyles.list_line_view} />
        {tableList?.map((data, i) => (
          <View key={i} style={mobileTableStyles.list_line_view}>
            <View style={mobileTableStyles.contents_container}>
              <CreateRow>
                <CreateLeftCol>
                  <MobileTableEditButton
                    onEditClick={() => onEditClick(data.id)}
                    buttonText="詳細確認"
                  />
                </CreateLeftCol>
                <CreateRightCol>
                  <MobileTableRequestStatusText status={data.status} />
                </CreateRightCol>
              </CreateRow>
              <CreateRow>
                <CreateLeftCol>
                  <View>
                    <Text style={mobileTableStyles.base_text}>
                      {`現場名：${data.siteName}`}
                    </Text>
                    <Text
                      style={mobileTableStyles.base_text}
                    >{`申請金額：${GetMoneyText(data.totalAmount)}`}</Text>
                  </View>
                </CreateLeftCol>
                <CreateCenterCol></CreateCenterCol>
              </CreateRow>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};
