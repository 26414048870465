import { IconList, NoteBookCollection } from "types";
import { AscSort } from "utils";

/**
 * 整形したWiki表示用情報を返却します
 * @param noteBooks collectionContextから取得したWiki情報
 * @returns IconList[]
 */
export const FetchNoteBooks = (
  noteBooks: NoteBookCollection[] | null
): IconList[] => {
  if (noteBooks === null) return [] as IconList[];

  const targetNoteBooks = noteBooks
    .filter(f => f.deleted === false)
    .sort((a, b) => AscSort(a.sort, b.sort));

  return targetNoteBooks.map(
    elem =>
      ({
        id: elem.id ?? "",
        text: elem.bookName,
        iconName: "open-book",
        iconType: "entypo",
        iconColor: "white",
      } as IconList)
  );
};
