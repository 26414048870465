import { MobileValidTable, WebValidTable } from "components";
import React from "react";
import { View } from "react-native";
import { PaidVacationList, PaidVacationSettingType, UserCollection } from "types";
import { IsWeb } from "utils";
export const ValidTable = ({
  onEditClick,
  tableList,
  UserCollection,
}: {
  onEditClick: (paidVacation: PaidVacationList) => void;
  tableList: PaidVacationSettingType | null;
  UserCollection: UserCollection[];
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : IsWeb() ? (
        <WebValidTable
          onEditClick={onEditClick}
          tableList={tableList}
          UserCollection={UserCollection}
        />
      ) : (
        <MobileValidTable
          onEditClick={onEditClick}
          tableList={tableList}
          UserCollection={UserCollection}
        />
      )}
    </>
  );
};
