/**
 * データの降順ソートを行います
 *
 * 例：[3,1,2]→[3,2,1]
 * @param a param1
 * @param b param2
 * @returns 判定結果
 */
export const DescSort = (a: any, b: any) => {
  return a < b ? 1 : -1;
};

/**
 * データの昇順ソートを行います
 *
 * 例：[3,1,2]→[1,2,3]
 * @param a param1
 * @param b param2
 * @returns 判定結果
 */
export const AscSort = (a: any, b: any) => {
  return a < b ? -1 : 1;
};
