import firebase from "firebase/compat/app";
import { CreateCheckResult, SiteData, UserInfo } from "types";

/**
 * 現場の更新書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param siteData 更新・登録対象の現場情報
 * @returns
 */
export const UpdateSite = async (
  reqUser: UserInfo | null,
  siteData: SiteData
): Promise<CreateCheckResult> => {
  const date = new Date();
  const doc = await firebase
    .firestore()
    .collection("site")
    .doc(siteData.id)
    .get();
  return await doc.ref
    .update({
      ...doc.data(),
      name: siteData.name,
      matterNumber: siteData.matterNumber,
      agreementNumber: siteData.agreementNumber,
      progressStatus: siteData.progressStatus,
      deleted: siteData.deleted,
      updateAt: date,
      updateUser: reqUser?.user.id,
    })
    .then(() => {
      return { isCheck: true, message: "更新しました" };
    })
    .catch(() => {
      return { isCheck: false, message: "更新に失敗しました" };
    });
};
