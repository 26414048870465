import { MobileUserTable, WebUserTable } from "components";
import React from "react";
import { View } from "react-native";
import { UserList } from "types";
import { IsWeb } from "utils";

export const UserTable = ({
  tableList,
  onEditClick,
}: {
  tableList: UserList[] | null;
  onEditClick: (id: string) => void;
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : IsWeb() ? (
        <WebUserTable onEditClick={onEditClick} tableList={tableList} />
      ) : (
        <MobileUserTable onEditClick={onEditClick} tableList={tableList} />
      )}
    </>
  );
};
