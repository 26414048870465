import { paymentStatusType, requestStatusType } from "constants/index";
import dayjs from "dayjs";
import {
  ExpenseCollection,
  ExpenseDownLoadExcelOutput,
  History,
  PaymentHistory,
  SiteCollection,
  UserCollection,
  UserInfo,
} from "types";
import { AscSort, DescSort } from "utils";
/**
 *　指定期間の経費申請情報を返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param userInfo userContextから取得した情報
 * @param attendances collectionContextから取得した勤怠情報
 * @returns
 */
export const FetchExpensesDownLoadList = (
  userInfo: UserInfo | null,
  users: UserCollection[] | null,
  expenses: ExpenseCollection[] | null,
  sites: SiteCollection[] | null,
  from: Date,
  to: Date,
  targetKey: string
): ExpenseDownLoadExcelOutput[] => {
  if (
    expenses === null ||
    users === null ||
    userInfo === null ||
    sites === null
  )
    return [];

  return expenses
    .sort((a, b) => DescSort(a.updateAt, b.updateAt))
    .map(m => ConvertResultData(m, users, sites))
    .filter(f => ComparisonDate(f, targetKey, from, to));
};

const ConvertResultData = (
  expense: ExpenseCollection,
  users: UserCollection[],
  sites: SiteCollection[]
): ExpenseDownLoadExcelOutput => {
  // 画像のURLを取得
  const images = {} as { [key: string]: string };
  expense.images.map((m, index) => {
    if (index >= 10) return;
    images[`picture${index + 1}`] = m.url;
  });
  const t = expense.paymentHistory;
  const user = users.filter(f => f.id === expense.userId).shift();
  const site = sites.filter(f => f.id === expense.siteId).shift();
  const paymentHistory = GetTargetPaymentHistory(t);
  const rejectAt = GetTargetExpenseHistory(expense.expenseHistory, "reject");
  const approveAt = GetTargetExpenseHistory(expense.expenseHistory, "approval");

  return {
    userName: user !== undefined ? user.name : "",
    documentId: expense.id,
    status: requestStatusType[expense.status ?? ""]?.text ?? "",
    paymentStatus: paymentStatusType[expense.paymentStatus ?? ""]?.text ?? "",
    siteName: site !== undefined ? site.name : "",
    price: expense.totalAmount === null ? "" : expense.totalAmount.toString(),
    paymentPrice:
      paymentHistory === null
        ? ""
        : (paymentHistory.paymentAmount ?? 0).toString(),
    requestAt: GetTimeStampToString(expense.requestAt),
    reckoningAt: GetTimeStampToString(expense.reckoningAt),
    approveAt: GetTimeStampToString(approveAt),
    rejectAt: GetTimeStampToString(rejectAt),
    paymentAt:
      paymentHistory === null
        ? ""
        : GetTimeStampToString(paymentHistory.paymentAt),
    ...images,
  };
};
const GetTimeStampToString = (date: FirebaseFirestore.Timestamp | null) => {
  return date === null ? "" : dayjs(date.toDate()).format("YYYY/MM/DD");
};

/** 指定の日付が指定範囲かどうかを判定します */
const ComparisonDate = (
  expense: ExpenseDownLoadExcelOutput,
  key: string,
  from: Date,
  to: Date
) => {
  if ("requestAt" === key && expense.requestAt !== "")
    return isWithInRangeDate(dayjs(expense.requestAt).toDate(), from, to);
  if ("reckoningAt" === key && expense.reckoningAt !== "")
    return isWithInRangeDate(dayjs(expense.reckoningAt).toDate(), from, to);
  if ("approveAt" === key && expense.approveAt !== "")
    return isWithInRangeDate(dayjs(expense.approveAt).toDate(), from, to);
  if ("rejectAt" === key && expense.rejectAt !== "")
    return isWithInRangeDate(dayjs(expense.rejectAt).toDate(), from, to);
  if ("paymentAt" === key && expense.paymentAt !== "")
    return isWithInRangeDate(dayjs(expense.paymentAt).toDate(), from, to);
  if ("receivedAt" === key && expense.receivedAt !== "")
    return isWithInRangeDate(dayjs(expense.receivedAt).toDate(), from, to);
  if ("withdrawalAt" === key && expense.withdrawalAt !== "")
    return isWithInRangeDate(dayjs(expense.withdrawalAt).toDate(), from, to);

  return false;
};

/** 日付の比較メソッド */
const isWithInRangeDate = (
  target: Date | null,
  from: Date,
  to: Date
): boolean => {
  if (target === null) return false;
  return dayjs(target).isBetween(from, to, null, "[]");
};

/** 指定の履歴の日付を取得 */
const GetTargetExpenseHistory = (history: History[], status: string) => {
  const result = history
    .filter(f => f.status === status)
    .sort((a, b) => DescSort(a.updateAt, b.updateAt))
    .shift();

  if (result === undefined) return null;
  return result.updateAt;
};

/** 指定の履歴の支払額を取得 */
const GetTargetPaymentHistory = (history: PaymentHistory[]) => {
  const result = history
    .sort((a, b) => AscSort(a.updateAt, b.updateAt))
    .shift();

  if (result === undefined) return null;
  return result;
};
