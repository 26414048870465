import { IsRequest } from "types/DownLoad";

/**
 * 申請の文字列を「/」区切りで取得します
 * @param requestList 取得した申請情報を格納します
 * @returns
 */
export const GetRequestString = (requestList: IsRequest) => {
  var result = "";
  if (requestList.overtime !== undefined)
    result = result + (result.length === 0 ? "残業" : "/残業");
  if (requestList.paidVacation !== undefined) {
    const paidVacationType = requestList.paidVacation.paidVacationType;
    const paidVacationString =
      paidVacationType === "allDay"
        ? "有給(全休)"
        : paidVacationType === "morning"
        ? "有給(午前)"
        : paidVacationType === "afternoon"
        ? "有給(午後)"
        : "有給";
    result +=
      result.length === 0 ? paidVacationString : `/${paidVacationString}`;
  }
  if (requestList.leaveEarly !== undefined)
    result = result + (result.length === 0 ? "早退" : "/早退");
  if (requestList.absence !== undefined)
    result = result + (result.length === 0 ? "欠勤" : "/欠勤");
  if (requestList.lateness !== undefined)
    result = result + (result.length === 0 ? "遅刻" : "/遅刻");
  if (requestList.privateOuting !== undefined)
    result = result + (result.length === 0 ? "私用外出" : "/私用外出");
  if (requestList.substituteAttendance !== undefined)
    result = result + (result.length === 0 ? "振替出勤" : "/振替出勤");
  if (requestList.holidayWork !== undefined)
    result = result + (result.length === 0 ? "休日出勤" : "/休日出勤");
  if (requestList.substituteHoliday !== undefined)
    result = result + (result.length === 0 ? "振替休日" : "/振替休日");
  if (requestList.staggeredWorkHours !== undefined)
    result = result + (result.length === 0 ? "時差出勤" : "/時差出勤");
  if (requestList.attendanceCorrection !== undefined)
    result = result + (result.length === 0 ? "勤怠修正" : "/勤怠修正");
  return result;
};
