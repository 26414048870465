import dayjs from "dayjs";
import * as authority from "server/collectionConnect/utils/authority";
import { RequestCollection, RequestList, UserInfo } from "types";
import { AscSort, DescSort } from "utils";
import { GetSortStatusNumber } from "../utils/request";
/**
 * 整形した勤怠申請承認待ち情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param requests collectionContextから取得した申請情報
 * @param targetMonth 指定月
 * @returns RequestList[]
 */
export const FetchApprovalWaitRequests = (
  userInfo: UserInfo | null,
  requests: RequestCollection[] | null,
  targetMonth: Date
): RequestList[] => {
  if (requests === null) return [] as RequestList[];

  const requestList = requests
    .filter(
      f => dayjs(f.requestAt?.toDate()) >= dayjs(targetMonth).startOf("month")
    )
    .filter(
      f => dayjs(f.requestAt?.toDate()) <= dayjs(targetMonth).endOf("month")
    )
    .filter(f =>
      authority
        .targetDispApprovalWaitRequest(userInfo)
        .includes(f.requestAuthorityId ?? "")
    )
    .filter(f => f.status !== "withdrawal")
    .sort((a, b) => {
      if (
        GetSortStatusNumber(a.status ?? "") !==
        GetSortStatusNumber(b.status ?? "")
      ) {
        return AscSort(
          GetSortStatusNumber(a.status ?? ""),
          GetSortStatusNumber(b.status ?? "")
        );
      }

      if (a.updateAt !== b.updateAt) {
        return DescSort(a.updateAt, b.updateAt);
      }

      return 0;
    });

  return requestList.map(elem => {
    const sortHistory = elem.requestHistory.sort((a, b) =>
      DescSort(a.updateAt, b.updateAt)
    );

    const approveAt = sortHistory.filter(f => f.status === "approval").shift();
    const rejectAt = sortHistory.filter(f => f.status === "reject").shift();

    return {
      id: elem.id,
      status: elem.status,
      requestType: elem.requestType,
      requestAt: elem.requestAt,
      acquisitionStartAt: elem.acquisitionStartAt,
      approveAt: approveAt === undefined ? null : approveAt.updateAt,
      rejectAt: rejectAt === undefined ? null : rejectAt.updateAt,
      createUser: elem.createUser,
      updateUser: elem.updateUser,
    } as RequestList;
  });
};
