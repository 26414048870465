import {
  ExpenseCollection,
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  ExpenseList,
  SiteCollection,
  UserInfo,
} from "types";
import { AscSort, DescSort } from "utils";
import { GetSiteName } from "utils/GetSiteName";

/**
 * 整形したダッシュボード用経費申請情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param expenses collectionContextから取得した経費情報
 * @param dataLoadType 読み込み予定件数
 * @returns DashboardsDataType[]
 */
export const FetchDashboardExpenses = (
  userInfo: UserInfo | null,
  expenses: ExpenseCollection[] | null,
  expenseLimitSetting: ExpenseLimitSettingCollection[],
  expenseItem: ExpenseItemCollection[],
  sites: SiteCollection[],
  dataLoadType: number
): ExpenseList[] => {
  if (expenses === null) return [] as ExpenseList[];

  const expenseList = expenses
    .filter(request => request.userId === userInfo?.user.id)
    .sort((a, b) => DescSort(a.updateAt?.toDate(), b.updateAt?.toDate()))
    .slice(0, dataLoadType);

  return expenseList.map(elem => {
    const sortExpenseHistory = elem.expenseHistory.sort((a, b) =>
      AscSort(a.updateAt ?? 0, b.updateAt ?? 0)
    );

    const approveAt = sortExpenseHistory
      .filter(f => f.status === "approval")
      .shift();
    const rejectAt = sortExpenseHistory
      .filter(f => f.status === "reject")
      .shift();
    const receivedAt = sortExpenseHistory
      .filter(f => f.status === "received")
      .shift();

    const sortPaymentHistory = elem.paymentHistory.sort((a, b) =>
      AscSort(a.updateAt ?? 0, b.updateAt ?? 0)
    );

    let paymentAt = null as FirebaseFirestore.Timestamp | null;
    let paymentAmountTotal = 0;
    sortPaymentHistory.forEach(doc => {
      paymentAt = doc.paymentAt;
      paymentAmountTotal += doc.paymentAmount ?? 0;
    });

    const filterExpenseItem = expenseItem.filter(f => f.id === elem.id).shift();
    const isNoExpenseLimit = filterExpenseItem
      ? filterExpenseItem.isNoExpenseLimit
      : false;

    const filterExpenseLimitSetting = expenseLimitSetting
      .filter(f => f.userId === userInfo?.user.id)
      .filter(f => f.itemId === elem.expenseItemId)
      .filter(f => f.deleted === false)
      .shift();
    const expenseLimitSettingId = filterExpenseLimitSetting?.id;

    return {
      id: elem.id,
      status: elem.status,
      paymentStatus: elem.paymentStatus,
      siteName: GetSiteName(sites, elem.siteId),
      totalAmount: elem.totalAmount,
      paymentAmount: paymentAmountTotal,
      requestAt: elem.requestAt,
      reckoningAt: elem.reckoningAt,
      approveAt: approveAt === undefined ? null : approveAt.updateAt,
      rejectAt: rejectAt === undefined ? null : rejectAt.updateAt,
      paymentAt: paymentAt,
      receivedAt: receivedAt === undefined ? null : receivedAt.updateAt,
      updateUser: elem.updateUser,
    } as ExpenseList;
  });
};
