import firebase from "firebase/compat/app";
import {
  ExpenseLimitSettingCollection,
  ExpenseLimitSettingUseHistory,
} from "types";

/**
 * expenseLimitSetting Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const ExpenseLimitSettingOnSnapShot = (
  setState: React.Dispatch<
    React.SetStateAction<ExpenseLimitSettingCollection[] | null>
  >
) => {
  return firebase
    .firestore()
    .collection("expenseLimitSetting")
    .onSnapshot(
      async docs => {
        const collectionList = await Promise.all(
          await docs.docs.map(async doc => {
            const useExpenseHistory = await GetUseExpenseHistory(doc);

            return {
              id: doc.id,
              useExpenseHistory: useExpenseHistory,
              ...doc.data(),
            } as ExpenseLimitSettingCollection;
          })
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};

/**
 * Expenseコレクション内のuseHistoryを取得するメソットです
 * @param doc：firebase.collectionオブジェクト
 * @returns History
 */
const GetUseExpenseHistory = async (
  doc: firebase.firestore.DocumentSnapshot
) => {
  return await doc.ref
    .collection("useExpenseHistory")
    .get()
    .then(details =>
      details.docs.map(
        detail =>
          ({ ...detail.data(), id: detail.id } as ExpenseLimitSettingUseHistory)
      )
    )
    .catch(() => [] as ExpenseLimitSettingUseHistory[]);
};
