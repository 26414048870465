import { Item } from "react-native-picker-select";
import { UserCollection } from "types";
/**
 *　整形したユーザー情報を返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param userInfo userContextから取得した情報
 * @param users collectionContextから取得したユーザー情報
 * @returns
 */
export const FetchUserItems = (users: UserCollection[] | null): Item[] => {
  if (users === null) return [] as Item[];
  users.sort((a, b) => {
    // 並び順の昇順で並び替え
    if (a.sort !== b.sort) return a.sort - b.sort;

    return 0;
  });

  return users.map(user => {
    return {
      value: user.id,
      label: user.name,
    } as Item;
  });
};
