import { requestType } from "constants/index";
import dayjs from "dayjs";
import { RequestCollection, UserCollection } from "types/CollectionType";
import { GetUserName } from "utils/GetUserName";
import { AscSort, DescSort } from "utils/sortBy";

/**
 * calendar Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const FetchTargetMonthRequestList = async (
  requests: RequestCollection[],
  users: UserCollection[],
  startDate: Date,
  endDate: Date
) => {
  const targets = requests.filter(f =>
    dayjs(f.acquisitionStartAt?.toDate()).isBetween(
      dayjs(startDate).startOf("D"),
      dayjs(endDate).endOf("D"),
      null,
      "[]"
    )
  );

  return targets
    .sort((a, b) => AscSort(a.acquisitionStartAt, b.acquisitionStartAt))
    .map(m => {
      try {
        const approvals = m.requestHistory
          .filter(f => f.status === "approval")
          .sort((a, b) => DescSort(a.updateAt, b.updateAt));
        const approval = approvals[0];
        const name = GetUserName(users, m.createUser ?? "");
        const aname =
          approval !== undefined && m.status === "approval"
            ? GetUserName(users, approval.updateUser ?? "")
            : "";

        const remark = requestType[m.requestType ?? ""]?.text ?? "";
        const start = dayjs(m.acquisitionStartAt?.toDate()).format(
          "YYYY/MM/DD HH:mm"
        );
        const end = dayjs(m.acquisitionEndAt?.toDate()).format(
          "YYYY/MM/DD HH:mm"
        );
        const result = {
          ["申請者名"]: name,
          ["承認者名"]: aname,

          ["申請内容"]: remark,
          "申請詳細（開始日）": start,
          "申請詳細（終了日）": end,
          ["承認"]:
            approval !== undefined && m.status === "approval" ? "あり" : "なし",
        };
        return result;
      } catch (e) {
        console.log(e);
      }
    });
};
