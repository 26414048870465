import { NoteBookCollection, NoteBookDeleteListType } from "types";

/**
 * 指定したWikiページ情報を返却します
 * @param noteBook collectionContextから取得したWikiページ情報
 * @param targetId 指定のWikiページID
 * @returns NoteBookData
 */ export const FetchNoteBookPageDeleteTargets = (
  NoteBookCollection: NoteBookCollection[] | null,
  targetId: string,
  targetIds: string[]
): NoteBookDeleteListType[] | null => {
  if (NoteBookCollection === null) return null;

  const target = NoteBookCollection.filter(f => targetId === f.id).shift();

  if (target === undefined) return null;
  const targetPages = target.notebookPage.filter(f =>
    targetIds.find(ff => ff === f.id)
  );
  return targetPages.map(m => ({
    id: m.id,
    bookName: m.pageTitle ?? "",
    updateAt: m.updateAt,
  }));
};
