import firebase from "firebase/compat/app";
import { CreateCheckResult, NoteBookPageData } from "types";
import { AscSort } from "utils";

/**
 * Wikiページの並び順の更新を行います
 * @returns
 */
export const UpdateNoteBookPageSort = async (
  noteBookPageData: NoteBookPageData
): Promise<CreateCheckResult> => {
  const doc = await firebase
    .firestore()
    .collection("noteBook")
    .doc(noteBookPageData.noteBookId)
    .collection("noteBookPage")
    .get();
  const docs = doc.docs.sort((a, b) =>
    AscSort(a.data().sort ?? 0, b.data().sort ?? 0)
  );

  var index = 1;
  var deleteIndex = 10001;
  const result = await Promise.all(
    docs.map(async d => {
      // 変更予定の並びを取得
      const currentIndex = d.data().deleted === true ? deleteIndex++ : index++;
      // 並びに変更がなければそのままスルー
      if (d.data().sort === currentIndex) return true;

      return await d.ref
        .update({
          ...d.data(),
          sort: currentIndex,
        })
        .then(e => true)
        .catch(e => false);
    })
  );
  return result.length === 0 || result.find(f => f)
    ? { isCheck: true, message: "更新しました" }
    : { isCheck: false, message: "更新に失敗しました" };
};
