import React from "react";
import { StyleSheet, Text, View } from "react-native";

import RNPickerSelect, { Item } from "react-native-picker-select";
type Props<T> = {
  /** フォームの名前を指定します */
  label: string;
  /** プレイスホルダーの名称を定義します */
  placeholderLabel: string;
  /** 選択の値を定義します */
  value: T;
  /** 変更の処理を定義します */
  onChange?: (e: T, index: number) => void;
  /**
   *  デフォルト：false
   *
   * 操作可能：false 操作不可：false */
  disabled?: boolean;
  items: Item[];
};

/**
 * プルダウンを作成します
 * @param param0
 * @returns
 */
export const SelectForm = <T,>({
  placeholderLabel,
  value,
  onChange,
  disabled,
  items,
  label,
}: Props<T>) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
      <RNPickerSelect
        placeholder={{
          label: placeholderLabel,
          value: "",
        }}
        value={value}
        onValueChange={(e: string, index) =>
          onChange && onChange(e as T, index)
        }
        disabled={disabled ?? false}
        items={items}
        style={disabled ? pickerSelectDisabledStyles : pickerSelectStyles}
        useNativeAndroidPickerStyle={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 20,
  },
  label: {
    fontSize: 20,
  },
});
const pickerSelectDisabledStyles = StyleSheet.create({
  placeholder: {
    color: "black",
  },
  inputWeb: {
    fontSize: 16,
    paddingVertical: 7,
    paddingHorizontal: 7,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    backgroundColor: "darkgray",
    color: "black",
    width: "100%",
    opacity: 1,
  },
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    backgroundColor: "darkgray",
    color: "black",
    width: "100%",
  },
  inputAndroid: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    backgroundColor: "darkgray",
    color: "black",
    width: "100%",
  },
});

const pickerSelectStyles = StyleSheet.create({
  placeholder: {
    color: "gray",
  },
  inputWeb: {
    fontSize: 16,
    paddingVertical: 7,
    paddingHorizontal: 7,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    color: "black",
    width: "100%",
  },
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    backgroundColor: "white",
    color: "black",
    width: "100%",
  },
  inputAndroid: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 4,
    backgroundColor: "white",
    color: "black",
    width: "100%",
  },
});
