import firebase from "firebase/compat/app";
import { CalendarData, CreateCheckResult, UserInfo } from "types";

/**
 * カレンダーを新規作成の書き込みを行います
 * @param reqUser userContextから取得した情報
 * @param CalendarData 登録予定のカレンダーデータ
 * @returns
 */
export const CreateCalendar = async (
  reqUser: UserInfo | null,
  CalendarData: CalendarData
): Promise<CreateCheckResult> => {
  const date = new Date();

  const siteRef = firebase
    .firestore()
    .collection("site")
    .doc(CalendarData.siteId);

  return await firebase
    .firestore()
    .collection("calendar")
    .add({
      title: CalendarData.title,
      startDate: CalendarData.startDate.toDate(),
      endDate: CalendarData.endDate.toDate(),
      isHoliday: CalendarData.isHoliday,
      memo: CalendarData.memo,
      userId: CalendarData.userId,
      isAllDay: CalendarData.isAllDay,
      siteRef: siteRef,
    })
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
