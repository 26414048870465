import dayjs from "dayjs";
import {
  AttendanceCollection,
  AttendanceHistoryList,
  HolidayCollection,
  RequestCollection,
  RequestType,
  UserCollection,
  UserInfo,
} from "types";
import { ConvertTimeStampFromDate } from "utils";
import { GetAttendanceHistory } from "./utils";
/**
 *　対象月の勤怠情報を返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param userInfo userContextから取得した情報
 * @param attendances collectionContextから取得した勤怠情報
 * @param requests collectionContextから取得した申請情報
 * @param targetMonth 指定月
 * @returns
 */
export const FetchAttendanceHistory = (
  userInfo: UserInfo | null,
  users: UserCollection[] | null,
  attendances: AttendanceCollection[] | null,
  requests: RequestCollection[] | null,
  holidays: HolidayCollection[] | null,
  targetDate: Date
): AttendanceHistoryList[] | null => {
  if (
    attendances === null ||
    requests === null ||
    holidays === null ||
    users === null ||
    userInfo === null
  )
    return null;
  const baseAttendances = GetAttendanceHistory(
    userInfo.user.id,
    attendances,
    requests,
    holidays,
    users,
    targetDate
  );
  if (baseAttendances === null) {
    return null;
  }
  const redDays: number[] = [];
  const blueDays: number[] = [];
  /** 申請欄の文字列の長さ情報 */
  const requestStrings = [] as {
    index: number;
    length: number;
  }[];
  // データを変換
  return baseAttendances.data.map((data, ii) => {
    if (data.dateColor === "red") redDays.push(ii);
    if (data.dateColor === "blue") blueDays.push(ii);
    const requestString = GetRequestString(
      userInfo.user.id,
      requests,
      targetDate,
      data.date
    );
    return {
      date: data.date,
      isDateColor: data.dateColor,
      startWork:
        data.startWork === null
          ? null
          : ConvertTimeStampFromDate(data.startWork),
      isStartWorkColor: data.startWorkColor,
      endWork:
        data.endWork === null ? null : ConvertTimeStampFromDate(data.endWork),
      isEndWorkColor: data.endWorkColor,
      request: requestString,
    } as AttendanceHistoryList;
  });
};
/** 申請列の文字列を取得します */
const GetRequestString = (
  userId: string,
  requests: RequestCollection[],
  targetDate: Date,
  day: number
) => {
  const startDate = dayjs(dayjs(targetDate).date(day)).startOf("date").toDate();
  const endDate = dayjs(dayjs(targetDate).date(day)).endOf("date").toDate();
  const target = requests
    .filter(f => f.userId === userId)
    .filter(f => f.status === "applying" || f.status === "approval")
    .filter(f =>
      dayjs(f.acquisitionStartAt?.toDate()).isBetween(
        startDate,
        endDate,
        null,
        "[]"
      )
    );
  if (target.length === 0) return "";
  if (target.length >= 2) return `${target.length}件申請あり`;
  return GetKeyName(target[0].requestType);
};
/** 申請タイプから文字列を取得します */
const GetKeyName = (key: RequestType | null) => {
  switch (true) {
    case key === "overtime":
      return "残業";
    case key === "paidVacation":
      return "有給";
    case key === "leaveEarly":
      return "早退";
    case key === "absence":
      return "欠勤";
    case key === "lateness":
      return "遅刻";
    case key === "privateOuting":
      return "私用外出";
    case key === "substituteAttendance":
      return "振替出勤";
    case key === "holidayWork":
      return "休日出勤";
    case key === "substituteHoliday":
      return "振替休日";
    case key === "staggeredWorkHours":
      return "時差出勤";
    case key === "attendanceCorrection":
      return "勤怠修正";
    default:
      return "";
  }
};
