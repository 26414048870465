import React, { ReactNode, createContext, useEffect, useState } from "react";
import { auth } from "server/Firebase";
import { UserInfo } from "types";
import { FetchUserInfo } from "utils/fetchUserInfo";

export const UserInfoContext = createContext(
  {} as {
    /** ログイン中のユーザーの情報 */
    userInfo: UserInfo | null;
    /** ログイン中のユーザー情報を格納します */
    setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
  }
);

export const UserInfoProvider = ({ children }: { children: ReactNode }) => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    // 認証状態が維持されていれば自動的にホーム画面へ遷移
    auth.onAuthStateChanged(user => {
      if (user) {
        // ユーザー情報を取得
        async function fetchUserInfo() {
          setUserInfo(await FetchUserInfo());
        }
        fetchUserInfo();
      }
    });
  }, []);

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  );
};
