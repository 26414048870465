import { UserAuthorityType, UserInfo } from "types";
import { GetUserAuthority } from "../../user/utils/GetUserAuthority";

import {
  ACCOUNTING,
  EMPLOYEE,
  HEAD,
  PART_TYPE,
  PRESIDENT,
} from "constants/index";

export const isPartTime = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, PART_TYPE);
};

export const isEmployee = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, EMPLOYEE);
};

export const isAccounting = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, ACCOUNTING);
};

export const isHead = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, HEAD);
};

export const isPresident = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, PRESIDENT);
};

export const targetDispApprovalWaitRequest = (
  userInfo: UserInfo | null
): string[] => {
  if (!userInfo) {
    return [];
  }

  const myAuthority = userInfo.authorityId;

  if ([PART_TYPE, EMPLOYEE].includes(myAuthority)) return [];
  if ([ACCOUNTING].includes(myAuthority)) return [HEAD];
  if ([HEAD].includes(myAuthority)) return [PART_TYPE, EMPLOYEE, ACCOUNTING];
  if ([PRESIDENT].includes(myAuthority))
    return [PART_TYPE, EMPLOYEE, ACCOUNTING, HEAD, PRESIDENT];

  throw new Error("non-existent permission");
};

export const targetDispApprovalWaitExpense = (
  userInfo: UserInfo | null
): string[] => {
  if (!userInfo) return [];

  const myAuthority = userInfo.authorityId;

  if ([PART_TYPE, EMPLOYEE].includes(myAuthority)) return [];
  if ([HEAD].includes(myAuthority)) return [PART_TYPE, EMPLOYEE];
  if ([PRESIDENT, ACCOUNTING].includes(myAuthority))
    return [PART_TYPE, EMPLOYEE, ACCOUNTING, HEAD, PRESIDENT];

  throw new Error("non-existent permission");
};

export const targetNotification = (myAuthorityId: string): string[] => {
  if ([PART_TYPE, EMPLOYEE].includes(myAuthorityId)) return [HEAD];
  if ([ACCOUNTING, HEAD, PRESIDENT].includes(myAuthorityId)) return [PRESIDENT];

  throw new Error("non-existent permission");
};

const checkAuthority = (
  userInfo: UserInfo | null,
  targetAuthority: string
): boolean => {
  if (!userInfo) return false;

  const authority: UserAuthorityType = GetUserAuthority(userInfo.authorityId);
  if (authority.id === targetAuthority) return true;

  return false;
};
