import React from "react";
import { Text, TouchableHighlight } from "react-native";
import { modalStyles } from "styles";

type Props = {
  /** ボタンクリック時の挙動を定義します */
  onClick: () => void;
};

/** モーダルの右上に兵司する×ボタンを描写します */
export const ModalCloseButton = ({ onClick }: Props) => {
  return (
    <TouchableHighlight
      onPress={onClick}
      underlayColor={"white"}
      style={modalStyles.closeButton}
    >
      <Text style={modalStyles.closeText}>&#0215;</Text>
    </TouchableHighlight>
  );
};
