import { UserInfoContext } from "GlobalContext";
import { ModalApprovalButton, ModalCancelButton } from "components";
import React, { useContext } from "react";
import {
  Image,
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import Markdown, { RenderRules } from "react-native-markdown-renderer";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import { ImageDataSources, NoteBookPageData, PageStatus } from "types";
import { IsAndroid, IsWeb, isAccounting, isPresident } from "utils";
type Props = {
  /** 編集・登録ボタンクリック時の動作 */
  onSubmit: () => void;
  /** キャンセルボタンをクリックした時の動作 */
  onCancel: () => void;
  /** ページのデータ */
  data: NoteBookPageData;
  /** ページ状況のステータスを定義します */
  status: PageStatus;
  /** 画像データ */
  images: ImageDataSources;
};

/** Wikiのページ情報を表示します */
export const PreviewPage = ({
  data,
  status,
  onSubmit,
  onCancel,
  images,
}: Props) => {
  const { userInfo } = useContext(UserInfoContext);

  /** マークダウンのレンダリングの定義を行います */
  const rules: RenderRules | undefined = {
    image: (node, children, parent, styles) => {
      var width = 0;
      var height = 0;
      var url = "";
      if (children.length > 0 && images.data.length > 0) {
        const child = (children[0] as any)?.props.children as string;
        const index = parseInt(child.replace("Upload", ""));
        const imgDataSource = images.data[index];
        if (imgDataSource !== undefined) {
          if (imgDataSource.height) height = imgDataSource.height;
          if (imgDataSource.width) width = imgDataSource.width;
          if (imgDataSource.url) url = imgDataSource.url;
        }
      }
      return (
        <Image
          source={{
            uri: url,
          }}
          style={[
            height > 0 && width > 0 ? { width: width, height: height } : styles,
          ]}
        />
      );
    },
  };

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
      <View style={styles.container}>
        <View
          style={[styles.headerContainer, { padding: IsAndroid() ? 0 : 10 }]}
        >
          <View style={styles.headingView}>
            <FontAwesomeIcon name="book" size={25} />
            <Text style={styles.headingText}>{data.bookName ?? ""}</Text>
          </View>
        </View>
        <Text
          style={[
            styles.unionLabel,
            {
              fontSize: IsAndroid() ? 14 : 20,
              paddingTop: IsAndroid() ? 0 : 10,
            },
          ]}
        >
          ページタイトル
        </Text>
        <Text
          style={[
            styles.unionLabel,
            {
              fontSize: IsAndroid() ? 14 : 20,
              paddingTop: IsAndroid() ? 0 : 10,
            },
          ]}
        >
          本文
        </Text>
        <View style={[styles.textContainer, { height: "50%" }]}>
          <ScrollView>
            {/* @ts-ignore としましょう */}
            <Markdown rules={rules}>{data.text ?? ""}</Markdown>
          </ScrollView>
        </View>

        <View style={[styles.buttonContainer, { padding: IsWeb() ? 25 : 0 }]}>
          <ModalCancelButton buttonText={"戻る"} onClick={() => onCancel()} />

          {isPresident(userInfo) || isAccounting(userInfo) ? (
            <ModalApprovalButton
              buttonText={status === "result" ? "登録" : "編集"}
              onClick={() => onSubmit()}
            />
          ) : (
            <></>
          )}
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 30,
  },
  headerContainer: {
    backgroundColor: "#fff",
    alignItems: "center",
    padding: 10,
    marginTop: -30,
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
  },
  headingText: {
    flex: 8,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    fontSize: 25,
  },
  textContainer: {
    flex: 5,
    backgroundColor: "#fff",
  },
  footerContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  formWrap: {
    marginTop: 0,
  },
  formItem: {
    backgroundColor: "#fff",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
  },
  toolContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  buttonContainer: {
    backgroundColor: "#fff",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: 25,
  },
  label: {
    fontSize: 20,
    width: "40%",
    paddingTop: 10,
  },
  unionLabel: {
    fontSize: 20,
    paddingTop: 10,
  },
  input: {
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  unionInput: {
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  able: {
    color: "black",
    backgroundColor: "white",
  },
  button: {
    marginTop: 32,
    paddingVertical: 16,
    width: 120,
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  inputTextArea: {
    width: "100%",
    height: "100%",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
});
