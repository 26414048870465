import { Toggle } from "components";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";

export const Header = ({
  onEditClick,
  onChangeValidity,
  validity,
  expiration,
}: {
  onEditClick: () => void;
  onChangeValidity: (e: number) => void;
  validity: number;
  expiration?: number;
}) => {
  return (
    <>
      <View style={styles.detailContainer}>
        <View style={styles.flexBasePosition}>
          <Text style={styles.subTitle}>有給有効期限</Text>
        </View>
        <View style={styles.flexBasePosition}>
          <View style={{ marginLeft: 10 }}>
            <Pressable
              style={[styles.button, { backgroundColor: "#7879F1" }]}
              onPress={onEditClick}
            >
              <Text style={styles.buttonText}>編集</Text>
            </Pressable>
          </View>
          <View style={{ marginLeft: 50 }}>
            <Text style={styles.subTitle}>{`付与日から${expiration}年間`}</Text>
          </View>
        </View>
      </View>
      <View style={styles.detailContainer}>
        <Text style={styles.subTitle}>有給休暇一覧</Text>
      </View>
      <View style={styles.detailContainer}>
        <Toggle
          selectionMode={validity}
          roundCorner={true}
          option1={"有効一覧"}
          option2={"取得一覧"}
          onSelectSwitch={(e: number) => onChangeValidity(e)}
          selectionColor={"black"}
        />
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  wrapper: { flexDirection: "row" },
  tableText: {
    fontSize: 14,
  },

  detailContainer: {
    marginBottom: 16,
  },
  button: {
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 20,
    elevation: 5,
    width: 100,
    height: 35,
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
  flexBasePosition: {
    flexDirection: "row",
    marginBottom: 10,
  },
  subTitle: {
    fontSize: 18,
  },
});
