import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import {
  AddButton,
  CalendarModal,
  CalendarTable,
  ExpenseLimitSettingTable,
  ExpenseTable,
  Loading,
  PageLoading,
  RequestModal,
  RequestTable,
  SwitchButtonList,
} from "components";
import { DashboardSwitchButtonList } from "constants/index";
import { RootStackParamList, RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import OcticonsIcon from "react-native-vector-icons/Octicons";
import {
  FetchDashBoardExpenseLimit,
  FetchDashBoardPaidVacation,
  FetchDashBoardUnReceivableExpenses,
  FetchDashboardCalendars,
  FetchDashboardExpenses,
  FetchDashboardRequests,
  FetchOverTime,
} from "server/collectionConnect";
import {
  CalendarCollection,
  CalendarModalStatus,
  ExpenseLimitSettingList,
  ExpenseList,
  RequestList,
  RequestModalStatus,
  RequestModalStatusType,
} from "types";
import { numberFormat } from "utils";

export function DashboardScreen({
  navigation,
}: RootStackScreenProps<"Dashboard">) {
  const { setAlert } = useContext(DialogContext);
  const {
    CalendarCollection,
    RequestCollection,
    UserCollection,
    PaidVacationCollection,
    ExpenseCollection,
    SiteCollection,
    ExpenseLimitSettingCollection,
    ExpenseItemCollection,
    onSnapShot,
  } = useContext(CollectionContext);
  const [editCalendar, setEditCalendar] = useState<CalendarModalStatus>(null);
  const [editRequest, setEditRequest] = useState<RequestModalStatus>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userInfo } = useContext(UserInfoContext);
  const [calendars, setCalendars] = useState<CalendarCollection[] | null>(null);
  const [expenses, setExpenses] = useState<ExpenseList[] | null>(null);
  const [requests, setRequests] = useState<RequestList[] | null>(null);
  const [expenseLimitSettings, setExpenseLimitSettings] = useState<
    ExpenseLimitSettingList[] | null
  >(null);
  const [overTime, setOverTime] = useState<string>("");
  const [unpaidAmount, setUnpaidAmount] = useState<number>(0);
  const [paidVacation, setPaidVacation] = useState<number>(0);
  const [selectedButton, setSelectedButton] = useState<string>("");
  const [dataLoad, setDataLoad] = useState<number>(10);

  useEffect(() => {
    // 初回読み込み時
    setIsLoading(true);
    selectionOnPress("calendar");
  }, []);

  useEffect(() => {
    if (userInfo === null) {
      setIsLoading(true);
      return;
    }

    if (
      selectedButton === "calendar" &&
      (CalendarCollection === null || SiteCollection === null)
    ) {
      setIsLoading(true);
      onSnapShot("calendar");
      onSnapShot("site");
      return;
    }
    if (
      selectedButton === "request" &&
      (RequestCollection === null || PaidVacationCollection === null)
    ) {
      setIsLoading(true);
      onSnapShot("request");
      onSnapShot("paidVacation");
      return;
    }
    if (
      selectedButton === "expense" &&
      (ExpenseCollection === null ||
        ExpenseItemCollection === null ||
        ExpenseLimitSettingCollection === null ||
        SiteCollection === null)
    ) {
      setIsLoading(true);
      onSnapShot("expense");
      onSnapShot("expenseItem");
      onSnapShot("expenseLimit");
      onSnapShot("site");
      return;
    }
    if (
      selectedButton === "expenseLimitSetting" &&
      (ExpenseCollection === null ||
        ExpenseItemCollection === null ||
        ExpenseLimitSettingCollection === null ||
        UserCollection === null)
    ) {
      setIsLoading(true);
      onSnapShot("user");
      onSnapShot("expenseItem");
      onSnapShot("expenseLimit");
      onSnapShot("expense");
      return;
    }
    // カレンダーコレクションとのバインディングによるデータ更新
    // タブ切り替えによるデータ更新
    if (selectedButton === "calendar") fetchCalendar();
    if (selectedButton === "request") {
      fetchRequest();
      fetchOverTime();
      fetchDashBoardPaidVacation();
    }
    if (selectedButton === "expense") {
      fetchDashBoardUnReceivableExpenses();
      fetchExpense();
    }
    if (selectedButton === "expenseLimitSetting") {
      fetchDashBoardExpenseLimit();
    }
    setIsLoading(false);
  }, [
    CalendarCollection,
    RequestCollection,
    ExpenseCollection,
    ExpenseItemCollection,
    ExpenseLimitSettingCollection,
    SiteCollection,
    dataLoad,
    selectedButton,
    userInfo,
  ]);

  /**
   * タブ切り替え時の処理
   *
   * @param dashboardButtonType
   */
  const selectionOnPress = (dashboardButtonType: string) => {
    setDataLoad(10);
    setSelectedButton(dashboardButtonType);
  };

  /**
   * カレンダーデータを取得し、ダッシュボードデータへ格納します
   */
  const fetchCalendar = () => {
    const result = FetchDashboardCalendars(
      userInfo,
      CalendarCollection,
      dataLoad
    );
    setCalendars(result);
  };

  /**
   * 申請データを取得し、ダッシュボードデータへ格納します
   */
  const fetchRequest = () => {
    const result = FetchDashboardRequests(
      userInfo,
      RequestCollection,
      dataLoad
    );
    setRequests(result);
  };

  /**
   * 経費データを取得し、ダッシュボードデータへ格納します
   */
  const fetchExpense = () => {
    const result = FetchDashboardExpenses(
      userInfo,
      ExpenseCollection,
      ExpenseLimitSettingCollection ?? [],
      ExpenseItemCollection ?? [],
      SiteCollection ?? [],
      dataLoad
    );
    setExpenses(result);
  };

  /**
   * 残業時間を取得します
   */
  const fetchOverTime = () => {
    const overTimeText = FetchOverTime(userInfo, RequestCollection);
    setOverTime(overTimeText);
  };

  /**
   * 有給日数を取得します
   */
  const fetchDashBoardPaidVacation = () => {
    const paidVacationCount = FetchDashBoardPaidVacation(
      userInfo,
      PaidVacationCollection,
      RequestCollection
    );
    setPaidVacation(paidVacationCount);
  };

  /**
   * 未受取経費を取得します
   */
  const fetchDashBoardUnReceivableExpenses = () => {
    const paidVacationCount = FetchDashBoardUnReceivableExpenses(
      userInfo,
      ExpenseCollection
    );
    setUnpaidAmount(paidVacationCount);
  };

  const fetchDashBoardExpenseLimit = () => {
    const result = FetchDashBoardExpenseLimit(
      userInfo,
      ExpenseCollection,
      ExpenseLimitSettingCollection,
      UserCollection ?? [],
      ExpenseItemCollection ?? [],
      dataLoad
    );
    setExpenseLimitSettings(result);
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={[styles.headerContainer]}>
        <SwitchButtonList
          onEditClick={key => selectionOnPress(key)}
          currentKey={selectedButton}
          buttonList={DashboardSwitchButtonList}
        />
        <Loading isLoading={isLoading} />

        {selectedButton == "calendar" && (
          <CalendarHeader navigation={navigation} />
        )}
        {selectedButton == "request" && (
          <RequestHeader
            navigation={navigation}
            overTime={overTime}
            paidVacation={paidVacation}
          />
        )}
        {selectedButton == "expense" && (
          <ExpenseHeader
            navigation={navigation}
            unpaidAmount={unpaidAmount}
            onClick={() => selectionOnPress("expenseLimitSetting")}
          />
        )}
        {selectedButton == "expenseLimitSetting" && (
          <ExpenseLimitSettingHeader />
        )}
      </View>
      <View style={[styles.listContainer]}>
        {selectedButton === "calendar" && (
          <CalendarTable
            tableList={calendars}
            onEditClick={() => {}}
            UserCollection={UserCollection ?? []}
          />
        )}

        {selectedButton === "expense" && (
          <ExpenseTable
            tableList={expenses}
            onEditClick={id =>
              setEditRequest({ isEdit: true, id: id, type: selectedButton })
            }
            UserCollection={UserCollection ?? []}
          />
        )}

        {selectedButton === "request" && (
          <RequestTable
            tableList={requests}
            onEditClick={id =>
              setEditRequest({ isEdit: true, id: id, type: selectedButton })
            }
            UserCollection={UserCollection ?? []}
          />
        )}

        {selectedButton === "expenseLimitSetting" && (
          <ExpenseLimitSettingTable
            tableList={expenseLimitSettings}
            onEditClick={() => {}}
          />
        )}
      </View>
      <View style={styles.moreContainer}>
        <Pressable onPress={() => setDataLoad(dataLoad + 10)}>
          <View style={styles.moreView}>
            <Text style={styles.moreViewText}>もっと見る</Text>
          </View>
        </Pressable>
      </View>
      {selectedButton == "calendar" && (
        <AddButton
          onClick={() => setEditCalendar({ id: null, isEdit: false })}
        />
      )}
      {(selectedButton === "expense" || selectedButton === "request") && (
        <AddButton
          onClick={() =>
            setEditRequest({
              id: null,
              isEdit: false,
              type: selectedButton as RequestModalStatusType,
            })
          }
        />
      )}
      <RequestModal
        modalStatus={editRequest}
        onCloseModal={() => setEditRequest(null)}
      />
      <CalendarModal
        modalStatus={editCalendar}
        onCloseModal={() => setEditCalendar(null)}
      />
    </>
  );
}

/**
 * カレンダータブ用のヘッダーを定義します
 * @param param0
 * @returns
 */
const CalendarHeader = ({
  navigation,
}: {
  navigation: NativeStackNavigationProp<
    RootStackParamList,
    "Dashboard",
    undefined
  >;
}) => {
  return (
    <View style={styles.headingView}>
      <Text style={styles.headingText}>直近の予定</Text>
      <Pressable
        onPress={() => {
          navigation.navigate("Calendar");
        }}
      >
        <OcticonsIcon style={styles.headingText} name="calendar" size={25} />
      </Pressable>
    </View>
  );
};

/**
 * 勤怠申請タブ用のヘッダーを定義します
 * @param param0
 * @returns
 */
const RequestHeader = ({
  navigation,
  overTime,
  paidVacation,
}: {
  navigation: NativeStackNavigationProp<
    RootStackParamList,
    "Dashboard",
    undefined
  >;
  overTime: string;
  paidVacation: number;
}) => {
  return (
    <>
      <View style={styles.topView}>
        <Text style={[styles.topViewTitle]}>残業時間</Text>
        <Text style={[styles.topViewTitle]}>有給日数(日)</Text>
      </View>
      <View style={styles.topView}>
        <Pressable style={[styles.topViewValue]}>
          <Text style={styles.topViewValueText}>{overTime}</Text>
        </Pressable>
        <Pressable style={[styles.topViewValue]}>
          <Text style={styles.topViewValueText}>{paidVacation}</Text>
        </Pressable>
      </View>
      <View style={styles.headingView}>
        <Text style={styles.headingText}>勤怠申請</Text>
        <Pressable
          onPress={() => {
            navigation.navigate("Request", {
              target: "request",
            });
          }}
        >
          <FontAwesomeIcon
            style={styles.headingText}
            name="pencil-square-o"
            size={25}
          />
        </Pressable>
      </View>
    </>
  );
};

/**
 * 経費申請タブ用のヘッダーを定義します
 * @param param0
 * @returns
 */
const ExpenseHeader = ({
  navigation,
  unpaidAmount,
  onClick,
}: {
  navigation: NativeStackNavigationProp<
    RootStackParamList,
    "Dashboard",
    undefined
  >;
  unpaidAmount: number;
  onClick: () => void;
}) => {
  return (
    <>
      <View style={styles.topView}>
        <Text style={[styles.topViewTitle]}>未支払金額</Text>
        <Text style={[styles.topViewTitle]}>経費枠残</Text>
      </View>
      <View style={styles.topView}>
        <Pressable style={[styles.topViewValue]}>
          <Text style={styles.topViewValueText}>
            {numberFormat(unpaidAmount, "\xA5")}
          </Text>
        </Pressable>
        <Pressable style={[styles.topViewValue]}>
          <Text style={styles.topViewValueText}>調整中</Text>
        </Pressable>
      </View>
      <View style={styles.topView}>
        <Pressable style={[styles.topViewTitle]}></Pressable>
        <Pressable style={[styles.topViewTitle]} onPress={onClick}>
          <Text style={[styles.topViewTitle, { fontSize: 12 }]}>詳細へ⇒</Text>
        </Pressable>
      </View>
      <View style={[styles.headingView]}>
        <Text style={styles.headingText}>経費申請</Text>
        <Pressable
          onPress={() => {
            navigation.navigate("Request", {
              target: "expense",
            });
          }}
        >
          <FontAwesomeIcon
            style={styles.headingText}
            name="pencil-square-o"
            size={25}
          />
        </Pressable>
      </View>
    </>
  );
};

/**
 * 経費枠タブ用のヘッダーを定義します
 * @param param0
 * @returns
 */
const ExpenseLimitSettingHeader = () => {
  return (
    <>
      <View style={styles.topView}>
        <Text style={[styles.topViewTitle]}>経費枠残</Text>
      </View>
      <View style={styles.topView}>
        <Pressable style={[styles.topViewValue, { width: 200 }]}>
          <Text style={styles.topViewValueText}>調整中</Text>
        </Pressable>
      </View>
      <View style={[styles.headingView]}>
        <Text style={styles.headingText}>経費枠詳細</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: "#fff",
    padding: 10,
  },
  listContainer: {
    flex: 4,
    backgroundColor: "#fff",
  },
  moreContainer: {
    flex: 1,
    paddingTop: 10,
    backgroundColor: "#fff",
  },
  topView: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  headingView: {
    flexDirection: "row",
    alignItems: "center",
  },
  topViewTitle: {
    top: 0,
    left: 0,
    right: 0,
    alignSelf: "center",
    borderRadius: 20,
    width: 120,
    margin: 5,
    textAlign: "center",
  },
  topViewValue: {
    top: 0,
    left: 0,
    right: 0,
    alignSelf: "center",
    borderRadius: 20,
    elevation: 3,
    width: 120,
    height: 30,
    margin: 5,
    textAlign: "center",
    backgroundColor: "#C0C0C0",
  },
  topViewValueText: {
    textAlign: "center",
    color: "black",
    fontWeight: "900",
    lineHeight: 30,
    fontSize: 18,
  },
  headingText: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    fontSize: 18,
  },
  moreView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  moreViewText: {
    color: "#1A0DAB",
  },
});
