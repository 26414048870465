import dayjs from "dayjs";
import { ExpenseCollection, UserInfo } from "types";

/**
 * 未支払い金額の合計値を集計して返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param expenses collectionContextから取得した経費申請情報
 * @returns number
 */
export const FetchDashBoardUnReceivableExpenses = (
  userInfo: UserInfo | null,
  expenses: ExpenseCollection[] | null
): number => {
  const today = dayjs(new Date()).toDate();
  const userId = userInfo?.user.id;

  if (expenses === null) return 0;

  const targetExpense = expenses
    .filter(expense => expense.userId === userId)
    .filter(expense => expense.paymentStatus === "unpaid")
    .filter(expense => expense.status === "approval");

  const expenseList = targetExpense.map(expense => expense.totalAmount ?? 0);

  const total = expenseList.reduce(function (sum, element) {
    return sum + element;
  }, 0);
  return total;
};
