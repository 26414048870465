import React, { ReactNode, createContext, useState } from "react";
import { Alert, Platform } from "react-native";
import { AlertInfo, ConfirmInfo } from "types";

/** モーダルを表示するためのプロバイダーです。 */
export const DialogContext = createContext(
  {} as {
    alert: AlertInfo;
    setAlert: (data: AlertInfo) => void;
    confirm: ConfirmInfo;
    setConfirm: React.Dispatch<React.SetStateAction<ConfirmInfo>>;
  }
);
export const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [alert, setAlert2] = useState<AlertInfo>({ isAlert: false, msg: "" });
  const [confirm, setConfirm] = useState<ConfirmInfo>({
    isConfirm: false,
    msg: "",
  });
  const setAlert = (data: AlertInfo) => {
    if (Platform.OS !== "web") {
      data.afterExec && data.afterExec();
      Alert.alert("", data.msg, [
        {
          text: data.confTxt ? data.confTxt : "確認",
        },
      ]);
    } else {
      setAlert2(data);
    }
  };
  return (
    <DialogContext.Provider value={{ alert, setAlert, confirm, setConfirm }}>
      {children}
    </DialogContext.Provider>
  );
};
