import dayjs from "dayjs";
import { RequestCollection, RequestList, UserInfo } from "types";
import { DescSort } from "utils";

/**
 * 整形したダッシュボード用勤怠申請情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param requests collectionContextから取得した申請情報
 * @param dataLoadType 読み込み予定件数
 * @returns DashboardsDataType[]
 */
export const FetchDashboardRequests = (
  userInfo: UserInfo | null,
  requests: RequestCollection[] | null,
  dataLoadType: number
): RequestList[] => {
  if (requests === null) return [] as RequestList[];

  const requestList = requests
    .filter(request => request.userId === userInfo?.user.id)
    .sort((a, b) =>
      DescSort(
        dayjs(a.updateAt?.toDate()).toDate(),
        dayjs(b.updateAt?.toDate()).toDate()
      )
    )
    .slice(0, dataLoadType);

  return requestList.map(elem => {
    const sortHistory = elem.requestHistory.sort((a, b) =>
      DescSort(a.updateAt, b.updateAt)
    );

    const approveAt = sortHistory.filter(f => f.status === "approval").shift();
    const rejectAt = sortHistory.filter(f => f.status === "reject").shift();

    return {
      id: elem.id,
      status: elem.status,
      requestType: elem.requestType,
      requestAt: elem.requestAt,
      acquisitionStartAt: elem.acquisitionStartAt,
      approveAt: approveAt === undefined ? null : approveAt.updateAt,
      rejectAt: rejectAt === undefined ? null : rejectAt.updateAt,
      updateUser: elem.updateUser,
    } as RequestList;
  });
};
