import { ModalCloseButton, UserForm } from "components";
import { userAuthority } from "constants/index";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Item } from "react-native-picker-select";
import { modalStyles } from "styles";
import { UserModalStatus } from "types";
import { ModalLibrary, allAuthorities } from "utils";

type Props = {
  modalStatus: UserModalStatus;
  onCloseModal: () => void;
  maxSortNo?: number;
};

export function UserModal({ modalStatus, onCloseModal, maxSortNo }: Props) {
  const [authoritySelect, setAuthoritySelect] = useState<Item[]>([]);

  const createAllAuthoritiesSelector = (): void => {
    const authorities = allAuthorities().map((authorityId: string) => {
      const authoritySelects: Item = {
        key: authorityId,
        value: authorityId,
        label: userAuthority[authorityId].text,
      };
      return authoritySelects;
    });
    setAuthoritySelect(authorities);
  };

  useEffect(() => {
    createAllAuthoritiesSelector();
  }, []);

  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={modalStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />

          <UserForm
            hideModal={onCloseModal}
            modalStatus={modalStatus}
            maxSortNo={maxSortNo}
            authoritySelect={authoritySelect}
          />
        </View>
      </ModalLibrary>
    </>
  );
}
