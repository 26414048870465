import { SiteCollection, SiteList, UserInfo } from "types";
import { AscSort } from "utils";
import { GetSortStatusNumber } from "./utils/CheckStatus";

/**
 * 整形した現場情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param site collectionContextから取得した現場情報
 * @returns SiteList
 */
export const FetchSites = (
  userInfo: UserInfo | null,
  site: SiteCollection[] | null
): SiteList[] => {
  if (site === null) return [] as SiteList[];

  const targetSites = site
    .filter(f => f.deleted === false)
    .sort((a, b) => {
      if (a.progressStatus !== b.progressStatus)
        return AscSort(
          GetSortStatusNumber(a.progressStatus),
          GetSortStatusNumber(b.progressStatus)
        );
      return AscSort(a.matterNumber, b.matterNumber);
    });

  return targetSites.map(
    m =>
      ({
        id: m.id,
        progressStatus: m.progressStatus,
        name: m.name,
        matterNumber: m.matterNumber,
        agreementNumber: m.agreementNumber,
        updateAt: m.updateAt,
      } as SiteList)
  );
};
