import { AttendanceCollection } from "types";

/**
 *　指定の勤怠情報を返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param targetId 指定のID
 * @param attendances collectionContextから取得した勤怠情報
 * @returns
 */
export const FetchLatestAttendanceById = (
  targetId: string,
  attendance: AttendanceCollection[] | null
): AttendanceCollection | null => {
  if (attendance === null) return null;

  const result = attendance.filter(f => f.id === targetId).shift();

  return result ?? null;
};
