import dayjs from "dayjs";
import React, { useState } from "react";
import { DimensionValue, Pressable, StyleSheet, Text, View } from "react-native";
import { CalendarData, HolidayCollection, User } from "types";
import { IsWeb } from "utils";

type Props = {
  propsDate: dayjs.Dayjs;
  user: User;
  schedules: CalendarData[];
  isHoliday: boolean | undefined;
  switchOverlap: Function;
  holidays: HolidayCollection[];
  DailyUserClick?: (date: dayjs.Dayjs, user: User) => void;
  hoverLeft?: boolean;
  hoverTop?: boolean;
};

/** 日付のマスを作成します */
export function DayElement({
  propsDate,
  user,
  schedules,
  isHoliday,
  switchOverlap,
  holidays,
  DailyUserClick,
  hoverLeft,
  hoverTop,
}: Props) {
  const [isHandleHover, setIsHandleHover] = useState<boolean>(false);
  const [isOverlap, setIsOverlap] = useState<boolean>(false);

  const handleHoverInEvent = (): void => {
    setIsHandleHover(true);
    setIsOverlap(true);
    switchOverlap(true);
  };

  const handleHoverOutEvent = (): void => {
    setIsHandleHover(false);
    setIsOverlap(false);
    switchOverlap(false);
  };

  if (IsWeb()) {
    return (
      <View style={isOverlap ? { zIndex: 10 } : { zIndex: -10 }}>
        <Pressable
          onPress={() => DailyUserClick && DailyUserClick(propsDate, user)}
          onHoverIn={() => {
            handleHoverInEvent();
          }}
          onHoverOut={() => {
            handleHoverOutEvent();
          }}
        >
          <View
            style={[
              styles.day,
              schedules.filter(f => !f.isHoliday).length > 0
                ? styles.active
                : styles.holiday,
            ]}
          >
            {isHandleHover ? (
              <View
                style={[
                  styles.hover,
                  {
                    right: hoverLeft ? 10 : undefined,
                    left: !hoverLeft ? 10 : undefined,
                    bottom: hoverTop ? 10 : undefined,
                    top: !hoverTop ? 10 : undefined,
                  },
                ]}
              >
                <View style={styles.wrap}>
                  {holidays
                    .filter(
                      f =>
                        dayjs(f.targetAt.toDate()).format("YYYY-MM-DD") ===
                        propsDate.format("YYYY-MM-DD")
                    )
                    .map((h, i) => (
                      <View key={i}>
                        <Text>タイトル: {h.title}</Text>
                        <Text>種類: 休み</Text>
                        <Text> 終日 </Text>
                      </View>
                    ))}
                  {schedules.map((schedule: CalendarData, i: number) => {
                    return (
                      <View key={i}>
                        <Text>タイトル: {schedule.title}</Text>
                        <Text>
                          種類: {schedule.isHoliday ? "休み" : "予定"}
                        </Text>
                        {schedule.paidVacationType === 'allDay' ? (
                          <Text>終日</Text>
                        ) : schedule.paidVacationType === 'morning' ? (
                          <Text>午前</Text>
                        ) : schedule.paidVacationType === 'afternoon' ? (
                          <Text>午後</Text>
                        ) : schedule.isAllDay ? (
                          <Text>終日</Text>
                        ) : (
                          <Text>
                            {dayjs(schedule.startDate.toDate()).format("H:mm")}~
                            {dayjs(schedule.endDate.toDate()).format("H:mm")}
                          </Text>
                        )}
                      </View>
                    );
                  })}
                </View>
              </View>
            ) : (
              <View></View>
            )}
          </View>
        </Pressable>
      </View>
    );
  }

  return (
    <Pressable
      onPress={() => DailyUserClick && DailyUserClick(propsDate, user)}
      style={[
        styles.flexItem,
        schedules.filter(f => !f.isHoliday).length > 0
          ? styles.active
          : styles.holiday,
      ]}
    />
  );
}
const styles = StyleSheet.create({
  day: {
    borderWidth: 1,
    height: 40,
    width: 56,
  },
  hover: {
    position: "absolute",
    // bottom: 10,
    borderWidth: 1,
    backgroundColor: "lightgray",
  },
  wrap: {
    width: "40vh" as DimensionValue ,
    padding: 26,
  },
  active: {
    backgroundColor: "#5bb0e9",
  },
  holiday: {
    backgroundColor: "#ff7f50",
  },
  flexItem: {
    width: "12%",
    height: 32,
    borderWidth: 1,
    borderColor: "gray",
  },
});
