import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  Dialog,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
} from "components";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import {
  CreateNoteBook,
  FetchNoteBook,
  UpdateNoteBook,
  UpdateNoteBookSort,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { NoteBookCollection, NoteBookData, WikiModalStatus } from "types";
type Props = {
  hideModal: Function;
  modalStatus: WikiModalStatus;
};

export function NoteBookForm({ hideModal, modalStatus }: Props) {
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { NoteBookCollection, clearSnapShot, onSnapShot } =
    useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noteBook, setNoteBook] = useState<NoteBookData>(defaultData());

  useEffect(() => {
    if (modalStatus === null) {
      setNoteBook(defaultData());
      return;
    }
    const result = FetchNoteBook(NoteBookCollection, modalStatus.id ?? "");
    if (result === null) {
      setNoteBook(defaultData());
      return;
    }
    setNoteBook(result);
  }, [modalStatus]);

  const validationCheck = (): boolean => {
    if (!noteBook.bookName) {
      setAlert({
        isAlert: true,
        msg: "ノートブックタイトルを入力してください",
      });
      return false;
    }
    if (!noteBook.sort) {
      setAlert({ isAlert: true, msg: "ブックの並びを入力してください" });
      return false;
    }
    if (!Number.isInteger(noteBook.sort)) {
      setAlert({ isAlert: true, msg: "ブックの並びは数値で入力してください" });
      return false;
    }
    return true;
  };

  const createNoteBook = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);
    try {
      clearSnapShot("notebook");
      const sortNumber = updateSortNumber(NoteBookCollection ?? [], noteBook);
      noteBook.sort = sortNumber;
      const result = await CreateNoteBook(
        userInfo,
        NoteBookCollection,
        noteBook
      );
      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
        return;
      }

      const result2 = await UpdateNoteBookSort();
      if (result2.isCheck) {
        setAlert({
          isAlert: true,
          msg: result2.message,
          confTxt: "OK",
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
            hideModal();
          },
        });
      } else {
        setAlert({
          isAlert: true,
          msg: result2.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
      }
    } catch {
      setAlert({ isAlert: true, msg: "登録に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const updateNoteBook = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);
    try {
      clearSnapShot("notebook");
      const sortNumber = updateSortNumber(NoteBookCollection ?? [], noteBook);
      noteBook.sort = sortNumber;

      const result = await UpdateNoteBook(
        userInfo,
        NoteBookCollection,
        noteBook
      );

      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
        return;
      }

      const result2 = await UpdateNoteBookSort();
      if (result2.isCheck) {
        setAlert({
          isAlert: true,
          msg: result2.message,
          confTxt: "OK",
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
            hideModal();
          },
        });
      } else {
        setAlert({
          isAlert: true,
          msg: result2.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
      }
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Loading isLoading={isLoading} />
        <Text style={modalFormStyles.headingText}>
          {modalStatus?.isEdit ? "ノートブック編集" : "ノートブック登録"}
        </Text>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={modalFormStyles.scrollContainer}
        >
          <View style={[formWrap(windowWidth).formWrap]}>
            <InputForm
              label="ノートブックタイトル"
              value={noteBook.bookName}
              onChange={e => setNoteBook({ ...noteBook, bookName: e })}
              disabled={true}
            />
            <InputForm
              label="ブックの並び"
              value={noteBook.sort === 0 ? "" : noteBook.sort}
              onChange={e =>
                setNoteBook({
                  ...noteBook,
                  sort: isNaN(parseInt(e)) ? 0 : parseInt(e),
                })
              }
              disabled={true}
            />

            <View style={modalFormStyles.formItem}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => hideModal()}
              />

              {modalStatus?.isEdit ?? false ? (
                <ModalApprovalButton
                  buttonText="更新"
                  onClick={() => updateNoteBook()}
                />
              ) : (
                <ModalApprovalButton
                  buttonText="登録"
                  onClick={() => createNoteBook()}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </View>
      <Dialog />
    </>
  );
}

/** Wikiページ登録の初期値を登録します */
const defaultData = (): NoteBookData => {
  return {
    id: "",
    bookName: "",
    sort: 1,
    deleted: false,
  };
};

const updateSortNumber = (
  NoteBookCollection: NoteBookCollection[],
  notebookData: NoteBookData
) => {
  const target = NoteBookCollection?.filter(
    f => f.id === notebookData.id
  ).shift();
  if (target === undefined) return notebookData.sort - 0.1;
  if ((target.sort ?? 0) < notebookData.sort) return notebookData.sort + 0.1;
  return notebookData.sort - 0.1;
};
