import { SiteCollection, SiteData } from "types";

/**
 * 指定した現場情報を返却します
 * @param site collectionContextから取得した現場情報
 * @param targetId 指定の現場ID
 * @returns SiteData
 */
export const FetchSite = (
  site: SiteCollection[] | null,
  targetId: string
): SiteData | null => {
  if (site === null) return null;

  const targetSite = site.filter(f => f.id === targetId).shift();
  if (targetSite === undefined) return null;

  return {
    ...targetSite,
  } as SiteData;
};
