export const GetSortStatusNumber = (status: string): number => {
  switch (status) {
    case "applying": // 申請中
      return 1;
    case "approval": // 承認済み
      return 2;
    case "paid": // 支払済み
      return 3;
    case "reject": // 否決
      return 4;
    case "withdrawal": // 取下
      return 5;
    case "received": // 受領済み
      return 6;
    default:
      return 99;
  }
};
