import { DialogContext, UserInfoContext } from "GlobalContext";
import {
  AddButton,
  DeleteButton,
  NoteBookModal,
  PageLoading,
} from "components";
import { BoxList } from "components/molecules/BoxList";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, View } from "react-native";

import { CollectionContext } from "GlobalContext";
import { RootStackScreenProps } from "navigations";
import { FetchNoteBooks } from "server/collectionConnect";
import { pageStyle } from "styles";
import {
  IconList,
  NoteBookDeleteList,
  WikiDeleteModalStatus,
  WikiModalStatus,
} from "types";
import { IsAndroid, isPresidentOrAccounting } from "utils";

export function WikiScreen({ navigation }: RootStackScreenProps<"Wiki">) {
  const { userInfo } = useContext(UserInfoContext);
  const { NoteBookCollection, onSnapShot } = useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<WikiModalStatus>(null);
  const [isDeleteModal, setIsDeleteModal] =
    useState<WikiDeleteModalStatus>(null);
  const [deleteWikiList, setDeleteWikiList] = useState<NoteBookDeleteList[]>(
    []
  );
  const [noteBooks, setNoteBooks] = useState<IconList[] | null>(null);
  useEffect(() => {
    if (NoteBookCollection === null) {
      setIsLoading(true);
      onSnapShot("notebook");
      return;
    }
    fetchNoteBooks();
    setIsLoading(false);
  }, [NoteBookCollection]);

  /** 削除チェックをつけた時の処理 */
  const updateDeletingWiki = (id: string) => {
    const target = deleteWikiList.filter(f => f.id === id).shift();
    if (target === undefined) {
      setDeleteWikiList([...deleteWikiList, { id: id, isDelete: true }]);
    } else {
      const targets = deleteWikiList.filter(f => f.id !== id);
      setDeleteWikiList(targets);
    }
  };

  /** 削除処理中に削除ボタンを押した時の処理 */
  const confirmDeleteButton = () => {
    if (deleteWikiList.length === 0) {
      setDeleting(false);
    } else {
      setIsDeleteModal({
        id: "",
        isEdit: true,
        ids: deleteWikiList.map(m => m.id),
      });
    }
  };

  /** ノートブック情報の取得 */
  const fetchNoteBooks = () => {
    try {
      var result = FetchNoteBooks(NoteBookCollection);
      setNoteBooks(result);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={pageStyle.container}>
        <View style={[pageStyle.contents_area, IsAndroid() && { height: 500 }]}>
          <ScrollView style={pageStyle.scroll_container}>
            <BoxList
              onClick={id =>
                navigation.navigate("NoteBookList", {
                  id: id,
                })
              }
              deleting={deleting}
              onDeleteClick={id => updateDeletingWiki(id)}
              iconList={noteBooks}
              deleteList={deleteWikiList}
            />
          </ScrollView>
        </View>
      </View>
      {isPresidentOrAccounting(userInfo) && (
        <>
          <DeleteButton
            onClick={() => setDeleting(true)}
            onDeleteClick={confirmDeleteButton}
            deleting={deleting}
          />
          <AddButton
            onClick={() => setIsEditModal({ id: null, isEdit: false })}
          />
        </>
      )}
      <NoteBookModal
        editStatus={isEditModal}
        deleteStatus={isDeleteModal}
        onCloseModal={() => {
          setIsEditModal(null);
          setIsDeleteModal(null);
          setDeleteWikiList([]);
          setDeleting(false);
        }}
      />
    </>
  );
}
