import firebase from "firebase/compat/app";
import { CalendarCollection } from "types";

/**
 * calendar Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const CalendarOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<CalendarCollection[] | null>>,
  startDate: Date,
  endDate: Date
) => {
  return firebase
    .firestore()
    .collection("calendar")
    .where("startDate", ">=", startDate)
    .where("startDate", "<=", endDate)
    .onSnapshot(
      async docs => {
        const collectionList = await Promise.all(
          docs.docs.map(async elem => {
            const siteRef = elem.data().siteRef;

            if (siteRef) {
              const siteData = await siteRef.get();
              return {
                id: elem.id,
                ...elem.data(),
                site: {
                  id: siteData.id,
                  ...siteData.data(),
                },
              } as CalendarCollection;
            }

            return {
              id: elem.id,
              ...elem.data(),
            } as CalendarCollection;
          })
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};
