import dayjs from "dayjs";
import { HolidayCollection } from "types";

/**
 *　指定日が休日かどうかを返却します
 * @param holidays
 * @param targetDay
 * @returns
 */
export const GetIsCurrentDateHoliday = (
  holidays: HolidayCollection[],
  targetDay: Date
): boolean => {
  const holiday = holidays
    .filter(
      f =>
        dayjs(f.targetAt.toDate()).format("YYYY-MM-DD") ===
        dayjs(targetDay).format("YYYY-MM-DD")
    )
    .shift();
  return holiday !== undefined;
};
