import { ModalCloseButton, NoteBookDeleteForm, NoteBookForm } from "components";
import React from "react";
import { View } from "react-native";
import { modalStyles } from "styles";
import { WikiDeleteModalStatus, WikiModalStatus } from "types";
import { ModalLibrary } from "utils";

type Props = {
  editStatus: WikiModalStatus;
  deleteStatus: WikiDeleteModalStatus;
  onCloseModal: () => void;
};

export function NoteBookModal({
  editStatus,
  deleteStatus,
  onCloseModal,
}: Props) {
  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={editStatus !== null || deleteStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />

          {editStatus !== null && (
            <NoteBookForm hideModal={onCloseModal} modalStatus={editStatus} />
          )}

          {deleteStatus !== null && (
            <NoteBookDeleteForm
              hideModal={onCloseModal}
              modalStatus={deleteStatus}
            />
          )}
        </View>
      </ModalLibrary>
    </>
  );
}
