import {
  CreateLeftCol,
  CreateRightCol,
  CreateRow,
  MobileTableEditButton,
  MobileTableRequestStatusText,
} from "components";
import { requestType } from "constants/index";
import dayjs from "dayjs";
import React from "react";
import { ScrollView, Text, View } from "react-native";
import { mobileTableStyles } from "styles";
import { RequestList } from "types";

export const MobileRequestTable = ({
  tableList,
  onEditClick,
}: {
  tableList: RequestList[];
  onEditClick: (id: string) => void;
}) => {
  return (
    <ScrollView
      style={mobileTableStyles.scroll_view_style}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <View style={mobileTableStyles.list_line_view} />
        {tableList?.map((data, i) => (
          <View key={i} style={mobileTableStyles.list_line_view}>
            <View style={mobileTableStyles.contents_container}>
              <CreateRow>
                <CreateLeftCol>
                  <MobileTableEditButton
                    onEditClick={() => onEditClick(data.id)}
                    buttonText="詳細確認"
                  />
                </CreateLeftCol>
                <CreateRightCol>
                  <MobileTableRequestStatusText status={data.status} />
                </CreateRightCol>
              </CreateRow>
              <CreateRow>
                <CreateLeftCol>
                  <Text style={mobileTableStyles.base_text}>
                    {`種別：${requestType[data.requestType ?? ""]?.text ?? ""}`}
                  </Text>
                </CreateLeftCol>
                <CreateRightCol>
                  <Text style={mobileTableStyles.base_text}>{`申請日：${dayjs(
                    data.requestAt.toDate()
                  ).format("YYYY/MM/DD")}`}</Text>
                </CreateRightCol>
              </CreateRow>
            </View>
          </View>
        ))}
      </View>
    </ScrollView>
  );
};
