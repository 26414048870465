import { NoteBookCollection, NoteBookDeleteListType } from "types";

/**
 * 指定したWikiページ情報を返却します
 * @param noteBook collectionContextから取得したWikiページ情報
 * @param targetId 指定のWikiページID
 * @returns NoteBookData
 */ export const FetchNoteBookDeleteTargets = (
  NoteBookCollection: NoteBookCollection[] | null,
  targetIds: string[]
): NoteBookDeleteListType[] | null => {
  if (NoteBookCollection === null) return null;

  const targets = NoteBookCollection.filter(f =>
    targetIds.find(ff => ff === f.id)
  );
  return targets.map(m => ({
    id: m.id,
    bookName: m.bookName ?? "",
    updateAt: m.updateAt,
  }));
};
