import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  Dialog,
  Loading,
  ModalCancelButton,
  ModalDeleteButton,
  NoteBookList,
} from "components";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import {
  DeleteNoteBookPage,
  FetchNoteBookPageDeleteTargets,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { NoteBookDeleteListType, WikiDeleteModalStatus } from "types";
type Props = {
  hideModal: Function;
  modalStatus: WikiDeleteModalStatus;
  onDelete: () => void;
};

export function NoteBookPageDeleteForm({
  hideModal,
  modalStatus,
  onDelete,
}: Props) {
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { setAlert } = useContext(DialogContext);
  const { NoteBookCollection } = useContext(CollectionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noteBooks, setNoteBooks] = useState<NoteBookDeleteListType[]>(
    defaultData()
  );

  useEffect(() => {
    if (modalStatus === null) {
      setNoteBooks(defaultData());
      return;
    }
    const result = FetchNoteBookPageDeleteTargets(
      NoteBookCollection,
      modalStatus.id ?? "",
      modalStatus.ids
    );
    if (result === null) {
      setNoteBooks(defaultData());
      return;
    }
    setNoteBooks(result);
  }, [modalStatus]);

  const deleteNoteBook = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const result = await DeleteNoteBookPage(
        userInfo,
        modalStatus?.ids ?? [],
        modalStatus?.id ?? ""
      );
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            onDelete();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "削除に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={styles.headingText}>削除するページ</Text>
        <Loading isLoading={isLoading} />

        <ScrollView
          showsVerticalScrollIndicator={false}
          style={modalFormStyles.scrollContainer}
        >
          <View style={[formWrap(windowWidth).formWrap]}>
            <NoteBookList noteBooksData={noteBooks} />
            <View style={modalFormStyles.formItem}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => hideModal()}
              />

              <ModalDeleteButton
                buttonText="削除"
                onClick={() => deleteNoteBook()}
                widths="40%"
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <Dialog />
    </>
  );
}

/** Wikiページ削除一覧の初期値を登録します */
const defaultData = (): NoteBookDeleteListType[] => {
  return [
    {
      id: "",
      bookName: "",
      updateAt: null,
    },
  ];
};

const styles = StyleSheet.create({
  container: {
    flex: 3,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    marginTop: 30,
  },
  headingText: {
    fontSize: 32,
  },
  formWrap: {
    marginTop: "10%",
  },
  formItem: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footerText: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 15,
    lineHeight: 30,
    color: "red",
  },
});
