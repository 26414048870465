import firebase from "firebase/compat/app";
import { ImageDataSources } from "types";

type Props = {
  docId: string;
  imgDataSources: ImageDataSources;
  folderName: string;
  functionsName?: string;
  updateImageUrl?: Function;
};

export const imagePutStrage = async ({
  docId,
  imgDataSources,
  folderName,
  functionsName,
  updateImageUrl,
}: Props) => {
  const storageRef = firebase.storage().ref(folderName + "/" + docId);

  // 新規で画像を登録
  await Promise.all(
    imgDataSources.data.map(async img => {
      try {
        // Firebaseに登録されている画像はスキップする
        if (img.url.indexOf("firebasestorage") > 0) {
          return;
        }

        const localUri = await fetch(img.url);
        const localBlob = await localUri.blob();
        const storageRef = firebase
          .storage()
          .ref(folderName + "/" + docId)
          .child(img.title);

        const putTask = storageRef.put(localBlob);

        putTask.on(
          "state_changed",
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error);
          },
          () => {
            putTask.snapshot.ref.getDownloadURL().then(async downloadURL => {
              if (functionsName) {
                await firebase.functions().httpsCallable(functionsName)({
                  imageData: {
                    docId: docId,
                    id: img.id,
                    title: img.title,
                    url: downloadURL,
                  },
                });
              }

              if (updateImageUrl) {
                updateImageUrl(downloadURL);
              }
            });
          }
        );
      } catch (e) {
        console.log(e);
      }
    })
  );
};

export const NoteBookImagePutStrage = async (
  docId: string,
  text: string,
  imgDataSources: ImageDataSources,
  folderName: string,
  docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
) => {
  const doc = await docRef.get();
  // 新規で画像を登録
  var convertedText = text;
  await Promise.all(
    imgDataSources.data.map(async img => {
      try {
        // Firebaseに登録されている画像はスキップする
        if (img.url.indexOf("firebasestorage") > 0) {
          return;
        }

        const localUri = await fetch(img.url);
        const localBlob = await localUri.blob();
        const storageRef = firebase
          .storage()
          .ref(folderName + "/" + docId)
          .child(img.title);

        const putTask = storageRef.put(localBlob);

        putTask.on(
          "state_changed",
          snapshot => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            console.log(error);
          },
          async () => {
            await putTask.snapshot.ref
              .getDownloadURL()
              .then(async downloadURL => {
                convertedText = convertText(
                  convertedText,
                  img.docId,
                  img.url,
                  downloadURL
                );
                await doc.ref.update({
                  ...doc.data(),
                  text: convertedText,
                });
              });
          }
        );
      } catch (e) {}
    })
  );
  return await convertedText;
};

const convertText = (
  text: string,
  docId: string,
  before: string,
  after: string
) => {
  const result = text.replace(
    "![" + docId + "](" + before + ")",
    "![" + docId + "](" + after + ")"
  );
  return result;
};
