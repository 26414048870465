import { UserCollection, UserList } from "types";
import { ConvertTimeStampFromDate } from "utils";
import { GetUserAuthority } from "./utils/GetUserAuthority";
/**
 *　整形したユーザー情報を返却します
 * @param userInfo userContextから取得した情報
 * @param users collectionContextから取得したユーザー情報
 * @returns
 */
export const FetchUsers = (users: UserCollection[] | null): UserList[] => {
  if (users === null) return [];

  // 並べ替え
  users.sort((a, b) => {
    // 並び順の昇順で並び替え
    if (a.sort !== b.sort) return a.sort - b.sort;

    return 0;
  });

  return users.map(
    user =>
      ({
        id: user.id,
        sort: user.sort ?? 0,
        authorityTitle: GetUserAuthority(user.authorityId).text,
        name: user.name,
        entryAt: user.entryAt,
        signedAt: ConvertTimeStampFromDate(new Date(user.signedAt ?? "")),
        retirementAt: user.retirementAt ?? null,
      } as UserList)
  );
};
