import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  PaidVacationCollection,
  PaidVacationType,
  RequestCollection,
  RequestData,
  RequestStatusList,
  RequestType,
} from "types";
import {
  GetCanUsePaidVacation,
  HasExistingPaidVacationRequest,
} from "../paidVacation/utils";

interface Request {
  userId: string | undefined;
  requestType: RequestType;
  requestAuthorityId: string;
  requestAt: Date;
  status: RequestStatusList;
  description: string;
  memo: string;
  createAt: Date;
  createUser: string | undefined;
  updateAt: Date;
  updateUser: string | undefined;
  isAttendanceCancel: boolean;
  correctionTargetAttendanceId: string;
  paidVacationId?: string;
  acquisitionStartAt?: Date;
  acquisitionEndAt?: Date;
  paidVacationType?: PaidVacationType;
}

/**
 * 各種申請の新規作成の書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param paidVacations collectionContextから取得した有給管理情報
 * @returns　CreateCheckResult
 */
export const CreateRequest = async (
  request: RequestData,
  paidVacations: PaidVacationCollection[] | null,
  requests: RequestCollection[] | null
): Promise<CreateCheckResult> => {
  if (requests === null || paidVacations === null)
    return {
      isCheck: false,
      message: "取得可能な有給がありません",
    };

  const date = new Date();
  try {
    var requestData: Request = {
      userId: request.userId,
      requestType: request.requestType,
      requestAuthorityId: request.requestAuthorityId,
      requestAt: date,
      acquisitionStartAt: request.acquisitionStartAt.toDate(),
      acquisitionEndAt: request.acquisitionEndAt.toDate(),
      status: "applying",
      description: request.description,
      correctionTargetAttendanceId: request.correctionTargetAttendanceId,
      memo: request.memo,
      createAt: date,
      createUser: request.userId,
      updateAt: date,
      updateUser: request.userId,
      paidVacationId: "",
      isAttendanceCancel: request.isAttendanceCancel,
    };

    // 有給休暇の場合は、申請できるかどうかを判定する
    if (request.requestType === "paidVacation") {
      requestData.paidVacationType = request.paidVacationType;
      const targetPaidVacation = GetCanUsePaidVacation(
        paidVacations,
        requests,
        request
      );

      if (targetPaidVacation === null)
        return {
          isCheck: false,
          message: "取得可能な有給がありません",
        };

      if (
        HasExistingPaidVacationRequest(
          requests,
          request.id,
          request.userId ?? "",
          request.acquisitionStartAt
        )
      )
        return {
          isCheck: false,
          message: "すでに指定された日付で有給申請が存在しています。",
        };

      requestData.paidVacationId = targetPaidVacation.id;
    }

    const result = await firebase
      .firestore()
      .collection("request")
      .add(requestData)
      .then(async requestDoc => {
        return await requestDoc
          .collection("requestHistory")
          .add({
            updateAt: date,
            updateUser: request.userId,
            status: "applying",
          })
          .then(() => ({ isCheck: true, message: "登録しました" }))
          .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));
      })
      .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));
    await new Promise(resolve => setTimeout(resolve, 1000));
    return result;
  } catch (error: any) {
    return { isCheck: false, message: "登録に失敗しました" };
  }
};
