import { MobileSiteTable, WebSiteTable } from "components";
import React from "react";
import { View } from "react-native";
import { SiteList } from "types";
import { IsWeb } from "utils";

export const SiteTable = ({
  tableList,
  onEditClick,
}: {
  tableList: SiteList[] | null;
  onEditClick: (id: string) => void;
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : IsWeb() ? (
        <WebSiteTable onEditClick={onEditClick} tableList={tableList} />
      ) : (
        <MobileSiteTable onEditClick={onEditClick} tableList={tableList} />
      )}
    </>
  );
};
