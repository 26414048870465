import {
  MobileExpenseLimitSettingTable,
  WebExpenseLimitSettingTable,
} from "components";
import React from "react";
import { View } from "react-native";
import { ExpenseLimitSettingList } from "types";
import { IsWeb } from "utils";

/**
 * 経費枠設定用テーブルをWeb・Mobileで切り替えます
 * @param param0
 * @returns
 */
export const ExpenseLimitSettingTable = ({
  tableList,
  onEditClick,
}: {
  tableList: ExpenseLimitSettingList[] | null;
  onEditClick: (id: string) => void;
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : IsWeb() ? (
        <WebExpenseLimitSettingTable
          onEditClick={onEditClick}
          tableList={tableList}
        />
      ) : (
        <MobileExpenseLimitSettingTable
          onEditClick={onEditClick}
          tableList={tableList}
        />
      )}
    </>
  );
};
