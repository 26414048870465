import { MobileAcquiredTable, WebAcquiredTable } from "components";
import React from "react";
import { View } from "react-native";
import { PaidVacationAcquiredList, UserCollection } from "types";
import { IsWeb } from "utils";
export const AcquiredTable = ({
  onEditClick,
  tableList,
  UserCollection,
}: {
  onEditClick: (paidVacation: PaidVacationAcquiredList) => void;
  tableList: PaidVacationAcquiredList[] | null;
  UserCollection: UserCollection[];
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : IsWeb() ? (
        <WebAcquiredTable
          onEditClick={onEditClick}
          tableList={tableList}
          UserCollection={UserCollection}
        />
      ) : (
        <MobileAcquiredTable
          onEditClick={onEditClick}
          tableList={tableList}
          UserCollection={UserCollection}
        />
      )}
    </>
  );
};
