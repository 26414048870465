import { DialogContext, UserInfoContext } from "GlobalContext";
import {
  DateForm,
  InputForm,
  ModalApprovalButton,
  ModalCancelButton,
  PageLoading,
  SelectForm,
} from "components";
import { genderList } from "constants/index";
import dayjs from "dayjs";
import firebase from "firebase/compat/app";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { ProfileData } from "types";
import { ConvertTimeStampFromDate } from "utils";

export function ProfileScreen({ navigation }: RootStackScreenProps<"Profile">) {
  const { userInfo } = useContext(UserInfoContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<ProfileData>({
    userId: "",
    uid: "",
    email: "",
    name: "",
    kana: null,
    gender: null,
    birthday: null,
    zipCode: null,
    address: null,
    tel: null,
  });

  const onBirthdayChangeHandler = (evt: Date): void => {
    setProfile({
      ...profile,
      birthday: ConvertTimeStampFromDate(evt),
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchProfile();
  }, []);

  const fetchProfile = async (): Promise<void> => {
    if (userInfo == null) {
      setAlert({ isAlert: true, msg: "ユーザー情報の取得に失敗しました" });
      return;
    }

    try {
      firebase
        .functions()
        .httpsCallable("fetchProfile")({ userInfo: userInfo.user })
        .then(res => {
          const profile = res.data;
          setProfile({
            userId: profile.userId,
            uid: profile.uid,
            email: profile.email,
            name: profile.name,
            kana: profile.kana,
            gender: profile.gender,
            birthday: profile.birthday
              ? ConvertTimeStampFromDate(
                  dayjs(profile.birthday._seconds * 1000).toDate()
                )
              : null,
            zipCode: profile.zipCode,
            address: profile.address,
            tel: profile.tel,
          });
        });
    } catch (error: any) {
      setAlert({ isAlert: true, msg: "プロフィール情報の取得に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const validationCheck = (): boolean => {
    if (!profile.name) {
      setAlert({ isAlert: true, msg: "氏名を入力してください" });
      return false;
    }
    if (!profile.email) {
      setAlert({ isAlert: true, msg: "メールアドレスを入力してください" });
      return false;
    } else {
      if (
        !profile.email.match(
          /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/
        )
      ) {
        setAlert({
          isAlert: true,
          msg: "メールアドレスの形式が正しくありません",
        });
        return false;
      }
    }
    if (profile.zipCode && profile.zipCode.length !== 7) {
      setAlert({ isAlert: true, msg: "郵便番号は7桁で入力してください" });
      return false;
    }
    if (profile.tel && ![10, 11].includes(profile.tel.length)) {
      setAlert({
        isAlert: true,
        msg: "電話番号は10桁または11桁で入力してください",
      });
      return false;
    }
    return true;
  };

  const updateProfile = async (): Promise<void> => {
    if (!validationCheck()) {
      return;
    }

    setIsLoading(true);
    try {
      firebase
        .functions()
        .httpsCallable("updateProfile")(profile)
        .then(res => {
          setAlert({ isAlert: true, msg: "更新しました" });
        });
    } catch (error: any) {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.formWrap}>
            <InputForm
              label="氏名"
              value={profile.name}
              onChange={e => {
                setProfile({ ...profile, name: e });
              }}
              disabled={true}
            />
            <InputForm
              label="ふりがな"
              value={profile.kana}
              onChange={e => {
                setProfile({ ...profile, kana: e });
              }}
              disabled={true}
            />
            <InputForm
              label="メール"
              value={profile.email}
              onChange={e => {
                setProfile({ ...profile, email: e });
              }}
              disabled={false}
            />
            <SelectForm
              label="性別"
              placeholderLabel="選択してください"
              value={profile.gender ?? ""}
              onChange={e => setProfile({ ...profile, gender: e })}
              items={genderList}
            />
            <DateForm
              value={profile.birthday?.toDate() ?? new Date()}
              onChange={onBirthdayChangeHandler}
              disabled={false}
              label={"誕生日"}
              type={"date"}
            />
            <InputForm
              label="郵便番号"
              value={profile.zipCode ? profile.zipCode : ""}
              onChange={e => {
                if (isNaN(Number(e))) {
                  setAlert({
                    isAlert: true,
                    msg: "数値で入力してください",
                  });
                  return;
                }
                setProfile({ ...profile, zipCode: e });
              }}
              disabled={true}
            />
            <InputForm
              label="住所"
              value={profile.address}
              onChange={e => {
                setProfile({ ...profile, address: e });
              }}
              disabled={true}
            />
            <InputForm
              label="電話番号"
              value={profile.tel ? profile.tel : ""}
              onChange={e => {
                if (isNaN(Number(e))) {
                  setAlert({
                    isAlert: true,
                    msg: "数値で入力してください",
                  });
                  return;
                }
                setProfile({ ...profile, tel: e });
              }}
              disabled={true}
            />

            <View style={styles.formItem}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => navigation.goBack()}
              />
              <ModalApprovalButton
                buttonText="更新"
                onClick={() => updateProfile()}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    padding: 10,
  },
  scrollContainer: {
    marginTop: "10%",
    marginBottom: "10%",
    height: "100%",
    width: "100%",
  },
  headingText: {
    fontSize: 32,
  },
  formWrap: {
    marginTop: "10%",
  },
  formItem: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: 25,
  },
  formItemCenter: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 25,
  },
  label: {
    fontSize: 20,
    width: "40%",
    paddingTop: 10,
  },
  input: {
    width: "60%",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  able: {
    color: "black",
    backgroundColor: "white",
  },
  disable: {
    color: "gray",
    backgroundColor: "darkgray",
  },
  button: {
    marginTop: 32,
    paddingVertical: 16,
    width: "40%",
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
  },
  resetButton: {
    width: "50%",
    paddingVertical: 10,
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
    padding: 10,
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  resetButtonText: {
    fontSize: 14,
    color: "#fff",
    fontWeight: "bold",
  },
});
