import dayjs from "dayjs";
import { RequestCollection } from "types";

/**
 * 指定の取得開始予定日の年月日指定で有給申請が存在する場合、true
 * @param requests 申請情報
 * @param acquisitionStartAt 取得開始予定日
 */
export const HasExistingPaidVacationRequest = (
  /** 申請情報 */
  requests: RequestCollection[],
  /** 申請ID */
  requestId: string,
  /** 申請者ID */
  requestUserId: string,
  /** 取得開始予定日 */
  acquisitionStartAt?: FirebaseFirestore.Timestamp | null
) => {
  if (!acquisitionStartAt) return false;

  const formattedAcquisitionStartDate = dayjs(
    acquisitionStartAt.toDate()
  ).format("YYYY-MM-DD");
  return requests.some(
    request =>
      request.id !== requestId &&
      request.requestType === "paidVacation" &&
      request.acquisitionStartAt &&
      request.userId === requestUserId &&
      (request.status === "applying" || request.status === "approval") &&
      dayjs(request.acquisitionStartAt.toDate()).format("YYYY-MM-DD") ===
        formattedAcquisitionStartDate
  );
};
