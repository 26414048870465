import { NoteBookCollection, NoteBookPageListType } from "types";
import { AscSort } from "utils";

/**
 * 指定したWikiページ情報を返却します
 * @param noteBook collectionContextから取得したWikiページ情報
 * @param targetId 指定のWikiページID
 * @returns NoteBookData
 */ export const FetchNoteBookPages = (
  NoteBookCollection: NoteBookCollection[] | null,
  targetId: string
): NoteBookPageListType | null => {
  if (NoteBookCollection === null) return null;

  const target = NoteBookCollection.filter(f => targetId === f.id).shift();

  if (target === undefined) return null;
  return {
    id: target.id,
    pages: target.notebookPage
      .filter(f => f.deleted === false)
      .sort((a, b) => AscSort(a.sort, b.sort)),
    bookName: target.bookName ?? "",
  };
};
