import React, { useEffect, useState } from "react";
import {
  Dimensions,
  FlatList,
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableHighlight,
  View,
} from "react-native";
import SafeAreaView from "react-native-safe-area-view";
import { ImageDataSources } from "types";
import { IsMobile, ModalLibrary } from "utils";

type Props = {
  /** 操作可能のステータスを定義します */
  editable: boolean;
  /** イメージのデータソースを定義します */
  parentImgDataSources: ImageDataSources;
  /** イメージのデータソースの変更時の挙動を定義します */
  setParentImgDataSources: (
    value: React.SetStateAction<ImageDataSources>
  ) => void;
};

/** 経費申請時に登録された画像を表示するフォームを生成します */
export const ImageSlider = ({
  editable,
  parentImgDataSources,
  setParentImgDataSources,
}: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [imgDataSources, setImgDataSources] =
    useState<ImageDataSources>(parentImgDataSources);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  useEffect(() => {
    setParentImgDataSources(imgDataSources);
  }, [imgDataSources]);

  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;

  return (
    <View style={{ paddingBottom: 30 }}>
      <SafeAreaView
        style={[
          styles.container,
          IsMobile() ? { width: windowWidth * 0.9 } : {},
        ]}
      >
        <FlatList
          data={parentImgDataSources.data}
          renderItem={({ item, index }) => (
            <View style={styles.item}>
              <Text numberOfLines={1} ellipsizeMode="tail" style={styles.title}>
                {item.title}
              </Text>
              <Pressable
                onPress={() => {
                  toggleModal();
                  setImgIndex(index);
                }}
              >
                <Image
                  style={styles.stretch}
                  source={{
                    uri: item.url,
                  }}
                />
              </Pressable>
              <Text style={[styles.title, { textAlign: "center" }]}>
                {index + 1}/{parentImgDataSources.data.length}
              </Text>
            </View>
          )}
          keyExtractor={item => item.id}
          horizontal={true}
        />
      </SafeAreaView>
      <ModalLibrary
        animationType="slide"
        visible={isModalVisible}
        ariaHideApp={false}
      >
        <View style={[styles.centeredView, { height: windowHeight * 2 }]}>
          <ScrollView>
            <TouchableHighlight
              onPress={() => {
                toggleModal();
              }}
              underlayColor={"white"}
              style={styles.closeButton}
            >
              <Text style={styles.closeText}>&#0215;</Text>
            </TouchableHighlight>
            <View style={styles.formTitleItem}>
              <Text style={styles.label}>タイトル：</Text>
              <TextInput
                inputAccessoryViewID={"1"}
                value={parentImgDataSources.data[imgIndex].title}
                editable={editable}
                onChangeText={e => {
                  setParentImgDataSources(prevState => {
                    const test = prevState.data;
                    test[imgIndex].title = e;
                    return {
                      data: test,
                    };
                  });
                }}
                style={[editable ? styles.input : styles.label]}
              />
            </View>
            <View style={[styles.formImageItem]}>
              <Image
                style={[
                  { width: windowWidth * 0.9, height: windowHeight * 0.9 },
                ]}
                resizeMode="contain"
                source={{
                  uri: parentImgDataSources.data[imgIndex].url,
                }}
              />
            </View>
          </ScrollView>
        </View>
      </ModalLibrary>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  formTitleItem: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 100,
    marginBottom: 25,
  },
  formImageItem: {
    marginTop: 25,
    marginBottom: 25,
  },
  item: {
    backgroundColor: "lightgray",
    margin: 10,
    height: "30%",
  },
  title: {
    fontSize: 16,
    width: 100,
    paddingBottom: 30,
    paddingLeft: 5,
  },
  imageLengthLabel: {
    fontSize: 8,
    width: "100%",
    alignContent: "flex-end",
  },
  label: {
    fontSize: 20,
    paddingTop: 10,
  },
  input: {
    width: "50%",
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    padding: 10,
  },
  stretch: {
    width: 100,
    height: 100,
    resizeMode: "cover",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: 30,
    right: 30,
  },
  closeText: {
    fontSize: 48,
  },
});
