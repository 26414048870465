import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";

type Props = {
  /** 選択している番号を定義 */
  selectionMode: number;
  /**  */
  roundCorner: boolean;
  /** 左側に表示される文字列を定義します */
  option1: string;
  /** 右側に表示される文字列を定義します */
  option2: string;
  /** 指定中の時のカラーを定義します */
  selectionColor: string;
  /** ボタンを切り替えた時の挙動を定義します */
  onSelectSwitch: Function;
};

/**
 * ２種類の切り替えを描写します
 * @param param0
 * @returns
 */
export const Toggle = ({
  selectionMode,
  roundCorner,
  option1,
  option2,
  selectionColor,
  onSelectSwitch,
}: Props) => {
  const [getSelectionMode, setSelectionMode] = useState(selectionMode);
  const [getRoundCorner, setRoundCorner] = useState(roundCorner);

  const updatedSwitchData = (val: number) => {
    setSelectionMode(val);
    onSelectSwitch(val);
  };

  return (
    <View>
      <View
        style={{
          height: 35,
          width: 150,
          backgroundColor: "white",
          borderRadius: getRoundCorner ? 25 : 0,
          borderWidth: 1,
          borderColor: selectionColor,
          flexDirection: "row",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(1)}
          style={{
            flex: 1,
            backgroundColor: getSelectionMode == 1 ? selectionColor : "white",
            borderRadius: getRoundCorner ? 25 : 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: getSelectionMode == 1 ? "white" : selectionColor,
            }}
          >
            {option1}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => updatedSwitchData(2)}
          style={{
            flex: 1,
            backgroundColor: getSelectionMode == 2 ? selectionColor : "white",
            borderRadius: getRoundCorner ? 25 : 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: getSelectionMode == 2 ? "white" : selectionColor,
            }}
          >
            {option2}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
