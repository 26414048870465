import dayjs from "dayjs";
import { PaidVacationAcquiredList, RequestCollection } from "types";
import { DescSort } from "utils";
/**
 * 今まで取得された有給の一覧を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param requests collectionContextから取得した申請情報
 * @returns DashboardsDataType[]
 */
export const FetchPaidVacationRequest = (
  requests: RequestCollection[] | null
): PaidVacationAcquiredList[] => {
  if (requests === null) return [];

  return requests
    .filter(request => request.requestType === "paidVacation")
    .filter(request => request.status === "approval")
    .map(request => {
      const approveHistory = request.requestHistory
        .filter(f => f.status === "approval")
        .sort((a, b) => DescSort(a.updateAt, b.updateAt))
        .shift();
      const approveAt =
        approveHistory !== undefined ? approveHistory.updateAt : null;
      return {
        id: request.id,
        userId: request.userId,
        acquisitionStartAt: dayjs(
          (request.acquisitionStartAt?.seconds ?? 0) * 1000
        ).toDate(),
        requestAt: dayjs(request.requestAt?.toDate()).toDate(),
        approveAt: dayjs(approveAt?.toDate()).toDate(),
      } as PaidVacationAcquiredList;
    });
};
