import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  ExpenseCollection,
  ExpenseData,
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  ExpenseLimitSettingUseHistory,
  ImageDataSources,
  UserInfo,
} from "types";
import { imagePutStrage } from "utils";
import { CheckExpenseLimitSetting } from "./utils";
/**
 * 経費枠の更新書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param expenseLimitSettingData 登録対象の現場情報
 * @returns　CreateCheckResult
 */
export const UpdateExpense = async (
  reqUser: UserInfo | null,
  expenses: ExpenseCollection[] | null,
  expenseItems: ExpenseItemCollection[] | null,
  expenseLimitSettings: ExpenseLimitSettingCollection[] | null,
  expense: ExpenseData,
  imgDataSources: ImageDataSources
): Promise<CreateCheckResult> => {
  const date = new Date();
  const user = reqUser?.user ?? null;

  if (
    expenseItems === null ||
    expenseLimitSettings === null ||
    expenses === null
  ) {
    return { isCheck: false, message: "登録に失敗しました" };
  }

  try {
    // 現場経費以外の場合
    if (expense.expenseItemId) {
      const targetExpenseItem = expenseItems
        .filter(f => f.id === expense.expenseItemId)
        .shift();
      const targetExpense = expenses.filter(f => f.id === expense.id).shift();
      // 申請中・取り下げ・否決から更新を行う場合
      if (
        targetExpense !== undefined &&
        (targetExpense.status === "applying" ||
          targetExpense.status === "withdrawal" ||
          targetExpense.status === "reject")
      ) {
        // 承認か支払い済みに移行する場合のみ判定を行う
        if (
          targetExpenseItem !== undefined &&
          !targetExpenseItem.isNoExpenseLimit &&
          (expense.status === "applying" ||
            expense.status === "approval" ||
            expense.status === "paid")
        ) {
          // 経費枠が利用可能かどうかをチェックする
          const result = CheckExpenseLimitSetting(
            expense,
            expenses,
            expenseLimitSettings
          );

          if (!result.isCheck) {
            return result;
          }
        }
      }
    }

    const doc = await firebase
      .firestore()
      .collection("expense")
      .doc(expense.id)
      .get();

    await firebase
      .firestore()
      .collection("expense")
      .doc(expense.id)
      .collection("expenseHistory")
      .add({
        updateAt: date,
        updateUser: user?.id,
        status: expense.status,
      });

    if ("paid" === expense.status) {
      // 承認履歴がない場合は作成
      const approvals = await firebase
        .firestore()
        .collection("expense")
        .doc(expense.id)
        .collection("expenseHistory")
        .where("status", "==", "approval")
        .limit(1)
        .orderBy("updateAt", "desc")
        .get();
      if (approvals.empty) {
        firebase
          .firestore()
          .collection("expense")
          .doc(expense.id)
          .collection("expenseHistory")
          .add({
            updateAt: date,
            updateUser: user?.id,
            status: "approval",
          });
      }

      let paymentAmountTotal = 0;
      const paids = await firebase
        .firestore()
        .collection("expense")
        .doc(expense.id)
        .collection("paymentHistory")
        .orderBy("paymentAt", "asc")
        .get();
      paids.forEach(doc => {
        paymentAmountTotal += doc.data().paymentAmount;
      });
      // 支払い金額が申請金額未満の場合
      if (expense.totalAmount > paymentAmountTotal) {
        firebase
          .firestore()
          .collection("expense")
          .doc(expense.id)
          .collection("paymentHistory")
          .add({
            updateAt: date,
            updateUser: user?.id,
            paymentAmount: expense.paymentAmount,
            paymentAt: expense.paymentAt ? expense.paymentAt.toDate() : date,
          });
      }
    }

    await imagePutStrage({
      docId: expense.id,
      imgDataSources: imgDataSources,
      folderName: "expense",
      functionsName: "updateExpenseImage",
    });
    const lastResult = await doc.ref
      .update({
        ...doc.data(),
        siteId: expense.siteId,
        totalAmount: expense.totalAmount,
        status: expense.status,
        reckoningAt: expense.reckoningAt?.toDate(),
        description: expense.description,
        memo: expense.memo,
        paymentStatus: expense.paymentStatus,
        managerDescription: expense.managerDescription,
        expenseItemId: expense.expenseItemId,
        expenseLimitSettingId: expense.expenseLimitSettingId,
        updateAt: date,
        updateUser: user?.id,
      })
      .then(async requestDoc => ({ isCheck: true, message: "登録しました" }))
      .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));

    await new Promise(resolve => setTimeout(resolve, 1000));
    return lastResult;
  } catch (error: any) {
    console.log(error);
    return { isCheck: false, message: "登録に失敗しました" };
  }
};

const isValidExpenseLimit = (
  expenseLimitSetting: ExpenseLimitSettingCollection[],
  useExpenseHistory: ExpenseLimitSettingUseHistory[],
  requestAmount: number
): boolean => {
  const els = expenseLimitSetting[0];

  let useExpenseAmountTotal = 0;
  useExpenseHistory.map(useExpense => {
    useExpenseAmountTotal += useExpense.amount ?? 0;
  });

  // 上限金額超過
  if ((els.amountLimit ?? 0) < useExpenseAmountTotal + requestAmount) {
    return false;
  }
  return true;
};
