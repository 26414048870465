import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { AddButton, PageLoading, UserModal, UserTable } from "components";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { FetchUsers } from "server/collectionConnect";
import { UserList, UserModalStatus } from "types";

export function UserScreen({ navigation }: RootStackScreenProps<"User">) {
  const { userInfo } = useContext(UserInfoContext);
  const [editUser, setEditUser] = useState<UserModalStatus>(null);
  const { UserCollection, onSnapShot } = useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [users, setUsers] = useState<UserList[] | null>(null);
  const [maxSortNo, setMaxSortNo] = useState<number>(0);

  useEffect(() => {
    if (UserCollection === null) {
      onSnapShot("user");
      return;
    }
    const result = FetchUsers(UserCollection);
    setUsers(result);
    setMaxSortNo(
      UserCollection.filter(
        f => f.retirementAt === undefined || f.retirementAt === null
      ).reduce((a, b) => (a.sort > b.sort ? a : b)).sort ?? 0
    );
    setIsLoading(false);
  }, [UserCollection]);

  if (isLoading) return <PageLoading isLoading={isLoading} />;
  return (
    <>
      <View style={styles.container} />
      <View style={styles.listContainer}>
        <UserTable
          tableList={users}
          onEditClick={id => setEditUser({ id: id, isEdit: true })}
        />
      </View>
      <AddButton onClick={() => setEditUser({ id: null, isEdit: false })} />
      <UserModal
        maxSortNo={maxSortNo}
        modalStatus={editUser}
        onCloseModal={() => setEditUser(null)}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    padding: 10,
  },
  listContainer: {
    flex: 8,
    backgroundColor: "#fff",
  },
  footerContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  headingText: {
    fontSize: 32,
    marginBottom: 20,
  },
});
