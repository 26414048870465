import { TableCell } from "components";
import React from "react";
import { DimensionValue, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { SiteList } from "types";

const tableHead = [
  "",
  "ステータス",
  "現場名",
  "案件番号",
  "契約番号",
  "更新日",
];

export const WebSiteTable = ({
  onEditClick,
  tableList,
}: {
  onEditClick: (id: string) => void;
  tableList: SiteList[];
}) => {
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );

  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "600px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList, onEditClick)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

const TableBody = (
  tableList: SiteList[],
  onEditClick: (id: string) => void
) => {
  const tableBody = tableList.map(tableData => {
    var tempArr = [];

    // 編集ボタン
    tempArr.push(
      <TableCell
        dataType="button"
        buttonText="編集"
        onEditClick={() => onEditClick(tableData.id)}
      />
    );
    // ステータス
    tempArr.push(
      <TableCell dataType="siteStatus" value={tableData.progressStatus} />
    );
    // 現場名
    tempArr.push(<TableCell dataType="string" value={tableData.name} />);
    // 案件番号
    tempArr.push(
      <TableCell dataType="string" value={tableData.matterNumber} />
    );
    // 契約番号
    tempArr.push(
      <TableCell dataType="string" value={tableData.agreementNumber} />
    );
    // 更新日
    tempArr.push(<TableCell dataType="date" value={tableData.updateAt} />);

    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return tableBody.filter(m => m !== undefined);
};
