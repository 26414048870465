import { PaidVacationCollection, PaidVacationList } from "types";

/**
 * 指定した有給管理情報を返却します
 * @param site collectionContextから取得した現場情報
 * @param targetId 指定の有給休暇ID
 * @returns SiteData
 */
export const FetchPaidVacation = (
  paidVacations: PaidVacationCollection[] | null,
  targetId: string
): PaidVacationList | null => {
  if (paidVacations === null) return null;

  const targetPaidVacation = paidVacations
    .filter(f => f.id === targetId)
    .shift();
  if (targetPaidVacation === undefined) return null;

  return {
    id: targetPaidVacation.id,
    userId: targetPaidVacation.userId,
    grantAt: targetPaidVacation.grantAt?.toDate(),
    lapseAt: targetPaidVacation.lapseAt?.toDate(),
    grantCount: targetPaidVacation.grantCount,
    remainingCount: targetPaidVacation.remainingCount,
  } as PaidVacationList;
};
