import { ExpirationForm, ModalCloseButton } from "components";
import React from "react";
import { View } from "react-native";
import { modalStyles } from "styles";
import { ExpirationModalStatus } from "types";
import { ModalLibrary } from "utils";

type Props = {
  modalStatus: ExpirationModalStatus;
  onCloseModal: () => void;
};

/**
 * 有給休暇付与・編集モーダルを生成します
 * @param param0
 * @returns
 */
export function ExpirationModal({ modalStatus, onCloseModal }: Props) {
  return (
    <>
      <ModalLibrary
        animationType="slide"
        visible={modalStatus !== null}
        ariaHideApp={false}
      >
        <View style={modalStyles.centeredView}>
          <ModalCloseButton onClick={onCloseModal} />

          <ExpirationForm hideModal={onCloseModal} modalStatus={modalStatus} />
        </View>
      </ModalLibrary>
    </>
  );
}
