import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import * as Permissions from "expo-permissions";
import { UpdateDeviceToken } from "server/collectionConnect";
import { errorLog } from "utils/errorLog";
import { IsAndroid } from "./CheckPlatform";

export async function RegistPushNotifications(userId: string): Promise<void> {
  try {
    // 実機端末か否かを判定
    if (Constants.isDevice) {
      const { status: existingStatus } = await Permissions.getAsync(
        Permissions.NOTIFICATIONS
      );
      let finalStatus = existingStatus;
      // ユーザーによる通知の許可or許可しないが決定していないときのみ
      if (existingStatus !== "granted") {
        const { status } = await Permissions.askAsync(
          Permissions.NOTIFICATIONS
        );
        finalStatus = status;
      }
      // ユーザーが通知許可しなかった場合は処理を抜ける
      if (finalStatus !== "granted") return;
      // デバイストークンを取得する

      const token = await Notifications.getExpoPushTokenAsync();
      Notifications.setBadgeCountAsync(0);
      try {
        await UpdateDeviceToken(token, userId, null);
      } catch (error: any) {
        console.log(error.message);
      }
    }

    // androidの場合
    if (IsAndroid()) {
      Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      });
    }
  } catch (e: any) {
    errorLog(e, "registerForPushNotifications", userId);
  }
}
