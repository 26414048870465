import firebase from "firebase/compat/app";
import { CreateCheckResult, UserData, UserInfo } from "types";

/**
 * 現場の新規作成の書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param site collectionContextから取得した現場情報
 * @param siteData 更新・登録対象の現場情報
 * @returns　CreateCheckResult
 */
export const UpdateUser = async (
  reqUser: UserInfo | null,
  userData: UserData,
  isAuthChange: boolean
): Promise<CreateCheckResult> => {
  const date = new Date();
  if (userData.retirementAt !== null || isAuthChange) {
    const res = await firebase
      .functions()
      .httpsCallable("updateUser")({
        uid: userData.uid,
        authorityId: userData.authorityId,
        retired: userData.retirementAt !== null,
      })
      .then(_ => true)
      .catch(_ => false);
    if (!res) return { isCheck: false, message: "更新に失敗しました" };
  }
  return await firebase
    .firestore()
    .collection("user")
    .doc(userData.id)
    .update({
      uid: userData.uid,
      email: userData.email,
      name: userData.name,
      entryAt: userData.entryAt,
      sort: userData.sort,
      createAt: date,
      createUser: reqUser?.user.id,
      updateAt: date,
      updateUser: reqUser?.user.id,
      retirementAt: userData.retirementAt,
    })
    .then(() => {
      return { isCheck: true, message: "更新しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "更新に失敗しました" };
    });
};
