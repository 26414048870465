import dayjs from "dayjs";
import { PaidVacationCollection, RequestCollection, UserInfo } from "types";
import { GetBalancePaidVacation } from "./utils";
/**
 * 当月の残業時間の合計値を集計して返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param paidVacations collectionContextから取得した有給管理情報
 * @returns number
 */
export const FetchDashBoardPaidVacation = (
  userInfo: UserInfo | null,
  paidVacations: PaidVacationCollection[] | null,
  requests: RequestCollection[] | null
): number => {
  const today = dayjs(new Date()).toDate();
  const userId = userInfo?.user.id;

  if (paidVacations === null || requests === null) return 0;

  const targetPaidVacation = paidVacations
    .filter(paidVacation => paidVacation.userId === userId)
    .filter(paidVacation => paidVacation.remainingCount ?? 0 > 0);

  const paidVacationList = targetPaidVacation.map(m => {
    // Firebaseの複合クエリの都合上取得後に日付のフィルタを実行しています
    if (
      dayjs(m.grantAt?.toDate()).toDate() <= today &&
      dayjs(m.lapseAt?.toDate()).toDate() >= today
    ) {
      const count = GetBalancePaidVacation(requests, m.id);
      return (m.grantCount ?? 0) - count;
    }
    return 0;
  });

  const total = paidVacationList.reduce(function (sum, element) {
    return sum + element;
  }, 0);
  return total;
};
