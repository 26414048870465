import {
  authorityType,
  progressStatusType,
  requestStatusType,
  scheduleType,
} from "constants/index";
import dayjs from "dayjs";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { FontColor } from "types";

/**
 * 指定したデータをフォーマットしてセル情報を返却します
 * @param dataType データ型を指定します
 * @returns
 */

export function TableCell(data: TableCellType): JSX.Element {
  if (data.dataType === "button") {
    return (
      <View style={{ padding: 2 }}>
        <Pressable
          style={[styles.status_button, { backgroundColor: "#7879F1" }]}
          onPress={data.onEditClick}
        >
          <Text style={styles.status_button}>{data.buttonText}</Text>
        </Pressable>
      </View>
    );
  }

  if (data.dataType === "requestStatus") {
    return (
      <View style={{ padding: 2 }}>
        <Pressable
          style={[
            styles.status_button,
            { backgroundColor: requestStatusType[data.value].color },
          ]}
        >
          <Text style={styles.status_button}>
            {requestStatusType[data.value].text}
          </Text>
        </Pressable>
      </View>
    );
  }

  if (data.dataType === "siteStatus") {
    return (
      <View style={{ padding: 2 }}>
        <Pressable
          style={[
            styles.status_button,
            { backgroundColor: progressStatusType[data.value].color },
          ]}
        >
          <Text style={styles.status_button}>
            {progressStatusType[data.value].text}
          </Text>
        </Pressable>
      </View>
    );
  }

  if (data.dataType === "userAuthority") {
    return (
      <View style={{ padding: 2 }}>
        <Pressable
          style={[
            styles.status_button,
            { backgroundColor: authorityType[data.value]?.color },
          ]}
        >
          <Text style={styles.status_button}>{data.value}</Text>
        </Pressable>
      </View>
    );
  }

  if (data.dataType === "calendarType") {
    return (
      <View style={{ padding: 2 }}>
        <Pressable
          style={[
            styles.status_button,
            { backgroundColor: scheduleType[data.value].color },
          ]}
        >
          <Text style={styles.status_button}>
            {scheduleType[data.value]?.text ?? ""}
          </Text>
        </Pressable>
      </View>
    );
  }
  return (
    <>
      <View style={styles.textContainer}>
        <Text
          style={[styles.tableText, { color: TextColor(data.color) }]}
          adjustsFontSizeToFit
          numberOfLines={2}
        >
          {ConvertValue(data)}
        </Text>
      </View>
    </>
  );
}

/** 指定のカラーに応じて文字色を返却します */
function TextColor(key: FontColor | undefined) {
  switch (key) {
    case undefined:
    case "default":
      return "#333";
    default:
      return key;
  }
}
/**
 * テーブルセルに表示する文字列を加工するメソッドです
 * @param data
 * @returns stringに変換したデータ
 */
function ConvertValue(data: TableCellType) {
  switch (data.dataType) {
    case "string":
      return data.value;
    case "money":
      return `¥${(data.value ?? 0).toLocaleString()}`;
    case "date":
      if (data.value === null || Number.isNaN(data.value.seconds)) return "";
      return dayjs(data?.value.toDate()).format("YYYY/MM/DD");
    case "datetime":
      if (data.value === null || Number.isNaN(data.value.seconds)) return "";
      return dayjs(data?.value.toDate()).format("YYYY/MM/DD HH:mm");
    case "time":
      if (data.value === null || Number.isNaN(data.value.seconds)) return "";
      return dayjs(data?.value.toDate()).format("HH:mm");
    case "number":
      if (data.value === null) return;
      return data.value.toString();
    default:
      break;
  }
}

/**
 * dataTypeに string を指定した時の引数です。
 *
 * value: string
 */
type StringCell = {
  dataType: "string";
  value: string | null;
};

/**
 * dataTypeに money を指定した時の引数です。
 *
 * value: number
 */
type MoneyCell = {
  dataType: "money";
  value: number | null;
};

/**
 * dataTypeに number を指定した時の引数です。
 *
 * value: number
 */
type NumberCell = {
  dataType: "number";
  value: number | null;
  color?: FontColor;
};

/**
 * dataTypeに date を指定した時の引数です。
 *
 * value: Date
 */
type DateCell = {
  dataType: "date";
  value: FirebaseFirestore.Timestamp | null;
};

/**
 * dataTypeに datetime を指定した時の引数です。
 *
 * value: Date
 */
type DateTimeCell = {
  dataType: "datetime";
  value: FirebaseFirestore.Timestamp | null;
};

/**
 * dataTypeに time を指定した時の引数です。
 *
 * value: Date
 */
type TimeCell = {
  dataType: "time";
  value: FirebaseFirestore.Timestamp | null;
};

/**
 * dataTypeに button を指定した時の引数です。
 *
 * onEditClick: (data: GestureResponderEvent) => void;
 *
 * buttonText: string;
 */
type ButtonCell = {
  dataType: "button";
  onEditClick: () => void;
  buttonText: string | null;
};

/**
 * dataTypeに requestStatus を指定した時の引数です。
 *
 * value: string
 */
type RequestStatusCell = {
  dataType: "requestStatus";
  value: string;
};

/**
 * dataTypeに siteStatus を指定した時の引数です。
 *
 * value: string
 */
type SiteStatusCell = {
  dataType: "siteStatus";
  value: string;
};

/**
 * dataTypeに calendarType を指定した時の引数です。
 *
 * value: string
 */
type CalendarTypeCell = {
  dataType: "calendarType";
  value: string;
};

/**
 * dataTypeに userAuthority を指定した時の引数です。
 *
 * value: string
 */
type UserAuthority = {
  dataType: "userAuthority";
  value: string;
};

/**
 * dataTypeを指定すればそれに応じた必須の引数がホバーで表示されます
 *
 * dataType: string | number | date | button | requestStatus
 */
type TableCellType = (
  | StringCell
  | NumberCell
  | MoneyCell
  | DateCell
  | DateTimeCell
  | ButtonCell
  | RequestStatusCell
  | SiteStatusCell
  | UserAuthority
  | CalendarTypeCell
  | TimeCell
) & { color?: FontColor };

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 2,
  },
  tableText: {
    fontSize: 14,
    color: "#333",
  },
  status_button: {
    overflow: "hidden",
    alignSelf: "center",
    textAlign: "center",
    lineHeight: 30,
    width: 70,
    borderRadius: 20,
    color: "#fff",
  },
});
