import dayjs from "dayjs";
import firebase from "firebase/compat/app";
import { CreateCheckResult, UserInfo } from "types";
import { FetchLatestAttendance } from "./FetchLatestAttendance";
import {
  GeoPoint,
  fetchGeoLocationAddress,
} from "./utils/FetchGeoLocationAddress";

/**
 * 勤怠の新規作成の書き込みを行います
 * @param userInfo userContextから取得したユーザー情報
 * @param attendanceType 勤怠種類
 * @param attendanceList CollectionContextから取得した勤怠情報
 * @param location 位置情報
 * @returns　CreateCheckResult
 */
export const CreateAttendance = async (
  userInfo: UserInfo | null,
  attendanceType: string,
  location: GeoPoint
): Promise<CreateCheckResult> => {
  const date = new Date();
  const latest = await FetchLatestAttendance(userInfo);
  // 本日の出退勤情報を取得
  if (
    dayjs(latest?.startWorkSchedule?.toDate()) >= dayjs().startOf("day") &&
    dayjs(latest?.startWorkSchedule?.toDate()) <= dayjs().endOf("day") &&
    latest &&
    latest?.endBreakSchedule
  ) {
    return { isCheck: false, message: "本日は既に退勤済です" };
  }

  // 勤務開始時の住所を取得
  const locationAddress = await fetchGeoLocationAddress(location);
  return await firebase
    .firestore()
    .collection("attendance")
    .add({
      userId: userInfo?.user.uid,
      attendanceType: attendanceType,
      startWorkSchedule: date,
      endWorkSchedule: null,
      openingAddress: locationAddress,
    })
    .then(() => ({ isCheck: true, message: "出勤登録が完了しました" }))
    .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));
};
