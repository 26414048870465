import firebase from "firebase/compat/app";
import { AttendanceCollection } from "types";

/**
 * attendance Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const AttendanceOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<AttendanceCollection[] | null>>,
  targetStartDate: Date,
  targetEndDate: Date
) => {
  return firebase
    .firestore()
    .collection("attendance")
    .where("startWorkSchedule", ">=", targetStartDate)
    .where("startWorkSchedule", "<=", targetEndDate)
    .onSnapshot(
      docs => {
        const collectionList = docs.docs.map(
          doc => ({ ...doc.data(), id: doc.id } as AttendanceCollection)
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};
