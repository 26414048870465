import { TableCell } from "components";
import dayjs from "dayjs";
import React from "react";
import { DimensionValue, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { CalendarCollection } from "types";

const tableHead = [
  "予定種別",
  "予定開始",
  "予定終了",
  "タイトル",
  "現場名",
  "メモ",
];

/** Web用のカレンダーを返却します */
export const WebCalendarTable = ({
  tableList,
}: {
  tableList: CalendarCollection[];
}) => {
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );

  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "500px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

/** テーブルの実際のデータを作成します */
const TableBody = (tableList: CalendarCollection[]) => {
  const tableBody = tableList.map(tableData => {
    var tempArr = [];

    // 予定種別
    tempArr.push(
      <TableCell
        dataType="calendarType"
        value={tableData.isHoliday ? "true" : "false"}
      />
    );
    // 予定開始
    tempArr.push(
      tableData.isAllDay ? (
        <TableCell
          dataType="string"
          value={`${dayjs(tableData.startDate.toDate()).format(
            "YYYY/MM/DD"
          )} 終日`}
        />
      ) : (
        <TableCell dataType="datetime" value={tableData.startDate} />
      )
    );
    // 予定終了
    tempArr.push(
      tableData.isAllDay ? (
        <TableCell
          dataType="string"
          value={`${dayjs(tableData.endDate.toDate()).format(
            "YYYY/MM/DD"
          )} 終日`}
        />
      ) : (
        <TableCell dataType="datetime" value={tableData.endDate} />
      )
    );
    // タイトル
    tempArr.push(<TableCell dataType="string" value={tableData.title} />);
    // 現場名
    tempArr.push(
      <TableCell
        dataType="string"
        value={tableData.site ? tableData.site.name : ""}
      />
    );
    // メモ
    tempArr.push(<TableCell dataType="string" value={tableData.memo} />);
    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return tableBody.filter(m => m !== undefined);
};
