import dayjs from "dayjs";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { CheckBox } from "react-native-elements";
import { NoteBookPage } from "types";

export function NoteBookPageList({
  onClick,
  onDeleteClick,
  deleting,
  deleteList,
  NoteBookPageList,
}: {
  onClick: (id: string) => void;
  /** 削除操作中かどうか
   *
   * 削除処理：true
   */
  deleting: boolean;
  /** 削除チェックをクリックした時の処理 */
  onDeleteClick: (id: string) => void;
  /** 削除リスト一覧 */
  deleteList: string[];
  NoteBookPageList: NoteBookPage[];
}) {
  return (
    <>
      {NoteBookPageList.map((noteBookPage, index: number) => {
        return renderDataContents(
          index,
          noteBookPage,
          onClick,
          deleting,
          deleteList.includes(noteBookPage.id),
          onDeleteClick
        );
      })}
    </>
  );
}

const renderDataContents = (
  index: number,
  noteBookPageData: NoteBookPage,
  onClick: (id: string) => void,
  deleting: boolean,
  isDelete: boolean,
  onDeleteClick: (id: string) => void
): JSX.Element => {
  return (
    <View key={index} style={[styles.listContainer, { borderTopWidth: 1 }]}>
      <View style={styles.listItem}>
        <View>
          {deleting ? (
            <View style={styles.formItem}>
              <CheckBox
                containerStyle={[styles.checkbox]}
                checked={isDelete}
                onPress={() => onDeleteClick(noteBookPageData.id)}
              />
            </View>
          ) : (
            <Pressable
              style={styles.processButton}
              onPress={() => onClick(noteBookPageData.id)}
            >
              <Text style={styles.buttonText}>詳細</Text>
            </Pressable>
          )}
        </View>
        <Text
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[styles.columnText, styles.contentsTextRight]}
        >
          {noteBookPageData.pageTitle}
        </Text>
      </View>

      <View style={styles.listItem}>
        <Text style={[styles.columnText, styles.contentsTextLeft]}>
          最終更新日：
          {dayjs(noteBookPageData.updateAt?.toDate()).format("YYYY/MM/DD")}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tableLayoutContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 20,
    marginRight: 20,
  },
  titleText: {
    alignSelf: "center",
    textAlign: "center",
    fontSize: 15,
    lineHeight: 30,
  },
  listContainer: {
    paddingTop: 10,
  },
  row: {
    flexDirection: "row",
    padding: 5,
  },
  columnText: {
    alignSelf: "center",
    textAlign: "center",
    lineHeight: 30,
    width: 100,
  },
  contentsTextRight: {
    textAlign: "right",
    width: "50%",
  },
  contentsTextLeft: {
    textAlign: "left",
    width: "100%",
  },
  listItem: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  processButton: {
    top: 0,
    left: 0,
    right: 0,
    alignSelf: "center",
    borderRadius: 20,
    elevation: 3,
    width: 80,
    height: 30,
    marginTop: 10,
    marginBottom: 15,
    textAlign: "center",
    backgroundColor: "#7879F1",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
  checkbox: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0,
    padding: 0,
  },
  headingText: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    fontSize: 25,
  },
  formItem: {
    backgroundColor: "#fff",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: -10,
  },
});
