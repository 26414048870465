import firebase from "firebase/compat/app";
import { Platform } from "react-native";
import { ErrorLogData } from "types";

export const errorLog = async (
  error: any,
  errorPoint: string,
  userId?: string
): Promise<void> => {
  // 以下エラーコードの場合は無視
  if (["auth/user-not-found", "auth/wrong-password"].includes(error.code)) {
    return;
  }

  const errorLog: ErrorLogData = {
    userId: userId ? userId : undefined,
    platform: Platform.OS,
    errorPoint: errorPoint,
    errorCode: error.code,
    errorMassage: error.message,
  };
  firebase.functions().httpsCallable("createErrorLog")(errorLog);
};
