import React from "react";
import { Linking, Pressable, Text, View } from "react-native";

type Props = {
  /** 対象のURL */
  url: string;
  /** 対象のテキスト */
  text: string;
};

/** リンクを作成します */
export const HyperLink = ({ url, text }: Props) => {
  return (
    <View>
      <Pressable
        onPress={() => {
          Linking.canOpenURL(url)
            .then(supported => {
              if (!supported) {
                console.log("無効なURLです: " + url);
              } else {
                return Linking.openURL(url);
              }
            })
            .catch(err => console.error("URLを開けませんでした。", err));
        }}
      >
        <Text style={{ color: "blue" }}>{text}</Text>
      </Pressable>
    </View>
  );
};
