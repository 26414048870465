import React, { DragEvent } from "react";
import { IsWeb } from "utils";

/** ドラッグ＆ドロップで画像をアップロードできる範囲を選択します */
export const ImageDropArea = ({ children, onDrop }: any) => {
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onDrop(e);
  };

  return (
    <>
      {IsWeb() ? (
        <div onDragOver={handleDragOver} onDrop={handleDrop}>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
