import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import {
  AcquiredTable,
  AddButton,
  AddModal,
  ExpirationModal,
  Header,
  Loading,
  ValidTable,
} from "components";
import dayjs from "dayjs";
import { StatusBar } from "expo-status-bar";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { Item } from "react-native-picker-select";
import {
  FetchPaidVacationRequest,
  FetchUserItems,
} from "server/collectionConnect";
import {
  ExpirationModalStatus,
  PaidVacationCollection,
  PaidVacationList,
  PaidVacationModalStatus,
  PaidVacationSettingType,
} from "types";

export const PaidVacationScreen = ({
  navigation,
}: RootStackScreenProps<"PaidVacation">) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setAlert } = useContext(DialogContext);
  const { userInfo } = useContext(UserInfoContext);
  const {
    PaidVacationCollection,
    CompanyCollection,
    UserCollection,
    RequestCollection,
    onSnapShot,
  } = useContext(CollectionContext);
  const [editModal, setEditModal] = useState<PaidVacationModalStatus>(null);
  const [expirationModal, setExpirationModal] =
    useState<ExpirationModalStatus>(null);
  const [isValidity, setIsValidity] = useState<number>(1);
  const [paidVacation, setPaidVacation] =
    useState<PaidVacationSettingType | null>(null);
  const [userList, setUserList] = useState<Item[]>([]);
  const { height, width } = useWindowDimensions();

  const fetchPaidVacations = () => {
    try {
      const paidVacationList = PaidVacationCollection?.map(
        (res: PaidVacationCollection) =>
          ({
            ...res,
            grantAt: dayjs(res.grantAt?.toDate()).toDate(),
            lapseAt: dayjs(res.lapseAt?.toDate()).toDate(),
          } as PaidVacationList)
      );
      setPaidVacation({
        ...paidVacation,
        paidVacationList: paidVacationList,
      });
    } catch {
      setAlert({
        isAlert: true,
        msg: "データが取得できませんでした",
      });
    }
  };

  const fetchPaidVacationAcquiredList = () => {
    try {
      const result = FetchPaidVacationRequest(RequestCollection);
      setPaidVacation({
        ...paidVacation,
        acquiredList: result,
      });
    } catch {
      setAlert({
        isAlert: true,
        msg: "データが取得できませんでした",
      });
    }
  };

  const fetchUserList = async () => {
    try {
      const users = FetchUserItems(UserCollection);
      setUserList(users);
    } catch {
      setAlert({
        isAlert: true,
        msg: "データが取得できませんでした",
      });
    }
  };

  const fetchExpiration = () => {
    try {
      setPaidVacation({
        ...paidVacation,
        expiration: CompanyCollection?.expiration ?? 0,
      });
    } catch {
      setAlert({
        isAlert: true,
        msg: "データが取得できませんでした",
      });
    }
  };

  useEffect(() => {
    if (
      userList.length === 0 ||
      PaidVacationCollection === null ||
      RequestCollection === null
    ) {
      onSnapShot("company");
      onSnapShot("user");
      onSnapShot("paidVacation");
      onSnapShot("request");
      setIsLoading(true);
      return;
    }
    if (isValidity === 1) fetchPaidVacations();
    if (isValidity === 2) fetchPaidVacationAcquiredList();
    setIsLoading(false);
  }, [PaidVacationCollection, RequestCollection, userList, isValidity]);

  useEffect(() => {
    if (CompanyCollection === null) {
      onSnapShot("company");
      return;
    }
    fetchExpiration();
  }, [CompanyCollection]);

  useEffect(() => {
    if (UserCollection === null) {
      onSnapShot("user");
      return;
    }
    fetchUserList();
  }, [UserCollection]);

  return (
    <>
      {isLoading ? (
        <View style={[styles.container, styles.detailContainer]}>
          <StatusBar style="auto" />
          <Loading isLoading={isLoading} />
        </View>
      ) : (
        <>
          <View style={styles.listContainer}>
            <Header
              onEditClick={() =>
                setExpirationModal({
                  id: "",
                  isEdit: true,
                  expiration: paidVacation?.expiration ?? 2,
                })
              }
              onChangeValidity={e => setIsValidity(e)}
              validity={isValidity}
              expiration={paidVacation?.expiration}
            />
            {isValidity === 1 ? (
              <ValidTable
                onEditClick={e =>
                  setEditModal({ id: e.id ?? "", isEdit: true })
                }
                tableList={paidVacation}
                UserCollection={UserCollection ?? []}
              />
            ) : (
              <AcquiredTable
                onEditClick={e => {}}
                tableList={paidVacation?.acquiredList ?? null}
                UserCollection={UserCollection ?? []}
              />
            )}
          </View>
          <AddButton
            onClick={() => setEditModal({ id: null, isEdit: false })}
          />
          <AddModal
            modalStatus={editModal}
            onCloseModal={() => setEditModal(null)}
            expiration={paidVacation?.expiration ?? 0}
            userList={userList}
          />
          <ExpirationModal
            modalStatus={expirationModal}
            onCloseModal={() => setExpirationModal(null)}
          />
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    paddingTop: 32,
    paddingLeft: 10,
    paddingRight: 10,
  },
  detailContainer: {
    marginBottom: 16,
  },
  listContainer: {
    flex: 8,
    backgroundColor: "#fff",
    paddingTop: 32,
    paddingLeft: 10,
    paddingRight: 10,
  },
});
