import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { EditPage, PageLoading, PreviewPage } from "components";
import firebase from "firebase/compat/app";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useEffect, useState } from "react";
import { Image } from "react-native";
import { Item } from "react-native-picker-select";
import {
  CreateNoteBookPage,
  FetchNoteBookPage,
  FetchNoteBooksSelect,
  UpdateNoteBookPage,
  UpdateNoteBookPageSort,
} from "server/collectionConnect";
import {
  ImageData,
  ImageDataSources,
  NoteBookCollection,
  NoteBookPageData,
  PageStatus,
} from "types";
import { NoteBookImagePutStrage } from "utils";

/** Wikiのページ・プレビューの表示 */
export function NoteBookPageScreen({
  navigation,
  route,
}: RootStackScreenProps<"NoteBookPage">) {
  const { userInfo } = useContext(UserInfoContext);
  const { NoteBookCollection, clearSnapShot, onSnapShot } =
    useContext(CollectionContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const noteBookId = route.params.noteBookId;
  const noteBookPageId = route.params.noteBookPageId;
  const [isNew] = useState<boolean>(noteBookPageId === undefined);
  const [noteBookPage, setNoteBookPage] = useState<NoteBookPageData | null>(
    null
  );
  const [noteBooksSelect, setNoteBooksSelect] = useState<Item[]>([]);
  const [imgDataSources, setImgDataSources] = useState<ImageDataSources>({
    data: [],
  });
  const [status, setStatus] = useState<PageStatus>(
    noteBookPageId === undefined ? "edit" : "preview"
  );

  /** 該当のブック情報を取得 */
  const fetchNoteBookPage = async () => {
    const result = await FetchNoteBookPage(
      NoteBookCollection ?? [],
      noteBookId,
      noteBookPageId ?? ""
    );
    await imageConvert(result);
    return result;
  };

  /** ノート編集時に画像をURL→__URL__に変換を行い、画像データをstateへ保管する */
  const imageConvert = async (pageData: NoteBookPageData) => {
    if (pageData === undefined) return;
    /** Wikiの本文 */
    var targetString = pageData.text;
    /** ![Upload1]形式の配列型 */
    // 本文から取得
    const uploadList = targetString.match(/\!\[(.+)\]/g);
    // 変換対象の画像が存在しなければデータをstateに保存して終了
    if (uploadList === null) {
      setNoteBookPage(pageData);
      setImgDataSources({
        data: [],
      });
      return;
    }
    /** Uploadの番号 */
    var index = 0;
    /** 画像データ */
    const images = await Promise.all(
      uploadList.map(async m => {
        /** Upload{number} */
        const uploadString = m.replace("![", "").replace("]", "");
        /** URLを取得するための正規表現を作成（![Upload]( xxx )） */
        const urlSearch = new RegExp(uploadString + `\]\((.+)\)`, "g");
        /** 本文中のurlのリスト */
        const urlResult = targetString.match(urlSearch);
        if (urlResult === null) return null;
        if (urlResult[0].match("__URL__") !== null) return null;
        /** URL */
        // 検索結果が取得していればURLのみに変換
        const url = urlResult[0]
          .replace(uploadString + "](", "")
          .replace(")", "");
        /** 画像のサイズ */
        const size = await getImageSize(url);
        // もし画像が見つからないなどのエラーに遭遇した場合は、画像自体を削除する
        if (size === null) {
          targetString = targetString.replace(m + "(" + url + ")", "");
          return null;
        }

        // 本文中の画像文字列を変換
        targetString = targetString.replace(m, "![Upload" + index + "]");
        targetString = targetString.replace(
          "![Upload" + index + "]" + "(" + url + ")",
          "![Upload" + index + "]" + "(__URL__)"
        );
        index = index + 1;
        return {
          docId: uploadString,
          url: url,
          id: uploadString.replace("Upload", ""),
          title: "",
          height: size.height,
          width: size.width,
        } as ImageData;
      })
    );
    // 本文データ・画像データを更新する
    const result = pageData;
    result.text = targetString;
    setNoteBookPage(result);
    setImgDataSources({ data: images.filter(f => f !== null) as ImageData[] });
  };

  /**画像データが格納された時に動作する。
   * 画像データから実際のサイズを取得し、stateを更新する */
  useEffect(() => {
    const replaceImageSize = async () => {
      await Promise.all(
        imgDataSources.data.map(async (m, index) => {
          if (m.height !== 0 && m.width !== 0) return;
          const size = await getImageSize(m.url);
          if (size === null) return;
          const target = {
            ...m,
            width: size.width,
            height: size.height,
          };
          setImgDataSources({
            data: imgDataSources.data.map((m2, index2) =>
              index2 === index ? target : m2
            ),
          });
        })
      );
    };
    replaceImageSize();
  }, [imgDataSources]);

  /** ページに関連するデータが更新された時・初期読み込み時に動作する
   */
  useEffect(() => {
    setIsLoading(true);
    if (NoteBookCollection === null) {
      onSnapShot("notebook");
      return;
    }
    const getData = async () => {
      fetchNoteBooksSelect();
      await fetchNoteBookPage();
      setIsLoading(false);
    };
    getData();
  }, [NoteBookCollection, noteBookId, noteBookPageId]);

  /** プレビュ・編集の切り替えを行った時に動作します
   * URLと__URL__を切り替えて画像を表示できるように変換します
   */
  useEffect(() => {
    if (noteBookPage === null) return;
    if (status !== "edit") {
      let imgmarkdown = noteBookPage.text;
      imgDataSources.data.forEach((imgDataSource, index) => {
        imgmarkdown = imgmarkdown.replace(
          "![Upload" + index + "](__URL__)",
          "![Upload" + index + "](" + imgDataSource.url + ")"
        );
      });

      setNoteBookPage({
        ...noteBookPage,
        text: imgmarkdown,
      });
    } else {
      let imgmarkdown = noteBookPage.text;
      imgDataSources.data.forEach((imgDataSource, index) => {
        imgmarkdown = imgmarkdown.replace(
          "![Upload" + index + "](" + imgDataSource.url + ")",
          "![Upload" + index + "](__URL__)"
        );
      });

      setNoteBookPage({
        ...noteBookPage,
        text: imgmarkdown,
      });
    }
  }, [status]);

  /** 画像を挿入する動作を行った時に動作します
   * 画像データとリンクする文字列を生成します
   */
  const InsertImageString = (words: string[]) => {
    if (noteBookPage === null) return;
    var imgmarkdownText = noteBookPage.text ? noteBookPage.text + "\n" : "";
    words.map(m => {
      if (imgmarkdownText.indexOf("![" + m + "]") == -1) {
        imgmarkdownText += "![" + m + "](__URL__)";
      }
    });

    setNoteBookPage({
      ...noteBookPage,
      text: imgmarkdownText,
    });
  };

  /** 登録・更新時に実行するバリデーションです */
  const validationCheck = (): boolean => {
    if (noteBookPage === null) return false;
    if (!noteBookPage.noteBookId) {
      setAlert({ isAlert: true, msg: "ノートブックを選択してください" });
      return false;
    }
    if (!noteBookPage.sort) {
      setAlert({ isAlert: true, msg: "ページ順序の並びを入力してください" });
      return false;
    }
    if (!Number.isInteger(noteBookPage.sort)) {
      setAlert({ isAlert: true, msg: "ページ順序は数値で入力してください" });
      return false;
    }
    if (!noteBookPage.pageTitle) {
      setAlert({ isAlert: true, msg: "ページタイトルを入力してください" });
      return false;
    }
    if (!noteBookPage.text) {
      setAlert({ isAlert: true, msg: "本文を入力してください" });
      return false;
    }
    return true;
  };

  /** ブックのセレクトデータを取得します */
  const fetchNoteBooksSelect = () => {
    try {
      const target = FetchNoteBooksSelect(NoteBookCollection);
      setNoteBooksSelect(target);
    } catch (error: any) {
      setAlert({ isAlert: true, msg: error.message });
    }
  };

  const GetNoteBookPageDocRef = (noteId: string, pageId: string) => {
    return firebase
      .firestore()
      .collection("noteBook")
      .doc(noteId)
      .collection("noteBookPage")
      .doc(pageId);
  };

  /** 画像を登録する際に、既存の画像かどうかを判定し、新規の画像のみを登録します */
  const updateTargetSource = () => {
    return {
      data: imgDataSources.data.filter(f => {
        const head = f.url.slice(0, 4);
        return head === "data";
      }),
    };
  };

  /** Wikiページの新規登録を行います */
  const createNoteBookPage = async (): Promise<void> => {
    if (!validationCheck() || noteBookPage === null) {
      return;
    }

    setIsLoading(true);
    try {
      clearSnapShot("notebook");
      const sortNumber = updateSortNumber(
        NoteBookCollection ?? [],
        noteBookPage
      );
      noteBookPage.sort = sortNumber;
      const result = await CreateNoteBookPage(
        userInfo,
        NoteBookCollection,
        noteBookPage
      );
      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
        return;
      }

      const images = updateTargetSource();
      if (images.data.length > 0) {
        await NoteBookImagePutStrage(
          result.id ?? "",
          noteBookPage.text,
          images,
          "noteBookPage",
          GetNoteBookPageDocRef(noteBookId, result.id ?? "")
        );
      }
      const result2 = await UpdateNoteBookPageSort(noteBookPage);
      if (result2.isCheck) {
        noteBookPage.id = result.id ?? "";
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
            navigation.navigate("NoteBookList", {
              id: noteBookId,
            });
          },
        });
      } else {
        setAlert({
          isAlert: true,
          msg: result2.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
      }
    } catch {
      setAlert({ isAlert: true, msg: "登録に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  /** Wikiページの更新を行います */
  const updateNoteBookPage = async (): Promise<void> => {
    if (!validationCheck() || noteBookPage === null) {
      return;
    }

    setIsLoading(true);
    try {
      clearSnapShot("notebook");
      const sortNumber = updateSortNumber(
        NoteBookCollection ?? [],
        noteBookPage
      );
      noteBookPage.sort = sortNumber;
      const result = await UpdateNoteBookPage(
        userInfo,
        NoteBookCollection,
        noteBookPage
      );
      if (!result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
        return;
      }
      const images = updateTargetSource();
      if (images.data.length > 0) {
        await NoteBookImagePutStrage(
          result.id ?? "",
          noteBookPage.text,
          imgDataSources,
          "noteBookPage",
          GetNoteBookPageDocRef(noteBookId, result.id ?? "")
        );
      }
      const result2 = await UpdateNoteBookPageSort(noteBookPage);
      if (result2.isCheck) {
        noteBookPage.id = result.id ?? "";
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
            navigation.navigate("NoteBookList", {
              id: noteBookId,
            });
          },
        });
      } else {
        setAlert({
          isAlert: true,
          msg: result2.message,
          afterExec: () => {
            // 更新を再開
            onSnapShot("notebook");
          },
        });
      }
    } catch (e) {
      console.log(e);
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  /** ドラッグアンドドロップで画像を挿入した時のアクションを定義します */
  const handleDrop = async (e: any) => {
    const imageDataArray: ImageData[] = [];
    let count = 0;
    const traverseFileTree = async (entry: any, path: any) => {
      const _path = path || "";

      if (entry.isFile) {
        const file: any = await new Promise(resolve => {
          entry.file((file: any) => {
            resolve(file);
          });
        });

        let extension = file.name.split(".").pop();
        if (extension && !["png", "PNG", "jpg", "jpeg"].includes(extension)) {
          setAlert({
            isAlert: true,
            msg: "ファイル形式はpngまたはjpegを指定してください",
          });
          return;
        }

        const reader = new FileReader();
        reader.onload = (event: any) => {
          const index = imgDataSources.data.length;
          imageDataArray.push({
            docId: "Upload" + index,
            id: String(count),
            url: event.currentTarget.result,
            title: "image" + (count + 1),
            height: 0,
            width: 0,
          });
          setImgDataSources({
            ...imgDataSources.data,
            data: imgDataSources.data.concat(imageDataArray),
          });
          InsertImageString(["Upload" + index]);
          count++;
        };
        reader.readAsDataURL(file);
      } else if (entry.isDirectory) {
        const directoryReader = entry.createReader();
        const entries: any = await new Promise(resolve => {
          directoryReader.readEntries((entries: any) => {
            resolve(entries);
          });
        });

        for (let i = 0; i < entries.length; i++) {
          await traverseFileTree(entries[i], _path + entry.name + "/");
        }
      }
    };

    const items = e.dataTransfer.items;
    for (let i = 0; i < items.length; i++) {
      const entry = items[i].webkitGetAsEntry();
      traverseFileTree(entry, "");
    }
  };

  /** ファイルアップロードの動作を定義します */
  const updateImgDataSources = (
    images: React.SetStateAction<ImageDataSources>
  ): void => {
    setImgDataSources(images);
  };

  /** 画像のサイズを取得します
   * @param uri
   * @returns size{ width:number, height:number}
   */
  function getImageSize(
    uri: string
  ): Promise<{ width: number; height: number } | null> {
    return new Promise((resolve, reject) => {
      Image.getSize(
        uri,
        (width, height) => {
          resolve({ width, height });
        },
        error => {
          resolve(null);
        }
      );
    });
  }

  if (isLoading) return <PageLoading isLoading={isLoading} />;

  return (
    <>
      {noteBookPage && status !== "edit" && (
        <PreviewPage
          data={noteBookPage}
          status={status}
          onSubmit={() => {
            if (status === "preview") setStatus("edit");
            if (status === "result") {
              if (isNew) createNoteBookPage();
              if (!isNew) updateNoteBookPage();
            }
          }}
          onCancel={() => {
            if (status === "preview")
              navigation.navigate("NoteBookList", {
                id: noteBookId,
              });
            if (status === "result") setStatus("edit");
          }}
          images={imgDataSources}
        />
      )}

      {noteBookPage && status === "edit" && (
        <EditPage
          data={noteBookPage}
          status={status}
          images={imgDataSources}
          notebooks={noteBooksSelect}
          onSubmit={() => setStatus("result")}
          onCancel={() =>
            navigation.navigate("NoteBookList", {
              id: noteBookId,
            })
          }
          setState={setNoteBookPage}
          updateImgDataSources={updateImgDataSources}
          insertImageString={InsertImageString}
          handleDrop={handleDrop}
        />
      )}
    </>
  );
}

const updateSortNumber = (
  NoteBookCollection: NoteBookCollection[],
  notebookPageData: NoteBookPageData
) => {
  const targetNote = NoteBookCollection?.filter(
    f => f.id === notebookPageData.noteBookId
  ).shift();
  if (targetNote === undefined) return notebookPageData.sort - 0.1;
  const target = targetNote.notebookPage
    .filter(f => f.id === notebookPageData.id)
    .shift();
  if (target === undefined) return notebookPageData.sort - 0.1;
  if ((target.sort ?? 0) < notebookPageData.sort)
    return notebookPageData.sort + 0.1;
  return notebookPageData.sort - 0.1;
};
