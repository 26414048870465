import dayjs from "dayjs";
import { Item } from "react-native-picker-select";
import { AttendanceCollection } from "types";
import { AscSort } from "utils";

/**
 *　申請のプルダウンに表示する勤怠りストを返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param userInfo userContextから取得した情報
 * @param attendances collectionContextから取得した勤怠情報
 * @returns
 */
export const FetchWorkScheduleList = (
  userId: string,
  attendance: AttendanceCollection[] | null,
  targetDate: Date,
  isCancel: boolean
): Item[] => {
  if (attendance === null) return [] as Item[];
  const lastMonthStartOfDay = dayjs(targetDate)
    .subtract(1, "month")
    .startOf("month");
  const currentMonthEndOfDay = dayjs(targetDate).endOf("month");
  // 対象月（先月1日～当月末）の勤怠情報を取得
  const attendances = attendance
    .filter(f => f.userId === userId)
    .filter(f =>
      dayjs(f.startWorkSchedule?.toDate()).isBetween(
        lastMonthStartOfDay,
        currentMonthEndOfDay
      )
    )
    .filter(f => !f.isCancel ?? false)
    .sort((a, b) => AscSort(a.startWorkSchedule, b.startWorkSchedule));

  // 出勤取消の時は、実出勤が存在しているもののみを表示
  if (isCancel) {
    return attendances.map(f => ({
      label: dayjs(f.startWorkSchedule?.toDate()).format("YYYY/MM/DD"),
      value: f.id,
    }));
  }
  const target = [
    ...[
      ...Array(dayjs(lastMonthStartOfDay).endOf("month").toDate().getDate()),
    ].map((_, ii) => {
      const index = ii + 1;
      /** 開始対象日 */
      const targetStartDate = dayjs(lastMonthStartOfDay)
        .date(index)
        .startOf("date")
        .toDate();

      /** 終了対象日 */
      const targetEndDate = dayjs(lastMonthStartOfDay)
        .date(index)
        .endOf("date")
        .toDate();
      const te = attendances
        .filter(f =>
          dayjs(f.startWorkSchedule?.toDate()).isBetween(
            targetStartDate,
            targetEndDate,
            null,
            "[]"
          )
        )
        .shift();
      if (te === undefined)
        return {
          label: dayjs(targetEndDate).format("YYYY/MM/DD"),
          value: dayjs(targetEndDate).format("YYYY/MM/DD"),
        };
      return {
        label: dayjs(te.startWorkSchedule?.toDate()).format("YYYY/MM/DD"),
        value: te.id,
      } as Item;
    }),
    ...[
      ...Array(dayjs(currentMonthEndOfDay).endOf("month").toDate().getDate()),
    ].map((_, ii) => {
      const index = ii + 1;
      /** 開始対象日 */
      const targetStartDate = dayjs(currentMonthEndOfDay)
        .date(index)
        .startOf("date")
        .toDate();

      /** 終了対象日 */
      const targetEndDate = dayjs(currentMonthEndOfDay)
        .date(index)
        .endOf("date")
        .toDate();
      const te = attendances
        .filter(f =>
          dayjs(f.startWorkSchedule?.toDate()).isBetween(
            targetStartDate,
            targetEndDate,
            null,
            "[]"
          )
        )
        .shift();
      if (te === undefined)
        return {
          label: dayjs(targetEndDate).format("YYYY/MM/DD"),
          value: dayjs(targetEndDate).format("YYYY/MM/DD"),
        };
      return {
        label: dayjs(te.startWorkSchedule?.toDate()).format("YYYY/MM/DD"),
        value: te.id,
      } as Item;
    }),
  ];
  return target;
};
