import {
  NativeStackScreenProps,
  createNativeStackNavigator,
} from "@react-navigation/native-stack";
import dayjs from "dayjs";
import React from "react";
import {
  AddAttendanceDataScreen,
  AttendanceDownloadScreen,
  AttendanceScreen,
  CalendarDailyScreen,
  CalendarPersonalScreen,
  CalendarScreen,
  ConfigurationScreen,
  DashboardScreen,
  EmployeeManagementScreen,
  ExpenseLimitSettingScreen,
  ExpensesDownloadScreen,
  HolidaysScreen,
  HomeScreen,
  NoteBookListScreen,
  NoteBookPageScreen,
  PaidVacationScreen,
  ProfileScreen,
  RequestScreen,
  ShiftManagementScreen,
  SignInScreen,
  SiteScreen,
  UserScreen,
  WikiScreen,
} from "screens";
import { RequestModalStatusType } from "types/Request";
import { User } from "types/User";

/**
 * Navigationする先のリストを定義
 * 形情報はnavigateするときの引数を使用する場合はここで記載してください
 */
export type RootStackParamList = {
  SignIn: undefined;
  Home: undefined;
  Calendar: undefined;
  CalendarDaily: { user?: User; date: dayjs.Dayjs };
  CalendarPersonal: { user: User };
  Attendance: undefined;
  Request: { target?: RequestModalStatusType };
  Configuration: undefined;
  Site: undefined;
  EmployeeManagement: undefined;
  Holidays: undefined;
  PaidVacation: undefined;
  ExpensesDownload: undefined;
  User: undefined;
  ShiftManagement: undefined;
  Dashboard: undefined;
  Profile: undefined;
  AttendanceDownload: undefined;
  Wiki: undefined;
  NoteBookList: { id: string };
  NoteBookPage: { noteBookId: string; noteBookPageId?: string };
  ExpenseLimitSetting: undefined;
  AddAttendanceData: undefined;
};

/**
 * スクリーン側で使用する型定義
 */
export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
  NativeStackScreenProps<RootStackParamList, Screen>;

const Stack = createNativeStackNavigator<RootStackParamList>();

export function Stacks() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SignIn"
        component={SignInScreen}
        options={({ route }) => ({
          title: "ADDGREEN",
        })}
      />
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={({ route }) => ({
          title: "ホーム",
          headerLeft: () => null,
          gestureEnabled: false,
          headerBackVisible: false,
        })}
      />
      <Stack.Screen
        name="Calendar"
        component={CalendarScreen}
        options={({ route }) => ({
          title: "カレンダー",
        })}
      />
      <Stack.Screen
        name="CalendarDaily"
        component={CalendarDailyScreen}
        options={({ route }) => ({
          title: "当日予定",
        })}
      />
      <Stack.Screen
        name="CalendarPersonal"
        component={CalendarPersonalScreen}
        options={({ route }) => ({
          title: route.params.user.name,
        })}
      />
      <Stack.Screen
        name="Attendance"
        component={AttendanceScreen}
        options={({ route }) => ({
          title: "勤怠管理",
        })}
      />
      <Stack.Screen
        name="Request"
        component={RequestScreen}
        options={({ route }) => ({
          title: "勤怠申請一覧",
        })}
      />
      <Stack.Screen
        name="Configuration"
        component={ConfigurationScreen}
        options={({ route }) => ({
          title: "設定",
        })}
      />
      <Stack.Screen
        name="Site"
        component={SiteScreen}
        options={({ route }) => ({
          title: "現場管理",
        })}
      />
      <Stack.Screen
        name="EmployeeManagement"
        component={EmployeeManagementScreen}
        options={({ route }) => ({
          title: "従業員管理",
        })}
      />
      <Stack.Screen
        name="Holidays"
        component={HolidaysScreen}
        options={({ route }) => ({
          title: "休日設定",
        })}
      />
      <Stack.Screen
        name="PaidVacation"
        component={PaidVacationScreen}
        options={({ route }) => ({
          title: "有給設定",
        })}
      />
      <Stack.Screen
        name="ExpensesDownload"
        component={ExpensesDownloadScreen}
        options={({ route }) => ({
          title: "経費ダウンロード",
        })}
      />
      <Stack.Screen
        name="User"
        component={UserScreen}
        options={({ route }) => ({
          title: "社員管理",
        })}
      />
      <Stack.Screen
        name="ShiftManagement"
        component={ShiftManagementScreen}
        options={({ route }) => ({
          title: "シフト管理",
        })}
      />
      <Stack.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={({ route }) => ({
          title: "ダッシュボード",
        })}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={({ route }) => ({
          title: "プロフィール設定",
        })}
      />
      <Stack.Screen
        name="AttendanceDownload"
        component={AttendanceDownloadScreen}
        options={({ route }) => ({
          title: "勤務実績ダウンロード",
        })}
      />
      <Stack.Screen
        name="Wiki"
        component={WikiScreen}
        options={({ route }) => ({
          title: "社内Wiki",
        })}
      />
      <Stack.Screen
        name="NoteBookList"
        component={NoteBookListScreen}
        options={({ route }) => ({
          title: "社内Wiki",
        })}
      />
      <Stack.Screen
        name="NoteBookPage"
        component={NoteBookPageScreen}
        options={({ route }) => ({
          title: "ノートブックページ",
        })}
      />
      <Stack.Screen
        name="ExpenseLimitSetting"
        component={ExpenseLimitSettingScreen}
        options={({ route }) => ({
          title: "経費枠設定",
        })}
      />
      <Stack.Screen
        name="AddAttendanceData"
        component={AddAttendanceDataScreen}
        options={({ route }) => ({
          title: "勤怠追加（テストモード）",
        })}
      />
    </Stack.Navigator>
  );
}
