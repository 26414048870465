import dayjs from "dayjs";
import { HolidayCollection, ViewHolidays } from "types";

/**
 * 整形した休暇設定表示用情報を返却します
 * @param holidays collectionContextから取得した休暇情報
 * @param targetDate 対象の日付
 * @returns ViewHolidays
 */
export const FetchHolidayTargetMonth = (
  holidays: HolidayCollection[] | null,
  targetDate: Date
): ViewHolidays => {
  if (holidays === null) return {} as ViewHolidays;
  const today = dayjs(new Date()).toDate();

  const monthBeginning = dayjs(targetDate)
    .startOf("month")
    .subtract(1, "week")
    .toDate();
  const monthEnd = dayjs(targetDate).endOf("month").add(1, "week").toDate();

  const targetHolidays = holidays
    .filter(f => f.targetAt?.toDate() >= monthBeginning)
    .filter(f => f.targetAt?.toDate() <= monthEnd);

  if (targetHolidays === null) return {} as ViewHolidays;

  return targetHolidays.reduce((accumulator, value) => {
    return {
      ...accumulator,
      [dayjs(value.targetAt.toDate()).format("YYYY-MM-DD")]: {
        selected: true,
        selectedColor: "tomato",
      },
    } as ViewHolidays;
  }, {});
};
