import firebase from "firebase/compat/app";
import { CreateCheckResult, UserInfo } from "types";

/**
 * 休日情報を新規作成の書き込みを行います
 * @param reqUser userContextから取得した情報
 * @param targetMonth 登録予定の日付
 * @returns
 */
export const CreateHoliday = async (
  reqUser: UserInfo | null,
  targetMonth: Date
): Promise<CreateCheckResult> => {
  const date = new Date();

  return await firebase
    .firestore()
    .collection("holiday")
    .add({
      title: "会社休",
      targetAt: targetMonth,
      createAt: date,
      createUser: reqUser?.user.id,
    })
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
