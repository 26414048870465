import { CollectionContext, DialogContext } from "GlobalContext";
import {
  ExpenseApprovalForm,
  ExpenseForm,
  RequestApprovalForm,
  RequestForm,
} from "components";
import React, { useContext, useEffect, useState } from "react";
import { Text, TouchableHighlight, View } from "react-native";
import { Item } from "react-native-picker-select";
import { modalStyles } from "styles";
import { RequestModalStatus } from "types";
import { ModalLibrary } from "utils";

type Props = {
  modalStatus: RequestModalStatus;
  onCloseModal: () => void;
};

export function RequestModal({ modalStatus, onCloseModal }: Props) {
  const { setAlert } = useContext(DialogContext);
  const { SiteCollection, AttendanceCollection, RequestCollection } =
    useContext(CollectionContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [siteSelect, setSiteSelect] = useState<Item[]>([
    { label: "", value: "" },
  ]);

  const hideModal = () => {
    onCloseModal();
    setModalVisible(!modalVisible);
  };

  const fetchAllSites = async (): Promise<void> => {
    try {
      if (SiteCollection === null) return;
      const siteSelects = SiteCollection.filter(
        f => f.deleted === false && f.progressStatus === "inProgress"
      ).map(m => {
        return {
          label: m.name,
          value: m.id,
        } as Item;
      });
      setSiteSelect(siteSelects);
    } catch {
      setAlert({ isAlert: true, msg: "現場情報の取得に失敗しました" });
    }
  };

  useEffect(() => {
    fetchAllSites();
  }, [SiteCollection]);

  return (
    <ModalLibrary
      animationType="slide"
      visible={modalStatus !== null}
      ariaHideApp={false}
    >
      <View style={modalStyles.centeredView}>
        <TouchableHighlight
          onPress={() => {
            hideModal();
          }}
          underlayColor={"white"}
          style={modalStyles.closeButton}
        >
          <Text style={modalStyles.closeText}>&#0215;</Text>
        </TouchableHighlight>

        {modalStatus?.type === "request" ? (
          <RequestForm
            modalStatus={modalStatus}
            hideModal={hideModal}
            RequestCollection={RequestCollection ?? []}
            AttendanceCollection={AttendanceCollection ?? []}
          />
        ) : modalStatus?.type === "expense" ? (
          <ExpenseForm
            hideModal={hideModal}
            modalStatus={modalStatus}
            siteSelect={siteSelect}
          />
        ) : modalStatus?.type === "requestApprovalWait" ? (
          <RequestApprovalForm
            modalStatus={modalStatus}
            hideModal={hideModal}
            RequestCollection={RequestCollection ?? []}
            AttendanceCollection={AttendanceCollection ?? []}
          />
        ) : modalStatus?.type === "expenseApprovalWait" ? (
          <ExpenseApprovalForm
            modalStatus={modalStatus}
            hideModal={hideModal}
            siteSelect={siteSelect}
          />
        ) : modalStatus?.type === "attendanceHistory" ? (
          <RequestForm
            modalStatus={modalStatus}
            hideModal={hideModal}
            RequestCollection={RequestCollection ?? []}
            AttendanceCollection={AttendanceCollection ?? []}
          />
        ) : (
          <></>
        )}
      </View>
    </ModalLibrary>
  );
}
