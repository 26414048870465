import firebase from "firebase/compat/app";
import { History, RequestCollection } from "types";

/**
 * requestCollectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const RequestOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<RequestCollection[] | null>>
) => {
  return firebase
    .firestore()
    .collection("request")
    .onSnapshot(
      async docs => {
        const collectionList = await Promise.all(
          await docs.docs.map(async doc => {
            const history = await GetHistory(doc);
            return {
              id: doc.id,
              requestHistory: history,
              ...doc.data(),
            } as RequestCollection;
          })
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};

/**
 * requestコレクション内のHistoryを取得するメソットです
 * @param doc：firebase.collectionオブジェクト
 * @returns History
 */
const GetHistory = async (doc: firebase.firestore.DocumentSnapshot) => {
  return await doc.ref
    .collection("requestHistory")
    .get()
    .then(details =>
      details.docs.map(
        detail => ({ ...detail.data(), id: detail.id } as History)
      )
    )
    .catch(() => [] as History[]);
};
