import dayjs from "dayjs";
import { RequestCollection, UserInfo } from "types";
/**
 * 当月の残業時間の合計値を集計して返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param requests collectionContextから取得した申請情報
 * @returns DashboardsDataType[]
 */
export const FetchOverTime = (
  userInfo: UserInfo | null,
  requests: RequestCollection[] | null
) => {
  if (requests === null) return "0分";

  const monthFrom = dayjs().startOf("month").toDate();
  const userId = userInfo?.user.id;

  const overTimeList = requests
    .filter(request => request.userId === userId)
    .filter(
      request =>
        dayjs((request?.acquisitionStartAt?.seconds ?? 0) * 1000).toDate() >=
        monthFrom
    )
    .filter(request => request.requestType === "overtime")
    .filter(request => request.status === "approval")
    .map(request => ({
      start: request.acquisitionStartAt,
      end: request.acquisitionEndAt,
    }));

  if (overTimeList.length > 0) {
    // 当月内の残業申請時間（開始～終了）の差分合計を算出
    const total = overTimeList.reduce(function (
      sum: number,
      elem: {
        start: FirebaseFirestore.Timestamp | null;
        end: FirebaseFirestore.Timestamp | null;
      }
    ) {
      const from = elem.start?.seconds ?? 0;
      const to = elem.end?.seconds ?? 0;
      return sum + to - from;
    }, 0);
    return `${Math.floor(total / (60 * 60))}時間${Math.floor(
      (total % (60 * 60)) / 60
    )}分`;
  }
  return "0分";
};
