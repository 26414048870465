import { WindowContext } from "GlobalContext";
import { Box } from "components";
import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import { IconList, NoteBookDeleteList } from "types";
import { IsMobile } from "utils/CheckPlatform";

type Props = {
  /** ボタンクリック時のアクション定義 */
  onClick: (id: string) => void;
  /** 削除操作中かどうか
   *
   * 削除処理：true
   */
  deleting?: boolean;
  /** 削除チェックをクリックした時の処理 */
  onDeleteClick?: (id: string, check: boolean) => void;
  /** アイコン一覧 */
  iconList: IconList[] | null;
  /** 削除リスト一覧 */
  deleteList: NoteBookDeleteList[] | null;
};

/** ページ遷移用のボックスリストを描写します */
export const BoxList = ({
  onClick,
  onDeleteClick,
  iconList,
  deleting,
  deleteList,
}: Props) => {
  const { windowHeight, windowWidth } = useContext(WindowContext);
  if (iconList === null) return <View></View>;
  if (iconList.length === 0) return <View>閲覧できるWikiは存在しません</View>;

  return (
    <View
      style={[
        styles.box_container,
        IsMobile() && {
          paddingBottom: windowHeight <= 400 ? 200 + 430 - windowHeight : 0,
        },
      ]}
    >
      {iconList.map(icon => (
        <Box
          key={icon.id}
          onClick={() => onClick(icon.id)}
          onDeleteClick={check =>
            onDeleteClick && onDeleteClick(icon.id, check)
          }
          isDelete={
            deleteList?.filter(d => d.id === icon.id).shift()?.isDelete ?? false
          }
          deleting={deleting}
          text={icon.text}
          iconName={icon.iconName}
          iconType={icon.iconType}
          iconColor={icon.iconColor}
          color="#7879F1"
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  box_container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
});
