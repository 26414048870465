import firebase from "firebase/compat/app";
import { CreateCheckResult, NoteBookCollection, UserInfo } from "types";

/**
 * Wikiの削除を行います
 * @param userInfo userContextから取得したユーザー情報
 * @param ids 削除対象のIDの配列
 * @param noteBooks collectionContextから取得したWiki情報
 * @returns　CreateCheckResult
 */
export const DeleteNoteBooks = async (
  userInfo: UserInfo | null,
  ids: string[],
  noteBooks: NoteBookCollection[] | null
): Promise<CreateCheckResult> => {
  const date = new Date();
  if (ids === null || noteBooks === null)
    return { isCheck: false, message: "削除対象が選択されていません" };

  ids.map(async id => {
    const target = noteBooks.filter(f => f.id === id).shift();
    if (target === undefined) return;

    const doc = await firebase.firestore().collection("noteBook").doc(id).get();
    await doc.ref
      .update({
        ...doc.data(),
        bookName: target.bookName,
        sort: target.sort,
        deleted: true,
        isManual: target.isManual,
        updateAt: date,
        updateUser: userInfo?.user.id,
      })
      .then(async () => {
        const noteBookPageSnapshop = await firebase
          .firestore()
          .collection("noteBook")
          .doc(id)
          .collection("noteBookPage")
          .get();

        noteBookPageSnapshop.docs.map(async notebook => {
          await firebase
            .firestore()
            .collection("noteBook")
            .doc(notebook.id)
            .update({
              deleted: true,
              updateAt: date,
              updateUser: userInfo?.user.id,
            });
        });
      });
  });
  return { isCheck: true, message: "削除しました" };
};
