import { UserCollection, UserData } from "types";
import { GetUserAuthority } from "./utils/GetUserAuthority";
/**
 *　整形したユーザー情報を返却します
 * @param userInfo userContextから取得した情報
 * @param users collectionContextから取得したユーザー情報
 * @returns
 */
export const FetchUser = (
  users: UserCollection[] | null,
  targetId: string
): UserData | null => {
  if (users === null || targetId === "") return null;
  const targetUser = users.filter(f => f.id === targetId).shift();

  if (targetUser === undefined) return null;

  return {
    id: targetUser.id,
    uid: targetUser.uid,
    authorityId: targetUser.authorityId,
    authorityTitle: GetUserAuthority(targetUser.authorityId).text,
    email: targetUser.email,
    name: targetUser.name,
    entryAt:
      targetUser.entryAt === null || targetUser.entryAt === undefined
        ? null
        : targetUser.entryAt.toDate(),
    retirementAt:
      targetUser.retirementAt === null || targetUser.retirementAt === undefined
        ? null
        : targetUser.retirementAt.toDate(),
    sort: targetUser.sort,
    password: "",
  } as UserData;
};
