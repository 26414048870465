import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  ExpenseCollection,
  ExpenseLimitSettingData,
} from "types";
import { GetUseAmount } from "../expense/utils";

/**
 * 経費枠の新規作成の書き込みを行います
 * @param expenseLimitSettingData 更新対象の経費枠情報
 * @returns　CreateCheckResult
 */
export const UpdateExpenseLimitSetting = async (
  expenseLimitSettingData: ExpenseLimitSettingData,
  expenses: ExpenseCollection[]
): Promise<CreateCheckResult> => {
  if (expenses === null)
    return { isCheck: false, message: "更新に失敗しました" };

  const date = new Date();

  const target = GetUseAmount(
    expenses,
    expenseLimitSettingData.id,
    expenseLimitSettingData.userId
  );

  if (target.firstDate !== null && target.lastDate !== null) {
    if (expenseLimitSettingData.deleted === true) {
      return {
        isCheck: false,
        message: "既に経費申請で使用されています。",
      };
    }
    if (target.amount > expenseLimitSettingData.amountLimit) {
      return {
        isCheck: false,
        message: "紐づく経費申請の金額合計を下回っています",
      };
    }
    if (
      target.firstDate.getTime() <
      expenseLimitSettingData.grantDate.toDate().getTime()
    ) {
      return {
        isCheck: false,
        message: "紐づく経費申請の利用日より後の付与日です",
      };
    }
    if (
      target.lastDate.getTime() >
      expenseLimitSettingData.expirationDate.toDate().getTime()
    ) {
      return {
        isCheck: false,
        message: "紐づく経費申請の利用日より前の有効期限です",
      };
    }
  }

  try {
    const doc = await firebase
      .firestore()
      .collection("expenseLimitSetting")
      .doc(expenseLimitSettingData.id)
      .get();

    return await doc.ref
      .update({
        ...doc.data(),
        amountLimit: expenseLimitSettingData.amountLimit,
        grantDate: expenseLimitSettingData.grantDate,
        expirationDate: expenseLimitSettingData.expirationDate,
        deleted: expenseLimitSettingData.deleted === true,
        updateAt: date,
      })
      .then(() => {
        if (expenseLimitSettingData.deleted === true)
          return { isCheck: true, message: "削除しました" };
        return { isCheck: true, message: "更新しました" };
      })
      .catch(() => {
        if (expenseLimitSettingData.deleted === true)
          return { isCheck: true, message: "削除に失敗しました" };
        return { isCheck: true, message: "更新に失敗しました" };
      });
  } catch (error: any) {
    return { isCheck: false, message: "更新に失敗しました" };
  }
};
