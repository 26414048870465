import { WindowContext } from "GlobalContext";
import { TableCell } from "components";
import React, { useContext } from "react";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { AttendanceHistoryList } from "types";
import { IsWeb } from "utils";
const tableHead = ["", "日", "出勤時間", "退勤時間", "申請有無"];

export const WebAttendanceHistoryTable = ({
  onEditClick,
  tableList,
}: {
  onEditClick: (date: number) => void;
  tableList: AttendanceHistoryList[];
}) => {
  const { windowHeight, windowWidth } = useContext(WindowContext);
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          height: windowHeight < 800 ? windowHeight - 100 : 500,
          width: IsWeb() ? 700 : windowWidth,
        }}
      >
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList, onEditClick)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

/**
 *
 * @param tableList テーブルの行をレンダリングします
 * @param onEditClick 編集ボタンを押した時のアクションを渡します
 * @param UserCollection ユーザー名を検索するための情報を渡します。
 * @returns
 */
const TableBody = (
  tableList: AttendanceHistoryList[],
  onEditClick: (date: number) => void
) => {
  const table = tableList.map(tableData => {
    var tempArr = [];
    var userName = "";
    if (userName === null) return;
    tempArr.push(
      <View style={[styles.columnContainer, { height: 35 }]}>
        <Pressable
          style={[styles.addSideButton]}
          onPress={() => onEditClick(tableData.date)}
        >
          <Text style={{ color: "white", lineHeight: 20 }}>＋申請</Text>
        </Pressable>
      </View>
    );
    // 日付
    tempArr.push(
      <TableCell
        dataType="number"
        value={tableData.date}
        color={tableData.isDateColor}
      />
    );
    // 出勤時間
    tempArr.push(
      <TableCell
        dataType="time"
        value={tableData.startWork}
        color={tableData.isStartWorkColor}
      />
    );
    // 退勤時間
    tempArr.push(
      <TableCell
        dataType="time"
        value={tableData.endWork}
        color={tableData.isEndWorkColor}
      />
    );
    // 申請有無
    tempArr.push(<TableCell dataType="string" value={tableData.request} />);

    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return table.filter(m => m !== undefined);
};

const styles = StyleSheet.create({
  columnContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  addSideButton: {
    backgroundColor: "#7879F1",
    height: 20,
    width: 50,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    shadowColor: "#000000",
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0,
    },
  },
});
