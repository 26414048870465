import { PluginFunc, default as dayjs, default as inst } from "dayjs";
import ja from "dayjs/locale/ja";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.locale({ ...ja, weekStart: 1 });
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

declare module "dayjs" {
  interface Dayjs {
    ceil(unit: any, amount: number): inst.Dayjs;
  }
}
const ceil: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.ceil = function (unit, amount) {
    if (this.get(unit) % amount === 0) {
      return this.startOf(unit);
    }
    return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
  };
};
dayjs.extend(ceil);

declare module "dayjs" {
  interface Dayjs {
    floor(unit: any, amount: number): inst.Dayjs;
  }
}
const floor: PluginFunc = (option, dayjsClass) => {
  dayjsClass.prototype.floor = function (unit, amount) {
    return this.subtract(this.get(unit) % amount, unit).startOf(unit);
  };
};

dayjs.extend(floor);
export default dayjs;
