import { StyleSheet } from "react-native";

/**
 * WEBのテーブルのレイアウト定義です
 */
export const webTableStyles = StyleSheet.create({
  MainContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  head: {
    height: 40,
    backgroundColor: "#C4C4C4",
    justifyContent: "center",
  },
  headText: { color: "#333", textAlign: "center", fontWeight: "bold" },
  text: { margin: 100, color: "red" },
  wrapper: { flexDirection: "row" },
});

/** モバイルのテーブルのレイアウト定義です */
export const mobileTableStyles = StyleSheet.create({
  /** スクロールビューの定義のCSSに使用します */
  scroll_view_style: {
    height: 300,
  },
  /** コンテンツのグループで使用します */
  contents_container: {
    padding: 10,
  },
  /** ２行の文字列を表示をする際にこのCSSを使用します */
  small_text: {
    fontSize: 14,
  },
  /** 文字列を描写する際は基本このCSSを使用します */
  base_text: {
    fontSize: 18,
  },
  /** 区切り線を表示するCSSで使用します */
  list_line_view: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
  },
});
