import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";

type Props = {
  /** ボタンのテキスト */
  buttonText: string;
  /** ボタンを押した時の処理 */
  onClick: () => void;
  /** 操作不可状態にする
   *
   * 操作不可：true
   *
   * 操作可能：false
   */
  disabled?: boolean;
};

/**
 * 承認系のモーダルボタンを作成します
 * @param param0
 * @returns
 */
export const ModalApprovalButton = ({
  buttonText,
  onClick,
  disabled,
}: Props) => {
  return (
    <Pressable
      style={[styles.button]}
      onPress={onClick}
      disabled={disabled ?? false}
    >
      <Text style={styles.buttonText}>{buttonText}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 32,
    paddingVertical: 16,
    width: "40%",
    alignItems: "center",
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
  },

  buttonText: {
    color: "#fff",
    fontWeight: "bold",
  },
});
