import { requestType } from "constants/index";
import dayjs from "dayjs";
import { CalendarDataWithRequest, RequestCollection } from "types";

export const GetViewRequest = (
  requests: RequestCollection[],
  monthBeginning: Date,
  monthEnd: Date
) => {
  // 承認済みの有給・振替休日・欠勤を取得
  return requests
    .filter(f => f.status === "approval")
    .filter(
      f =>
        f.requestType === "paidVacation" ||
        f.requestType === "absence" ||
        f.requestType === "substituteHoliday"
    )
    .filter(f =>
      dayjs(f.acquisitionStartAt?.toDate()).isBetween(
        monthBeginning,
        monthEnd,
        null,
        "[]"
      )
    )
    .map(
      m =>
        ({
          uid: m.id,
          title: requestType[m.requestType ?? ""]?.text ?? "",
          startDate: m.acquisitionStartAt,
          endDate: m.acquisitionEndAt,
          memo: m.memo,
          userId: m.userId,
          isHoliday: true,
          isAllDay: true,
          isRequest: true,
          paidVacationType: m.paidVacationType,
        } as CalendarDataWithRequest)
    );
};
