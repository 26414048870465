import dayjs from "dayjs";
import { PaidVacationCollection, RequestCollection } from "types";
import { RequestData } from "types/Request";
import { AscSort } from "utils";
import { GetBalancePaidVacation } from "./GetBalancePaidVacation";

/**
 * 使用できる有給管理を取得
 * @param paidVacations 有給管理情報
 * @param requests 申請情報
 * @param request 登録予定のデータ
 * @returns 使用できる有給情報
 */
export const GetCanUsePaidVacation = (
  paidVacations: PaidVacationCollection[],
  requests: RequestCollection[],
  request: RequestData
): PaidVacationCollection | null => {
  // 現在指定されている有給管理が利用可能であれば、そのまま返却
  if (GetBalancePaidVacation(requests, request.paidVacationId ?? "")) {
    const target = paidVacations
      .filter(f => f.id === request.paidVacationId)
      .filter(f => {
        const count = GetBalancePaidVacation(requests, f.id);
        return count < (f.grantCount ?? 0);
      })
      .shift();
    if (target !== undefined) return target;
  }

  // 有給を利用する日に使用できるものが存在するかどうかを検証
  const targetPaidVacations = paidVacations
    .sort((a, b) => AscSort(a.grantAt?.toDate(), b.grantAt?.toDate()))
    .filter(f => f.userId === request.userId)
    .filter(
      f => dayjs(f?.grantAt?.toDate()).toDate() <= dayjs(new Date()).toDate()
    )
    .filter(
      f => dayjs(f?.lapseAt?.toDate()).toDate() >= dayjs(new Date()).toDate()
    );
  if (targetPaidVacations.length === 0) return null;

  const result = targetPaidVacations
    .filter(f => {
      const count = GetBalancePaidVacation(requests, f.id);
      return count < (f.grantCount ?? 0);
    })
    .shift();

  if (result === undefined) return null;

  return result;
};
