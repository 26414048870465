import React from "react";
import { Pressable, StyleSheet, Text } from "react-native";

type Props = {
  /** オブジェクトに表示するテキストを定義します */
  buttonText: string;
  /** オブジェクトの背景色を定義します */
  buttonColor: string;
};

/**
 * ボタンのような見た目のステータス表記に使用します
 * @param param0
 * @returns
 */
export const MobileTableViewStatus = ({ buttonColor, buttonText }: Props) => {
  return (
    <Pressable
      style={[styles.editButton, { backgroundColor: buttonColor }]}
      disabled={true}
    >
      <Text style={styles.buttonText}>{buttonText}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  editButton: {
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 20,
    elevation: 5,
    width: 100,
    height: 35,
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
});
