import { NoteBookCollection, NoteBookPageData } from "types";

/**
 * 指定したWikiページ情報を返却します
 * @param noteBook collectionContextから取得したWikiページ情報
 * @param targetId 指定のWikiページID
 * @returns NoteBookData
 */ export const FetchNoteBookPage = (
  NoteBookCollection: NoteBookCollection[] | null,
  targetId: string,
  targetPageId: string
): NoteBookPageData => {
  if (NoteBookCollection === null) return defaultData(targetId);

  const target = NoteBookCollection.filter(f => targetId === f.id).shift();
  if (target === undefined) return defaultData(targetId);
  const targetPage = target.notebookPage
    .filter(f => f.id === targetPageId)
    .shift();
  if (targetPage === undefined) return defaultData(targetId);
  return {
    originNoteBookId: target.id,
    noteBookId: target.id,
    bookName: target.bookName ?? "",
    id: targetPage.id,
    pageTitle: targetPage.pageTitle ?? "",
    sort: targetPage.sort ?? 0,
    text: targetPage.text ?? "",
    deleted: targetPage.deleted ?? false,
  };
};

const defaultData = (noteBookId: string) => {
  return {
    originNoteBookId: "",
    noteBookId: noteBookId,
    bookName: "",
    id: "",
    pageTitle: "",
    sort: 1,
    text: "",
    deleted: false,
  };
};
