import { TableCell } from "components";
import React from "react";
import { DimensionValue, ScrollView, View } from "react-native";
import { Text } from "react-native-elements";
import { Row, Table } from "react-native-table-component";
import { webTableStyles } from "styles";
import { UserList } from "types";

const tableHead = [
  "",
  "並び",
  "権限",
  "社員名",
  "入社日",
  "最終ログイン日",
  "離職日",
];

export const WebUserTable = ({
  onEditClick,
  tableList,
}: {
  onEditClick: (id: string) => void;
  tableList: UserList[];
}) => {
  if (tableList.length === 0)
    return (
      <View>
        <Text>登録済みのデータはありませんでした。</Text>
      </View>
    );
  return (
    <View style={[webTableStyles.MainContainer]}>
      <View style={{ alignSelf: "stretch", marginTop: 5, height: "600px" as DimensionValue }}>
        <Table
          borderStyle={{
            borderWidth: 0.5,
            borderColor: "#dadada",
          }}
        >
          <Row
            key={Math.random()}
            flexArr={tableHead.map(() => 1)}
            data={tableHead}
            textStyle={webTableStyles.headText}
          />
        </Table>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Table borderStyle={{ borderWidth: 0.5, borderColor: "#dadada" }}>
            {TableBody(tableList, onEditClick)}
          </Table>
        </ScrollView>
      </View>
    </View>
  );
};

const TableBody = (
  tableList: UserList[],
  onEditClick: (id: string) => void
) => {
  const tableBody = tableList.map(tableData => {
    var tempArr = [];

    // 編集ボタン
    tempArr.push(
      <TableCell
        dataType="button"
        buttonText="編集"
        onEditClick={() => onEditClick(tableData.id)}
      />
    );
    // 並び順
    tempArr.push(<TableCell dataType="number" value={tableData.sort} />);
    // 権限
    tempArr.push(
      <TableCell dataType="userAuthority" value={tableData.authorityTitle} />
    );
    // 社員名
    tempArr.push(<TableCell dataType="string" value={tableData.name} />);
    // 入社日
    tempArr.push(<TableCell dataType="date" value={tableData.entryAt} />);
    // 最終ログイン日
    tempArr.push(<TableCell dataType="date" value={tableData.signedAt} />);
    // 退勤日
    tempArr.push(<TableCell dataType="date" value={tableData.retirementAt} />);

    return (
      <Row
        key={Math.random()}
        flexArr={tableHead.map(() => 1)}
        data={tempArr}
        textStyle={webTableStyles.text}
      />
    );
  });
  return tableBody.filter(m => m !== undefined);
};
