import { WebAttendanceHistoryTable } from "components";
import React from "react";
import { View } from "react-native";
import { AttendanceHistoryList } from "types";

export const AttendanceHistoryTable = ({
  tableList,
  onEditClick,
}: {
  tableList: AttendanceHistoryList[] | null;
  onEditClick: (date: number) => void;
}) => {
  return (
    <>
      {tableList === null ? (
        <View></View>
      ) : (
        <WebAttendanceHistoryTable
          onEditClick={onEditClick}
          tableList={tableList}
        />
      )}
    </>
  );
};
