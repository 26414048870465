import { DialogContext, UserInfoContext, WindowContext } from "GlobalContext";
import {
  Dialog,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  SelectForm,
} from "components";
import { expirationList } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";
import { UpdateExpiration } from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import { ExpirationModalStatus } from "types";
type Props = {
  hideModal: Function;
  modalStatus: ExpirationModalStatus;
};

export function ExpirationForm({ hideModal, modalStatus }: Props) {
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  const { setAlert } = useContext(DialogContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expiration, setExpiration] = useState<number | null>(null);

  useEffect(() => {
    if (modalStatus === null) {
      setExpiration(null);
      return;
    }

    if (modalStatus.expiration === null) {
      setExpiration(2);
      return;
    }
    setExpiration(modalStatus.expiration);
  }, [modalStatus]);

  const updateButtonClick = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const result = await UpdateExpiration(userInfo, expiration ?? 2);
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: result.message,
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>有給休暇有効期限</Text>
        <Loading isLoading={isLoading} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={modalFormStyles.scrollContainer}
        >
          <View style={[formWrap(windowWidth).formWrap]}>
            <SelectForm
              label="付与日から"
              placeholderLabel="選択してください"
              value={expiration ?? ""}
              onChange={e => setExpiration(isNaN(parseInt(e as string)) ? null : parseInt(e as string))}
              items={expirationList}
            />

            <View style={modalFormStyles.formItem}>
              <ModalCancelButton
                buttonText="キャンセル"
                onClick={() => hideModal()}
              />

              <ModalApprovalButton
                buttonText="更新"
                onClick={() => updateButtonClick()}
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <Dialog />
    </>
  );
}
