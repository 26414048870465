import React, { ReactNode, createContext, useEffect, useState } from "react";
import { Dimensions } from "react-native";
export const WindowContext = createContext(
  {} as {
    /** ウインドウサイズ */
    windowWidth: number;
    /** ウインドウサイズを格納します */
    setWindowWidth: React.Dispatch<React.SetStateAction<number>>;
    /** ウインドウサイズ */
    windowHeight: number;
    /** ウインドウサイズを格納します */
    setWindowHeight: React.Dispatch<React.SetStateAction<number>>;
  }
);

export const WindowProvider = ({ children }: { children: ReactNode }) => {
  const { width, height, scale } = Dimensions.get("window");
  const [windowWidth, setWindowWidth] = useState<number>(width);
  const [windowHeight, setWindowHeight] = useState<number>(width);

  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      "change",
      ({ window, screen }) => {
        const { width, height, scale } = Dimensions.get("window");
        setWindowWidth(width);
        setWindowHeight(height);
      }
    );
  });
  return (
    <WindowContext.Provider
      value={{ windowWidth, setWindowWidth, windowHeight, setWindowHeight }}
    >
      {children}
    </WindowContext.Provider>
  );
};
