import firebase from "firebase/compat/app";
import {
  CreateCheckResult,
  PaidVacationCollection,
  RequestCollection,
  RequestData,
  UserInfo,
} from "types";
import {
  GetCanUsePaidVacation,
  HasExistingPaidVacationRequest,
} from "../paidVacation/utils";

/**
 * 各種申請の更新の書き込みを行います
 * @param request 更新予定の各種申請データ
 * @param paidVacations collectionContextから取得した有給管理情報
 * @returns　CreateCheckResult
 */
export const UpdateRequest = async (
  userInfo: UserInfo,
  request: RequestData,
  paidVacations: PaidVacationCollection[] | null,
  requests: RequestCollection[] | null
): Promise<CreateCheckResult> => {
  if (requests === null || paidVacations === null)
    return {
      isCheck: false,
      message: "更新に失敗しました。",
    };

  const date = new Date();
  // 有給休暇の場合は、申請できるかどうかを判定する
  if (
    request.requestType === "paidVacation" &&
    request.status !== "reject" &&
    request.status !== "withdrawal"
  ) {
    const targetPaidVacation = GetCanUsePaidVacation(
      paidVacations,
      requests,
      request
    );
    if (targetPaidVacation === null) {
      return {
        isCheck: false,
        message: "取得可能な有給がありません",
      };
    }

    if (
      HasExistingPaidVacationRequest(
        requests,
        request.id,
        request.userId ?? "",
        request.acquisitionStartAt
      )
    )
      return {
        isCheck: false,
        message: "すでに指定された日付で有給申請が存在しています。",
      };

    request.paidVacationId = targetPaidVacation.id;
  }

  const doc = await firebase
    .firestore()
    .collection("request")
    .doc(request.id)
    .get();

  await firebase
    .firestore()
    .collection("request")
    .doc(request.id)
    .collection("requestHistory")
    .add({
      updateAt: date,
      updateUser: userInfo.user.id,
      status: request.status,
    });

  const result = await doc.ref
    .update({
      ...doc.data(),
      acquisitionStartAt: request.acquisitionStartAt.toDate(),
      acquisitionEndAt: request.acquisitionEndAt.toDate(),
      status: request.status,
      description: request.description,
      memo: request.memo,
      managerDescription: request.managerDescription,
      correctionTargetAttendanceId: request.correctionTargetAttendanceId,
      isAttendanceCancel: request.isAttendanceCancel,
      updateAt: date,
      updateUser: userInfo.user.id,
      paidVacationId: request.paidVacationId,
      ...(request.requestType === "paidVacation" && {
        paidVacationType: request.paidVacationType,
      }),
    })
    .then(async () => ({ isCheck: true, message: "更新しました" }))

    .catch(() => ({ isCheck: false, message: "更新に失敗しました" }));

  await new Promise(resolve => setTimeout(resolve, 1000));
  return result;
};
