import {
  CalendarCollection,
  CreateCheckResult,
  RequestCollection,
  SiteCollection,
  SiteData,
  UserInfo,
} from "types";

/**
 * 現場を更新する際に重複した契約・案件番号が存在していないかをチェックします
 * @param reqUser userContextから取得したユーザー情報
 * @param site collectionContextから取得した現場情報
 * @param siteData 更新・登録対象の現場情報
 * @param isUpdate true:更新、false:新規
 * @returns CreateCheckResult
 */
export const CheckRepetitionSite = (
  reqUser: UserInfo | null,
  site: SiteCollection[] | null,
  calendar: CalendarCollection[] | null,
  request: RequestCollection[] | null,
  siteData: SiteData,
  isUpdate: boolean
): CreateCheckResult => {
  const date = new Date();

  if (site !== null) {
    var sites = site
      .filter(f => f.deleted === false)
      .filter(
        f =>
          f.matterNumber == siteData.matterNumber ||
          f.agreementNumber == siteData.agreementNumber
      )
      .filter(f => f.agreementNumber !== "" && f.matterNumber !== "");

    if (isUpdate) sites = sites.filter(f => f.id !== siteData.id);
    let errorMessage = "";
    if (sites.length > 0) {
      sites
        .filter(f => f.matterNumber === siteData.matterNumber)
        .find(f => {
          errorMessage =
            "既に案件番号「" +
            f.matterNumber +
            "」は、「" +
            f.name +
            "」として既に登録されています";
        });
      sites
        .filter(f => f.agreementNumber === siteData.agreementNumber)
        .find(f => {
          errorMessage +=
            "\n" +
            "既に契約番号「" +
            f.agreementNumber +
            "」は、「" +
            f.name +
            "」として既に登録されています";
        });
      if (errorMessage !== "") return { isCheck: false, message: errorMessage };
    }

    if (siteData.deleted === true) {
      const requestChecked = request?.find(f => f.siteId === siteData.id);
      if (requestChecked)
        return {
          isCheck: false,
          message: `申請機能で${siteData.name}は使用されています。\n選択肢を消したい場合はステータスで「キャンセル」を指定してください。`,
        };
      const calendarChecked = calendar?.find(f => f.site?.id === siteData.id);

      if (calendarChecked)
        return {
          isCheck: false,
          message: `カレンダー機能で${siteData.name}は使用されています。\n選択肢を消したい場合はステータスで「キャンセル」を指定してください。`,
        };
    }
  }
  return { isCheck: true, message: "" };
};
