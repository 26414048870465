import firebase from "firebase/compat/app";
import { CreateCheckResult, UserData } from "types";
import { AscSort } from "utils";

/**
 * ユーザーの並び順の更新を行います
 * @param data 更新・登録対象のユーザー情報
 * @returns
 */
export const UpdateUserSort = async (
  data: UserData
): Promise<CreateCheckResult> => {
  const doc = await firebase.firestore().collection("user").get();
  const docs = doc.docs.sort((a, b) =>
    AscSort(a.data().sort ?? 0, b.data().sort ?? 0)
  );

  var index = 1;
  var retireIndex = 10001;
  const result = await Promise.all(
    docs.map(async d => {
      // 変更予定の並びを取得
      const currentIndex =
        d.data().retirementAt === null || d.data().retirementAt === undefined
          ? index++
          : retireIndex++;
      // 並びに変更がなければそのままスルー
      if (d.data().sort === currentIndex) return true;

      return await d.ref
        .update({
          ...d.data(),
          sort: currentIndex,
        })
        .then(e => true)
        .catch(e => false);
    })
  );
  return result.length === 0 || result.find(f => f)
    ? { isCheck: true, message: "更新しました" }
    : { isCheck: false, message: "更新に失敗しました" };
};
