import {
  ACCOUNTING,
  EMPLOYEE,
  HEAD,
  PART_TYPE,
  PRESIDENT,
  userAuthority,
} from "constants/index";
import { UserAuthorityType, UserInfo } from "types";

/** 全ての権限の一覧を返却します */
export const allAuthorities = (): string[] => {
  return [PART_TYPE, EMPLOYEE, ACCOUNTING, HEAD, PRESIDENT];
};

/**
 * アルバイトのみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isPartTime = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, PART_TYPE);
};

/**
 * 社員のみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isEmployee = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, EMPLOYEE);
};

/**
 * 経理のみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isAccounting = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, ACCOUNTING);
};

/**
 * 部長のみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isHead = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, HEAD);
};

/**
 * 社長のみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isPresident = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, PRESIDENT);
};

/**
 * 社長・経理のみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isPresidentOrAccounting = (userInfo: UserInfo | null): boolean => {
  return (
    checkAuthority(userInfo, PRESIDENT) || checkAuthority(userInfo, ACCOUNTING)
  );
};

/**
 * 社長・部長のみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isPresidentOrHead = (userInfo: UserInfo | null): boolean => {
  return checkAuthority(userInfo, PRESIDENT) || checkAuthority(userInfo, HEAD);
};

/**
 * 社長・部長・経理のみ対象
 * @param userInfo ログイン中のユーザー情報
 * @returns 判定結果
 */
export const isPresidentOrHeadOrAccounting = (
  userInfo: UserInfo | null
): boolean => {
  return (
    checkAuthority(userInfo, PRESIDENT) ||
    checkAuthority(userInfo, HEAD) ||
    checkAuthority(userInfo, ACCOUNTING)
  );
};

/**
 * 指定の権限を有しているかどうかをチェックします
 * @param userInfo ログイン中のユーザー情報
 * @param targetAuthority 指定された権限情報
 * @returns
 */
const checkAuthority = (
  userInfo: UserInfo | null,
  targetAuthority: string
): boolean => {
  if (!userInfo) {
    return false;
  }

  const authority: UserAuthorityType = userAuthority[userInfo.authorityId];
  if (authority.id === targetAuthority) {
    return true;
  } else {
    return false;
  }
};
