import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import { CheckBox } from "react-native-elements";

type Props = {
  /** フォームの名前を指定します */
  label: string;
  /** 選択の値を定義します */
  checked: boolean;
  /** 変更の処理を定義します */
  onChange?: () => void;
  /**
   *  デフォルト：false
   *
   * 操作可能：false 操作不可：true */
  disabled?: boolean;
};

/**
 * チェックボックスフォームを作成します
 * @param param0
 * @returns
 */
export const CheckBoxForm = ({ checked, onChange, disabled, label }: Props) => {
  return (
    <View
      style={[
        styles.container,
        Platform.OS !== "web"
          ? {
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingBottom: 20,
            }
          : {},
      ]}
    >
      <Text style={styles.label}>{label}</Text>
      <CheckBox
        checked={checked}
        onPress={onChange}
        containerStyle={[styles.checkbox]}
        disabled={disabled ?? false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
  },
  label: {
    fontSize: 20,
  },
  checkbox: {
    marginTop: 10,
    borderWidth: 0,
    padding: 0,
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  able: {
    color: "black",
    backgroundColor: "white",
  },
  disable: {
    color: "gray",
    backgroundColor: "darkgray",
  },
});
