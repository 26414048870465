import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
} from "GlobalContext";
import { DateForm, InputForm, PageLoading } from "components";
import dayjs from "dayjs";
import firebase from "firebase/compat/app";
import { RootStackScreenProps } from "navigations";
import React, { useContext, useState } from "react";
import { Button, StyleSheet, Text, View } from "react-native";
import { FetchLatestAttendance } from "server/collectionConnect";
import { FetchTargetMonthRequestList } from "server/collectionConnect/administration/fetchTargetMonthRequestList";
import { AttendanceCollection, CreateCheckResult, UserInfo } from "types";

/** AllSmileが勤怠データを検証する際に使用します */
export const AddAttendanceDataScreen =
  ({}: RootStackScreenProps<"AddAttendanceData">) => {
    const { userInfo } = useContext(UserInfoContext);
    const {
      AttendanceCollection,
      onSnapShot,
      UserCollection,
      RequestCollection,
    } = useContext(CollectionContext);
    const { setAlert } = useContext(DialogContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, setData] = useState<{
      attendanceType: string;
      startDate: Date;
      endDate: Date;
      addressStart: string;
      addressEnd: string;
    }>({
      attendanceType: "normal",
      startDate: new Date(),
      endDate: new Date(),
      addressStart: "",
      addressEnd: "",
    });

    React.useEffect(() => {
      if (
        AttendanceCollection === null ||
        UserCollection === null ||
        RequestCollection === null
      ) {
        if (AttendanceCollection === null) onSnapShot("attendance");
        if (UserCollection === null) onSnapShot("user");
        if (RequestCollection === null) onSnapShot("request");
        setIsLoading(true);
        return;
      }
      setIsLoading(false);
    }, [AttendanceCollection]);

    const buttonClick = async () => {
      try {
        setIsLoading(true);
        const result = await CreateAttendance(
          userInfo,
          data.attendanceType,
          data.startDate,
          data.endDate,
          data.addressStart,
          data.addressEnd,
          AttendanceCollection ?? []
        );
        setIsLoading(false);
        setAlert({
          isAlert: true,
          msg: result.message,
        });
      } catch {
        setIsLoading(false);
      }
    };

    const button2Click = async () => {
      try {
        setIsLoading(true);
        const result = await FetchTargetMonthRequestList(
          RequestCollection ?? [],
          UserCollection ?? [],
          data.startDate,
          data.endDate
        );
        console.log(result);
        setIsLoading(false);
      } catch {
        setIsLoading(false);
      }
    };

    if (isLoading) return <PageLoading isLoading={isLoading} />;

    return (
      <View style={styles.container}>
        <DateForm
          value={data.startDate ?? new Date()}
          onChange={e => setData({ ...data, startDate: e })}
          disabled={false}
          label={"出勤日"}
          type={"datetime"}
        />
        <DateForm
          value={data.endDate ?? new Date()}
          onChange={e => setData({ ...data, endDate: e })}
          disabled={false}
          label={"退勤日"}
          type={"datetime"}
        />
        <InputForm
          label="出勤住所"
          value={data.addressStart}
          onChange={e => setData({ ...data, addressStart: e })}
          disabled={true}
        />
        <InputForm
          label="退勤住所"
          value={data.addressEnd}
          onChange={e => setData({ ...data, addressEnd: e })}
          disabled={true}
        />
        <Button
          onPress={() => {
            buttonClick();
          }}
          title="追加"
        />
        <Button
          onPress={() => {
            button2Click();
          }}
          title="勤怠ダウンロード"
        />
        <h2>勤怠の申請履歴ダウンロードの仕様について</h2>
        <ol>
          <li>
            <Text>手順１：出勤日・退勤日を指定範囲に設定</Text>
          </li>
          <li>
            <Text>手順２：勤怠ダウンロードをクリック</Text>
          </li>
          <li>
            <Text>手順３：F12をクリックし、開発者タブを開く</Text>
          </li>
          <li>
            <Text>{`手順４：コンソールタブを開くと、[{...}]のような行が出てきるので、右クリック→オブジェクトのコピー`}</Text>
          </li>
          <li>
            <Text>
              手順５：コピー後、
              <a
                href="https://products.aspose.app/cells/ja/conversion/json-to-csv"
                rel="noreferrer"
                target="_blank"
              >
                ここ
              </a>
              に貼り付ける
            </Text>
          </li>
          <li>
            <Text>手順６：指定の形式を選択し、ダウンロード</Text>
          </li>
        </ol>
      </View>
    );
  };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    paddingTop: 32,
  },
  calendarHeader: {
    flexDirection: "row",
    marginBottom: 24,
  },
  month: {
    fontSize: 21,
    paddingHorizontal: 32,
    paddingTop: 4,
  },
  button: {
    padding: 16,
    borderRadius: 4,
    elevation: 3,
    backgroundColor: "#4169e1",
  },
  smallBtn: {
    paddingVertical: 8,
    backgroundColor: "#4289cb",
  },
  buttonTxt: {
    fontWeight: "bold",
  },
});

/**
 * 勤怠の新規作成の書き込みを行います
 * @param userInfo userContextから取得したユーザー情報
 * @param attendanceType 勤怠種類
 * @param attendanceList CollectionContextから取得した勤怠情報
 * @returns　CreateCheckResult
 */
const CreateAttendance = async (
  userInfo: UserInfo | null,
  attendanceType: string,
  startDate: Date,
  endDate: Date,
  addressStart: string,
  addressEnd: string,
  attendanceList: AttendanceCollection[]
): Promise<CreateCheckResult> => {
  const latest = await FetchLatestAttendance(userInfo);
  // 本日の出退勤情報を取得
  if (
    dayjs(startDate) >= dayjs().startOf("day") &&
    dayjs(startDate) <= dayjs().endOf("day") &&
    latest &&
    latest?.endBreakSchedule
  ) {
    return { isCheck: false, message: "指定日は既に退勤済です" };
  }

  return await firebase
    .firestore()
    .collection("attendance")
    .add({
      userId: userInfo?.user.uid,
      attendanceType: attendanceType,
      startWorkSchedule: startDate,
      endWorkSchedule: endDate,
      openingAddress: addressStart,
      terminationAddress: addressEnd,
    })
    .then(() => ({ isCheck: true, message: "登録が完了しました" }))
    .catch(() => ({ isCheck: false, message: "登録に失敗しました" }));
};
