import { StyleSheet } from "react-native";
import { IsWeb } from "utils";

/** モーダルの外枠で使用します */
export const modalStyles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    top: 30,
    right: 30,
  },
  closeText: {
    fontSize: 48,
  },
});

/** モーダルの実際の入力フォームで使用します */
export const modalFormStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    marginTop: IsWeb() ? 100 : "20%",
  },
  scrollContainer: {
    height: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  headingText: {
    fontSize: 32,
  },
  formItem: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingBottom: 25,
  },
  formItemCenter: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 25,
  },
});

/** 入力フォームの横幅をPlatFormによって切り分けます
 * @param width 現在の横幅（WindowProviderから取得）
 * @returns
 */
export const formWrap = (width: number) =>
  StyleSheet.create({
    formWrap: IsWeb()
      ? {
          width: width,
          paddingLeft: width * 0.35,
          paddingRight: width * 0.35,
        }
      : { marginTop: "10%" },
  });
