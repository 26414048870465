import React from "react";
import { StyleSheet, TouchableHighlight } from "react-native";
import FontistoIcon from "react-native-vector-icons/Fontisto";
import { IsMobile } from "utils";
type Props = {
  /** クリック時の処理を定義します */
  onClick: () => void;
  /** 削除処理中のクリック時の処理を定義します */
  onDeleteClick: () => void;
  /** 削除処理中かどうか
   *
   * 削除処理中：true
   */
  deleting: boolean;
};

/**
 * 右下の追加ボタンを作成します
 * @param param0
 * @returns
 */
export const DeleteButton = ({
  onDeleteClick,
  onClick,
  deleting,
}: Props): JSX.Element => {
  return (
    <TouchableHighlight
      style={[styles.deleteButton]}
      underlayColor="#7879F1"
      onPress={() => (deleting ? onDeleteClick() : onClick())}
    >
      <FontistoIcon name="trash" size={30} color={deleting ? "red" : "black"} />
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  deleteButton: {
    bottom: IsMobile() ? 30 : 10,
    height: 50,
    width: 50,
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 20,
    shadowColor: "#000000",
    shadowOpacity: 0.8,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0,
    },
  },
});
