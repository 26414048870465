import firebase from "firebase/compat/app";
import { ExpenseCollection, History, ImageData, PaymentHistory } from "types";

/**
 * expense Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const ExpenseOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<ExpenseCollection[] | null>>
) => {
  return firebase
    .firestore()
    .collection("expense")
    .onSnapshot(
      async docs => {
        const collectionList = await Promise.all(
          await docs.docs.map(async doc => {
            const expenseHistory = await GetExpenseHistory(doc);
            const paymentHistory = await GetPaymentHistory(doc);
            const images = await GetImages(doc);
            return {
              ...doc.data(),
              id: doc.id,
              expenseHistory: expenseHistory,
              paymentHistory: paymentHistory,
              images: images,
            } as ExpenseCollection;
          })
        );
        setState(collectionList);
      },
      (err: any) => console.log(err)
    );
};

/**
 * requestコレクション内のHistoryを取得するメソットです
 * @param doc：firebase.collectionオブジェクト
 * @returns History
 */
const GetExpenseHistory = async (doc: firebase.firestore.DocumentSnapshot) => {
  return await doc.ref
    .collection("expenseHistory")
    .get()
    .then(details =>
      details.docs.map(
        detail => ({ ...detail.data(), id: detail.id } as History)
      )
    )
    .catch(() => [] as History[]);
};

/**
 * requestコレクション内のHistoryを取得するメソットです
 * @param doc：firebase.collectionオブジェクト
 * @returns History
 */
const GetPaymentHistory = async (doc: firebase.firestore.DocumentSnapshot) => {
  return await doc.ref
    .collection("paymentHistory")
    .get()
    .then(details =>
      details.docs.map(
        detail => ({ ...detail.data(), id: detail.id } as PaymentHistory)
      )
    )
    .catch(() => [] as History[]);
};

/**
 * requestコレクション内のimagesを取得するメソットです
 * @param doc：firebase.collectionオブジェクト
 * @returns History
 */
const GetImages = async (doc: firebase.firestore.DocumentSnapshot) => {
  return await doc.ref
    .collection("images")
    .get()
    .then(details =>
      details.docs.map(
        detail => ({ ...detail.data(), id: detail.id } as ImageData)
      )
    )
    .catch(() => [] as ImageData[]);
};
