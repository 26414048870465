import dayjs from "dayjs";
import * as authority from "server/collectionConnect/utils/authority";
import {
  ExpenseApprovalWaitList,
  ExpenseCollection,
  ExpenseItemCollection,
  ExpenseLimitSettingCollection,
  SiteCollection,
  UserInfo,
} from "types";
import { AscSort, DescSort } from "utils";
import { GetSiteName } from "utils/GetSiteName";
import { GetSortStatusNumber } from "../utils/request";
/**
 * 整形した経費申請承認待ち情報を返却します
 * @param userInfo userContextから取得したユーザー情報
 * @param expenses collectionContextから取得した経費情報
 * @param targetMonth 対象月
 * @param sites collectionContextから取得した現場情報
 * @returns ExpenseList[]
 */
export const FetchExpenseApprovalWaits = (
  userInfo: UserInfo | null,
  expenses: ExpenseCollection[] | null,
  targetMonth: Date,
  expenseItems: ExpenseItemCollection[] | null,
  expenseLimitSettings: ExpenseLimitSettingCollection[] | null,
  sites: SiteCollection[]
): ExpenseApprovalWaitList[] => {
  if (expenses === null) return [] as ExpenseApprovalWaitList[];

  const expenseList = expenses
    .filter(
      f => dayjs(f.requestAt?.toDate()) >= dayjs(targetMonth).startOf("month")
    )
    .filter(
      f => dayjs(f.requestAt?.toDate()) <= dayjs(targetMonth).endOf("month")
    )
    .filter(f =>
      authority
        .targetDispApprovalWaitExpense(userInfo)
        .includes(f.requestAuthorityId ?? "")
    )
    .filter(f => f.status !== "withdrawal")
    .sort((a, b) => {
      if (
        GetSortStatusNumber(a.status ?? "") !==
        GetSortStatusNumber(b.status ?? "")
      ) {
        return AscSort(
          GetSortStatusNumber(a.status ?? ""),
          GetSortStatusNumber(b.status ?? "")
        );
      }

      if (a.updateAt !== b.updateAt) {
        return DescSort(a.updateAt, b.updateAt);
      }

      return 0;
    });

  return expenseList.map(doc => {
    const sortHistory = doc.expenseHistory.sort((a, b) =>
      DescSort(a.updateAt, b.updateAt)
    );

    const approveAt = sortHistory.filter(f => f.status === "approval").shift();
    const rejectAt = sortHistory.filter(f => f.status === "reject").shift();

    const receivedAt = sortHistory.filter(f => f.status === "received").shift();

    let paymentAt = null as FirebaseFirestore.Timestamp | null;
    let paymentAmountTotal = 0;

    const sortPaymentHistory = doc.paymentHistory.sort((a, b) =>
      AscSort(a.updateAt ?? 0, b.updateAt ?? 0)
    );

    sortPaymentHistory.forEach(doc => {
      paymentAt = doc.paymentAt;
      paymentAmountTotal += doc.paymentAmount ?? 0;
    });

    return {
      id: doc.id,
      status: doc.status,
      paymentStatus: doc.paymentStatus,
      requestUser: doc.createUser,
      siteName: GetSiteName(sites, doc.siteId),
      totalAmount: doc.totalAmount,
      paymentAmount: paymentAmountTotal,
      requestAt: doc.requestAt,
      reckoningAt: doc.reckoningAt,
      approveAt: approveAt === undefined ? null : approveAt.updateAt,
      rejectAt: rejectAt === undefined ? null : rejectAt.updateAt,
      paymentAt: paymentAt,
      receivedAt: receivedAt === undefined ? null : receivedAt.updateAt,
      createUser: doc.createUser,
      updateUser: doc.updateUser,
    } as ExpenseApprovalWaitList;
  });
};
