import { RequestCollection, RequestData } from "types";
import { AscSort } from "utils";

/**
 * 指定した勤怠申請情報を返却します
 * @param requests collectionContextから取得した申請情報
 * @param targetId 指定ID
 * @returns RequestData | null
 */
export const FetchRequest = (
  requests: RequestCollection[] | null,
  targetId: string
): RequestData | null => {
  if (requests === null) return null;
  const request = requests.filter(request => request.id === targetId).shift();

  if (request === undefined) return null;

  const sortHistory = request.requestHistory
    .filter(history => history.status === "approval")
    .sort((a, b) => AscSort(a ?? 0, b ?? 0));

  const approveAt = sortHistory
    .filter(f => f.status === "approval")
    .splice(1, 1);

  const rejectAt = sortHistory.filter(f => f.status === "reject").splice(1, 1);

  return {
    id: request.id,
    userId: request.userId,
    requestType: request.requestType,
    requestAt: request.requestAt,
    acquisitionStartAt: request.acquisitionStartAt,
    acquisitionEndAt: request.acquisitionEndAt,
    requestAuthorityId: request.requestAuthorityId,
    status: request.status,
    description: request.description,
    memo: request.memo,
    managerDescription: request.managerDescription,
    isAttendanceCancel: request.isAttendanceCancel ?? false,
    correctionTargetAttendanceId: request.correctionTargetAttendanceId ?? false,
    updateUser: request.updateUser,
    approveAt: approveAt[0] ? approveAt[0].updateAt : null,
    rejectAt: rejectAt[0] ? rejectAt[0].updateAt : null,
    updateAt: request.updateAt,
    paidVacationId: request.paidVacationId,
    paidVacationType: request.paidVacationType,
  } as RequestData;
};
