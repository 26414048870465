import {
  CollectionContext,
  DialogContext,
  UserInfoContext,
  WindowContext,
} from "GlobalContext";
import {
  CheckBoxForm,
  DateForm,
  Dialog,
  ImageSlider,
  InputForm,
  Loading,
  ModalApprovalButton,
  ModalCancelButton,
  MultipleInputForm,
  SelectForm,
} from "components";
import { expenseRequestTypeList } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { KeyboardAvoidingView, ScrollView, Text, View } from "react-native";
import { Item } from "react-native-picker-select";
import {
  FetchExpense,
  FetchExpenseLimits,
  UpdateExpense,
} from "server/collectionConnect";
import { formWrap, modalFormStyles } from "styles";
import {
  ExpenseData,
  ExpenseItemCollection,
  ImageDataSources,
  RequestModalStatus,
  UserInfo,
} from "types";
import {
  ConvertTimeStampFromDate,
  GetUserName,
  isPresidentOrAccounting,
  numberFormat,
} from "utils";
type Props = {
  hideModal: Function;
  siteSelect: Item[];
  modalStatus: RequestModalStatus;
};

export function ExpenseApprovalForm({
  hideModal,
  modalStatus,
  siteSelect,
}: Props) {
  const { setAlert } = useContext(DialogContext);
  const {
    ExpenseLimitSettingCollection,
    ExpenseItemCollection,
    ExpenseCollection,
    UserCollection,
  } = useContext(CollectionContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { userInfo } = useContext(UserInfoContext);
  const { windowWidth } = useContext(WindowContext);
  if (userInfo == null) {
    setAlert({ isAlert: true, msg: "ユーザー情報が取得できませんでした" });
    return <></>;
  }
  const [isApproval, setIsApproval] = useState<boolean>(true);
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [imgDataSources, setImgDataSources] = useState<ImageDataSources>({
    data: [],
  });
  const [expense, setExpense] = useState<ExpenseData>(
    defaultStateValue(userInfo)
  );
  const [expenseItem, setExpenseItem] = useState<ExpenseItemCollection[]>([]);
  const [expenseItemSelect, setExpenseItemSelect] = useState<Item[]>([]);
  const [expenseLimitSelect, setExpenseLimitSelect] = useState<Item[]>([]);

  useEffect(() => {
    if (modalStatus === null) {
      setExpense(defaultStateValue(userInfo));
      return;
    }
    if (modalStatus.type === "expenseApprovalWait") {
      const result = FetchExpense(
        userInfo,
        ExpenseCollection,
        ExpenseLimitSettingCollection ?? [],
        ExpenseItemCollection ?? [],
        modalStatus.id
      );
      if (result === null) {
        setExpense(defaultStateValue(userInfo));
        return;
      }
      if (result.images !== undefined && result.images.length > 0) {
        setImgDataSources({ ...imgDataSources, data: result.images });
      }

      delete result.images;
      setExpense(result);
      setIsApproval(result.status !== "reject");
      setIsPayment(result.status === "paid");
    }
  }, [modalStatus]);

  const updateImgDataSources = (
    images: React.SetStateAction<ImageDataSources>
  ): void => {
    setImgDataSources(images);
  };

  const onPaymentDateChangeHandler = (evt: Date): void => {
    setExpense({
      ...expense,
      paymentAt: ConvertTimeStampFromDate(evt),
    });
  };

  // 経費種類情報の取得
  const fetchExpenseItems = async (): Promise<void> => {
    try {
      if (ExpenseItemCollection === null) return;
      setExpenseItem(ExpenseItemCollection);
      const itemSelect = ExpenseItemCollection.map(m => {
        return {
          key: m.id,
          label: m.name,
          value: m.id,
        } as Item;
      });
      setExpenseItemSelect(itemSelect);
    } catch {
      setAlert({ isAlert: true, msg: "経費種類情報の取得に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  // 経費枠情報の取得
  const fetchExpenseLimits = async (): Promise<void> => {
    try {
      const result = FetchExpenseLimits(
        expense.userId ?? "",
        ExpenseCollection,
        ExpenseLimitSettingCollection,
        expense.expenseItemId
      );
      if (result === null) {
        setAlert({ isAlert: true, msg: "経費枠情報の取得に失敗しました" });
        return;
      }

      if (ExpenseItemCollection === null) {
        setAlert({ isAlert: true, msg: "経費枠情報の取得に失敗しました" });
        return;
      }
      const result2 = result.map(r => {
        const matchExpenseItem = ExpenseItemCollection.find(
          u => u.id === r.itemId
        );
        return {
          key: r.id,
          label: `${matchExpenseItem?.name}:${numberFormat(
            r.amountLimit,
            ""
          )}(残額:${numberFormat(r.balanceAmount, "")})`,
          value: r.id,
        };
      });

      setExpenseLimitSelect(result2);
    } catch {
      setAlert({ isAlert: true, msg: "経費枠情報の取得に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenseItems();
  }, [ExpenseItemCollection]);

  useEffect(() => {
    if (expense.expenseItemId) {
      fetchExpenseLimits();
    }
  }, [expense, ExpenseLimitSettingCollection]);

  useEffect(() => {
    const matchExpenseItem: ExpenseItemCollection | undefined =
      expenseItem.find(u => u.id === expense.expenseItemId);
    if (
      matchExpenseItem &&
      !matchExpenseItem.isNoExpenseLimit &&
      expense.reckoningAt
    ) {
      // 経費種類が現場経費でない、かつ利用日が入力されている場合
      fetchExpenseLimits();
    }
  }, [expense.reckoningAt, expense.expenseItemId]);

  const updateExpense = async (): Promise<void> => {
    if (userInfo == null) {
      setAlert({ isAlert: true, msg: "ユーザー情報が取得できませんでした" });
      return;
    }
    setIsLoading(true);

    if (isPayment) {
      // 支払日が入力可能な場合は「支払済」で更新
      expense.status = "paid";
      expense.paymentStatus = "full";
      expense.paymentAmount = expense.totalAmount;
    } else {
      expense.status = isApproval ? "approval" : "reject";
    }

    try {
      const result = await UpdateExpense(
        userInfo,
        ExpenseCollection,
        ExpenseItemCollection,
        ExpenseLimitSettingCollection,
        expense,
        imgDataSources
      );
      if (result.isCheck) {
        setAlert({
          isAlert: true,
          msg: "更新しました",
          confTxt: "OK",
          afterExec: () => {
            hideModal();
          },
        });
      } else {
        setAlert({ isAlert: true, msg: result.message });
      }
    } catch {
      setAlert({ isAlert: true, msg: "更新に失敗しました" });
    } finally {
      setIsLoading(false);
    }
  };

  const isPaid = (): boolean => {
    return ["paid", "received"].includes(expense.status);
  };
  return (
    <>
      <View style={modalFormStyles.container}>
        <Text style={modalFormStyles.headingText}>経費申請承認</Text>
        <Loading isLoading={isLoading} />
        <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={modalFormStyles.scrollContainer}
          >
            <View style={[formWrap(windowWidth).formWrap]}>
              <SelectForm
                label="申請種類"
                placeholderLabel="選択してください"
                value="expenseRequest"
                onChange={e => setExpense({ ...expense, requestType: e })}
                items={expenseRequestTypeList}
                disabled={true}
              />
              <InputForm
                label="申請者名"
                value={
                  GetUserName(UserCollection ?? [], expense.userId ?? "") ?? ""
                }
                onChange={() => {}}
                disabled={false}
              />

              <DateForm
                value={expense.requestAt.toDate()}
                disabled={true}
                label={"申請日"}
                type={"date"}
              />

              <DateForm
                value={expense.reckoningAt?.toDate() || new Date()}
                disabled={true}
                label={"利用日"}
                type={"date"}
              />

              <SelectForm
                label="現場名"
                placeholderLabel="選択してください"
                value={expense.siteId ?? ""}
                onChange={e => setExpense({ ...expense, siteId: e })}
                items={siteSelect}
                disabled={true}
              />
              <InputForm
                label="金額"
                value={numberFormat(expense.totalAmount, "\xA5")}
                onChange={() => {}}
                disabled={false}
              />
              <SelectForm
                label="経費種類"
                placeholderLabel="選択してください"
                value={expense.expenseItemId ?? ""}
                onChange={e => setExpense({ ...expense, expenseItemId: e })}
                items={expenseItemSelect}
                disabled={true}
              />

              <SelectForm
                label="経費枠"
                placeholderLabel="選択してください"
                value={expense.expenseLimitSettingId ?? ""}
                onChange={e =>
                  setExpense({ ...expense, expenseLimitSettingId: e })
                }
                items={expenseLimitSelect}
                disabled={true}
              />

              <MultipleInputForm
                label="申請事由"
                value={expense.description}
                onChange={e => {
                  setExpense({ ...expense, description: e });
                }}
                disabled={false}
              />
              <MultipleInputForm
                label="備考"
                value={expense.memo}
                onChange={e => {
                  setExpense({ ...expense, memo: e });
                }}
                disabled={false}
              />

              <CheckBoxForm
                label="承認"
                checked={isApproval}
                onChange={() => {
                  setIsApproval(true);
                  setExpense({
                    ...expense,
                    status: "approval",
                  });
                }}
                disabled={
                  ["applying", "approval", "reject"].includes(expense.status)
                    ? false
                    : true
                }
              />
              <CheckBoxForm
                label="却下"
                checked={!isApproval}
                onChange={() => {
                  setIsApproval(false);
                  setIsPayment(false);
                  setExpense({
                    ...expense,
                    status: "reject",
                  });
                }}
                disabled={
                  ["applying", "approval", "reject"].includes(expense.status)
                    ? false
                    : true
                }
              />

              <DateForm
                value={
                  expense.approveAt &&
                  (expense.status !== "reject") === isApproval
                    ? expense.approveAt.toDate()
                    : new Date()
                }
                disabled={true}
                label={isApproval ? "承認日" : "否決日"}
                type={"date"}
              />

              <MultipleInputForm
                label="コメント"
                value={expense.managerDescription}
                onChange={e => {
                  setExpense({ ...expense, managerDescription: e });
                }}
                disabled={
                  !["paid", "received", "withdrawal"].includes(expense.status)
                }
              />
              {isApproval && isPresidentOrAccounting(userInfo) && (
                <>
                  <CheckBoxForm
                    label="支払"
                    checked={isPayment}
                    onChange={() => {
                      setIsPayment(!isPayment);
                    }}
                    disabled={
                      !isApproval ||
                      ["paid", "received", "reject", "withdrawal"].includes(
                        expense.status
                      )
                    }
                  />

                  {isPayment && (
                    <DateForm
                      value={expense.paymentAt?.toDate() ?? new Date()}
                      onChange={onPaymentDateChangeHandler}
                      disabled={!isPayment || isPaid()}
                      label={"支払日"}
                      type={"date"}
                    />
                  )}
                </>
              )}

              {imgDataSources.data.length > 0 && (
                <ImageSlider
                  parentImgDataSources={imgDataSources}
                  setParentImgDataSources={updateImgDataSources}
                  editable={false}
                />
              )}

              <View style={modalFormStyles.formItem}>
                <ModalCancelButton
                  buttonText="キャンセル"
                  onClick={() => hideModal()}
                />

                {modalStatus?.isEdit && (
                  <ModalApprovalButton
                    buttonText="更新"
                    onClick={() => updateExpense()}
                  />
                )}
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </View>
      <Dialog />
    </>
  );
}

/**
 * モーダル内のデフォルトのデータ
 * @param userInfo
 * @returns
 */
const defaultStateValue = (userInfo: UserInfo): ExpenseData => {
  return {
    id: "",
    userId: userInfo.user.id,
    siteId: "",
    requestType: "expenseRequest",
    requestAuthorityId: userInfo.authorityId,
    requestAt: ConvertTimeStampFromDate(new Date()),
    reckoningAt: null,
    totalAmount: 0,
    status: "applying",
    description: "",
    memo: "",
    managerDescription: "",
    approveAt: null,
    paymentAt: null,
    paymentAmount: null,
    paymentStatus: "unpaid",
    expenseItemId: "",
    isNoExpenseLimit: false,
    expenseLimitSettingId: "",
  };
};
