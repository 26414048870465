import firebase from "firebase/compat/app";
import { CompanyCollection } from "types";

/**
 * company Collectionのデータをバインディングする関数です。
 * @param setState useState関数のsetメソッド
 * @returns
 */
export const CompanyOnSnapShot = (
  setState: React.Dispatch<React.SetStateAction<CompanyCollection | null>>
) => {
  return firebase
    .firestore()
    .collection("company")
    .doc("company")
    .onSnapshot(
      doc => {
        const collection = {
          id: doc.id,
          ...doc.data(),
        } as CompanyCollection;
        setState(collection);
      },
      (err: any) => console.log(err)
    );
};
