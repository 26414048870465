import firebase from "firebase/compat/app";
import { CreateCheckResult, ExpenseLimitSettingData, UserInfo } from "types";

/**
 * 経費枠の新規作成の書き込みを行います
 * @param reqUser userContextから取得したユーザー情報
 * @param expenseLimitSettingData 登録対象の現場情報
 * @returns　CreateCheckResult
 */
export const CreateExpenseLimitSetting = async (
  reqUser: UserInfo | null,
  expenseLimitSettingData: ExpenseLimitSettingData
): Promise<CreateCheckResult> => {
  const date = new Date();

  return await firebase
    .firestore()
    .collection("expenseLimitSetting")
    .add({
      userId: expenseLimitSettingData.userId,
      itemId: expenseLimitSettingData.itemId,
      amountLimit: expenseLimitSettingData.amountLimit,
      grantDate: expenseLimitSettingData.grantDate.toDate(),
      expirationDate: expenseLimitSettingData.expirationDate.toDate(),
      deleted: false,
      createAt: date,
      updateAt: date,
    })
    .then(() => {
      return { isCheck: true, message: "登録しました" };
    })
    .catch(error => {
      return { isCheck: false, message: "登録に失敗しました" };
    });
};
