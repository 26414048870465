import React from "react";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";

type Props = {
  /** ローディングの時のテキストを定義します */
  text?: string;
  /** 表示・非表示を定義します */
  isLoading: boolean;
};

/**
 * ローディングを作成します
 * @param props
 * @returns
 */
export const Loading = (props: Props) => {
  const { text, isLoading } = props;
  if (!isLoading) {
    return null;
  }

  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" />
      <Text style={styles.text}>{text ? text : "処理中"}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255,255,255,0.9)",
    zIndex: 100,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 40,
  },
  text: {
    fontSize: 16,
    margin: 20,
  },
});
