import { CreateLeftCol } from "components";
import dayjs from "dayjs";
import React from "react";
import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import { PaidVacationList, PaidVacationSettingType, UserCollection } from "types";
import { GetUserName } from "utils";

const CreateCol = ({ children }: { children?: React.ReactNode }) => {
  return <View style={styles.tableColumn}>{children}</View>;
};

const CreateRow = ({ children }: { children?: React.ReactNode }) => (
  <View style={styles.tableRow}>{children}</View>
);

export const MobileValidTable = ({
  onEditClick,
  tableList,
  UserCollection,
}: {
  onEditClick: (paidVacation: PaidVacationList) => void;
  tableList: PaidVacationSettingType | null;
  UserCollection: UserCollection[];
}) => {
  return (
    <ScrollView style={{ height: 300 }} showsVerticalScrollIndicator={false}>
      <View>
        <View style={styles.ListLineView} />
        {tableList?.paidVacationList?.map((paidVacation, i) => (
          <View key={i}>
            <View style={[styles.tableContainer, { padding: 10 }]}>
              <CreateRow>
                <CreateLeftCol>
                  <Pressable
                    style={[styles.editButton, { backgroundColor: "#7879F1" }]}
                    onPress={() => onEditClick(paidVacation)}
                  >
                    <Text style={styles.buttonText}>編集</Text>
                  </Pressable>
                </CreateLeftCol>
                <CreateCol>
                  <Text style={styles.tableText}>{`付与日：${dayjs(
                    paidVacation.grantAt
                  ).format("YYYY/MM/DD")}`}</Text>
                </CreateCol>
              </CreateRow>
              <CreateRow>
                <CreateLeftCol>
                  <Text style={styles.subTitle}>
                    {GetUserName(UserCollection, paidVacation.userId ?? "")}
                  </Text>
                </CreateLeftCol>
                <CreateCol>
                  <View>
                    <Text style={styles.tableText}>
                      {`失効予定日：${dayjs(paidVacation.lapseAt).format(
                        "YYYY/MM/DD"
                      )}`}
                    </Text>
                    <Text
                      style={styles.tableText}
                    >{`付与日数：${paidVacation.grantCount}`}</Text>
                  </View>
                </CreateCol>
              </CreateRow>
            </View>
            <View style={styles.ListLineView} />
          </View>
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  tableText: {
    fontSize: 14,
  },
  tableContainer: {
    marginHorizontal: "auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColumn: {
    borderColor: "#fff",
    borderWidth: 1,
    flex: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  editButton: {
    top: 0,
    left: 0,
    right: 0,
    borderRadius: 20,
    elevation: 5,
    width: 100,
    height: 35,
    textAlign: "center",
  },
  buttonText: {
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
  },
  ListLineView: {
    borderBottomColor: "black",
    borderBottomWidth: 1,
    marginTop: 10,
  },
  subTitle: {
    fontSize: 18,
  },
});
