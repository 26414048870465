import dayjs from "dayjs";
import {
  GetDiffString,
  GetRequestString,
} from "server/collectionConnect/utils/downLoad";
import {
  AttendanceCollection,
  AttendanceDownloadExcelOutputDataType,
  AttendanceHistoryBase,
  HolidayCollection,
  IsRequest,
  RequestCollection,
  UserCollection,
  UserInfo,
} from "types";
import { GetAttendanceHistory } from "../attendance";
/**
 *　最新の勤怠情報を返却します
 *
 * 有給管理のプルダウンのユーザーリストに使用します
 * @param userInfo userContextから取得した情報
 * @param attendances collectionContextから取得した勤怠情報
 * @returns
 */
export const FetchAllUsersDownLoadList = (
  userInfo: UserInfo | null,
  users: UserCollection[] | null,
  attendances: AttendanceCollection[] | null,
  requests: RequestCollection[] | null,
  holidays: HolidayCollection[] | null,
  targetDate: Date
): AttendanceDownloadExcelOutputDataType[] => {
  if (
    attendances === null ||
    requests === null ||
    holidays === null ||
    users === null ||
    userInfo === null
  )
    return [];

  var data: AttendanceHistoryBase[] = [];
  // 全ユーザー分の勤怠データを取得
  users.map(f => {
    const baseAttendances = GetAttendanceHistory(
      f.id,
      attendances,
      requests,
      holidays,
      users,
      targetDate
    );
    if (baseAttendances === null) return;
    // 勤怠が存在しているか、申請が存在している時のみ対象に追加
    const filters = baseAttendances.data.filter(
      f =>
        f.startWork !== null ||
        f.originalStartWork !== null ||
        checkIsRequest(f.request)
    );
    if (filters.length !== 0) data = data.concat(filters);
  });
  const result = data.map(m => {
    return {
      userName: m.userName,
      documentId: m.id,
      scheduleStartWork:
        m.scheduleStartWork !== null
          ? dayjs(m.scheduleStartWork).format("YYYY/MM/DD HH:mm")
          : "",
      actualStartWork:
        m.beforeOriginalStartWork !== null
          ? dayjs(m.beforeOriginalStartWork).format("YYYY/MM/DD HH:mm")
          : "",
      startWork:
        m.startWork !== null
          ? dayjs(m.startWork).format("YYYY/MM/DD HH:mm")
          : "",
      scheduleEndWork:
        m.scheduleEndWork !== null
          ? dayjs(m.scheduleEndWork).format("YYYY/MM/DD HH:mm")
          : "",
      actualEndWork:
        m.beforeOriginalEndWork !== null
          ? dayjs(m.beforeOriginalEndWork).format("YYYY/MM/DD HH:mm")
          : "",
      endWork:
        m.endWork !== null ? dayjs(m.endWork).format("YYYY/MM/DD HH:mm") : "",
      breakTime: GetDiffString(m.restTime),
      actualWork: GetDiffString(m.workingTime),
      overTime: GetDiffString(m.overWorkingTime),
      overTimeLateNight: GetDiffString(m.midnightTime),
      alert: GetRequestString(m.request),
      startWorkAddress: m.openingAddress,
      endWorkAddress: m.terminationAddress,
      scheduleStartWorkColor: Color.Black,
      actualStartWorkColor: Color.Black,
      startWorkColor: Color.Black,
      scheduleEndWorkColor: Color.Black,
      actualEndWorkColor: Color.Black,
      endWorkColor: Color.Black,
      breakTimeColor: Color.Black,
      actualWorkColor: Color.Black,
      overTimeColor: Color.Black,
      overTimeLateNightColor: Color.Black,
    };
  });
  return result;
};

const checkIsRequest = (request: IsRequest) => {
  if (request.overtime !== undefined) return true;
  if (request.paidVacation !== undefined) return true;
  if (request.leaveEarly !== undefined) return true;
  if (request.absence !== undefined) return true;
  if (request.lateness !== undefined) return true;
  if (request.privateOuting !== undefined) return true;
  if (request.substituteAttendance !== undefined) return true;
  if (request.substituteHoliday !== undefined) return true;
  if (request.staggeredWorkHours !== undefined) return true;
  if (request.attendanceCorrection !== undefined) return true;
  if (request.holidayWork !== undefined) return true;
  return false;
};

export const Color = {
  Black: "00000000",
  Red: "00ff0000",
  Blue: "000000ff",
};
